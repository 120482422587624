/**
 * Internal Dependencies
 */
import axiosInstance, { httpErrorHandler } from '@/services/http.js';

const updateDataObjectsMetadata = (id, params) => {
  return axiosInstance.put(`/documents/${id}/dataObjects/metadata`, params)
  .then((res) => httpErrorHandler(res));
};

export default {
  updateDataObjectsMetadata,
};
