<template>
	<div class="authors">
		<slot />
	</div><!-- /.authors -->
</template>

<script>
export default {
	/**
	 * Name
	*/
	name: 'Authors',
}
</script>
