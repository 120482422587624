/**
 * External Dependencies
 */
import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_API_URL,
	withCredentials: true
});

// AUtomatically add token on Axios GET requests
axiosInstance.interceptors.request.use(function (config) {
	if (config.method !== 'get') return config; // Not a get request
	if (config.params?.token) return config; // Already contain a token params
	let currentUrlParams = new URLSearchParams(window.location.search);
	let requestedUrlParams = new URLSearchParams(config.url);
	let currentUrlToken = currentUrlParams.get('token');
	let requestedUrlToken = requestedUrlParams.get('token');
	let token = requestedUrlToken || currentUrlToken || undefined;
	if (!token) {
		if (config.params?.token) delete config.params.token;
	}	else {
		config.params = {
			// add your default ones
			token: token,
			// spread the request's params
			...config.params
		};
	}
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

export function httpErrorHandler(response) {
	const status = response.status;
	const error = response.data.err;
	
	if (status === 401) {
		throw new Error('HTTP ERROR: Access not granted.');
	} else if (status === 404) {
		throw new Error('HTTP ERROR: URL no found (route does not exist)');
	} else if (status === 503) {
		throw new Error('HTTP ERROR: Internal service error.');
	} else if (status !== 200) {
		throw new Error('Something went wrong.');
	}
	if (error) {
		throw new Error(response.data.res);
	}
	
	return response.data.res;
}

export default axiosInstance;
