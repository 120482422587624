<template>
  <svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 11.9999C19.2239 11.9999 19 12.2238 19 12.4999L19 17.4999C18.9991 18.328 18.3281 18.999 17.5 18.9999L2.5 18.9999C1.672 18.999 1.00092 18.328 1 17.4999L1 6.49994C1.00024 6.26202 1.06091 6.0401 1.15967 5.84027L8.23633 12.9169C8.7038 13.385 9.3385 13.6475 10 13.6464C10.6615 13.6476 11.2962 13.385 11.7637 12.9169L13.4033 11.2773C13.5938 11.0829 13.5938 10.7719 13.4033 10.5775C13.21 10.3802 12.8935 10.377 12.6962 10.5703L11.0566 12.2099C10.4724 12.7919 9.52753 12.7919 8.9433 12.2099L1.87445 5.14105C2.06537 5.05286 2.27594 5.00018 2.5 4.99994L10.5 4.99994C10.7762 4.99994 11 4.77606 11 4.49994C11 4.22382 10.7762 3.99994 10.5 3.99994L2.5 3.99994C1.11987 4.00122 0.0012817 5.11975 0 6.49994L0 17.4999C0.0012817 18.8801 1.11987 19.9987 2.5 19.9999L17.5 19.9999C18.8802 19.9987 19.9988 18.8801 20 17.4999L20 12.4999C20 12.2238 19.7762 11.9999 19.5 11.9999ZM20.8535 3.14648L17.8535 0.146484C17.8535 0.146423 17.8535 0.146362 17.8534 0.146301C17.6581 -0.0488281 17.3417 -0.0487671 17.1465 0.146484L14.1465 3.14648C13.956 3.34088 13.956 3.65198 14.1465 3.84637C14.3398 4.04358 14.6564 4.04675 14.8535 3.85345L17 1.70697L17 8.50049C17.0002 8.77649 17.2241 9.00012 17.5 8.99994L17.5006 8.99994C17.7766 8.99976 18.0002 8.77594 18 8.49994L18 1.70697L20.1465 3.85345C20.2402 3.94739 20.3674 4.00012 20.5 3.99994C20.6326 3.99994 20.7597 3.94727 20.8535 3.85358C21.0488 3.65833 21.0488 3.34174 20.8535 3.14648Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconInvite',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
