<template>
	<div class="form__entry alt-text">
		<slot />
	</div>
</template>

<script>
	export default {
		/**
		 * Name
		 */
		name: 'FormEntry'
	}
</script>

