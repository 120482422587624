/* eslint-disable */


/**
 * Internal Dependencies
 */
import { DATATYPE_COLORS } from '@/utils/use-dataObjects'
const XmlViewer = function(id, screenId, events = {}) {
	let self = this;
	this.containerId = id;
	this.screenId = screenId;
	// HTML elements
	this.screen = $(`#${this.screenId}`);
	this.screenElement = this.screen.get(0);
	this.viewerId = id + `Viewer`;
	this.container = $(`#${this.containerId}`);
	this.containerElement = this.container.get(0);
	this.viewer = $(`<div id="${this.viewerId}" class="xmlViewer"></div>`);
	this.viewerElement = this.viewer.get(0);
	this.dataInstancesList = null;
	this.dataInstancesListElement = null;
	this.dataObjectsList = null;
	this.dataObjectsListElement = null;
	this.activeDataObjectType = null;
	
	this.container.append(this.viewer);
	// Events
	this.events = events;
	this.sentencesMapping = { object: undefined, array: undefined };
	// Data
	this.dataObjects = null
	return this;
};

// Attach event
XmlViewer.prototype.attach = function(event, fn) {
	this.events[event] = fn;
};

// Get order of appearance of sentences
XmlViewer.prototype.getSentences = function(selectedSentences, lastSentence) {
	let sentences = [lastSentence].concat(selectedSentences),
		min = Infinity,
		max = -Infinity;
	for (let i = 0; i < sentences.length; i++) {
		let index = this.sentencesMapping.array.indexOf(sentences[i].id);
		min = index > -1 && index < min ? index : min;
		max = index > -1 && index > max ? index : max;
	}
	if (min !== Infinity && max !== -Infinity)
		return this.sentencesMapping.array.slice(min, max + 1);
	else return [];
};

// Get order of appearance of sentences
XmlViewer.prototype.getSentencesMapping = function() {
	if (typeof this.sentencesMapping.object !== `undefined`) return this.sentencesMapping.object;
	let result = {};
	this.viewer.find(`s[xml\\:id]`).map(function(i, el) {
		result[$(el).attr(`xml:id`)] = i;
	});
	this.sentencesMapping.object = result;
	this.sentencesMapping.array = new Array(Object.keys(result).length);
	for (var key in result) {
		this.sentencesMapping.array[parseInt(result[key])] = key;
	}
	return result;
};

// Get last sentences index
XmlViewer.prototype.getSentencesIndexesLimit = function() {
	return {
		min : this.sentencesMapping.object[this.sentencesMapping.array[0]],
		max: this.sentencesMapping.object[this.sentencesMapping.array[this.sentencesMapping.array.length - 1]]
	};
}

// hoverSentence
XmlViewer.prototype.hoverSentence = function(sentence) {
	return this.viewer.find(`s[xml\\:id="${sentence.id}"]`).addClass(`hover`);
};

// endHoverSentence
XmlViewer.prototype.endHoverSentence = function(sentence) {
	return this.viewer.find(`s[xml\\:id="${sentence.id}"]`).removeClass(`hover`);
};

// Scroll to a sentence
XmlViewer.prototype.scrollToSentence = function(sentence, cb) {
	if (sentence && sentence.id) {
		let el = this.viewer.find(`s[xml\\:id="${sentence.id}"]`).first(),
			position = el.position(),
			top = position ? position.top : 0;
		return cb(top);
	} else return cb(0);
};

// Select a sentence
XmlViewer.prototype.selectSentence = function(sentence) {
	let el = this.viewer
		.find(`s[xml\\:id="${sentence.id}"]`)
		.removeClass(`hover`)
		.addClass(`selected`);
};

// Unselect a sentence
XmlViewer.prototype.unselectSentence = function(sentence) {
	let el = this.viewer.find(`s[xml\\:id="${sentence.id}"]`).removeClass(`selected`);
};

// Get infos of a sentence
XmlViewer.prototype.getInfoOfSentence = function(sentence) {
	let el = this.viewer.find(`s[xml\\:id="${sentence.id}"]`);
	if (el.get(0)) {
		let hasDataObjects = typeof el.attr(`corresp`) !== `undefined`;
		let dataInstanceIds = hasDataObjects ? el.attr(`corresp`).replace(/#/gm, ``).split(` `) : [];
		return {
			id: el.attr(`xml:id`),
			dataInstanceIds: dataInstanceIds,
			hasDataObjects: hasDataObjects,
			isSelected: el.hasClass(`selected`),
			text: el.text()
		};
	}
};

// Get infos of the first sentence
XmlViewer.prototype.getInfoOfFirstSentence = function(sentence) {
	return this.getInfoOfSentence({ id: this.sentencesMapping.array[0] });
};

// Add a dataObject
XmlViewer.prototype.addDataObject = function(dataObject, sentence) {
	let $dataObject = $(`<dataset id="${dataObject._id}">`),
		$dataInstance = $(`<dataInstance id="${dataObject.dataInstanceId}">`);
	if (this.dataObjectsList.find(`dataset[xml\\:id="${dataObject._id}"]`).length === 0)
		this.dataObjectsList.append($dataObject);
	if ( this.dataInstancesList.find(`dataInstance[xml\\:id="${dataObject.dataInstanceId}"]`).length === 0 )
		this.dataInstancesList.append($dataInstance);
	if ( this.dataObjects[dataObject.dataInstanceId] === undefined ) {
		this.dataObjects[dataObject.dataInstanceId] = dataObject;
	}
	
	this.addLink(dataObject, sentence);
};

// Remove a dataObject
XmlViewer.prototype.removeDataObject = function(dataObject) {
	let $dataObject = this.dataObjectsList.find(`dataset[xml\\:id="${dataObject._id}"]`),
		$dataInstance = this.dataInstancesList.find(
			`dataInstance[xml\\:id="${dataObject.dataInstanceId}"]`
		);
	if ($dataObject.length === 1) $dataObject.remove();
	if ($dataInstance.length === 1) $dataInstance.remove();
	if ( this.dataObjects[dataObject.dataInstanceId] !== undefined ) delete this.dataObjects[dataObject.dataInstanceId]
	
	this.removeLinks(dataObject);
};

// Set active dataObject type
XmlViewer.prototype.setActiveDataObjectType = function (dataObjectType) {
	const self = this;
	if (!dataObjectType) return;
	self.activeDataObjectType = dataObjectType;
	const dataObjects = self.dataObjects;

	// Color corresps
	this.viewer.find(`s[corresp]`).map(function() {
		const el = $(this);
		const dataInstanceIds = el
			.attr(`corresp`)
			.replace(/#/gm, ``)
			.split(` `);
		
		dataInstanceIds.map(function(dataInstanceId) {
			if (dataObjects[dataInstanceId]?.dataObjectType && dataInstanceIds.some((dataInstanceId) => dataObjects[dataInstanceId]?.dataObjectType === dataObjectType)) {
				self.colorize(dataObjects[dataInstanceId], { id: el.attr(`xml:id`) });
			} else {
				self.uncolorize(dataInstanceId, { id: el.attr(`xml:id`) });
			}
		});
	});
};

// colorize a sentence
XmlViewer.prototype.colorize = function(dataObject, sentence) {
	const self = this;
	const el = this.viewer.find(`s[xml\\:id="${sentence.id}"]`);
	const colors = DATATYPE_COLORS[self.activeDataObjectType];
	
	if (
		el.get(0) &&
		dataObject &&
		colors &&
		colors.foreground &&
		colors.background &&
		colors.background.rgb &&
		colors.background.border
	) {
		el.css(`color`, colors.foreground)
			.css(`background-color`, colors.background.rgb)
			.css(`border-color`, colors.background.border);
	}
};

// uncolorize a sentence
XmlViewer.prototype.uncolorize = function (dataInstanceId, sentence) {
	let el = this.viewer.find(`s[xml\\:id="${sentence.id}"]`);
	if (el.get(0)) {
		el.removeAttr(`colors`)
			.css(`color`, ``)
			.css(`background-color`, ``)
			.css(`border-color`, ``);
	}
};

// Add a Link
XmlViewer.prototype.addLink = function(dataObject, sentence) {
	let el = this.viewer.find(`s[xml\\:id="${sentence.id}"]`);
	if (el.get(0)) {
		if (!el.attr(`corresp`)) el.attr(`corresp`, `#${dataObject.dataInstanceId}`);
		else
			el.attr(
				`corresp`,
				(
					el.attr(`corresp`).replace(`#${dataObject.dataInstanceId}`, ``) +
					` #${dataObject.dataInstanceId}`
				).trim()
			);
		return this.colorize(dataObject, sentence);
	}
};

// Get all links
XmlViewer.prototype.getLinks = function(dataObject) {
	return this.viewer
		.find(`s[corresp]`)
		.get()
		.reduce(function(acc, item) {
			let el = $(item);
			if (el.attr(`corresp`).indexOf(`#${dataObject.dataInstanceId}`) > -1)
				acc.push({
					sentence: { id: el.attr(`xml:id`), text: el.text() },
					dataObject: { _id: dataObject._id, dataInstanceId: dataObject.dataInstanceId }
				});
			return acc;
		}, []);
};

// Remove links
XmlViewer.prototype.removeLinks = function(dataObject) {
	let self = this;
	dataObject.sentences.map(function(sentence) {
		self.removeLink(dataObject, sentence);
	});
};

// Remove a link
XmlViewer.prototype.removeLink = function(dataObject, sentence) {
	let el = this.viewer.find(`s[xml\\:id="${sentence.id}"]`);
	if (el.get(0)) {
		let nbCorresps = el.attr(`corresp`).split(` `).length;
		if (nbCorresps === 1) {
			el.removeAttr(`corresp`)
				.removeAttr(`colors`, ``)
				.css(`color`, ``)
				.css(`background-color`, ``)
				.css(`border-color`, ``);
		} else {
			el.attr(
				`corresp`,
				el
					.attr(`corresp`)
					.replace(`#${dataObject.dataInstanceId}`, ``)
					.trim()
			);
			this.uncolorize(dataObject.dataInstanceId, sentence);
		}
	}
};

// Render the XML
XmlViewer.prototype.load = function(opts = {}, cb) {
	let self = this;
	this.activeDataObjectType = opts.activeDataObjectType ? opts.activeDataObjectType : undefined;
	this.sentencesMapping = opts.mapping ? opts.mapping : {};
	this.viewer.html(opts.xmlString);
	// Init events
	this.viewer
		.find(`s`)
		.click(function() {
			let el = $(this);
			if (typeof self.events.onClick === `function`)
				return self.events.onClick({ id: el.attr(`xml:id`) });
		})
		.hover(
			// in
			function() {
				let el = $(this);
				if (typeof self.events.onHover === `function`)
					return self.events.onHover({ id: el.attr(`xml:id`) });
			},
			// out
			function() {
				let el = $(this);
				if (typeof self.events.onEndHover === `function`)
					return self.events.onEndHover({ id: el.attr(`xml:id`) });
			}
		);

	this.dataInstancesList = this.viewer.find(`list[type="dataInstance"]`);
	if (this.dataInstancesList.length === 0) {
		this.dataInstancesList = $(`<list type="dataInstance">`);
	}
	this.dataInstancesListElement = this.dataInstancesList.get(0);
	this.dataObjectsList = this.viewer.find(`list[type="dataset"]`);
	if (this.dataObjectsList.length === 0) {
		this.dataObjectsList = $(`<list type="dataset">`);
	}
	this.dataObjectsListElement = this.dataInstancesList.get(0);
	
	let dataObjects = {};
	let links = [];
	let dataInstances = {};
	
	this.dataInstancesList.find(`dataInstance`).map(function() {
		let el = $(this),
			dataObjectId = el.attr(`corresp`).replace(`#`, ``),
			dataInstanceId = el.attr(`xml:id`),
			cert = el.attr(`cert`),
			reuse = el.attr(`reuse`);
		dataInstances[dataObjectId] = dataInstanceId;
		dataObjects[dataInstanceId] = {
			dataInstanceId: dataInstanceId,
			_id: dataObjectId,
			cert: cert,
			reuse: reuse
		};
	});
	
	this.dataObjectsList.find(`dataset`).map(function() {
		let el = $(this),
			dataObjectId = el.attr(`xml:id`),
			type = el.attr(`type`),
			subtype = el.attr(`subtype`);
		dataObjects[dataInstances[dataObjectId]].color = opts.colors[dataObjectId];
		dataObjects[dataInstances[dataObjectId]].type = type;
		dataObjects[dataInstances[dataObjectId]].subtype = subtype;
		dataObjects[dataInstances[dataObjectId]].dataObjectType = opts.colors[dataObjectId]?.dataObjectType ? opts.colors[dataObjectId].dataObjectType : undefined;
	});

	this.dataObjects = dataObjects;
	
	// Color active sentences
	this.viewer.find(`s[corresp]`).map(function() {
		const el = $(this);
		const dataInstanceIds = el.attr(`corresp`).replace(/#/gm, ``).split(` `);
		
		dataInstanceIds.map(function(dataInstanceId) {
			if (!dataObjects[dataInstanceId]) return
			
			links.push({
				sentence: { id: el.attr(`xml:id`) },
				dataObject: dataObjects[dataInstanceId],
				dataObjectType: dataObjects[dataInstanceId].dataObjectType
			});
			
			// Colorize active sentences
			if ( dataObjects[dataInstanceId]?.dataObjectType === self.activeDataObjectType) {
				self.colorize(dataObjects[dataInstanceId], { id: el.attr(`xml:id`) });
			}
		});
	});

	return cb({
		colors: opts.colors,
		links: links,
		activeDataObjectType: opts.activeDataObjectType
	});
};

export default XmlViewer;
