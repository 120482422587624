<template>
	<svg width="20px" height="20px" version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fill-rule="evenodd">
			<g transform="translate(-1290 -271)" :fill="color" fill-rule="nonzero">
				<g transform="translate(1290 271)">
					<path
						d="m10.5 19h-7.5c-1.104-0.0014038-1.9986-0.896-2-2v-14c0.0014038-1.104 0.896-1.9986 2-2h6v3.662c0.0016479 1.2878 1.0452 2.3313 2.333 2.3329h3.667v2.5051c0 0.27612 0.22388 0.5 0.5 0.5s0.5-0.22388 0.5-0.5v-3c0-0.13263-0.052673-0.25977-0.14648-0.35352l-6-6c-0.09375-0.093811-0.22089-0.14648-0.35352-0.14648h-6.5c-1.6561 0.0018311-2.9982 1.3439-3 3v14c0.0018311 1.6561 1.3439 2.9982 3 3h7.5c0.27612 0 0.5-0.22388 0.5-0.5s-0.22388-0.5-0.5-0.5zm-0.5-17.293 4.288 4.288h-2.955c-0.73596-5.493e-4 -1.3324-0.59705-1.333-1.3329v-2.955zm9.8499 17.436-1.5575-1.5575c1.0247-1.3706 0.92761-3.3144-0.31781-4.5601-1.3852-1.3213-3.564-1.3213-4.9492 0-0.65662 0.65643-1.0254 1.5469-1.0254 2.4753 1.831e-4 1.9328 1.5672 3.4995 3.5 3.4993 0.75903-1.221e-4 1.485-0.25787 2.0861-0.70679l1.5568 1.5568c0.0023193 0.0023804 0.0046997 0.0046997 0.0070801 0.007019 0.1972 0.1933 0.51373 0.19012 0.70697-0.007019 0.1933-0.1972 0.19012-0.51373-0.007019-0.70703zm-2.5823-1.8754c-0.98883 0.94531-2.5463 0.94531-3.5352 0-0.46899-0.46887-0.73248-1.1049-0.73242-1.7681 1.221e-4 -1.3806 1.1194-2.4996 2.5-2.4995 0.66345-0.002063 1.3 0.26173 1.7676 0.73243l9.155e-4 9.155e-4c0.97571 0.9762 0.97528 2.5585-9.155e-4 3.5342zm-7.5294-0.26758v-0.24558h-0.3717c-0.41652 0-0.72496-0.09292-0.92531-0.27876-0.14236-0.13717-0.21353-0.4823-0.21353-1.0354v-6.8761h1.5105c0.44288 0 0.80009 0.060841 1.0716 0.18252 0.27153 0.12168 0.48902 0.31084 0.65246 0.56748 0.10018 0.15929 0.17926 0.42478 0.23726 0.79646h0.30053l-0.11863-2.1106h-8.7548l-0.12654 2.1106h0.30053c0.036907-0.40708 0.1529-0.73009 0.34798-0.96903 0.19508-0.23894 0.4297-0.40044 0.70387-0.48451 0.2109-0.061947 0.56678-0.09292 1.0677-0.09292h1.2891v6.8761c0 0.50442-0.057996 0.82743-0.17399 0.96903-0.18981 0.23009-0.51142 0.34513-0.96485 0.34513h-0.37961v0.24558h4.5475z"
					/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'IconDocumentCheck',

	/**
	 * props
	 */
	props: ['color']
};
</script>
