<template>
	<div
		ref="tabs"
		class="tabs"
		:class="['are-expanded']"
	>
		<div class="tabs__links">
			<ul id="dataObjectsSortableList" ref="dataObjectsSortableList">
				<li
					v-for="dataObject in sortedFilteredDataObjects"
					:key="dataObject._id"
					:dataObjectId="dataObject._id"
					class="tabs__link"
					:class="{
						'is-active': dataObject._id === activeDataObjectId,
						'is-completed': dataObject.actionRequired === 'No',
						'is-new': !dataObject.reuse,
						'is-optionnal': dataObject.kind === 'dataset' && (dataObject.representativeImage || dataObject.qc),
						'has-suggested-properties-filled':
						dataObject.actionRequired !== 'No' &&
						(dataObject.kind === 'code' || dataObject.kind === 'software' || dataObject.kind === 'reagent') &&
						(dataObject.suggestedEntity !== '' || dataObject.suggestedURL !== '' || dataObject.suggestedRRID !== ''),
						'has-issue': ( dataObject.flagged === true || dataObject.flagged === 'true')
					}"
				>
					<div v-if="mergeState || deleteState" class="checkbox checkbox--default">
						<input
							tabindex="0"
							type="checkbox"
							ref="checkbox"
							class="sr-only"
							:name="`merge-${dataObject._id}`"
							:id="`merge-${dataObject._id}`"
							:checked="dataObjectsForAction.some((entry) => entry === dataObject._id)"
							@change="(e) => handleCheckboxChange(e, dataObject._id)"
						/>

						<label :for="`merge-${dataObject._id}`" />
					</div><!-- /.checkbox -->

					<button
						:title="dataObject.name ? dataObject.name : dataObject._id"
						type="button"
						@click.prevent="handleLinkButtonClick(dataObject)"
					>
						<Dot v-if="( dataObject.flagged === true || dataObject.flagged === 'true' )" />

						<span>
							<Icon v-if="dataObject.actionRequired === 'No'" name="check" color="currentColor" />
						</span>
						
						<p class="overflow-truncate">{{dataObject.name ? dataObject.name : dataObject._id }}</p>
					</button>
				</li>
			</ul>
		</div> <!-- /.tabs__links -->
	</div> <!-- /.tabs -->
</template>

<script>
/**
 * External Dependencies
 */
import { mapGetters, mapActions } from 'vuex';
import Sortable from 'sortablejs';

/**
 * Internal Dependencies
 */
import Dot from '@/components/dot/dot';
import Icon from '@/components/icon/icon';

export default {
	/**
	 * Name
	 */
	name: 'Tabs',

	/**
	 * Components
	 */
	components: {
		Dot,
		Icon
	},

	/**
	 * Data
	 */
	data() {
		return {
			resizeDataObjectsSortableList: true,
			sortable: null,
			currentTabMargin: 85,
			minMargin: 72,
			maxMargin: 300,
			minTabWith: 400,
			tabWidth: 190,
			expandedTriggerPoint: 222,
			listMargin: 5, // margin of data object list
			// Parent Data
			parentWidth: 0,
			parentObserver: null
		}
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('pdfViewer', [
			'documentHandler',
			'dataObjects',
			'activeDataObject',
			'activeDataObjectType',
			'mergeState',
			'deleteState',
			'dataObjects',
			'dataObjectsForAction',
			'filteredDataObjects',
			'activeDataObjectId'
		]),
		sortedFilteredDataObjects() {
			return [].concat(this.filteredDataObjects).sort((a, b) => {
				if (a.index === b.index) return 0;
				return a.index < b.index ? -1 : 1;
			});
		},
		calculatedWidth(){
			if (this.currentTabMargin > this.maxMargin) {
				return this.maxMargin;
			} else if (this.currentTabMargin <= this.minMargin) {
				return this.minMargin;
			}

			return this.currentTabMargin;
		},
		areTabsExpanded() {
			return this.parentWidth >= this.tabsWidth + this.expandedTriggerPoint
		},
		tabsWidth() {
			return this.parentWidth - this.calculatedWidth <= this.minTabWith ? this.minTabWith : this.parentWidth - this.calculatedWidth
		}
	},
	
	/**
	 * Methods
	 */
	methods: {
		...mapActions('pdfViewer', [
			'addDataObjectForAction',
			'removeDataObjectForAction',
			'setActiveDataObject'
		]),
		handleCheckboxChange(e, dataObjectId) {
			if (e.target.checked) {
				this.addDataObjectForAction(dataObjectId);
			} else {
				this.removeDataObjectForAction(dataObjectId);
			}
		},
		initSortableList() {
			let el = document.getElementById('dataObjectsSortableList');
			this.sortable = Sortable.create(el, {
				draggable: 'li',
				// Element dragging ended
				onEnd: (evt) => {
					return this.refreshDataObjectIndex({
						dataObjectId: evt.item.getAttribute("dataObjectId"),
						from: evt.oldIndex,
						to: evt.newIndex
					})
				},
			});
		},
		refreshDataObjectIndex(infos) {
			// let mappingLimit = this.documentHandler.getSentencesIndexesLimit();
			// let limit = mappingLimit.pdf ? mappingLimit.pdf : mappingLimit.tei;
			let target = this.sortedFilteredDataObjects[infos.to];
			let source = this.sortedFilteredDataObjects[infos.from];
			let delta = infos.to - infos.from;
			if (delta === 0) return;
			let previousItem = undefined;
			let nextItem = undefined;
			const SORT_COEFF = 0.1;
			if (delta > 0) {
				// data object goes to the bottom of the list
				previousItem = target;
				if (infos.to < this.sortedFilteredDataObjects.length - 1) nextItem = this.sortedFilteredDataObjects[infos.to + 1];
			} else {
				// data object goes to the up of the list
				if (infos.to > 0) previousItem = this.sortedFilteredDataObjects[infos.to - 1];
				nextItem = target;
			}
			if (delta > 0) {
				// data object goes to the bottom of the list
				if (typeof nextItem === "undefined") source.index = this.strip(previousItem.index) + SORT_COEFF;
				else {
					let coeff = ((this.strip(nextItem.index) - this.strip(previousItem.index)) / 10);
					source.index = this.strip(nextItem.index) - (coeff > 0 ? coeff : SORT_COEFF);
				}
			} else {
				// data object goes to the up of the list
				if (typeof previousItem === "undefined") source.index = this.strip(nextItem.index) - SORT_COEFF;
				else {
					let coeff = ((this.strip(nextItem.index) - this.strip(previousItem.index)) / 10);
					source.index = this.strip(previousItem.index) + (coeff > 0 ? coeff : SORT_COEFF);
				}
			}
			// console.log(previousItem ? previousItem.id : null, source.id, nextItem ? nextItem.id : null, delta > 0 ? "bottom" : "up");
			source.index = this.strip(source.index);
			// console.log(previousItem ? previousItem.index : null, source.index, nextItem ? nextItem.index : null, delta > 0 ? "bottom" : "up");
			this.sortable.option("disabled", true);
			return this.documentHandler.saveDataObject(source, (_, res) => {
				this.sortable.option("disabled", false);
				console.log(_, res);
			});
		},
		strip(number) {
			let str = number.toString();
			let dotIndex = str.indexOf('.');
			if (dotIndex < 0) return number;
			return parseFloat(parseFloat(number).toPrecision(dotIndex + 5));
		},
		handleLinkButtonClick(dataObject) {
			if (this.activeDataObject && this.activeDataObject._id === dataObject._id && dataObject.sentences.length > 1) {
				let currentSentenceId = this.documentHandler.activeSentence.id;
				let nextSentence = dataObject.sentences.reduce(function (acc, item) {
					if (acc === false) acc = item;
					if (item.id === currentSentenceId) acc = false;
					return acc;
				}, false);
				let nextSentenceId = nextSentence === false ? dataObject.sentences[0].id : nextSentence.id;
				this.documentHandler.selectSentence({
					selectedDataObject: dataObject,
					sentences: dataObject.sentences,
					sentence: {
						id: nextSentenceId
					}
				});
			} else {
				this.setActiveDataObject({
					dataObject: dataObject,
					scrollToSentence: true
				});
			}
		}
	},
	
	/**
	 * Mounted
	 */
	mounted () {
		this.initSortableList();
		this.isLoading = false;
	},
};
</script>
