<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 29 29"
		version="1.1"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-176.000000, -406.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(109.000000, 60.000000)">
					<g transform="translate(46.000000, 293.000000)">
						<g transform="translate(0.000000, 46.000000)">
							<path
								d="M48.4736844,7 L21.7622264,7 C21.341055,7 21,7.3418939 21,7.76315858 L21,35.2376807 C21,35.6589454 21.3418934,36 21.7631581,36 L49.2377733,36 C49.6589448,36 50,35.6581075 50,35.2368428 L50,7.76222692 C50,7.34105549 48.8949491,7 48.4736844,7 Z M30.1578949,34.4736849 L22.526316,34.4736849 L22.526316,26.8421059 L30.1578949,26.8421059 L30.1578949,34.4736849 Z M30.1578949,25.3157902 L22.526316,25.3157902 L22.526316,17.6842112 L30.1578949,17.6842112 L30.1578949,25.3157902 Z M30.1578949,16.1578954 L22.526316,16.1578954 L22.526316,8.52631647 L30.1578949,8.52631647 L30.1578949,16.1578954 Z M39.3157896,34.4736849 L31.6842107,34.4736849 L31.6842107,26.8421059 L39.3157896,26.8421059 L39.3157896,34.4736849 Z M39.3157896,25.3157902 L31.6842107,25.3157902 L31.6842107,17.6842112 L39.3157896,17.6842112 L39.3157896,25.3157902 Z M39.3157896,16.1578954 L31.6842107,16.1578954 L31.6842107,8.52631647 L39.3157896,8.52631647 L39.3157896,16.1578954 Z M48.4736844,34.4736849 L40.8421054,34.4736849 L40.8421054,26.8421059 L48.4736844,26.8421059 L48.4736844,34.4736849 Z M48.4736844,25.3157902 L40.8421054,25.3157902 L40.8421054,17.6842112 L48.4736844,17.6842112 L48.4736844,25.3157902 Z M48.4736844,16.1578954 L40.8421054,16.1578954 L40.8421054,8.52631647 L48.4736844,8.52631647 L48.4736844,16.1578954 Z"
							></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
	export default {
		/**
		 * name
		 */
		name: 'iconDatasets',

		/**
		 * props
		 */
		props: {
			color: {},
			width: {
				default: 29
			},
			height: {
				default: 29
			}
		}
};
</script>
