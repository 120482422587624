<template>
	<svg
		width="17px"
		height="21px"
		viewBox="0 0 17 21"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-572.000000, -910.000000)" :stroke="color">
				<g transform="translate(172.000000, 151.000000)">
					<g transform="translate(391.000000, 752.000000)">
						<g transform="translate(10.000000, 8.000000)">
							<line x1="1.5" y1="0" x2="1.5" y2="2" stroke-linecap="round"></line>
							<line x1="1.5" y1="11" x2="1.5" y2="19" stroke-linecap="round"></line>
							<path
								d="M6.86030821,11 L0,11 L0,2 L8,2 C7.4264612,2.36162011 7.09312786,3.11869181 7,4.27121508"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
							<rect stroke-linejoin="round" x="7" y="4" width="8" height="9"></rect>
							<line x1="15" y1="7" x2="9" y2="13"></line>
							<line x1="13" y1="4" x2="7" y2="10"></line>
							<line x1="7" y1="6" x2="2" y2="11"></line>
							<line x1="6" y1="2" x2="3.41060513e-13" y2="8"></line>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
<script>
export default {
	/**
	 * name
	 */
	name: 'iconFlag',

	/**
	 * props
	 */
	props: ['color']
};
</script>
