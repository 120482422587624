<template>
	<svg width="24px" height="40px" viewBox="0 0 24 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M12,1.66738892 C17.7069615,1.66738892 21.0592206,5.1074888 21.7110785,11.3888651 L21.7434142,11.7280821 L21.75,11.8797628 L21.7507672,21.9037317 C22.5486848,23.427269 23,25.1609294 23,27 C23,33.0751322 18.0751322,38 12,38 C5.92486775,38 1,33.0751322 1,27 C1,20.9248678 5.92486775,16 12,16 C14.3217876,16 16.4755666,16.7193298 18.2506338,17.947286 L18.25,11.967 L18.227229,11.7231127 C17.7643718,7.25706953 15.8783364,5.25702622 12.2500843,5.17034085 L12,5.16738892 C8.21784165,5.16738892 6.24731695,7.21800328 5.77258152,11.933583 L5.74373796,12.2449055 L2.25626204,11.9490816 C2.81916081,5.31305139 6.17937145,1.66738892 12,1.66738892 Z M12,19 C7.581722,19 4,22.581722 4,27 C4,31.418278 7.581722,35 12,35 C16.418278,35 20,31.418278 20,27 C20,22.581722 16.418278,19 12,19 Z M12,24 C13.6568542,24 15,25.3431458 15,27 C15,28.6568542 13.6568542,30 12,30 C10.3431458,30 9,28.6568542 9,27 C9,25.3431458 10.3431458,24 12,24 Z" :fill="color"></path>
    </g>
</svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconLock',

	/**
	 * props
	*/
	props: ['color']
	}
</script>

