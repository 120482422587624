/**
 * Internal Dependencies
 */
import axiosInstance, { httpErrorHandler } from '@/services/http.js';

const updateOrCreateAnnotation = (params) => {
  return axiosInstance
    .put(`/hypothesis/bioRxiv`, params)
    .then((res) => httpErrorHandler(res));
};

export default {
  updateOrCreateAnnotation,
};

