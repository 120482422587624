<template>
	<Main
		:class="`main--datasets main--datasets-${activeDataObjectType}`"
	>
		<template #subheader>	
			<Subheader>
				<SubheaderDatasets />
			</Subheader>
		</template>

		<Resizer
			:leftStartingWidth="10"
			:leftMinWidth="10"
			:leftMinWidthPx="125"
			:leftMaxWidth="20"
			:middleStartingWidth="30"
			:middleMinWidth="20"
			:middleMinWidthPx="455"
			:middleMaxWidth="45"
			:rightStartingWidth="60"
			:rightMinWidth="35"
			:rightMaxWidth="70"
			forceRefreshTitle="Refresh the PDF view"
			@leftBarDragDropEnd="() => { this.dragDropEnd = true; }"
			@rightBarDragDropEnd="() => { this.dragDropEnd = true; }"
			@rightSideWidthChanged="() => { if (this.dragDropEnd && !this.refreshingPDF) { this.dragDropEnd = false; this.handleRefreshView(); } }"
			@windowResized="() => { if (!this.refreshingPDF) this.handleRefreshView(); }"
			@forceRefreshClick="() => { if (!this.refreshingPDF) this.handleRefreshView(); }"
		>
			<template #leftContainer>
				<Tabs :class="userRoleWeight >= 10 ? '' : 'full-height'" />
				<DatasetUtils v-if="userRoleWeight >= 10" />
			</template>
			<template #middleContainer>
				<div class="middleContainer">
					<div class="dataset-wrapper">
						<Loader
							:loading="loading"
							:error="error"
							:errorMessage="errorMessage"
						>
							<Dataset
								v-if="filteredDataObjects.length"
								:dataObject="activeDataObject"
								:readOnly="userRoleWeight < 10"
							/>
							<Intro v-if="!filteredDataObjects.length" />
						</Loader>
					</div>
				</div>
			</template>
			<template #rightContainer>
				<PDF ref="PDF" />
			</template>
		</Resizer>
	</Main>
</template>

<script>
/* eslint-disable */
/**
 * External Dependencies
 */
import { mapActions, mapGetters } from 'vuex'

/**
 * Internal Dependencies
 */
import PDF from '@/blocks/pdf/pdf';
import Tabs from '@/components/tabs/tabs';
import Main from '@/components/main/main';
import Intro from '@/components/intro/intro'
import Loader from '@/blocks/loader/loader';
import Dataset from '@/blocks/dataset/dataset'
import Subheader from '@/components/subheader/subheader';
import DatasetUtils from '@/components/datasets-utils/datasets-utils';
import SubheaderDatasets from '@/components/subheader/subheader-datasets';

import URLMANAGER from '@/lib/datasets/url';

import Resizer from '@/components/resizer/resizer';

import { DocumentView } from '@/lib/datasets/documentView';
import { DataObjectForm } from '@/lib/datasets/dataObjectForm';
import { DataObjectsList } from '@/lib/datasets/dataObjectsList';
import { DocumentHandler } from '@/lib/datasets/documentHandler';
import documentsService from '@/services/documents/documents';

import { formatDataObject, sortDataObjects } from '@/utils/use-dataObjects';

export default {
	/**
	 * Name
	 */
	name: 'Datasets',

	/**
	 * Components
	 */
	components: {	
		Loader,
		Subheader,
		SubheaderDatasets,
		PDF,
		Tabs,
		Main,
		Intro,
		Dataset,
		Resizer,
		DatasetUtils
	},
	
	/**
	 * Data
	 */
	data: function() {
		return {
			loading: false,
			dragDropEnd: false,
			refreshingPDF: false,
			error: false,
			errorMessage: "Something went wrong..."
		};
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', ['user', 'userRoleWeight']),
		...mapGetters('pdfViewer', [
			'dataObjects',
			'document',
			'documentHandler',
			'activeDataObject',
			'activeDataObjectId',
			'activeDataObjectType',
			'filteredDataObjects'
		]),
		documentId() {
			return this.$route.params.id
		},
		documentToken() {
			return this.document?.token ?? '' 
		},
	},

	/**
	 * Methods
	 */
	methods: {
		...mapActions('pdfViewer', [
			'setDocument',
			'setDocumentHandler',
			'setActiveDataObject',
			'setActiveDataObjectType',
			'setDataObjects',
			'setDataTypes',
			'setMergeState',
			'setActiveSentence',
			'clearState',
		]),
		handleRefreshView() {
			this.refreshingPDF = true;
			return this.documentHandler.refresh(() => {
				this.refreshingPDF = false;
			});
		},
		async initializePdfViewer() {
				const doc = await documentsService.getDocument(this.documentId, {
					dataObjects: true,
					metadata: true
				});
				document.title = doc.name;
				const token = doc.token;
				const pdf = await documentsService.getDocumentPdf(this.documentId, { token });
				const pdfURl = await documentsService.getDocumentPdfUrl(this.documentId, token);
				const tei = await documentsService.getDocumentTei(this.documentId, { token });
				const xml = await documentsService.getDocumentTeiContent(this.documentId, { token });
				const dataTypes = await documentsService.getJsonDataTypes({ token });

				let mappings = { 
					tei: tei && tei.res ? tei.res?.metadata?.mapping?.object : undefined,
					pdf: pdf && pdf.res ? pdf.res?.metadata?.mapping?.object : undefined
				};
				let mapping = mappings.pdf ? mappings.pdf : mappings.tei ? mappings.tei : {};

				const documentView = new DocumentView(`documentView`);
				const dataObjectsList = new DataObjectsList(`dataObjectsList`);
				const dataObjectForm = new DataObjectForm(`dataObjectForm`);

				const dataObjects = doc.dataObjects.current.sort(sortDataObjects(mapping));
				
				// Add color and datatype definitions
				for (let i = 0; i < dataObjects.length; i++) {
					formatDataObject(dataObjects[i]);
				}

				const activeDataObjectId = dataObjects[0]?._id;
				const activeDataObjectType = dataObjects[0]?.dataObjectType || this.activeDataObjectType;
	
				const currentDocument = new DocumentHandler({
					ids: {
						document: doc._id
					},
					user: this.user,
					datatypes: dataTypes,
					activeDataObjectId: activeDataObjectId,
					activeDataObjectType: activeDataObjectType,
					dataObjects: doc.dataObjects,
					metadata: doc.metadata,
					tei: { data: xml, metadata: tei.res.metadata },
					pdf: pdf && pdf.res ? { url: pdfURl, metadata: pdf.res.metadata } : undefined
				},
				{
					onDocumentViewReady: () => {
						this.loading = false;
					},
					onReady: () => {
						this.setActiveDataObjectType(activeDataObjectType);
						let params = Object.assign({}, URLMANAGER.getParamsOfCurrentURL(), this.$route.query);
						let dataObject = null;
						if (params.selectedDataObjectId) {
						  dataObject = currentDocument.getDataObject(params.selectedDataObjectId);
						} else {
							if (dataObjects.length > 0) dataObject = currentDocument.getDataObject(dataObjects[0]._id);
						}
						this.documentHandler.documentView.toggleDocumentView(this.documentHandler.documentView.pdfAvailable);
						this.$refs.PDF.setPdfView(this.documentHandler.documentView.pdfAvailable);
						return this.documentHandler.refresh(() => {
							if (dataObject !== null) return this.setActiveDataObject({
								dataObject: dataObject,
								scrollToSentence: true
							});
							// when there are no dataObjects in the document
							return setTimeout(() => { return this.documentHandler.refresh(); }, 1500);
						});
					},
					onSentenceClick: (dataObject, sentence) => {
						this.setActiveSentence(sentence)
						
						this.setActiveDataObject({
							dataObject: dataObject,
							scrollToSentence: false
						});
					},
				});

				this.setDocument(doc);
				this.setDocumentHandler(currentDocument);
				this.setDataTypes(dataTypes);
				this.setDataObjects(dataObjects);
	
				currentDocument.link({
					documentView: documentView,
					dataObjectsList: dataObjectsList,
					dataObjectForm: dataObjectForm
				});
			try {
			} catch (error) {
				this.loading = false;
				this.error = true
				this.errorMessage = error.message
			}
		}
	},

	/**
	 * Created
	 */	
	created () {
		this.clearState();
		
	},
	
	/**
	 * Mounted
	 */
	mounted () {
		this.initializePdfViewer();
	},

	/**
	 * Destroyed
	 */
	destroyed () {
		this.clearState();
	}
};
</script>
