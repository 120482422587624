<template>
	<svg width="18px" height="22px" viewBox="0 0 18 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-182.000000, -303.000000)" :fill="color" fillRule="nonzero">
				<g transform="translate(176.000000, 209.000000)">
					<path d="M23.4896851,100.287292 C23.4848023,100.224976 23.46875,100.166626 23.4414673,100.110229 C23.4332276,100.093262 23.4291382,100.076172 23.4190064,100.060242 C23.3798218,99.9980469 23.3303223,99.9417725 23.2636719,99.9003906 L15.2636719,94.9414062 C15.1019898,94.8417968 14.8980103,94.8417968 14.7363281,94.9414062 L6.7363281,99.9003906 C6.7227173,99.9088135 6.7155151,99.9242553 6.7028198,99.9338989 C6.6665649,99.9614258 6.6358643,99.9922485 6.6083374,100.028503 C6.5986328,100.04126 6.5831909,100.048523 6.574707,100.062195 C6.567749,100.073486 6.5674438,100.086426 6.5614624,100.098022 C6.5462036,100.127197 6.5366821,100.157654 6.5274048,100.189819 C6.5179444,100.223022 6.5097656,100.254944 6.5073853,100.288757 C6.5064087,100.301453 6.49999997,100.312256 6.49999997,100.325195 L6.49999997,109.674805 C6.49999997,109.847778 6.5893555,110.008423 6.7363281,110.099609 L14.7363281,115.058594 C14.7399292,115.060852 14.7446899,115.059875 14.748352,115.062012 C14.751892,115.064087 14.7532348,115.068298 14.7568359,115.070313 C14.8309937,115.112183 14.9147949,115.134033 15,115.133791 C15.0852051,115.134033 15.1690063,115.112183 15.2431641,115.070312 C15.2467652,115.068298 15.2481079,115.064087 15.251648,115.062012 C15.2553101,115.059875 15.2600709,115.060852 15.2636719,115.058594 L23.2636719,110.099609 C23.4105225,110.008301 23.5,109.847717 23.5,109.674805 L23.5,100.325195 C23.5,100.311645 23.4907837,100.300659 23.4896851,100.287292 Z M14.5,113.735352 L7.5,109.396484 L7.5,101.223633 L14.5,105.561523 L14.5,113.735352 Z M15,104.695312 L14.5944824,104.44397 L7.9492188,100.325195 L15,95.9541016 L22.0507812,100.325195 L16.8687133,103.537048 L15,104.695312 Z M22.5,109.396484 L15.5,113.735352 L15.5,105.561523 L22.5,101.223633 L22.5,109.396484 Z"></path>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconCube',

	/**
	 * props
	*/
	props: ['color']
	}
</script>

