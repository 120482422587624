<template>
  <svg width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <g id="list-ul">
      <path d="M0.636364 6.36364C0.284934 6.36364 0 6.64857 0 7C0 7.35143 0.284934 7.63636 0.636364 7.63636C0.987793 7.63636 1.27273 7.35143 1.27273 7C1.27273 6.64857 0.987793 6.36364 0.636364 6.36364ZM4.45455 1.27273L23.5455 1.27273C23.8969 1.27273 24.1818 0.987793 24.1818 0.636364C24.1818 0.284934 23.8969 0 23.5455 0L4.45455 0C4.10312 0 3.81818 0.284934 3.81818 0.636364C3.81818 0.987793 4.10312 1.27273 4.45455 1.27273ZM0.636364 12.7273C0.284934 12.7273 0 13.0122 0 13.3636C0 13.7151 0.284934 14 0.636364 14C0.987793 14 1.27273 13.7151 1.27273 13.3636C1.27273 13.0122 0.987793 12.7273 0.636364 12.7273ZM23.5455 6.36364L4.45455 6.36364C4.10312 6.36364 3.81818 6.64857 3.81818 7C3.81818 7.35143 4.10312 7.63636 4.45455 7.63636L23.5455 7.63636C23.8969 7.63636 24.1818 7.35143 24.1818 7C24.1818 6.64857 23.8969 6.36364 23.5455 6.36364ZM0.636364 0C0.284934 0 0 0.284934 0 0.636364C0 0.987793 0.284934 1.27273 0.636364 1.27273C0.987793 1.27273 1.27273 0.987793 1.27273 0.636364C1.27273 0.284934 0.987793 0 0.636364 0ZM23.5455 12.7273L4.45455 12.7273C4.10312 12.7273 3.81818 13.0122 3.81818 13.3636C3.81818 13.7151 4.10312 14 4.45455 14L23.5455 14C23.8969 14 24.1818 13.7151 24.1818 13.3636C24.1818 13.0122 23.8969 12.7273 23.5455 12.7273Z" :fill="color" stroke="none" />
    </g>
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconViewList',

	/**
	 * props
	*/
	props: ['color']
	}
</script>

