<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 17 20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.5278 4L12.2778 4L12.2778 2.5C12.2766 1.11981 11.2202 0.0012817 9.91667 0L7.08333 0C5.77982 0.0012817 4.72343 1.11981 4.72222 2.5L4.72222 4L0.472222 4C0.211439 4 0 4.22388 0 4.5C0 4.77612 0.211439 5 0.472222 5L1.88889 5L1.88889 17.5C1.8901 18.8802 2.94649 19.9987 4.25 20L12.75 20C14.0535 19.9987 15.1099 18.8802 15.1111 17.5L15.1111 5L16.5278 5C16.7886 5 17 4.77612 17 4.5C17 4.22388 16.7886 4 16.5278 4ZM5.66667 2.5C5.66753 1.67194 6.30127 1.00092 7.08333 1L9.91667 1C10.6987 1.00092 11.3325 1.67194 11.3333 2.5L11.3333 4L5.66667 4L5.66667 2.5ZM14.1667 17.5C14.1658 18.3281 13.5321 18.9991 12.75 19L4.25 19C3.46794 18.9991 2.8342 18.3281 2.83333 17.5L2.83333 5L14.1667 5L14.1667 17.5ZM6.13889 16L6.13947 16C6.40007 15.9998 6.61128 15.776 6.61111 15.5L6.61111 8.5C6.61111 8.22388 6.39967 8 6.13889 8C5.87811 8 5.66667 8.22388 5.66667 8.5L5.66667 15.5005C5.66684 15.7766 5.87822 16.0002 6.13889 16ZM10.8611 16L10.8617 16C11.1223 15.9998 11.3335 15.776 11.3333 15.5L11.3333 8.5C11.3333 8.22388 11.1219 8 10.8611 8C10.6003 8 10.3889 8.22388 10.3889 8.5L10.3889 15.5005C10.3891 15.7766 10.6004 16.0002 10.8611 16Z"
			:fill="color"
			stroke="none"
		/>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconTrash',

	/**
	 * props
	 */
	props: {
		color: {
			default: 'currentColor'
		},
		width: {
			default: 17
		},
		height: {
			default: 20
		}
	}
};
</script>
