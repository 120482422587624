<template>
	<div class="intro">
		<div class="intro__head">
			<h3>
				No {{ typePlural }} Yet

				<span>Need to Add Some?</span>
			</h3>

			<figure>
				<img src="@/assets/images/intro-img@2x.png" alt="Example Image Title" />
			</figure>
		</div><!-- /.intro__head -->

		<div class="intro__body">
			<div class="intro__about">
				<p>Our AI has not detected any {{ typePlural }} in this document.</p>

				<p v-if="userRoleWeight >= 10">
					<Icon name="arrow_up" color="#8CABCD" /> If you want to add some, first select a text
					passage in the document which references {{ activeDataObjectType }}
				</p>

				<p v-if="userRoleWeight >= 10">
					<Icon name="arrow_down" color="#8CABCD" />

					Then click the <Icon name="add" color="currentColor" /> “Add” button below
				</p>

				<p v-if="userRoleWeight >= 10">
					<Button className="tertiary">I need more help</Button>
				</p>
			</div>
			<!-- /.intro__about -->

			<div class="intro__tip" v-if="userRoleWeight >= 10">
				<h6>Pro-Tip</h6>

				<p>
					If multiple text passages make reference to the same {{ activeDataObjectType }}, you can shift+click to
					select more than one
				</p>

				<p>
					Additionally, you can add more text passgaes later by using the “Connect Text” button
				</p>
			</div>
			<!-- /.intro__tip -->
		</div><!-- /.intro__body -->
	</div><!-- /.intro -->
</template>

<script>
/**
 * External Dependencies
 */
import { mapGetters } from 'vuex'

/**
 * Internal Dependencies
 */
import Icon from '@/components/icon/icon';
import Button from '@/components/button/button';

export default {
	/**
	 * Name
	 */
	name: 'Intro',

	/**
	 * Components
	 */
	components: {
		Icon,
		Button
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('pdfViewer', ['activeDataObjectType']),
		...mapGetters('account', ['user', 'userRoleWeight']),
		typePlural() {
			return this.activeDataObjectType + 's'
		}
	},
	
}
</script>
