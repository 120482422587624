<template>
  <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 19L2.5 19C1.67194 18.9991 1.00092 18.3281 1 17.5L1 2.5C1.00092 1.67194 1.67194 1.00092 2.5 1L9 1L9 4.66699C9.00165 5.95477 10.0452 6.99835 11.333 7L15 7L15 11.5C15 11.7761 15.2239 12 15.5 12C15.7761 12 16 11.7761 16 11.5L16 6.5C16 6.36737 15.9473 6.24023 15.8535 6.14648L9.85352 0.146484C9.75977 0.0526733 9.63263 0 9.5 0L2.5 0C1.11981 0.0012817 0.0012817 1.11981 0 2.5L0 17.5C0.0012817 18.8802 1.11981 19.9987 2.5 20L10.5 20C10.7761 20 11 19.7761 11 19.5C11 19.2239 10.7761 19 10.5 19ZM10 1.70697L14.293 6L11.333 6C10.597 5.99939 10.0006 5.40295 10 4.66699L10 1.70697ZM4.5 6C4.22388 6 4 6.22388 4 6.5C4 6.77612 4.22388 7 4.5 7L6.5 7C6.77612 7 7 6.77612 7 6.5C7 6.22388 6.77612 6 6.5 6L4.5 6ZM10.5 14L4.5 14C4.22388 14 4 14.2239 4 14.5C4 14.7761 4.22388 15 4.5 15L10.5 15C10.7761 15 11 14.7761 11 14.5C11 14.2239 10.7761 14 10.5 14ZM17.5 17L16 17L16 15.5C16 15.2239 15.7761 15 15.5 15C15.2239 15 15 15.2239 15 15.5L15 17L13.5 17C13.2239 17 13 17.2239 13 17.5C13 17.7761 13.2239 18 13.5 18L15 18L15 19.5C15 19.5002 15 19.5004 15 19.5005C15.0002 19.7766 15.224 20.0002 15.5 20C15.5002 20 15.5004 20 15.5006 20C15.7766 19.9998 16.0002 19.776 16 19.5L16 18L17.5 18C17.7761 18 18 17.7761 18 17.5C18 17.2239 17.7761 17 17.5 17ZM11.5 10L4.5 10C4.22388 10 4 10.2239 4 10.5C4 10.7761 4.22388 11 4.5 11L11.5 11C11.7761 11 12 10.7761 12 10.5C12 10.2239 11.7761 10 11.5 10Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'DocumentNew',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
