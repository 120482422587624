<template>
	<div class="main__error">
		<slot />
	</div>
</template>

<script>
	export default {
		/**
		 * Name
		 */
		name: 'MainError',
	}
</script>

