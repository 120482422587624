<template>
	<div class="subheader__inner" tabindex="0" aria-labelledby="subheader-title">
		<div class="subheader__left">
			<nav class="nav-datasets">
				<ul>
					<li v-for="type in dataObjectsTypes" :key="type.id">
						<button
							:data-value="type.id"
							:class="{ 'is-active': type.id === activeDataObjectType }"
							@click.prevent="manageDataObjectsTypesChange(type.id)"
						>
							<Dot v-if="isDatatypeFlagged(type.id)" />
							<Icon :name="type.icon" color="currentColor" />

							{{ type.label }}
						</button>
					</li>
				</ul>
			</nav> <!-- /.nav-datasets -->
		</div> <!-- /.subheader__left -->

		<div v-if="reportPageURl" class="subheader__right">
			<Button :href="`#/documents/${$route.params.id}/report`" className="tertiary">
				<Icon name="lock" color="currentColor" />

				Open Science Report
			</Button>
		</div> <!-- /.subheader__right -->

		<template v-if="document && document.metadata._id">
			<Summary
				:metadata="document.metadata"
				:isVisible="isSummaryVisible"
				@closeBtnClick="toggleSummary"
			/>

			<div class="dataset-title">
				<div class="dataset__inner">
					<Icon name="document" />

					<h6
						id="subheader-title"
						class="overflow-truncate"
						@click.prevent="toggleSummary"
						v-tooltip.bottom-center="document.metadata.article_title ? document.metadata.article_title : document.name"
					>
						{{ document.metadata.article_title ? document.metadata.article_title : document.name }}
					</h6>

					<Dropdown placement="bottom-end">
						<template #header>
							<span class="btn-dropdown"></span>
						</template>

						<div class="dropdown__nav">
							<ul>
								<li>
									<a href="#" @click.prevent="toggleSummary">
										<Icon name="view_list" color="currentColor" />

										Document Summary
									</a>
								</li>

								<li v-if="userRoleWeight >= 100" >
									<a :href="`#/documents/${$route.params.id}/manage`" target="_blank">
										<Icon name="document" color="currentColor" />

										Manage Document
									</a>
								</li>

								<li>
									<button type="button" @click.prevent="copyText(dataObjectsPageURl, 'Public URL copied !')">
										<Icon name="share" color="currentColor" />

										Link To This Page
									</button>
								</li>

								<li>
									<a :href="uploadedFileURl" target="_blank">
										<Icon name="document_view" color="currentColor" />

										View Uploaded File
									</a>
								</li>

								<li v-if="krtFileURl">
									<a :href="krtFileURl" target="_blank">
										<Icon name="document_view" color="currentColor" />

										View KRT File
									</a>
								</li>
							</ul>

							<ul v-if="userRoleWeight >= 100" data-title="Curator Tools">
								<li v-if="userRoleWeight >= 1000">
									<a :href="lensFileURl" target="_blank">
										<Icon name="document_view" color="currentColor" />

										Open in Lens
									</a>
								</li>
								<li v-if="userRoleWeight >= 100">
									<button @click.prevent="detectSentences">
										<Icon name="document_check" />

										OCR Detect
									</button>
								</li>

								<li v-if="userRoleWeight >= 1000">
									<button @click.prevent="$refs.importDataObjectsPopup.showModal()">
										<Icon name="document_import" />

										Import Data
									</button>
								</li>

								<li v-if="userRoleWeight >= 100">
									<button @click.prevent="$refs.softcitePopup.showModal()">
										<Icon name="document_import" />

										Softcite
									</button>
								</li>

								<li v-if="userRoleWeight >= 1000">
									<button @click.prevent="$refs.customListPopup.showModal()">
										<Icon name="document_import" />

										CustomList / Sciscore
									</button>
								</li>

								<li v-if="userRoleWeight >= 1000">
									<button @click.prevent="$refs.bioNLPPopup.showModal()">
										<Icon name="document_import" />

										BioNLP
									</button>
								</li>

								<li v-if="userRoleWeight >= 1000">
									<button @click.prevent="handleGetOrganizationsChanges" :disabled="postGetOrganizationsChanges.loading" :class="postGetOrganizationsChanges.loading ? 'is-disabled' : ''">
										<Icon name="document_import" color="CurrentColor" />

										Org. Changes
										<Spinner
											v-if="postGetOrganizationsChanges.loading"
											size="small"
										></Spinner>
									</button>
								</li>

								<li v-if="userRoleWeight >= 1000">
									<button @click.prevent="handleGetDataSeerChanges" :disabled="postGetDataSeerChanges.loading" :class="postGetDataSeerChanges.loading ? 'is-disabled' : ''">
										<Icon name="document_import" color="CurrentColor" />

										DS Changes
										<Spinner
											v-if="postGetDataSeerChanges.loading"
											size="small"
										></Spinner>
									</button>
								</li>
							</ul>
						</div>
						<!-- /.dropdown__nav -->
					</Dropdown>
				</div>
				<!-- /.dataset__inner -->

				<ul>
					<li v-if="document.metadata.submitting_author">
						<strong>{{ document.metadata.submitting_author }}</strong>
					</li>

					<li>
						<a :href="uploadedFileURl" target="_blank">
							{{ document.name }}
						</a>
					</li>
				</ul>
			</div>
			<!-- /.dataset-title -->
		</template>

		<div style="position: fixed;">
			<PopupDraggable ref="importDataObjectsPopup" name="import-dataObjects-popup" size="small">
					<Loader
						size="medium"
						:loading="popup.importDataObjects.loading"
						:error="popup.importDataObjects.error"
						:errorMessage="popup.importDataObjects.errorMessage"
					></Loader>
					<Grid rowGap="medium">
						<GridColumn>
							<h6>Import data object(s) from another DataSeer document</h6>
						</GridColumn>

						<GridColumn>
							<Field
								name="suggestedEntity"
								v-model.trim="popup.importDataObjects.source"
							>

								Set the ID of the document from which you want to import data object(s)
							</Field>
						</GridColumn>

						<GridColumn>
							<Checkboxes>
								<FieldCheckbox
									isToggle
									name="createDataObjects"
									v-model="popup.importDataObjects.createDataObjects"
								>
									{{ popup.importDataObjects.createDataObjects ? "Automatically create data objects" : "Get import data logs only" }}
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn v-if="popup.importDataObjects.createDataObjects">
							<Checkboxes>
								<FieldCheckbox
									name="keepCurrentData"
									v-model="popup.importDataObjects.keepCurrentData"
									isDropdown
								>
									Keep the current data object(s)
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn>
								<Button
									:disabled="popup.importDataObjects.loading || !popup.importDataObjects.processFinished"
									@onClick.prevent="handleImportDataObjects()"
								>
									Start import process
								</Button>
						</GridColumn>

						<GridColumn>
							<RichtextEntry label="Import Logs" icon="documents">
								<p v-for="message in popup.importDataObjects.messages" :key="message" v-html="message">
									{{ message }}
								</p>
							</RichtextEntry>
						</GridColumn>

						<GridColumn>
								<Button
									:disabled="popup.importDataObjects.loading || !popup.importDataObjects.processFinished"
									@onClick.prevent="reloadTab()"
								>
									Finish Import Process
								</Button>
						</GridColumn>
					</Grid>
			</PopupDraggable>
			<PopupDraggable ref="softcitePopup" name="softcite-popup" size="small">
					<Loader
						size="medium"
						:loading="popup.softcite.loading"
						:error="popup.softcite.error"
						:errorMessage="popup.softcite.errorMessage"
					></Loader>
					<Grid rowGap="medium">
						<GridColumn>
							<h6>Softcite process management</h6>
						</GridColumn>

						<GridColumn>
							<Checkboxes>
								<FieldCheckbox
									isToggle
									name="softcite.importData"
									v-model="popup.softcite.importData"
								>
									{{ popup.softcite.importData ? "Automatically create data objects (using Softcite data)" : "Get Softcite logs only" }}
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn>
							<h6>Import settings</h6>
						</GridColumn>

						<GridColumn>
							<Checkboxes>
								<FieldCheckbox
									name="softcite.softcite"
									v-model="popup.softcite.requestService"
									isToggle
								>
									{{ popup.softcite.requestService ? "Request Softcite service if necessary (may take a while)" : "Only work with existing data (fast)" }}
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn v-if="popup.softcite.importData">
							<Checkboxes>
								<FieldCheckbox
									name="softcite.ignoreSoftCiteSoftware"
									v-model="popup.softcite.ignoreSoftCiteSoftware"
								>
									Ignore software
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn v-if="popup.softcite.importData">
							<Checkboxes>
								<FieldCheckbox
									name="softcite.ignoreSoftCiteCommandLines"
									v-model="popup.softcite.ignoreSoftCiteCommandLines"
								>
									Ignore command lines
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn>
								<Button
									:disabled="popup.softcite.loading || !popup.softcite.processFinished"
									@onClick.prevent="handleSoftcite()"
								>
									Start process
								</Button>
						</GridColumn>

						<GridColumn>
							<RichtextEntry label="Logs" icon="documents">
								<p v-for="message in popup.softcite.messages" :key="message" v-html="message">
									{{ message }}
								</p>
							</RichtextEntry>
						</GridColumn>

						<GridColumn>
								<Button
									:disabled="popup.softcite.loading || !popup.softcite.processFinished"
									@onClick.prevent="manageSoftciteProcessFinished"
								>
									Finish Process
								</Button>
						</GridColumn>
					</Grid>
			</PopupDraggable>

			<PopupDraggable ref="customListPopup" name="customList-popup" size="small">
					<Loader
						size="medium"
						:loading="popup.customList.loading"
						:error="popup.customList.error"
						:errorMessage="popup.customList.errorMessage"
					></Loader>
					<Grid rowGap="medium">
						<GridColumn>
							<h6>Code/Software CustomList process management</h6>
						</GridColumn>

						<GridColumn>
							<p>Manage all suggested properties of all dataObjects</p>
						</GridColumn>

						<GridColumn>
							<Checkboxes>
								<FieldCheckbox
									name="customList.saveDocument"
									v-model="popup.customList.saveDocument"
									isToggle
								>
									{{ popup.customList.saveDocument ? "Automatically update dataObjects"  : "Do not automatically update dataObjects" }}
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn>
								<Button
									:disabled="popup.customList.loading || !popup.customList.processFinished"
									@onClick.prevent="handleCustomList()"
								>
									Start process
								</Button>
						</GridColumn>

						<GridColumn>
							<RichtextEntry label="Logs" icon="documents">
								<p v-for="message, i in popup.customList.messages" :key="i" v-html="message">
									{{ message }}
								</p>
							</RichtextEntry>
						</GridColumn>

						<GridColumn>
								<Button
									:disabled="popup.customList.loading || !popup.customList.processFinished"
									@onClick.prevent="manageCustomListProcessFinished"
								>
									Finish Process
								</Button>
						</GridColumn>
					</Grid>
			</PopupDraggable>

			<PopupDraggable ref="bioNLPPopup" name="bionlp-popup" size="small">
					<Loader
						size="medium"
						:loading="popup.bioNLP.loading"
						:error="popup.bioNLP.error"
						:errorMessage="popup.bioNLP.errorMessage"
					></Loader>
					<Grid rowGap="medium">
						<GridColumn>
							<h6>bioNLP process management</h6>
						</GridColumn>

						<GridColumn>
							<Checkboxes>
								<FieldCheckbox
									name="bioNLP.labMaterialsSectionsOnly"
									v-model="popup.bioNLP.labMaterialsSectionsOnly"
									isToggle
								>
									{{ popup.bioNLP.labMaterialsSectionsOnly ? "Automatically detect \"Lab Materials\" sections"  : "Do not automatically detect \"Lab Materials\" sections" }}
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn>
							<Field
								name="bioNLP.pages"
								v-model.trim="popup.bioNLP.pages"
							>

								Enter the range of page numbers you wish to process (e.g. : 1-5, 8, 11-13)
							</Field>
						</GridColumn>

						<GridColumn>
							<Checkboxes>
								<FieldCheckbox
									name="bioNLP.requestService"
									v-model="popup.bioNLP.requestService"
									isToggle
								>
									{{ popup.bioNLP.requestService ? "Request BioNLP service if necessary (may take a while)" : "Only work with existing data (fast)" }}
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn v-if="popup.bioNLP.requestService">
							<Checkboxes>
								<FieldCheckbox
									name="bioNLP.refreshData"
									v-model="popup.bioNLP.refreshData"
									isToggle
								>
									{{ popup.bioNLP.refreshData ? "Refresh existing data" : "Do not refresh existing data (current BioNLP results may not be up to date)" }}
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn>
							<Checkboxes>
								<FieldCheckbox
									isToggle
									name="bioNLP.importData"
									v-model="popup.bioNLP.importData"
								>
									{{ popup.bioNLP.importData ? "Automatically create data objects (using BioNLP data)" : "Get BioNLP logs only" }}
								</FieldCheckbox>
							</Checkboxes>
						</GridColumn>

						<GridColumn>
								<Button
									:disabled="popup.bioNLP.loading || !popup.bioNLP.processFinished"
									@onClick.prevent="handleBioNLP()"
								>
									Start process
								</Button>
						</GridColumn>

						<GridColumn>
							<RichtextEntry label="Logs" icon="documents">
								<p v-for="message in popup.bioNLP.messages" :key="message" v-html="message">
									{{ message }}
								</p>
							</RichtextEntry>
						</GridColumn>

						<GridColumn>
								<Button
									:disabled="popup.bioNLP.loading || !popup.bioNLP.processFinished"
									@onClick.prevent="manageBioNLPProcessFinished"
								>
									Finish Process
								</Button>
						</GridColumn>
					</Grid>
			</PopupDraggable>
		</div>
	</div> <!-- /.subheader__inner -->
</template>

<script>
/* eslint-disable */
/**
 * External Dependencies
 */
import { mapActions, mapGetters } from 'vuex';

/**
 * Internal Dependencies
 */
import PopupDraggable from '@/components/popup/popup-draggable';
import PopupConfirm from '@/components/popup/popup-confirm';
import Dot from '@/components/dot/dot';
import Icon from '@/components/icon/icon';
import Summary from '@/blocks/summary/summary';
import Button from '@/components/button/button.vue';
import Dropdown from '@/components/dropdown/dropdown';
import Field from '@/components/field/field';
import Grid, { GridColumn } from '@/components/grid/grid';
import Checkboxes from '@/components/checkboxes/checkboxes';
import FieldCheckbox from '@/components/field-checkbox/field-checkbox';
import RichtextEntry from '@/components/richtext-entry/richtext-entry';
import Loader from '@/blocks/loader/loader';
import Spinner from '@/components/spinner/spinner';

import URLMANAGER from '@/lib/datasets/url';
import API from '@/lib/datasets/api';

import AccountsService from '@/services/account/accounts';

export default {
	/**
	 * Name
	 */
	name: 'SubheaderDatasets',

	/**
	 * Components
	 */
	components: {
		Grid,
		GridColumn,
		Field,
		Checkboxes,
		FieldCheckbox,
		RichtextEntry,
		Dot,
		Icon,
		Button,
		PopupDraggable,
		PopupConfirm,
		Loader,
		Spinner,
		Summary,
		Dropdown
	},

	/**
	 * Data
	 */
	data: function() {
		return {
			isSummaryVisible: false,
			postGetOrganizationsChanges: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			postGetDataSeerChanges: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			popup: {
				importDataObjects: {
					createDataObjects: true,
					source: "",
					keepCurrentData: true,
					loading: false,
					processFinished: true,
					error: false,
					errorMessage: "",
					messages: []
				},
				softcite: {
					importData: true,
					requestService: true,
					ignoreSoftCiteCommandLines: false,
					ignoreSoftCiteSoftware: false,
					loading: false,
					processFinished: true,
					error: false,
					errorMessage: "",
					messages: []
				},
				customList: {
					loading: false,
					processFinished: true,
					error: false,
					saveDocument: true,
					errorMessage: "",
					messages: []
				},
				bioNLP: {
					importData: true,
					refreshData: false,
					requestService: true,
					labMaterialsSectionsOnly: false,
					pages: "",
					loading: false,
					processFinished: true,
					error: false,
					errorMessage: "",
					messages: []
				}
			},
		};
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', ['userRoleWeight']),
		...mapGetters('pdfViewer', [
			'filteredDataObjects',
			'activeDataObject',
			'activeSentence',
			'activeDataObjectType',
			'documentUsername',
			'publicURL',
			'uploadedFileURl',
			'krtFileURl',
			'lensFileURl',
			'dataObjects',
			'documentHandler',
			'document',
			'dataObjectsTypes'
		]),
		reportPageURl() {
			return this.document ? `/documents/${this.document._id}/report` : undefined;
		},
		dataObjectsPageURl() {
			let str = URLMANAGER.buildURL(
				`#/documents/${this.document._id}/datasets`,
				{ token: this.document.token },
				{ root: window.location.origin + window.location.pathname, origin : true }
			);
			return this.document ? str : undefined;
		}
	},

	/**
	 * Methods
	 */
	methods: {
		...mapActions('pdfViewer', [
			'setActiveDataObject',
			'setActiveDataObjectType',
			'importDataObjects',
			'importDataFromSoftcite',
			'extractDataFromSoftcite',
			'importDataFromBioNLP',
			'extractDataFromBioNLP',
			'deleteAllDataObjects',
			'detectSentences'
		]),
		async handleGetOrganizationsChanges() {
			console.log('handleGetOrganizationsChanges');
			this.postGetOrganizationsChanges.loading = true;
			const untrustedAccounts = await AccountsService.getAccounts({
				organizations: this.document.organizations.map((item) => { return item._id.toString(); })
			});
			let untrustedAccountsIds = untrustedAccounts.map((item) => { return item._id.toString(); });
			return API.documents.getDataObjectsChangesCSV({
				documents: [this.document._id],
				untrustedAccounts: untrustedAccountsIds
			}, (err, res) => {
				console.log(err, res);
				this.postGetOrganizationsChanges.loading = false;
				this.downloadCSV(`${this.document.name}.changes.csv`, res);
			});
		},
		async handleGetDataSeerChanges() {
			console.log('handleGetDataSeerChanges');
			this.postGetDataSeerChanges.loading = true;
			const VUE_APP_DS_ORGANIZATION_ID = typeof process.env.VUE_APP_DS_ORGANIZATION_ID !== 'undefined' ? process.env.VUE_APP_DS_ORGANIZATION_ID : '';
			if (VUE_APP_DS_ORGANIZATION_ID === "") return alert("feature unavailable. Please contact an admin.");
			const accounts = await AccountsService.getAccounts({
				organizations: VUE_APP_DS_ORGANIZATION_ID
			});
			let accountsIds = accounts.map((item) => { return item._id.toString(); });
			return API.documents.getDataObjectsHistories({
				documents: [this.document._id],
				accounts: accountsIds
			}, (err, res) => {
				console.log(err, res);
				this.postGetDataSeerChanges.loading = false;
				this.downloadCSV(`${this.document.name}.histories.csv`, res);
			});
		},
		downloadCSV(filename, data) {
			let a = document.createElement("a");
			document.body.appendChild(a);
			a.style = "display: none";
			let blob = new Blob([data], {type: "text/csv"});
			let url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = filename;
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		},
		manageDataObjectsTypesChange(currentDataObjectType) {
			let otherDataObjects = this.dataObjects.filter((item) => {
				return item.sentences.filter((s) => { return s.id === this.activeSentence.id; } ).length > 0 && 
					item.dataObjectType === currentDataObjectType &&
					this.activeSentence.dataInstanceIds.indexOf(item.dataInstanceId) > -1;
				});
			this.setActiveDataObjectType(currentDataObjectType);
			this.setActiveDataObject({
				dataObject: otherDataObjects.length <= 0 ? undefined : otherDataObjects[0],
				scrollToSentence: false
			});
		},
		toggleSummary() {
			this.isSummaryVisible = !this.isSummaryVisible;
		},
		copyText(text, message) {
			this.$copyText(text).then(
				() => { alert(message.length ? message : 'Copied !') },
				() => { alert('Can not copy') }
			);
		},
		isDatatypeFlagged(datatype) {
			return this.dataObjects.some(
				el => el.dataObjectType === datatype &&
				(el.flagged === true || el.flagged === 'true')
			);
		},
		handleImportDataObjects() {
			this.popup.importDataObjects.error = false;
			this.popup.importDataObjects.processFinished = false;
			this.popup.importDataObjects.loading = true;
			this.popup.importDataObjects.errorMessage = "An error has occured...";
			if (this.popup.importDataObjects.messages.length > 0) this.popup.importDataObjects.messages = [];
			if (!this.popup.importDataObjects.keepCurrentData && this.popup.importDataObjects.createDataObjects) {
				this.deleteAllDataObjects((err, res) => {
					console.log(err, res);
					if (err) return this.finishImportDataObjectsProcess(err);
					this.popup.importDataObjects.messages.push("All existing data object(s) deleted...");
					this.processImportDataObjects(this.finishImportDataObjectsProcess);
				})
			} else {
				this.processImportDataObjects(this.finishImportDataObjectsProcess);
			}
		},
		handleSoftcite() {
			this.popup.softcite.error = false;
			this.popup.softcite.processFinished = false;
			this.popup.softcite.loading = true;
			this.popup.softcite.errorMessage = "An error has occured...";
			if (this.popup.softcite.messages.length > 0) this.popup.softcite.messages = [];
			this.processSoftcite(this.finishSoftciteProcess);
		},
		handleCustomList() {
			this.popup.customList.error = false;
			this.popup.customList.processFinished = false;
			this.popup.customList.loading = true;
			this.popup.customList.errorMessage = "An error has occured...";
			if (this.popup.customList.messages.length > 0) this.popup.customList.messages = [];
			this.processCustomList(this.finishCustomListProcess);
		},
		handleBioNLP() {
			this.popup.bioNLP.error = false;
			this.popup.bioNLP.processFinished = false;
			this.popup.bioNLP.loading = true;
			this.popup.bioNLP.errorMessage = "An error has occured...";
			if (this.popup.bioNLP.messages.length > 0) this.popup.bioNLP.messages = [];
			this.processBioNLP(this.finishBioNLPProcess);
		},
		finishImportDataObjectsProcess(err) {
			this.popup.importDataObjects.error = !!err;
			this.popup.importDataObjects.processFinished = true;
			this.popup.importDataObjects.loading = false;
		},
		finishSoftciteProcess(err) {
			this.popup.softcite.error = !!err;
			this.popup.softcite.processFinished = true;
			this.popup.softcite.loading = false;
		},
		finishCustomListProcess(err) {
			this.popup.customList.error = !!err;
			this.popup.customList.processFinished = true;
			this.popup.customList.loading = false;
		},
		finishBioNLPProcess(err) {
			this.popup.bioNLP.error = !!err;
			this.popup.bioNLP.processFinished = true;
			this.popup.bioNLP.loading = false;
		},
		reloadTab() {
			window.location.reload();
		},
		processImportDataObjects(cb) {
			return this.importDataObjects({
				opts: {
					onlyLogs: !this.popup.importDataObjects.createDataObjects,
					source: this.popup.importDataObjects.source,
					target: this.document._id
				},
				cb: (err, res) => {
					console.log(err, res);
					if (err) return cb(err);
					if (res instanceof Error) {
						this.popup.importDataObjects.messages.push(res.toString());
					} else {
						this.popup.importDataObjects.messages = this.popup.importDataObjects.messages.concat(res.summary);
						this.popup.importDataObjects.messages = this.popup.importDataObjects.messages.concat(res.logs);
					}
					// let timeoutID = setTimeout(() => {
					// 	alert("DS APP will automatically restart");
					// 	clearTimeout(timeoutID);
					// 	window.location.reload();
					// }, 1000)
					return cb();
				}
			})
		},
		processSoftcite(cb) {
			this.popup.softcite.error = false;
			this.popup.softcite.errorMessage = "";
			let fn = this.popup.softcite.importData ? this.importDataFromSoftcite : this.extractDataFromSoftcite;
			let opts = this.popup.softcite.importData ? {
					documentId: this.document._id,
					softcite: this.popup.softcite.requestService,
					ignoreSoftCiteCommandLines: this.popup.softcite.ignoreSoftCiteCommandLines,
					ignoreSoftCiteSoftware: this.popup.softcite.ignoreSoftCiteSoftware
				} : {
					documentId: this.document._id,
					softcite: this.popup.softcite.requestService
				};
			return fn({
				opts: opts,
				cb: (err, res) => {
					console.log(err, res);
					if (err) {
						this.popup.softcite.error = true;
						this.popup.softcite.errorMessage = "The Softcite service is currently unavailable";
						this.popup.softcite.messages.push(`${err.responseText} (${err.statusText})`);
						return cb(err);
					}
					if (res instanceof Error) {
						this.popup.softcite.messages.push(res.toString());
					} else {
						this.popup.softcite.messages = this.popup.softcite.messages.concat(res.summary);
						this.popup.softcite.messages = this.popup.softcite.messages.concat(res.logs);
					}
					return cb();
				}
			})
		},
		processCustomList(cb) {
			this.popup.customList.error = false;
			this.popup.customList.errorMessage = "";
			return API.documents.refreshDataObjectsSuggestedProperties({
					id: this.document._id,
					params: {
						saveDocument: this.popup.customList.saveDocument
					}
				}, (err, query) => {
					console.log(err, query);
					if (err) {
						this.popup.customList.error = true;
						this.popup.customList.errorMessage = "This service is currently unavailable";
						this.popup.customList.messages.push(`${err.responseText} (${err.statusText})`);
						return cb(err);
					}
					if (query instanceof Error) {
						this.popup.customList.messages.push(query.toString());
					} else {
						if (query.err) this.popup.customList.messages.push("An error has occured... Please contact an admin");
						let would = this.popup.customList.saveDocument ? "" : "would ";
						let counts = {
							notFound: 0,
							found: 0,
							total: 0
						}
						let logs = query.res.map(function(item) {
							counts.notFound += !!item.from ? 0 : 1;
							counts.found += !!item.from ? 1 : 0;
							counts.total += 1;
							let summary = !!item.from ? `${item.name} ${would}have been found in ${item.from}. ` : `${item.name} ${would}have not been found. `;
							let changes = !!item.from && item.changes.length > 0 ? `Following properties ${would}have been updated: ` : `No changes ${would}have been made.`;
							let details = item.changes.length > 0 ? `(${item.changes.join(', ')})` : "";
							return [summary, changes].concat(details).join('');
						});
						this.popup.customList.messages = this.popup.customList.messages.concat([`${counts.total} dataObjects processed, ${counts.found} found & ${counts.notFound} not found. More info below.`]);
						this.popup.customList.messages = this.popup.customList.messages.concat(logs.flat());
					}
					return cb();
				});
		},
		processBioNLP(cb) {
			this.popup.bioNLP.error = false;
			this.popup.bioNLP.errorMessage = "";
			let fn = this.popup.bioNLP.importData ? this.importDataFromBioNLP : this.extractDataFromBioNLP;
			let opts = {
					documentId: this.document._id,
					bioNLP: this.popup.bioNLP.requestService,
					labMaterialsSectionsOnly: this.popup.bioNLP.labMaterialsSectionsOnly,
					refreshData: this.popup.bioNLP.refreshData,
					pages: this.popup.bioNLP.pages
				};
			return fn({
				opts: opts,
				cb: (err, res) => {
					console.log(err, res);
					if (err) {
						this.popup.bioNLP.error = true;
						this.popup.bioNLP.errorMessage = "The BioNLP service is currently unavailable";
						this.popup.bioNLP.messages.push(`${err.responseText} (${err.statusText})`);
						return cb(err);
					}
					if (res instanceof Error) {
						this.popup.bioNLP.messages.push(res.toString());
					} else {
						this.popup.bioNLP.messages = this.popup.bioNLP.messages.concat(res.summary);
						this.popup.bioNLP.messages = this.popup.bioNLP.messages.concat(res.logs);
					}
					return cb();
				}
			})
		},
		manageSoftciteProcessFinished() {
			if(this.popup.softcite.importData) return this.reloadTab();
			else return this.$refs.softcitePopup.hideModal();
		},
		manageBioNLPProcessFinished() {
			if(this.popup.bioNLP.importData) return this.reloadTab();
			else return this.$refs.bioNLPPopup.hideModal();
		},
		manageCustomListProcessFinished() {
			return this.reloadTab();
		}
	}
};
</script>
