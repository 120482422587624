<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19">
		<path
			d="M4.743,1h1a.5.5,0,0,0,0-1h-1a.5.5,0,0,0,0,1Zm4,0h1a.5.5,0,1,0,0-1h-1a.5.5,0,0,0,0,1Zm4,0h1a.5.5,0,0,0,0-1h-1a.5.5,0,0,0,0,1ZM1.282,1.571A1.593,1.593,0,0,1,1.9,1.136.5.5,0,1,0,1.533.207,2.591,2.591,0,0,0,.521.922a.5.5,0,0,0,.761.649ZM16.41,1.3a1.419,1.419,0,0,1,.419.423l.068.123a.5.5,0,1,0,.9-.426,2.364,2.364,0,0,0-.837-.953.5.5,0,1,0-.554.832ZM1,5.173v-1a.5.5,0,0,0-1,0v1a.5.5,0,0,0,1,0Zm16-.514v1a.5.5,0,0,0,1,0v-1a.5.5,0,0,0-1,0ZM4,4.5H14a.5.5,0,0,1,.09.992L14,5.5H4a.5.5,0,0,1-.09-.992Zm0,3H14a.5.5,0,0,1,.09.992L14,8.5H4a.5.5,0,0,1-.09-.992ZM1,9.173v-1a.5.5,0,0,0-1,0v1a.5.5,0,0,0,1,0Zm16-.514v1a.5.5,0,1,0,1,0v-1a.5.5,0,0,0-1,0ZM4,10.5H14a.5.5,0,0,1,.09.992L14,11.5H4a.5.5,0,0,1-.09-.992ZM1,13.173v-1a.5.5,0,0,0-1,0v1a.5.5,0,0,0,1,0ZM20.5,16a.5.5,0,0,1,.09.992L20.5,17h-6a.5.5,0,0,1-.09-.992L14.5,16ZM4,13.5H8a.5.5,0,0,1,.09.992L8,14.5H4a.5.5,0,0,1-.09-.992ZM1.036,16.971A1.043,1.043,0,0,1,1,16.7v-.527a.5.5,0,0,0-1,0V16.7a2.042,2.042,0,0,0,.07.529.5.5,0,0,0,.966-.259ZM4.089,18h-1a.5.5,0,0,0,0,1h1a.5.5,0,0,0,0-1Zm4,0h-1a.5.5,0,0,0,0,1h1a.5.5,0,0,0,0-1Zm4,0h-1a.5.5,0,0,0,0,1h1a.5.5,0,1,0,0-1Z"
			:fill="color"
			fill-rule="evenodd"
		/>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconDisconnect',

	/**
	 * props
	 */
	props: ['color']
};
</script>
