var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field",class:{
		'is-readonly': _vm.readonly || _vm.disabled,
		'has-error': _vm.error,
		'has-trailing-icon': _vm.trailingIcon,
		'field--textarea': this.type === 'textarea',
		'field--sm': this.size === 'small',
		'field--md': this.size === 'medium',
		'field--lg': this.size === 'large'
	}},[(this.$slots.default)?_c('label',{staticClass:"field__label",class:{ 'sr-only': _vm.hideLabel },attrs:{"for":_vm.toKebabCase(_vm.name)}},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"field__controls"},[(this.type === 'textarea')?_c('textarea',{staticClass:"field__input",attrs:{"type":_vm.type,"id":_vm.toKebabCase(_vm.name),"name":_vm.name,"tabindex":_vm.tabindex,"readonly":_vm.readonly || _vm.disabled,"placeholder":_vm.placeholder,"title":_vm.value},domProps:{"value":_vm.value},on:{"input":_vm.handleChange}}):_c('input',{staticClass:"field__input",attrs:{"type":_vm.type,"id":_vm.toKebabCase(_vm.name),"name":_vm.name,"tabindex":_vm.tabindex,"readonly":_vm.readonly || _vm.disabled,"placeholder":_vm.placeholder,"title":_vm.value},domProps:{"value":_vm.value},on:{"input":_vm.handleChange}}),(_vm.trailingIcon)?_c('div',{staticClass:"field__trailing-icon"},[_c('Icon',{attrs:{"name":_vm.trailingIcon}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }