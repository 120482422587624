<template>
	<svg
		:width="width"
		:height="height"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 22 17"
		fill="none"
	>
		<path
				fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.8535 6.14648L15.8535 0.146484C15.7598 0.0526733 15.6326 0 15.5 0H7C5.34387 0.0018311 4.00183 1.34387 4 3V5H3C1.34387 5.00183 0.0018311 6.34387 0 8V14C0.0018311 15.6561 1.34387 16.9982 3 17H15C16.6561 16.9982 17.9982 15.6561 18 14V13H19C20.6561 12.9982 21.9982 11.6561 22 10V6.5C22 6.36737 21.9473 6.24023 21.8535 6.14648ZM16 1.70697L20.293 6H17.333C16.597 5.99939 16.0006 5.40295 16 4.66699V1.70697ZM15 16C16.104 15.9986 16.9986 15.104 17 14V13H7C5.34387 12.9982 4.00183 11.6561 4 10V6H3C1.896 6.0014 1.0014 6.896 1 8V14C1.0014 15.104 1.896 15.9986 3 16H15ZM21 10C20.9986 11.104 20.104 11.9986 19 12H7C5.896 11.9986 5.0014 11.104 5 10V3C5.0014 1.896 5.896 1.0014 7 1H15V4.66699C15.0016 5.95477 16.0452 6.99835 17.333 7H21V10ZM13 3H7V4H13V3ZM7 6H13V7H7V6ZM19 9H7V10H19V9Z"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'IconReferences',

	/**
	 * props
	 */
	props: {
		color: {
			type: String
		},
		width: {
			default: 22
		},
		height: {
			default: 17
		}
	}
};
</script>
