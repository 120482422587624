var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid",class:{
		'grid-cols-2': _vm.columnSize === 'half',
		'grid-cols-3': _vm.columnSize === 'third',
		'grid-cols-4': _vm.columnSize === 'quarter',
		'gap-x-sm': _vm.columnGap === 'small',
		'gap-x-md': _vm.columnGap === 'medium',
		'gap-x-lg': _vm.columnGap === 'large',
		'gap-y-sm': _vm.rowGap === 'small',
		'gap-y-md': _vm.rowGap === 'medium',
		'gap-y-lg': _vm.rowGap === 'large',
		'gap-y-xlg': _vm.rowGap === 'extralarge'
	}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }