<template>
	<svg
		width="20px"
		height="13px"
		viewBox="0 0 20 13"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<title>Shape</title>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-1286.000000, -260.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(1264.282951, 43.000000)">
					<g transform="translate(21.717049, 217.000000)">
						<path
							d="M1.5,0 C0.6715698,0 0,0.6715698 0,1.5 C0,2.3284302 0.6715698,3 1.5,3 C2.328064,2.9990845 2.9990845,2.328064 3,1.5 C3,0.6715698 2.3284302,0 1.5,0 Z M1.5,2 C1.223877,2 1,1.776123 1,1.5 C1,1.223877 1.223877,1 1.5,1 C1.7759399,1.0005493 1.9994507,1.2240601 2,1.5 C2,1.776123 1.776123,2 1.5,2 Z M5.5,2 L19.5,2 C19.776123,2 20,1.776123 20,1.5 C20,1.223877 19.776123,1 19.5,1 L5.5,1 C5.223877,1 5,1.223877 5,1.5 C5,1.776123 5.223877,2 5.5,2 Z M19.5,6 L9.5,6 C9.223877,6 9,6.223877 9,6.5 C9,6.776123 9.223877,7 9.5,7 L19.5,7 C19.776123,7 20,6.776123 20,6.5 C20,6.223877 19.776123,6 19.5,6 Z M19.5,11 L13.5,11 C13.223877,11 13,11.223877 13,11.5 C13,11.776123 13.223877,12 13.5,12 L19.5,12 C19.776123,12 20,11.776123 20,11.5 C20,11.223877 19.776123,11 19.5,11 Z M5.5,5 C4.6715698,5 4,5.6715698 4,6.5 C4,7.3284302 4.6715698,8 5.5,8 C6.328064,7.9990845 6.9990845,7.328064 7,6.5 C7,5.6715698 6.3284302,5 5.5,5 Z M5.5,7 C5.223877,7 5,6.776123 5,6.5 C5,6.223877 5.223877,6 5.5,6 C5.7759399,6.0005493 5.9994507,6.2240601 6,6.5 C6,6.776123 5.776123,7 5.5,7 Z M9.5,10 C8.6715698,10 8,10.6715698 8,11.5 C8,12.3284302 8.6715698,13 9.5,13 C10.328064,12.9990845 10.9990845,12.328064 11,11.5 C11,10.6715698 10.3284302,10 9.5,10 Z M9.5,12 C9.223877,12 9,11.776123 9,11.5 C9,11.223877 9.223877,11 9.5,11 C9.7759399,11.0005493 9.9994507,11.2240601 10,11.5 C10,11.776123 9.776123,12 9.5,12 Z"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconListAlt',

	/**
	 * props
	 */
	props: ['color'],
};
</script>
