<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 30 30"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-405.000000, -403.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(109.000000, 60.000000)">
					<g transform="translate(46.000000, 293.000000)">
						<g transform="translate(229.000000, 46.000000)">
							<g transform="translate(21.000000, 4.000000)">
								<path
									d="M6,10.5 L6,5.25 C6,3.15 7.65,1.5 9.75,1.5 C10.2,1.5 10.5,1.2 10.5,0.75 C10.5,0.3 10.2,0 9.75,0 C6.9,0 4.5,2.4 4.5,5.25 L4.5,10.5 C4.5,12.6 2.85,14.25 0.75,14.25 C0.3,14.25 0,14.55 0,15 C0,15.45 0.3,15.75 0.75,15.75 C2.85,15.75 4.5,17.4 4.5,19.5 L4.5,24.75 C4.5,27.6 6.9,30 9.75,30 C10.2,30 10.5,29.7 10.5,29.25 C10.5,28.8 10.2,28.5 9.75,28.5 C7.65,28.5 6,26.85 6,24.75 L6,19.5 C6,17.55 4.95,15.9 3.45,15 C4.95,14.1 6,12.45 6,10.5 Z M29.25,14.25 C27.15,14.25 25.5,12.6 25.5,10.5 L25.5,5.25 C25.5,2.4 23.1,0 20.25,0 C19.8,0 19.5,0.3 19.5,0.75 C19.5,1.2 19.8,1.5 20.25,1.5 C22.35,1.5 24,3.15 24,5.25 L24,10.5 C24,12.45 25.05,14.1 26.55,15 C25.05,15.9 24,17.55 24,19.5 L24,24.75 C24,26.85 22.35,28.5 20.25,28.5 C19.8,28.5 19.5,28.8 19.5,29.25 C19.5,29.7 19.8,30 20.25,30 C23.1,30 25.5,27.6 25.5,24.75 L25.5,19.5 C25.5,17.4 27.15,15.75 29.25,15.75 C29.7,15.75 30,15.45 30,15 C30,14.55 29.7,14.25 29.25,14.25 Z"
								></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconBrackets',

	/**
	 * props
	 */
	props: {
		color: {},
		width: {
			default: 29
		},
		height: {
			default: 29
		}
	}
};
</script>
