var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Main',{attrs:{"className":"main--table","loading":_vm.loading,"error":_vm.error,"errorMessage":_vm.errorMessage},scopedSlots:_vm._u([{key:"subheader",fn:function(){return [_c('Subheader',[_c('SubheaderTable',{attrs:{"title":"Organizations","icon":"organization","buttonLabel":"Add New Organization","buttonUrl":"/add-organization","showFiltersButton":"","searchInputValue":_vm.globalSearchValue},on:{"searchInput":_vm.handleSearchInput,"filtersButtonClick":function($event){return _vm.setFiltersVisibility(!_vm.filtersVisibility)}}})],1)]},proxy:true}])},[(_vm.filtersVisibility)?_c('TableFilters',{on:{"closeButtonClick":function($event){return _vm.setFiltersVisibility(false)}}},[_c('FormOrganizationFilters',{attrs:{"initialFilters":_vm.filters},on:{"applyFilters":_vm.applyFilters}})],1):_vm._e(),_c('Table',{attrs:{"modifier":"organizations"}},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table","columns":_vm.columns,"rows":_vm.filteredRows,"totalRows":_vm.filteredRows.length,"pagination-options":{
				enabled: true,
				perPage: _vm.itemsPerPage
			},"sort-options":{
				enabled: true,
				initialSortBy: { 
					field: 'name',
					type: 'asc'
				}
			},"search-options":{
				enabled: true,
				externalQuery: _vm.globalSearchValue
			}},on:{"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'Name')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Sort By Name'),expression:"'Sort By Name'",modifiers:{"top-center":true}}]},[_vm._v(" "+_vm._s(props.column.label)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',{staticClass:"table__title"},[_c('Icon',{attrs:{"name":"organization","color":props.row.visible ? 'currentColor' : '#8CABCD'}}),_vm._v(" "+_vm._s(props.row.name)+" ")],1):(props.column.field === 'organization')?_c('span',[_c('ul',{staticClass:"table__organization"},_vm._l((props.row.organization),function(organization){return _c('li',{key:organization},[_vm._v(" "+_vm._s(organization)+" ")])}),0)]):(props.column.field === 'visible')?_c('span',[(props.row.visible)?_c('span',{staticStyle:{"color":"#006AC9"}},[_vm._v("Active")]):_c('span',{staticStyle:{"color":"#8CABCD"}},[_vm._v("Inactive")])]):(props.column.field === 'action')?_c('div',{staticClass:"table__actions"},[_c('Button',{attrs:{"size":"small","className":"tertiary","to":("/edit-organization/" + (props.row._id)),"highlighted":"","block":""}},[_vm._v(" Edit Organization ")])],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('Pagination',{attrs:{"itemsPerPage":_vm.itemsPerPage,"perPageOptions":_vm.perPageOptions,"totalItems":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }