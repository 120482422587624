<template>
	<Form className="form--new-document" @submit.prevent="handleFormSubmit" :loading="loading">
		<FormHead centered>
			<h2>Upload Your Research Text</h2>

			<p>
				DataSeer will process the document and highlight passages which may need links to a
				dataset, code, lab materials, or protocol
			</p>
		</FormHead>

		<FormStatus ref="formStatus" v-show="error || success" :url="message.url" :text="message.text" :isError="error" />
		
		<FormBody>
			<Grid rowGap="extralarge">
				<GridColumn>
					<FieldFile
						:error="$v.formData.file.$error"
						v-model="formData.file"
						accept=".tei, .pdf"
						name="file"
					>
						<template #label>
							<Icon name="document_new" color="currentColor" />

							Select Your Primary File
						</template>

						<template #helptext> Supported formats: <strong>PDF, TEI</strong> </template>
					</FieldFile>
				</GridColumn>

				<GridColumn>
					<FieldFile
						v-model="formData.attachedFiles"
						name="attachedFiles"
						multiple="multiple"
						accept=".pdf"
					>
						<template #label>
							<Icon name="documents" color="currentColor" />

							Select Additional Support Files <em>Optional</em>
						</template>

						<template #helptext>
							Supported formats: <strong>PDF</strong> 
						</template>
					</FieldFile>
				</GridColumn>

				<GridColumn>
					<FieldFile
						v-model="formData.krt"
						name="attachedFiles"
						accept=".csv, .tsv"
					>
						<template #label>
							<Icon name="documents" color="currentColor" />

							Select Key Resource Table File <em>Optional</em>
						</template>

						<template #helptext>
							Supported formats: <strong>CSV, XLS, XLSX</strong> 
						</template>
					</FieldFile>
				</GridColumn>

				<template v-if="isAdministrator">
					<GridColumn>
						<FieldSelect
							v-model.trim="formData.owner"
							name="owner"
							placeholder="Document Owner"
							:options="accountsList"
						>
							<Icon name="user" color="currentColor" />

							Document Owner
						</FieldSelect>
					</GridColumn>

					<GridColumn>
						<FieldSelect
							v-model.trim="formData.organizations"
							name="organization"
							placeholder="Include All"
							multiple
							:options="organizationsList"
							@input="handleOrganizationsChange"
						>
							<Icon name="organization" color="currentColor" />

							Institution/Organization
						</FieldSelect>
					</GridColumn>
				</template>

				<GridColumn v-if="isAdministrator">
					<div class="checkboxes checkboxes--left">
						<ul>
							<FieldCheckbox
								v-model="formData.alreadyProcessed"
								name="alreadyProcessed"
							>
								This is a new version of an article DataSeer has already assessed
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.dataseerML"
								name="dataseerML"
							>
								Automatically identify datasets (DataSeer ML will process this document)
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.removeResponseToViewerSection"
								name="removeResponseToViewerSection"
							>
								Remove "Response To Viewer" pages at the begining of the PDF<br/>
								<i>(useful for American Naturalist documents)</i>
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.mergePDFs"
								name="mergePDFs"
							>
								Merge attached PDF files with the main PDF file
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.importDataFromSoftcite"
								name="formData.importDataFromSoftcite"
							>
								Automatically import data from Softcite
								<br/>
								<i>({{ formData.ignoreSoftCiteSoftware ? "" : "Software" }}{{ formData.ignoreSoftCiteCommandLines ? "" : `${!formData.ignoreSoftCiteSoftware ? " & " : ""}Command Lines` }}{{ (!formData.ignoreSoftCiteSoftware || !formData.ignoreSoftCiteCommandLines) ? " will be automatically created" : ""}}{{ formData.ignoreSoftCiteSoftware && formData.ignoreSoftCiteCommandLines ? "No data objects will be created" : "" }})</i>
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.importDataFromBioNLP"
								name="importDataFromBIONLP"
							>
								Automatically import data from BioNLP pipeline
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.importDataFromKRT"
								name="importDataFromKRT"
							>
								Automatically import data from KRT pipeline
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.refreshDataObjectsSuggestedProperties"
								name="refreshDataObjectsSuggestedProperties"
							>
								Automatically fill in suggested properties of code/software (using "CustomList & SciScore")
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.deleteDataObjects"
								name="deleteDataObjects"
							>
								Delete all extracted dataObjects
							</FieldCheckbox>

							<FieldCheckbox
								v-model="formData.mute"
								name="formData.mute"
							>
								Disable email (no email will be sent)
							</FieldCheckbox>
						</ul>
					</div> <!-- /.checkboxes -->
				</GridColumn>

			</Grid>
		</FormBody>

		<FormActions>
			<li>
				<Button tabindex="0" type="submit">Upload Documents</Button>
			</li>

			<li>
				<Button tabindex="0" to="/documents" type="button" className="tertiary">Cancel</Button>
			</li>
		</FormActions>
	</Form>
</template>

<script>
/**
 * External Dependencies
 */
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

/**
 * Internal Dependencies
 */
import Icon from '@/components/icon/icon';
import Button from '@/components/button/button';
import Grid, { GridColumn } from '@/components/grid/grid';
import FieldFile from '@/components/field-file/field-file';
import FieldSelect from '@/components/field-select/field-select';
import FieldCheckbox from '@/components/field-checkbox/field-checkbox';
import Form, { FormActions, FormHead, FormBody, FormStatus } from '@/components/form/form';

import accountsService from '@/services/account/accounts';
import documentsService from '@/services/documents/documents';
import organizationsService from '@/services/organizations/organizations';

import ScrollHandler from "@/utils/use-scroll-handler";

export default {
	/**
	 * Name
	 */
	name: 'FormNewDocument',

	/**
	 * Components
	 */
	components: {
		Form,
		FormActions,
		FormHead,
		FormBody,
		FormStatus,
		Grid,
		GridColumn,
		Icon,
		Button,
		FieldFile,
		FieldSelect,
		FieldCheckbox
	},

	/**
	 * Data
	 */
	data: function() {
		return {
			formData: {
				file: '',
				krt: '',
				attachedFiles: '',
				alreadyProcessed: false,
				removeResponseToViewerSection: true,
				dataseerML: true,
				mergePDFs: true,
				mute: false,
				softcite: false,
				importDataFromSoftcite: false,
				ignoreSoftCiteCommandLines: false,
				ignoreSoftCiteSoftware: false,
				bioNLP: false,
				importDataFromBioNLP: false,
				refreshDataObjectsSuggestedProperties: false,
				importDataFromKRT: false,
				deleteDataObjects: false,
				organizations: [],
				owner: '',
			},
			defaultSettings: {
				upload: {
					alreadyProcessed: false,
					removeResponseToViewerSection: true,
					dataseerML: true,
					mergePDFs: true,
					mute: false,
					softcite: false,
					importDataFromSoftcite: false,
					ignoreSoftCiteCommandLines: false,
					ignoreSoftCiteSoftware: false,
					bioNLP: false,
					importDataFromBioNLP: false,
					refreshDataObjectsSuggestedProperties: false,
					importDataFromKRT: false,
					deleteDataObjects: false
				}
			},
			success: false,
			error: false,
			loading: false,
			message: { text: '', url: '' },
			formMessages: {
				success: 'Your document was successfully uploaded !',
				error: 'There was an error uploading your document.'
			},
			accountsList: [],
			organizationsList: [],
			organizations: [],
		};
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', [
			'userId',
			'userOrganizationsIds',
			'userRoleWeight'
		]),
		isAdministrator() {
			return this.userRoleWeight >= 1000;
		},
		isModerator() {
			return this.userRoleWeight >= 100;
		}
	},

	validations: {
		formData: {
			file: {
				required,
			}
		}
	},

	/**
	 * Methods
	 */
	methods: {
		resetFormState() {
			this.success = false,
			this.error = false,
			this.message = { text: '', url: '' }
		},
		handleOrganizationsChange(selection) {
			const selectedOrganizations = this.organizations.filter((item) => {
				return selection.indexOf(item._id) > -1;
			});
			let settings = selectedOrganizations.length > 0 ? Object.assign({}, selectedOrganizations[0].settings) : Object.assign({}, this.defaultSettings);
			this.formData.alreadyProcessed = settings.upload.alreadyProcessed;
			this.formData.removeResponseToViewerSection = settings.upload.removeResponseToViewerSection;
			this.formData.dataseerML = settings.upload.dataseerML;
			this.formData.mergePDFs = settings.upload.mergePDFs;
			this.formData.mute = settings.upload.mute;
			this.formData.softcite = settings.upload.softcite;
			this.formData.importDataFromSoftcite = settings.upload.importDataFromSoftcite;
			this.formData.ignoreSoftCiteCommandLines = settings.upload.ignoreSoftCiteCommandLines;
			this.formData.ignoreSoftCiteSoftware = settings.upload.ignoreSoftCiteSoftware;
			this.formData.bioNLP = settings.upload.bioNLP;
			this.formData.importDataFromBioNLP = settings.upload.importDataFromBioNLP;
			this.formData.refreshDataObjectsSuggestedProperties = settings.upload.refreshDataObjectsSuggestedProperties;
			this.formData.importDataFromKRT = settings.upload.importDataFromKRT;
			this.formData.deleteDataObjects = settings.upload.deleteDataObjects;
		},
		async populateFormData() {
			try {
				const accountsList = await accountsService.getAccountsList();
				const organizationsList = await organizationsService.getOrganizationsList();
				const organizations = await organizationsService.getOrganizations();

				this.accountsList = accountsList.filter(function(item) {
					return item.label.indexOf("@") > -1;
				}).sort(function(a, b) {
					if (a.label < b.label) return -1;
					if (a.label > b.label) return 1;
					return 0;
				});
				this.organizationsList = organizationsList;
				this.organizations = organizations;

				this.formData = {
					...this.formData,
					organizations: this.userOrganizationsIds,
					owner: this.userId,
				}
				const selectedOrganizations = this.organizations.filter((item) => {
					return this.formData.organizations.indexOf(item._id) > -1;
				});
				let settings = selectedOrganizations.length > 0 ? Object.assign({}, selectedOrganizations[0].settings) : Object.assign({}, this.defaultSettings);
				this.formData.alreadyProcessed = settings.upload.alreadyProcessed;
				this.formData.removeResponseToViewerSection = settings.upload.removeResponseToViewerSection;
				this.formData.dataseerML = settings.upload.dataseerML;
				this.formData.mergePDFs = settings.upload.mergePDFs;
				this.formData.mute = settings.upload.mute;
				this.formData.softcite = settings.upload.softcite;
				this.formData.importDataFromSoftcite = settings.upload.importDataFromSoftcite;
				this.formData.ignoreSoftCiteCommandLines = settings.upload.ignoreSoftCiteCommandLines;
				this.formData.ignoreSoftCiteSoftware = settings.upload.ignoreSoftCiteSoftware;
				this.formData.bioNLP = settings.upload.bioNLP;
				this.formData.importDataFromBioNLP = settings.upload.importDataFromBioNLP;
				this.formData.refreshDataObjectsSuggestedProperties = settings.upload.refreshDataObjectsSuggestedProperties;
				this.formData.importDataFromKRT = settings.upload.importDataFromKRT;
				this.formData.deleteDataObjects = settings.upload.deleteDataObjects;
			} catch (e) {
				this.error = true
				this.message.text = e.message
			}
						
			this.loading = false
		},
		async handleFormSubmit() {
			this.resetFormState();
			this.$v.$touch();
			ScrollHandler(this.$refs.formStatus.$el);
			if (this.$v.$invalid) return;

			this.loading = true;
			
			const params = {
				file: this.formData.file[0],
				attachedFiles: this.formData.attachedFiles,
				krt: this.formData.krt[0],
				dataseerML: this.formData.dataseerML,
				mergePDFs: this.formData.mergePDFs,
				mute: this.formData.mute,
				softcite:this.formData.softcite,
				importDataFromSoftcite: this.formData.importDataFromSoftcite,
				ignoreSoftCiteCommandLines: this.formData.ignoreSoftCiteCommandLines,
				ignoreSoftCiteSoftware: this.formData.ignoreSoftCiteSoftware,
				owner: this.formData.owner,
				organizations: this.formData.organizations,
				removeResponseToViewerSection: this.formData.removeResponseToViewerSection,
				bioNLP: this.formData.bioNLP,
				importDataFromBioNLP: this.formData.importDataFromBioNLP,
				refreshDataObjectsSuggestedProperties: this.formData.refreshDataObjectsSuggestedProperties,
				importDataFromKRT : this.formData.importDataFromKRT,
				deleteDataObjects : this.formData.deleteDataObjects
			}

			try {
				const response = await documentsService.addDocument(params)
				if (response && response._id) this.message.url = `#documents/${response._id}/datasets`;
				this.success = true;
				this.message.text = this.formMessages.success;
			} catch (error) {
				this.error = true;
				this.message.text = error.message || this.formMessages.error;
			}

			this.loading = false;
		}
	},

	created () {
		this.populateFormData();
	},
};
</script>
