<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1532.000000, -778.000000)" :fill=color fill-rule="nonzero">
            <g transform="translate(1191.000000, 584.000000)">
                <g transform="translate(307.000000, 187.000000)">
                    <g transform="translate(34.000000, 7.000000)">
                        <path d="M13,9.5 L10.5,9.5 L10.5,7 C10.5,6.723877 10.276123,6.5 10,6.5 C9.723877,6.5 9.5,6.723877 9.5,7 L9.5,9.5 L7,9.5 C6.723877,9.5 6.5,9.723877 6.5,10 C6.5,10.276123 6.723877,10.5 7,10.5 L9.5,10.5 L9.5,13.0005493 C9.5001831,13.2765503 9.723999,13.5001831 10,13.5 L10.0006104,13.5 C10.2765503,13.4998169 10.5001831,13.276001 10.5,13 L10.5,10.5 L13,10.5 C13.276123,10.5 13.5,10.276123 13.5,10 C13.5,9.723877 13.276123,9.5 13,9.5 Z M5.5,0 L2.5,0 C1.119812,0.0012817 0.0012817,1.119812 0,2.5 L0,5.5006104 C0.0001831,5.7765503 0.223999,6.0001831 0.5,6 L0.5006104,6 C0.7765503,5.9998169 1.0001831,5.776001 1,5.5 L1,2.5 C1.0009155,1.671936 1.671936,1.0009155 2.5,1 L5.5,1 C5.776123,1 6,0.776123 6,0.5 C6,0.223877 5.776123,0 5.5,0 Z M5.5,19 L2.5,19 C1.671936,18.9990845 1.0009155,18.328064 1,17.5 L1,14.5 C1,14.223877 0.776123,14 0.5,14 C0.223877,14 0,14.223877 0,14.5 L0,17.5 C0.0012817,18.880188 1.119812,19.9987183 2.5,20 L5.5,20 C5.776123,20 6,19.776123 6,19.5 C6,19.223877 5.776123,19 5.5,19 Z M19.5,14 C19.223877,14 19,14.223877 19,14.5 L19,17.5 C18.9990845,18.328064 18.328064,18.9990845 17.5,19 L14.5,19 C14.223877,19 14,19.223877 14,19.5 C14,19.776123 14.223877,20 14.5,20 L17.5,20 C18.880188,19.9987183 19.9987183,18.880188 20,17.5 L20,14.5 C20,14.223877 19.776123,14 19.5,14 Z M17.5,0 L14.5,0 C14.223877,0 14,0.223877 14,0.5 C14,0.776123 14.223877,1 14.5,1 L17.5,1 C18.328064,1.0009155 18.9990845,1.671936 19,2.5 L19,5.5006104 C19.0001831,5.7765503 19.223999,6.0001831 19.5,6 L19.5006104,6 C19.7765503,5.9998169 20.0001831,5.776001 20,5.5 L20,2.5 C19.9987183,1.119812 18.880188,0.0012817 17.5,0 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconFocus',

	/**
	 * props
	*/
	props: ['color']
	}
</script>

