<template>
	<svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-1376.000000, -191.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(1373.000000, 191.000000)">
					<g transform="translate(3.000000, 0.000000)">
						<path
							d="M17.8535156,15.5664062 L15.4335327,13.1464843 C15.4335327,13.1464233 15.4334717,13.1463622 15.4334106,13.1463012 C15.2381591,12.9511718 14.9216918,12.9512328 14.7265625,13.1464843 L11.1464844,16.7265624 C11.0526733,16.8203125 11,16.9474487 11,17.0800781 L11,19.5005493 C11.0001831,19.7765503 11.223999,20.0001831 11.5,19.9999999 L13.9199219,19.9999999 C14.0525513,19.9999999 14.1796875,19.9473267 14.2734375,19.8535156 L17.8535156,16.2734375 C17.8535156,16.2733765 17.8535766,16.2733154 17.8536377,16.2732544 C18.0487671,16.0780029 18.0487061,15.7615356 17.8535156,15.5664062 Z M13.7128906,19 L12,19 L12,17.2871094 L15.0800781,14.2069702 L16.7930298,15.9199219 L13.7128906,19 Z M4.5,14 C4.223877,14 4,14.223877 4,14.5 C4,14.776123 4.223877,15 4.5,15 L7.5,15 C7.776123,15 8,14.776123 8,14.5 C8,14.223877 7.776123,14 7.5,14 L4.5,14 Z M12,10.5 C12,10.223877 11.776123,10 11.5,10 L4.5,10 C4.223877,10 4,10.223877 4,10.5 C4,10.776123 4.223877,11 4.5,11 L11.5,11 C11.776123,11 12,10.776123 12,10.5 Z M4.5,7 L6.5,7 C6.776123,7 7,6.776123 7,6.5 C7,6.223877 6.776123,6 6.5,6 L4.5,6 C4.223877,6 4,6.223877 4,6.5 C4,6.776123 4.223877,7 4.5,7 Z M8.5,19 L3,19 C1.8959961,18.9985962 1.0014038,18.1040039 1,17 L1,3 C1.0014038,1.8959961 1.8959961,1.0014038 3,1 L9,1 L9,4.6669922 C9.0016479,5.9547729 10.0452271,6.9983521 11.3330078,7 L15,7 L15,10.5 C15,10.776123 15.223877,11 15.5,11 C15.776123,11 16,10.776123 16,10.5 L16,6.5 C16,6.3673706 15.9473267,6.2402344 15.8535156,6.1464844 L9.8535156,0.1464844 C9.7597656,0.0526733 9.6326294,0 9.5,0 L3,0 C1.3438721,0.0018311 0.0018311,1.3438721 0,3 L0,17 C0.0018311,18.6561279 1.3438721,19.9981689 3,20 L8.5,20 C8.776123,20 9,19.776123 9,19.5 C9,19.223877 8.776123,19 8.5,19 Z M10,1.7069702 L14.2930298,6 L11.3330078,6 C10.5970459,5.9993896 10.0006104,5.4029541 10,4.6669922 L10,1.7069702 Z"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'DocumentModify',

	/**
	 * props
	 */
	props: ['color'],
};
</script>
