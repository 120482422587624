<template>
	<div class="shell">
		<slot />
	</div>
	<!-- /.shell -->
</template>

<script>
export default {
	/**
	 * Name
	 */
	name: 'Shell'
};
</script>
