<template>
  <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5008 8.00078L5.50078 8.00078C5.22466 8.00078 5.00078 8.22466 5.00078 8.50078C5.00078 8.77691 5.22466 9.00078 5.50078 9.00078L14.5008 9.00078C14.7769 9.00078 15.0008 8.77691 15.0008 8.50078C15.0008 8.22466 14.7769 8.00078 14.5008 8.00078ZM11.5008 11.0008L5.50078 11.0008C5.22466 11.0008 5.00078 11.2247 5.00078 11.5008C5.00078 11.7769 5.22466 12.0008 5.50078 12.0008L11.5008 12.0008C11.7769 12.0008 12.0008 11.7769 12.0008 11.5008C12.0008 11.2247 11.7769 11.0008 11.5008 11.0008ZM17.4161 3.29107C13.7106 -0.804452 7.38659 -1.12068 3.29107 2.58477C-0.804452 6.29027 -1.12068 12.6142 2.58477 16.7098L0.147269 19.1473C0.0535189 19.241 0.000845603 19.3682 0.000845603 19.5007C0.000784503 19.7768 0.224661 20.0007 0.500785 20.0008L10.0008 20.0008C12.4802 20.0007 14.8712 19.0795 16.7098 17.4161C20.8054 13.7106 21.1215 7.38659 17.4161 3.29107ZM10.0008 19.0008L1.70775 19.0008L3.63653 17.0721C3.63659 17.072 3.63665 17.072 3.63665 17.0719C3.83184 16.8766 3.83178 16.5602 3.63653 16.365C1.94915 14.6777 1.00103 12.3892 1.00078 10.0028C1.00024 5.03277 5.02874 1.00335 9.99877 1.0028C14.9687 1.00225 18.9982 5.03075 18.9988 10.0008C18.9993 14.9708 14.9708 19.0002 10.0008 19.0008Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * color
	*/
	name: 'iconComment',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
