var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Main',{attrs:{"className":"main--table"},scopedSlots:_vm._u([{key:"subheader",fn:function(){return [_c('Subheader',[_c('SubheaderDocuments',{attrs:{"searchInputValue":_vm.globalSearchValue},on:{"searchInput":_vm.handleSearchInput,"filtersButtonClick":function($event){return _vm.setFiltersVisibility(!_vm.filtersVisibility)},"downloadDataObjectsButtonClick":function($event){return _vm.downloadDataObjects()}}})],1)]},proxy:true}])},[(_vm.filtersVisibility)?_c('TableFilters',{on:{"closeButtonClick":function($event){return _vm.setFiltersVisibility(false)}}},[_c('FormFilters',{attrs:{"initialValues":_vm.serverParams.filters},on:{"applyFilters":_vm.onApplyFilters}})],1):_vm._e(),_c('Table',{attrs:{"modifier":"documents"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"totalRows":_vm.totalRows,"pagination-options":{
				enabled: true,
				perPage: _vm.serverParams.perPage
			},"sort-options":{
				enabled: true,
				initialSortBy: {
					field: 'name',
					type: 'desc'
				}
			},"mode":"remote","styleClass":"vgt-table","isLoading":_vm.loading,"row-style-class":_vm.rowStyleClassFn},on:{"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event},"on-sort-change":_vm.onSortChange,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label == 'Title')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Sort By Title'),expression:"'Sort By Title'",modifiers:{"top-center":true}}]},[_vm._v(" "+_vm._s(props.column.label)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',{staticClass:"table__title"},[_c('router-link',{attrs:{"to":("/documents/" + (props.row._id) + "/datasets"),"target":_vm.isAdministrator ? "_blank" : ""}},[_c('Icon',{attrs:{"name":"document","color":"CurrentCOlor"}}),_vm._v(" "+_vm._s(props.row.name)+" ")],1)],1):(props.column.field === 'owner')?_c('span',{staticClass:"table__owner overflow-truncate"},[_vm._v(" "+_vm._s(props.row.owner.fullname)+" ")]):(props.column.field == 'status')?_c('span',{staticClass:"table__status",class:{
						'is-validating': props.row.status !== 'finish',
						'is-complete': props.row.status === 'finish'
					}},[(props.row.status === 'finish')?_c('label',{staticClass:"text"},[_vm._v("Complete")]):_c('label',{staticClass:"text"},[_vm._v("Validating")])]):(props.column.field === 'files')?_c('span',{staticClass:"table__files"},[_c('ul',_vm._l((props.row.files),function(file){return _c('li',{key:file._id,staticClass:"overflow-truncate"},[_vm._v(" "+_vm._s(file.filename)+" ")])}),0)]):(props.column.field === 'action')?_c('div',{staticClass:"table__actions"},[_c('ul',[_c('li',[_c('Button',{attrs:{"highlighted":"","size":"small","className":"tertiary","href":("#/documents/" + (props.row._id) + "/datasets"),"target":_vm.isAdministrator ? "_blank" : ""}},[_vm._v(" View ")])],1),_c('li',[_c('Button',{attrs:{"highlighted":"","size":"small","className":"tertiary","href":("#/documents/" + (props.row._id) + "/report"),"target":_vm.isAdministrator ? "_blank" : ""}},[_vm._v(" Report ")])],1),_c('li',[_c('Dropdown',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('Button',{attrs:{"size":"small","className":"tertiary","square":"","highlighted":""}},[_c('Icon',{attrs:{"name":"angle_down","color":"currentColor"}})],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"dropdown__nav"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":("/documents/" + (props.row._id) + "/datasets"),"target":_vm.isAdministrator ? "_blank" : ""}},[_c('Icon',{attrs:{"name":"connect","color":"currentColor"}}),_vm._v(" View/Edit Datasets ")],1)],1),_c('li',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.copyText(
												(_vm.baseRoute + "/" + (props.row._id) + "?token=" + (props.row.token)),
												'Public URL copied !')}}},[_c('Icon',{attrs:{"name":"share","color":"currentColor"}}),_vm._v(" Get Public Share Link ")],1)]),_c('li',[_c('router-link',{attrs:{"to":("/documents/" + (props.row._id) + "/report"),"target":_vm.isAdministrator ? "_blank" : ""}},[_c('Icon',{attrs:{"name":"document_view","color":"currentColor"}}),_vm._v(" View Data Report ")],1)],1)]),(_vm.userRoleWeight >= 1000)?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":("/documents/" + (props.row._id) + "/manage"),"target":_vm.isAdministrator ? "_blank" : ""}},[_c('Icon',{attrs:{"name":"document","color":"currentColor"}}),_vm._v(" Manage Document ")],1)],1),_c('li',[_c('a',{attrs:{"href":("mailto:" + (props.row.owner.username))}},[_c('Icon',{attrs:{"name":"invite","color":"currentColor"}}),_vm._v(" Contact Author ")],1)])]):_vm._e(),_c('ul',[_c('li',{staticClass:"is-highlighted"},[_c('button',{on:{"click":function($event){$event.preventDefault();_vm.openConfirmModal({
													message: ("Are you sure you want to delete " + (props.row.name) + "?"),
													confirm: 'Yes, Delete',
													cancel: 'No, Keep It',
													onConfirm: function () { return _vm.deleteDocument(props.row.name, props.row._id); },
												})}}},[_c('Icon',{attrs:{"name":"trash","color":"currentColor"}}),_vm._v(" Delete Document ")],1)])])])])],1)])]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('Pagination',{attrs:{"itemsPerPage":_vm.serverParams.perPage,"perPageOptions":_vm.perPageOptions,"totalItems":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged}})]}}])},[_c('template',{slot:"loadingContent"},[_c('Spinner')],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }