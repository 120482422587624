<template>
	<div class="form__body">
		<slot />
	</div>
	<!-- /.form__body -->
</template>

<script>
export default {
	/**
	 * Name
	 */
	name: 'FormBody'
};
</script>
