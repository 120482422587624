<template>
	<svg width="30px" height="33px" viewBox="0 0 30 33" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
		<path d="M22.275 31.35L8.25 31.35C4.60638 31.3464 1.65363 28.3936 1.65 24.75L1.65 7.425C1.65 6.9694 1.2806 6.6 0.825 6.6C0.369397 6.6 0 6.9694 0 7.425L0 24.75C0.00533759 29.3041 3.69588 32.9947 8.25 33L22.275 33C22.7306 33 23.1 32.6306 23.1 32.175C23.1 31.7194 22.7306 31.35 22.275 31.35ZM29.4583 10.1417L19.5583 0.241699C19.4036 0.0869109 19.1938 0 18.975 0L11.55 0C8.81739 0.00302131 6.60302 2.21739 6.6 4.95L6.6 21.45C6.60302 24.1826 8.81739 26.397 11.55 26.4L24.75 26.4C27.4826 26.397 29.697 24.1826 29.7 21.45L29.7 10.725C29.7 10.5062 29.6131 10.2964 29.4583 10.1417ZM19.8 2.8165L26.8835 9.9L21.9995 9.9C20.7851 9.89899 19.801 8.91487 19.8 7.70054L19.8 2.8165ZM28.05 21.45C28.0477 23.2716 26.5716 24.7477 24.75 24.75L11.55 24.75C9.72839 24.7477 8.25232 23.2716 8.25 21.45L8.25 4.95C8.25232 3.12839 9.72839 1.65232 11.55 1.65L18.15 1.65L18.15 7.70054C18.1527 9.82538 19.8746 11.5473 21.9995 11.55L28.05 11.55L28.05 21.45ZM11.55 18.15C11.55 18.6056 11.9194 18.975 12.375 18.975L22.275 18.975C22.7306 18.975 23.1 18.6056 23.1 18.15C23.1 17.6944 22.7306 17.325 22.275 17.325L12.375 17.325C11.9194 17.325 11.55 17.6944 11.55 18.15ZM12.375 12.375L15.675 12.375C16.1306 12.375 16.5 12.0056 16.5 11.55C16.5 11.0944 16.1306 10.725 15.675 10.725L12.375 10.725C11.9194 10.725 11.55 11.0944 11.55 11.55C11.55 12.0056 11.9194 12.375 12.375 12.375Z" :fill="color" stroke="none" />
	</svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconDocuments',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
