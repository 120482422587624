<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42px" height="65px" viewBox="0 0 42 65" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-179.000000, -566.000000)" :fill="color" fill-rule="nonzero">
        <path d="M187.90473,565.700298 L188.019792,565.80268 L204.59595,581.670683 C205.393856,582.434502 205.42149,583.70053 204.657671,584.498436 C203.934053,585.254348 202.759699,585.318934 201.959651,584.674186 L201.829917,584.560157 L190.768,573.971 L194.318,585.661 L194.661367,586.540245 C200.346825,600.955383 208.600449,613.875942 219.430704,625.313003 L220.140678,626.056795 C220.906524,626.852756 220.88211,628.11885 220.08615,628.884696 C219.290189,629.650542 218.024094,629.626129 217.258248,628.830168 C205.712532,616.830444 196.93434,603.212621 190.932712,587.988537 L190.561226,587.03494 L190.51402,586.897632 L186.941,575.134 L183.636643,590.085143 C183.409064,591.114651 182.425971,591.781989 181.399025,591.633169 L181.252097,591.606308 C180.222589,591.378729 179.555251,590.395636 179.704071,589.36869 L179.730932,589.221762 L184.683921,566.815727 C185.00933,565.343658 186.770055,564.763182 187.90473,565.700298 Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
	/**
	 * name
	*/
 name: 'icoArrowUp',

	/**
	 * props
	*/
	props: ['color']
}
</script>
