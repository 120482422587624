<template>
	<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.7461 16.1712L6.97803 17.9387C5.61066 19.3038 3.39612 19.3038 2.02887 17.9385C0.660219 16.5718 0.658632 14.3543 2.02539 12.9856L6.61969 8.39082C7.98847 7.02559 10.204 7.02559 11.5728 8.39082C11.7672 8.58131 12.0782 8.58131 12.2726 8.39082C12.4699 8.19759 12.473 7.88106 12.2798 7.68385C10.5206 5.92799 7.67182 5.92799 5.9126 7.68385L1.31836 12.2786C0.473573 13.1232 -0.000669978 14.2691 1.42233e-06 15.4637C0.00152732 17.9491 2.01752 19.9627 4.50293 19.9612C5.69635 19.9633 6.84149 19.4899 7.68506 18.6458L9.45307 16.8782C9.45545 16.8758 9.45783 16.8734 9.46021 16.871C9.65344 16.6738 9.65027 16.3573 9.45307 16.164C9.25586 15.9708 8.93933 15.974 8.7461 16.1712ZM18.645 1.31868C16.8868 -0.43956 14.0361 -0.43956 12.2778 1.31868L10.5107 3.08626C10.5092 3.08784 10.5075 3.08943 10.5059 3.09108C10.312 3.28773 10.3143 3.60439 10.511 3.79829C10.7077 3.9922 11.0244 3.98994 11.2183 3.79329L12.9848 2.02565C14.3712 0.702286 16.5532 0.703567 17.9379 2.02858C17.9382 2.02882 17.9384 2.02901 17.9385 2.02919C19.3052 3.39613 19.3049 5.6122 17.9379 6.97884L13.3432 11.5726C11.9575 12.8958 9.77631 12.8958 8.39056 11.5726C8.19617 11.3821 7.88513 11.3821 7.69074 11.5726C7.49353 11.7658 7.49036 12.0824 7.6836 12.2796C8.52594 13.127 9.67236 13.6021 10.8672 13.5989C12.0619 13.6022 13.2082 13.127 14.0503 12.2796L18.645 7.68587C20.4033 5.92763 20.4033 3.07692 18.645 1.31868Z" :fill="color" stroke="none" />
	</svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconChain',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
