<template>
  <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
	<path d="M12.1 7.15L7.1 12.15C7.1 12.25 7 12.45 7 12.55C7 12.85 7.2 13.05 7.5 13.05C7.6 13.05 7.8 12.95 7.9 12.95L12.9 7.95C13.1 7.75 13.1 7.45 12.9 7.25C12.7 7.05 12.3 7.05 12.1 7.15ZM11.1 13.85L7 18.05C5.6 19.45 3.4 19.45 2 18.05C0.6 16.65 0.6 14.45 2 13.05L6.2 8.85L6.2 8.85C6.4 8.65 6.4 8.35 6.2 8.15C6 7.95 5.7 7.95 5.5 8.15L1.3 12.35C0.5 13.15 0 14.35 0 15.55C1.11022e-15 18.05 2 20.05 4.5 20.05C5.7 20.05 6.8 19.55 7.7 18.75L11.9 14.55C11.9 14.55 11.9 14.55 11.9 14.55C12.1 14.35 12.1 14.05 11.9 13.85C11.7 13.65 11.3 13.65 11.1 13.85ZM18.7 7.75C20.5 5.95 20.5 3.15 18.7 1.35C16.9 -0.45 14.1 -0.45 12.3 1.35L8.1 5.55C8.1 5.55 8.1 5.55 8.1 5.55C7.9 5.75 7.9 6.05 8.1 6.25C8.3 6.45 8.6 6.45 8.8 6.25L13 2.05C14.4 0.65 16.6 0.65 18 2.05C19.4 3.45 19.4 5.65 18 7.05L13.8 11.25C13.7 11.35 13.7 11.45 13.7 11.65C13.7 11.95 13.9 12.15 14.2 12.15C14.3 12.15 14.5 12.05 14.6 12.05L18.7 7.75L18.7 7.75ZM19.5 17.05L18 17.05L18 15.55C18 15.25 17.8 15.05 17.5 15.05C17.2 15.05 17 15.25 17 15.55L17 17.05L15.5 17.05C15.2 17.05 15 17.25 15 17.55C15 17.85 15.2 18.05 15.5 18.05L17 18.05L17 19.55C17 19.85 17.2 20.05 17.5 20.05L17.5 20.05C17.8 20.05 18 19.85 18 19.55L18 18.05L19.5 18.05C19.8 18.05 20 17.85 20 17.55C20 17.25 19.8 17.05 19.5 17.05Z" transform="translate(-1.110223E-15 0.95)" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconShare',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
