<template>
	<svg width="18px" height="20px" version="1.1" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fill-rule="evenodd">
			<g transform="translate(-1291 -313)" :fill="color" fill-rule="nonzero">
				<g transform="translate(1267 48)">
					<g transform="translate(24 265)">
						<path
							d="m16.5 13.5h-7.8l2.1-2.1c0.2-0.2 0.2-0.5 0-0.7s-0.5-0.2-0.7 0l-3 3v0.1l-0.1 0.1v0.2 0.2c0 0.1 0.1 0.1 0.1 0.2l3 3c0.1 0.1 0.2 0.1 0.4 0.1 0.1 0 0.3-0.1 0.4-0.1 0.2-0.2 0.2-0.5 0-0.7l-2.1-2.1h7.8c0.3 0 0.5-0.2 0.5-0.5-0.1-0.5-0.3-0.7-0.6-0.7zm-1 3.5c-0.2 0-0.5 0.1-0.5 0.4-0.2 0.9-1 1.6-2 1.6h-10c-1.1 0-2-0.9-2-2v-14c0-1.1 0.9-2 2-2h6v3.7c0 1.3 1 2.3 2.3 2.3h3.7v3.5c0 0.3 0.2 0.5 0.5 0.5s0.5-0.2 0.5-0.5v-4-0.2c0-0.1-0.1-0.1-0.1-0.2l-6-6s-0.1-0.1-0.2-0.1h-0.2-6.5c-1.7 0-3 1.3-3 3v14c0 1.7 1.3 3 3 3h10c1.4 0 2.7-1 2.9-2.4 0.1-0.3-0.1-0.5-0.4-0.6zm-5.5-15.3 4.3 4.3h-3c-0.7 0-1.3-0.6-1.3-1.3v-3z"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'IconDocumentImport',

	/**
	 * color
	 */
	props: ['color']
};
</script>
