<template>
  <svg width="27px" height="33px" viewBox="0 0 27 33" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.425 11.55L10.725 11.55C11.1806 11.55 11.55 11.1806 11.55 10.725C11.55 10.2694 11.1806 9.9 10.725 9.9L7.425 9.9C6.9694 9.9 6.6 10.2694 6.6 10.725C6.6 11.1806 6.9694 11.55 7.425 11.55ZM26.3358 10.4074C26.294 10.3072 26.2335 10.2168 26.1578 10.1412L16.2588 0.242203C16.1832 0.16647 16.0928 0.106046 15.9926 0.064151C15.8928 0.0224578 15.7852 0 15.675 0L4.95 0C2.21739 0.00302131 0.00302131 2.21739 0 4.95L0 28.05C0.00302131 30.7826 2.21739 32.997 4.95 33L21.45 33C24.1826 32.997 26.397 30.7826 26.4 28.05L26.4 10.725C26.4 10.6148 26.3775 10.5072 26.3358 10.4074ZM16.5 2.8165L23.5835 9.9L18.6995 9.9C17.4851 9.89899 16.501 8.91487 16.5 7.70054L16.5 2.8165ZM24.75 28.05C24.7477 29.8716 23.2716 31.3477 21.45 31.35L4.95 31.35C3.12839 31.3477 1.65232 29.8716 1.65 28.05L1.65 4.95C1.65232 3.12839 3.12839 1.65232 4.95 1.65L14.85 1.65L14.85 7.70054C14.8527 9.82537 16.5746 11.5473 18.6995 11.55L24.75 11.55L24.75 28.05ZM6.6 17.325C6.6 17.7806 6.9694 18.15 7.425 18.15L18.975 18.15C19.4306 18.15 19.8 17.7806 19.8 17.325C19.8 16.8694 19.4306 16.5 18.975 16.5L7.425 16.5C6.9694 16.5 6.6 16.8694 6.6 17.325ZM18.975 23.1L7.425 23.1C6.9694 23.1 6.6 23.4694 6.6 23.925C6.6 24.3806 6.9694 24.75 7.425 24.75L18.975 24.75C19.4306 24.75 19.8 24.3806 19.8 23.925C19.8 23.4694 19.4306 23.1 18.975 23.1Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconDocument',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
