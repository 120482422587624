<template>
  <svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 19L3 19C1.896 18.9986 1.0014 18.104 1 17L1 3C1.0014 1.896 1.896 1.0014 3 1L9 1L9 4.66699C9.00165 5.95477 10.0452 6.99835 11.333 7L15 7L15 12.7097C15 12.9858 15.2239 13.2097 15.5 13.2097C15.7761 13.2097 16 12.9858 16 12.7097L16 6.5C16 6.36737 15.9473 6.24023 15.8535 6.14648L9.85352 0.146484C9.75977 0.0526733 9.63263 0 9.5 0L3 0C1.34387 0.0018311 0.0018311 1.34387 0 3L0 17C0.0018311 18.6561 1.34387 19.9982 3 20L8.5 20C8.77612 20 9 19.7761 9 19.5C9 19.2239 8.77612 19 8.5 19ZM6.5 6L4.5 6C4.22388 6 4 6.22388 4 6.5C4 6.77612 4.22388 7 4.5 7L6.5 7C6.77612 7 7 6.77612 7 6.5C7 6.22388 6.77612 6 6.5 6ZM12 10.5C12 10.2239 11.7761 10 11.5 10L4.5 10C4.22388 10 4 10.2239 4 10.5C4 10.7761 4.22388 11 4.5 11L11.5 11C11.7761 11 12 10.7761 12 10.5ZM9.5 14C9.77612 14 10 14.2239 10 14.5C10 14.7761 9.77612 15 9.5 15L4.5 15C4.22388 15 4 14.7761 4 14.5C4 14.2239 4.22388 14 4.5 14L9.5 14ZM14.5925 14.5439C12.637 14.5439 10.9916 15.4225 9.69318 17.1459C9.56256 17.3193 9.55872 17.5571 9.68369 17.7346C10.9844 19.5821 12.6326 20.5272 14.5925 20.5272C16.5525 20.5272 18.2006 19.5821 19.5014 17.7346C19.6263 17.5571 19.6225 17.3193 19.4919 17.1459C18.1934 15.4225 16.548 14.5439 14.5925 14.5439ZM14.8477 15.5499C16.1965 15.6141 17.3509 16.1925 18.333 17.3053L18.461 17.457L18.3403 17.6124C17.289 18.8993 16.0485 19.5272 14.5925 19.5272L14.3519 19.5214C12.9993 19.4554 11.8376 18.8278 10.8448 17.6124L10.723 17.457L10.8521 17.3053C11.8955 16.123 13.1335 15.5439 14.5925 15.5439L14.8477 15.5499ZM13 17.5C13 18.3284 13.6716 19 14.5 19C15.3284 19 16 18.3284 16 17.5C16 16.6716 15.3284 16 14.5 16C13.6716 16 13 16.6716 13 17.5Z" :fill="color" fill-rule="evenodd" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconDocumentView',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
