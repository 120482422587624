<template>
	<ul class="dropdown__nav-datasets">
		<li v-for="dataObject in dataObjects" :key="dataObject._id">
			<button
				:class="{
					'is-active': dataObject._id === activeDataObjectId
				}"
				@click.prevent="$emit('dropdownButtonClick', dataObject);"
			>
				<Icon :name="icons[dataObject.dataObjectType]" color="currentColor" width="19" height="21" />

				<span>{{ dataObject.name ? dataObject.name : dataObject._id }}</span>

				<Icon v-if="dataObject._id === activeDataObjectId" name="check" :color="cssVariables.base" />
			</button>
		</li>
	</ul>
</template>

<script>
	/**
	 * External Dependencies
	 */
	import { mapGetters } from 'vuex'
	
	/**
	 * Internal Dependencies
	 */
	import variables from '@/assets/scss/generic/_variables.scss'
	import Icon from '@/components/icon/icon'
	
	export default {
		/**
		 * Name
		 */
		name: "DropdownNavDatasets",

		components: {
			Icon,
		},

		/**
		 * props
		 */
		props: {
			dataObjects: {
				type: Array,
				default: () => []
			},
		},

		data() {
			return {
				icons: {
					unknow: 'datasets',
					dataset: 'datasets',
					code: 'brackets',
					material: 'flask',
					protocol: 'protocols'
				}
			}
		},

		computed: {
			...mapGetters('pdfViewer', [
				'activeDataObjectId'
			]),
			cssVariables() {
				return variables
			},
		}
	}
</script>

