<template>
	<Form ref="form" className="form--edit" :loading="loading">
		<FormStatus v-if="error || success" :text="message" :isError="error" />

		<FormBody>
			<FormGroup title="Document Info">
				<Grid columnGap="large" columnSize="half">
					<GridColumn>
						<Grid rowGap="small">
							<GridColumn>
								<Field
									type="textarea"
									size="medium"
									placeholder="Name"
									v-model="formData.name"
									name="Document Title"
								>
									<Icon name="document" color="currentColor" />

									Document Name
								</Field>
							</GridColumn>

							<GridColumn v-if="userRoleWeight >= 1000">
								<FieldSelect
									placeholder="Select Owner"
									v-model="formData.owner"
									:options="ownersList"
								>
									<Icon name="user" color="currentColor" />

									Document Owner(s)
								</FieldSelect>
							</GridColumn>

							<GridColumn v-if="userRoleWeight >= 1000">
								<FieldSelect
									multiple
									v-model="formData.organizations"
									:options="organizationsList"
								>
									<Icon name="organization" color="currentColor" />

									Institution/Organization(s)
								</FieldSelect>
							</GridColumn>
						</Grid>
					</GridColumn>

					<GridColumn>
						<Grid rowGap="small">
							<GridColumn>
								<Field
									name="Journal"
									v-model="formMetadata.journal"
								>
									<Icon name="book" color="currentColor" />

									Journal
								</Field>
							</GridColumn>
								<Field
									name="Publisher"
									v-model="formMetadata.publisher"
								>
									<Icon name="book" color="currentColor" />

									Publisher
								</Field>
							<GridColumn>

							</GridColumn>
							
							<GridColumn>
								<FieldDatepicker
									placeholder="From"
									v-model="formMetadata.createdAt"
								>
									<Icon name="book" color="currentColor" />

									Publish Date
								</FieldDatepicker>
							</GridColumn>
							
							<GridColumn>
								<Field
									name="DOI"
									trailingIcon="refresh"
									v-model="formMetadata.doi"
								>
									<Icon name="book" color="currentColor" />

									DOI
								</Field>
							</GridColumn>
						</Grid>
					</GridColumn>
				</Grid>
				<Grid columnGap="large">
					<GridColumn>
						<Field
							type="textarea"
							placeholder="Article title"
							v-model="formMetadata.article_title"
							name="article_title"
						>
							<Icon name="document" color="currentColor" />

							Article title
						</Field>
					</GridColumn>
					<GridColumn>
						<Field
							type="textarea"
							placeholder="Data Availability Statement"
							v-model="formMetadata.DAS.content"
							name="DAS.content"
						>
							<Icon name="document" color="currentColor" />

							Data Avalaibility Statement
						</Field>
					</GridColumn>
					<GridColumn>
						<Field
							type="textarea"
							placeholder="Materials Availability Statement"
							v-model="formMetadata.MAS.content"
							name="MAS.content"
						>
							<Icon name="document" color="currentColor" />

							Materials Avalaibility Statement
						</Field>
					</GridColumn>
					<GridColumn>
						<Field
							placeholder="Original File (URL)"
							v-model="formData.urls.originalFile"
							name="originalFile"
						>
							<Icon name="chain" color="currentColor" />

							Original File (URL)
						</Field>
					</GridColumn>
					<GridColumn v-if="isHHMI">
						<Field
							placeholder="Preprint URL"
							v-model="formData.HHMI.preprint"
							name="HHMI.preprint"
						>
							<Icon name="chain" color="currentColor" />

							HHMI Preprint URL
						</Field>
					</GridColumn>
					<GridColumn v-if="isHHMI">
						<Field
							placeholder="Preprint DOI"
							v-model="formData.HHMI.DOI"
							name="HHMI.DOI"
						>
							<Icon name="book" color="currentColor" />

							HHMI PI ORCID
						</Field>
					</GridColumn>
					<GridColumn>
						<Field
							placeholder="License"
							v-model="formMetadata.license"
							name="License"
						>
							<Icon name="document" color="currentColor" />

							License
						</Field>
					</GridColumn>
					<GridColumn>
						<Field
							type="textarea"
							size="medium"
							placeholder="Acknowledgement"
							v-model="formMetadata.acknowledgement"
							name="Acknowledgement"
						>
							<Icon name="book" color="currentColor" />

							Acknowledgement
						</Field>
					</GridColumn>
					<GridColumn>
						<Field
							placeholder="Affiliation"
							v-model="formMetadata.affiliation"
							name="Affiliation"
						>
							<Icon name="paperclip" color="currentColor" />

							Affiliation
						</Field>
					</GridColumn>
				</Grid>
			</FormGroup>

			<FormGroup title="Hypothes.is annotation" v-if="userRoleWeight >= 1000">
				<Grid columnGap="large">
					<GridColumn>
						<Field
							placeholder="https://www.biorxiv.org/content/..."
							v-model="formData.urls.hypothesis"
							name="hypothesisURL"
						>
							<Icon name="chain" color="currentColor" />

							URL to which the annotation will be attached (designed for bioRxiv documents only)
						</Field>
					</GridColumn>
					<GridColumn>
						<Button type="button" @onClick.prevent="handleUpdateOrCreateHypothesisAnnotation()">Update Hypothes.is Annotation</Button>
					</GridColumn>
				</Grid>
			</FormGroup>

			<FormGroup title="Authors">
				<Accordion>
					<AccordionItem v-for="(author, index) in formattedAuthors" :key="index" :label="author.name ? author.name : 'Unknow'">
						<Grid columnGap="large">
							<GridColumn>
								<Field
									:name="`author-${index}-name`"
									:value="authors[index].name"
									@input="value => authors[index].name = value"
								>
									Name
								</Field>
							</GridColumn>
							<GridColumn>
								<Field
									:name="`author-${index}-email`"
									v-model="authors[index].email"
								>
									Email
								</Field>
							</GridColumn>
							<GridColumn>
								<Field
									readonly 
									type="textarea"
									:value="authors[index].affiliations.join('\n')"
									:name="`author-${index}-affiliations`"
								>
									Affiliations
								</Field>
							</GridColumn>
							<GridColumn>
								<Field
									v-model="authors[index].orcid.currentValue"
									:name="`author-${index}-ORCID`"
								>
									ORCID
								</Field>
							</GridColumn>
							<GridColumn>
								<Field
									readonly 
									type="textarea"
									v-model="authors[index].orcid.suggestedValues"
									:name="`author-${index}-sugestedORCID`"
									:ref="`author-${index}-sugestedORCID`"
								>
									Suggested ORCIDs
								</Field>
							</GridColumn>
						</Grid>
						<br/>
						<Grid columnGap="large" columnSize="half">
							<GridColumn>
								<Checkboxes vertical>
									<FieldCheckbox :name="`author-${index}-ASAPAffiliationInUpload`" v-model="authors[index].orcid.ASAPAffiliationInUpload" isToggle>
										ASAP Affiliation in upload
									</FieldCheckbox>
								</Checkboxes>
							</GridColumn>
							<GridColumn>
								<Checkboxes vertical>
									<FieldCheckbox :name="`author-${index}-partOfASAPNetwork`" v-model="authors[index].orcid.partOfASAPNetwork" isToggle>
										Part of ASAP Network
									</FieldCheckbox>
								</Checkboxes>
							</GridColumn>
						</Grid>
						<br/>
						<Grid columnGap="large" columnSize="half">
							<GridColumn>
								<Button type="button" @onClick.prevent="openConfirmModal({
														message: `Are you sure you want to delete ${authors[index].name ? authors[index].name : 'this author'}?`,
														confirm: 'Yes, Delete',
														cancel: 'No, Keep It',
														onConfirm: () => handleDeleteAuthorSubmit(index),
													})">Delete this author</Button>
							</GridColumn>
							<GridColumn>
								<Button type="button" @onClick.prevent="handleRefreshSuggestedORCIDsSubmit(index)" style="float: right;">Refresh Suggested ORCIDs</Button>
							</GridColumn>
						</Grid>
					</AccordionItem>

					<AccordionItem label="Add a new author">
						<Grid columnGap="large">
							<GridColumn>
								<Field
									:name="`new-author-name`"
									:value="newAuthor.name"
									@input="value => newAuthor.name = value"
								>
									Name
								</Field>
							</GridColumn>
							<GridColumn>
								<Field
									:name="`new-author-email`"
									v-model="newAuthor.email"
								>
									Email
								</Field>
							</GridColumn>
							<GridColumn>
								<Field
									:name="`new-author-ORCID`"
									v-model="newAuthor.orcid.currentValue"
								>
									ORCID
								</Field>
							</GridColumn>
							<GridColumn>
								<Button type="button" @onClick.prevent="handleNewAuthorSubmit">Add a new author</Button>
							</GridColumn>
						</Grid>
					</AccordionItem>
				</Accordion>
			</FormGroup>
			
			<FormGroup title="Files" v-if="userRoleWeight >= 1000">
				<Grid columnGap="large" columnSize="half">
					<GridColumn>
						<Grid rowGap="small">
							<GridColumn>
								<Field placeholder="Enter Display Name" v-model="primaryFileName" name="Display Name">
									<Icon name="document" color="currentColor" />

									Primary File Display Name
								</Field>
							</GridColumn>
							
							<GridColumn>
								<FieldFile
									v-model="primaryFile"
									accept=".docx, .pdf"
									name="primaryFile"
									buttonText="Upload New Version"
									alt
								>
									<template #label>
										<Icon name="document_new" color="currentColor" />

										Primary File
									</template>
								</FieldFile>
							</GridColumn>
						</Grid>
					</GridColumn>

					<GridColumn>
						<FieldFile
							v-model="appendFiles"
							name="appendFiles"
							buttonText="Upload Files"
							multiple
							alt
						>
							<template #label>
								<Icon name="document_new" color="currentColor" />

								Append File(s)
							</template>
						</FieldFile>
					</GridColumn>
				</Grid>
			</FormGroup>

			<FormGroup title="Settings" v-if="userRoleWeight >= 1000">
				<Grid columnGap="large" columnSize="half">
					<GridColumn fullwidth>
						<Checkboxes vertical>
							<FieldCheckbox name="visible" v-model="formData.visible" isToggle>
								Document Is {{ formData.visible ? 'Active' : 'Inactive'}}
							</FieldCheckbox>
							
							<FieldCheckbox name="locked" v-model="formData.locked" isToggle>
								Document Is {{ formData.locked ? 'Locked' : 'Not Locked'}}
							</FieldCheckbox>
						</Checkboxes>
					</GridColumn>
				</Grid>
			</FormGroup>
		</FormBody>

		<FormActions>
			<li>
				<Button type="button" @onClick.prevent="handleFormSubmit">Save Changes</Button>
			</li>

			<li>
				<Button
					type="button"
					className="tertiary"
					@onClick.prevent="$router.go(-1)"
				>
					Cancel
				</Button>
			</li>

			<li>
				<Button type="button" className="tertiary" @onClick.prevent="handleDocumentDelete">
					<Icon name="trash" color="#E36329" />

					Delete Document
				</Button>
			</li>
		</FormActions>
	</Form>
</template>

<script>
/**
 * External Dependencies
 */
import { mapGetters } from 'vuex';
/**
 * Internal Dependencies
 */
import Checkboxes from '@/components/checkboxes/checkboxes';
import Form, { FormStatus, FormActions, FormBody, FormGroup } from '@/components/form/form';
import Icon from '@/components/icon/icon';
import Grid, { GridColumn } from '@/components/grid/grid';
import Field from '@/components/field/field';
import Button from '@/components/button/button';
import FieldFile from '@/components/field-file/field-file';
import FieldDatepicker from '@/components/field-datepicker/field-datepicker';
import FieldSelect from '@/components/field-select/field-select';
import FieldCheckbox from '@/components/field-checkbox/field-checkbox';
import Accordion, { AccordionItem } from '@/components/accordion/accordion';

import documentsService from '@/services/documents/documents';
import hypothesisService from '@/services/hypothesis/hypothesis';
import accountsService from '@/services/account/accounts';
import organizationsService from '@/services/organizations/organizations';

export default {
	/**
	 * Name
	 */
	name: 'FormManageDocument',

	/**
	 * Components
	 */
	components: {
		Accordion,
		AccordionItem,
		Form,
		FormBody,
		FormGroup,
		FormStatus,
		FormActions,
		Checkboxes,
		Icon,
		Grid,
		GridColumn,
		Field,
		Button,
		FieldFile,
		FieldSelect,
		FieldCheckbox,
		FieldDatepicker
	},

	/**
	 *Props
	 */
	props: {
		document: {
			type: Object,
			default: () => {}
		},
		isHHMI: {
			type: Boolean,
			default: false
		},
	},

	/**
	 * Data
	 */
	data: function() {
		return {
			newAuthor: {
				name: '',
				email: '',
				orcid: {
					suggestedValues: [],
					currentValue: '',
					fromTEI: [],
					fromAPI: []
				},
				affiliations: []
			},
			loading: false,
			error: false,
			success: false,
			message: '',
			authors: [],
			primaryFileName: '',
			primaryFile: '',
			appendFiles: '',
			formData: {
				organizations: [],
				owner: "",
				urls: {
					originalFile: ""
				},
				HHMI: {
					URL: "",
					DOI: ""
				}
			},
			formMetadata: {
				readmeIncluded: {},
				DAS: {
					content: "",
				},
				MAS: {
					content: "",
				},
				describesFiles: {},
				describesVariables: {}
			},
			ownersList: [],
			organizationsList: [],
			documentConfirmDeleteMessage: 'Are you sure you want to delete this document?',
		};
	},
	
	/**
	 * Methods
	 */
	computed: {
		...mapGetters('account', ['userRoleWeight']),
		documentId() {
			return this.document._id
		},
		formattedAuthors() {
			return this.authors
		}
	},

	/**
	 * Methods
	 */
	methods: {
		async handleRefreshSuggestedORCIDsSubmit(index) {
			await this.updateMateada({
					...this.formMetadata,
					refreshORCIDsFromASAPList: true,
					automaticallySetPartOfASAPNetwork: true,
					authors: this.authors
				});
			this.$refs[`author-${index}-sugestedORCID`].value = this.suggestedORCIDsFromAuthors(index).join('\n');
		},
		suggestedORCIDsFromAuthors(index) {
			return [].concat(
				this.authors[index].orcid.suggestedValues.length > 0 ? ["Found in ASAP authors list:"].concat(this.authors[index].orcid.suggestedValues, ['']) : [],
				this.authors[index].orcid.fromTEI.length > 0 ? ["Found in PDF:"].concat(this.authors[index].orcid.fromTEI, ['']) : []
			);
		},
		async getDropdownOptions() {
			const accounts = await accountsService.getAccountsList();
			const organizations = await organizationsService.getOrganizationsList();
			
			this.ownersList = accounts.filter(function(a) {
				return a.label.indexOf('@') > -1;
			});
			this.organizationsList = organizations;
		},
		parseDataToForm(data) {
			if (!data) return;
			const {
				name,
				owner,
				visible,
				locked,
				urls,
				HHMI,
				organizations,
				metadata: {
					readmeIncluded,
					DAS,
					MAS,
					describesFiles,
					describesVariables,
					authors,
					journal,
					article_title,
					doi,
					createdAt,
					publisher,
					license,
					acknowledgement,
					affiliation
				},
				files
			} = data;

			// Populate authors
			if (Array.isArray(authors)) {
				this.authors = authors
					.map((item, index) => ({
						id: index,
						authors: item.affiliations.join(', '),
						...item,
					}))
			}

			// Populate metadata
			if (typeof data.metadata === "object") {
				this.formMetadata = {
					readmeIncluded: readmeIncluded,
					DAS: DAS,
					MAS: MAS,
					describesFiles: describesFiles,
					describesVariables: describesVariables,
					journal: journal,
					article_title: article_title,
					license: license,
					acknowledgement: acknowledgement,
					affiliation: affiliation,
					publisher: publisher,
					createdAt: createdAt,
					doi: doi
				}
			}
			
			// Populate form data
			if (typeof name !== "undefined") this.formData.name = name;
			if (typeof visible !== "undefined") this.formData.visible = visible;
			if (typeof locked !== "undefined") this.formData.locked = locked;
			if (Array.isArray(organizations)) {
				this.formData.organizations = organizations.map((organization) => organization._id ? organization._id : organization);
			}

			if (typeof owner === "object") {
				this.formData.owner = owner._id;
			} else if (typeof owner === "string") {
				this.formData.owner = owner;
			}
			if (typeof urls === "object") {
				if (typeof urls.originalFile !== "undefined") this.formData.urls.originalFile = urls.originalFile;
				if (typeof urls.hypothesis !== "undefined") this.formData.urls.hypothesis = urls.hypothesis;
			}

			if (typeof HHMI === "object") {
				if (HHMI.preprint) this.formData.HHMI.preprint = HHMI.preprint;
				if (HHMI.DOI) this.formData.HHMI.DOI = HHMI.DOI;
			}

			if (Array.isArray(files)) {
				// Populate files
				if (typeof files[0] === "object") {
					this.primaryFileName = files[0].filename;
					this.primaryFile = [files[0]];
				}
				const appendFiles = files.filter((_, index) => index > 0 && typeof _ === "object");
				this.appendFiles = appendFiles.length > 0 ? appendFiles : this.appendFiles;
			}
		},
		resetForm() {
			this.loading = false;
			this.error = false;
			this.success = false;
			this.message = '';
		},
		async handleNewAuthorSubmit() {
			this.authors.push({...this.newAuthor});
			this.newAuthor.name = '';
			this.newAuthor.email = '';
			this.newAuthor.orcid.currentValue = '';
			await this.updateMateada({
					...this.formMetadata,
					refreshORCIDsFromASAPList: true,
					automaticallySetPartOfASAPNetwork: true,
					automaticallySetASAPAffiliationInUpload: true,
					authors: this.authors
				});
		},
		async handleDeleteAuthorSubmit(index) {
			this.authors.splice(index, 1);
			await this.updateMateada({
					...this.formMetadata,
					refreshORCIDsFromASAPList: true,
					authors: this.authors
				});
		},
		async updateMateada(params) {
			this.loading = true;
			try {
				const metadata = await documentsService.updateDocumentMetadata(this.documentId, params);
				this.parseDataToForm({ metadata });
				this.success = true;
				this.message = `${this.formData.name} has been updated!`;
			} catch (error) {
				this.error = true;
				this.message = error.message;
			}
			this.loading = false;
		},
		async handleFormSubmit() {
			this.resetForm();
			this.loading = true;
			try {
				const updatedDocument = await documentsService.updateDocument(this.documentId, this.formData);
				this.parseDataToForm(updatedDocument);
				await this.updateMateada({
						...this.formMetadata,
						authors: this.authors
					});
				this.success = true;
				this.message = `${this.formData.name} has been updated!`;
			} catch (error) {
				this.error = true;
				this.message = error.message;
			}

			this.loading = false;
		},
		async handleDocumentDelete() {
			const confirm = window.confirm(this.documentConfirmDeleteMessage);

			if (!confirm) return
			
			this.resetForm();
			
			try {
				await documentsService.deleteDocument(this.documentId);
				
				this.message = `${this.formData.name} has been deleted!`;
				this.$router.push('/documents');
			} catch (error) {
				this.error = true;
				this.message = error.message;
			}
		},
		async handleUpdateOrCreateHypothesisAnnotation() {
			this.resetForm();
			this.loading = true;
			try {
				await hypothesisService.updateOrCreateAnnotation({ id: this.documentId, url: this.formData.urls.hypothesis });
				this.success = true;
				this.message = `Hypothes.is annotation has been created!`;
			} catch (error) {
				this.error = true;
				this.message = `Hypothes.is annotation creation failed.`;
			}

			this.loading = false;
		}
	},

	/**
	 * Created
	 */
	created () {
			this.getDropdownOptions();
			this.parseDataToForm(this.document);
	},
};
</script>
