<template>
	<li>
		<div
			class="checkbox"
			:class="{
				[className]: className,
				'has-error': error,
				'checkbox--select': isDropdown,
				'checkbox--toggle': isToggle,
				'checkbox--default': isDefault
			}"
		>
			<input
				tabindex="0"
				type="checkbox"
				ref="checkbox"
				class="sr-only"
				:name="name"
				:id="toKebabCase(name)"
				:checked="sanitizedValue === true"
				:value="sanitizedValue"
				:disabled="disabled"
				@change="handleChange"
			/>

			<label class="checkbox__label" v-if="!isDropdown" :for="toKebabCase(name)">
				<slot />

				<Icon v-if="isDefault && sanitizedValue === true" name="check" />
			</label>

			<p v-if="isDropdown">
				<slot />
			</p>

			<div class="field" v-if="isDropdown">
				<vSelect
					placeholder="Select"
					:id="toKebabCase(name)"
					:clearable="false"
					:searchable="false"
					:options="selectOptions"
					label="label"
					:value="sanitizedValue"
					:reduce="option => option.value"
					@input="setSelected"
					:disabled="disabled"
				>
					<template #option="{ label }">
						<span :data-title="label">
							{{ label }}
						</span>
					</template>

					<template #selected-option="{ label }">
						<span :data-title="label">
							{{ label }}
						</span>
					</template>
				</vSelect>
			</div>
			<!-- /.field -->
		</div>
		<!-- /.checkbox -->
	</li>
</template>

<script>
/**
 * External Dependencies
 */
import vSelect from 'vue-select';

/**
 * Internal Dependencies
 */
import Icon from '@/components/icon/icon';
import toKebabCase from '@/utils/str-to-kebab-case';

export default {
	/**
	 * Name
	 */
	name: 'FieldCheckbox',

	/**
	 * Components
	 */
	components: {
		Icon,
		vSelect
	},

	/**
	 * Props
	 */
	props: {
		value: {
			default: undefined,
		},
		name: String,
		className: String,
		error: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		isDropdown: {
			type: Boolean,
			default: false
		},
		isToggle: {
			type: Boolean,
			default: false
		},
		trueLabel: {
			type: String,
			default: 'Yes'
		},
		falseLabel: {
			type: String,
			default: 'No'
		},
		/**
		 * Whether to enable the N/A option. By default, the checkbox will only toggle 
		 * between true/false states. When enabled, it adds a third "N/A" state with value "N/A".
		 */
		allowNA: {
			type: Boolean,
			default: false
		},
		/**
		 * Custom label for the N/A option when allowNA is true
		 */
		naLabel: {
			type: String,
			default: 'N/A'
		}
	},

	/**
	 * Data
	 */
	data: function() {
		const baseOptions = [
			{
				label: 'Select',
				value: ""
			},
			{
				label: this.trueLabel,
				value: true
			},
			{
				label: this.falseLabel,
				value: false
			}
		];

		// Only add N/A option if explicitly enabled
		return {
			selectOptions: this.allowNA 
				? [...baseOptions, { label: this.naLabel, value: "N/A" }]
				: baseOptions
		};
	},

	computed: {
		sanitizedValue() {
			// Handle N/A case
			if (this.value === "N/A" && this.allowNA) return "N/A";
			
			// Handle boolean and undefined cases
			if (typeof this.value === 'boolean' || this.value === undefined) return this.value;
			
			// Handle string values
			switch(this.value.toLowerCase().trim()) {
				case 'true':
					return true;
				case 'n/a':
					// Only return "N/A" if N/A is allowed, otherwise treat as false
					return this.allowNA ? "N/A" : false;
				case '':
				case 'false':
					return false;
				default: 
					return this.value;
			}
		},
		isDefault() {
			return !this.isToggle && !this.isDropdown;
		}
	},

	/**
	 * Methods
	 */
	methods: {
		handleChange(event) {
			// For non-dropdown checkbox, handle state cycling
			if (!this.isDropdown && this.allowNA) {
				const currentValue = this.sanitizedValue;
				let newValue;
				
				if (currentValue === false) newValue = true;
				else if (currentValue === true) newValue = this.allowNA ? "N/A" : false;
				else newValue = false;
				
				this.$emit('onChange', { target: { checked: newValue }});
				this.$emit('input', newValue);
			} else {
				// Standard true/false toggle
				this.$emit('onChange', event);
				this.$emit('input', event.target.checked);
			}
		},
		setSelected(value) {
			this.$emit('input', value);
		},
		toKebabCase
	},
};
</script>
