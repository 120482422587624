/* eslint-disable */

/**
 * External Dependencies 
 */
import $ from 'jquery';
import async from 'async';

/**
 * Internal Dependencies
 */
import API from './api';
import { formatDataObject, setDataObjectDataType, getDataObjectType, DATATYPE_COLORS, sortDataObjects } from '@/utils/use-dataObjects'

export const DocumentHandler = function(opts = {}, events) {
	let self = this;
	this.ids = opts.ids;
	// It will store change states for each dataObject
	this.hasChanged = {};
	// Events
	this.events = events;
	this.lastAction = {
		type: undefined, // What kind of action it was
		data: undefined // Data that were involved
	};
	// It will store ready states for each elements (dataObjectForm, dataObjectsList & documentView)
	this.ready = {
		dataObjectForm: false,
		dataObjectsList: false,
		documentView: false
	};
	//Here we store information about each colored sentence
	this.colors = {};
	this.sentencesMapping = undefined;
	this.user = opts.user;
	this.datatypes = opts.datatypes;
	this.dataObjects = opts.dataObjects;
	this.metadata = opts.metadata;
	this.tei = { data: opts.tei.data, metadata: { mapping: opts.tei.metadata.mapping } };
	
	//Here we store information active DataObject and Datatype
	this.activeDataObjectType = opts.activeDataObjectType;
	this.activeDataObjectId = opts.activeDataObjectId;

	//Active Sentences
	this.activeSentence = undefined;
	
	if (opts.pdf) {
		this.pdf = {
			url: opts.pdf.url,
			metadata: {
				sentences: opts.pdf.metadata.sentences,
				pages: opts.pdf.metadata.pages,
				mapping: opts.pdf.metadata.mapping
			}
		};
	};
	
	// Here we update the information for each colored sentence, based on the current dataObjects
	for (let i = 0; i < opts.dataObjects.current.length; i++) {
		self.dataObjects.current.map((dataObject) => {
			this.colors[dataObject._id] = {
				...dataObject.color,
				dataObjectType: dataObject.dataObjectType
			};
		})
	}

	return this;
};

// Attach event
DocumentHandler.prototype.attach = function(event, fn) {
	this.events[event] = fn;
};

// Check if document isReady
DocumentHandler.prototype.isReady = function(key, value) {
	if (typeof key === `string` && typeof value === `boolean`) {
		this.ready[key] = value;
		if (this.isReady()) return this.init();
	} else {
		let result = true;
		for (let key in this.ready) {
			result = result && this.ready[key];
			if (!result) return result;
		}
		return result;
	}
};

// Refresh the sentences mapping
DocumentHandler.prototype.refreshSentencesMapping = function() {
	if (this.sentencesMapping) {
		if (this.documentView.pdfVisible)
			this.dataObjectsList.setSentencesMapping(this.sentencesMapping.pdf);
		else this.dataObjectsList.setSentencesMapping(this.sentencesMapping.xml);
	}
};

// Refresh the sentences mapping
DocumentHandler.prototype.getSentencesIndexesLimit = function() {
	return this.documentView.getSentencesIndexesLimit();
}

// Attach event
DocumentHandler.prototype.init = function() {
	// refresh mapping
	this.sentencesMapping = this.documentView.getSentencesMapping();
	this.refreshSentencesMapping();
	
	let self = this;
	//let firstId = this.dataObjectsList.getFirstDataObjectId();
	let	firstId = this.activeDataObjectId;
	let	dataObject = firstId ? this.getDataObject(firstId) : undefined;
	let	sentence = dataObject ? dataObject.sentences[0] : undefined;

	if (typeof self.events.onInit === `function`) return self.events.onInit();
	console.log(`init`);
	
	// if (!dataObject) this.dataObjectForm.hide();
	if (sentence) {
		// this.dataObjectsList.refreshMsg();
		this.selectSentence({ sentence: sentence, noAnim: true }, function() {
			if (typeof self.events.onReady === `function`) return self.events.onReady(dataObject);
		});
	} else {
		// this.dataObjectForm.hide();
		// this.dataObjectForm.setEmptyMessage();
		// this.dataObjectsList.refreshMsg();
		if (typeof self.events.onReady === `function`) return self.events.onReady();
	}
};

// Refresh pdf viewer display
DocumentHandler.prototype.refresh = function(cb) {
	const self = this;
	const { activeSentence } = self;
	this.documentView.refresh(() => {
		return self.selectSentence({
			sentence: activeSentence ? activeSentence : self.documentView.getFirstSentence(),
			noAnim: true
		}, () => {
			return typeof cb === `function` ? cb(true) : undefined;
		});
	});
}

// Check if document has change(s) not saved
DocumentHandler.prototype.hasChanges = function() {
	let result = false;
	for (let key in this.hasChanged) {
		result = result || this.hasChanged[key] === true;
	}
	return result;
};

// Change status of current dataObject (or the one with the given id) to "modified"
DocumentHandler.prototype.modified = function(id) {
	let dataObject = this.getDataObject(id);
	if (dataObject) {
		this.hasChanged[dataObject._id] = true;
		dataObject.status = `modified`;
		this.dataObjectsList.update(dataObject._id, dataObject);
		if (dataObject._id === this.dataObjectForm.currentId()) this.dataObjectForm.modified();
	}
};

// Change status of current dataObject (or the one with the given id) to "saved"
DocumentHandler.prototype.saved = function(id) {
	let dataObject = this.getDataObject(id);
	if (dataObject) {
		dataObject.status = `saved`;
		this.hasChanged[dataObject._id] = false;
	}
};

// Change status of current dataObject (or the one with the given id) to "valid"
DocumentHandler.prototype.valid = function(id) {
	let dataObject = this.getDataObject(id);
	if (dataObject) {
		dataObject.status = `valid`;
		this.hasChanged[dataObject._id] = true;
	}
};

// Change status of the dataObject (with the given id) to "loading"
DocumentHandler.prototype.loading = function(id) {
	if (id === this.dataObjectForm?.currentId()) this.dataObjectForm?.loading();
	this.dataObjectsList.loading(id);
};

// Set the active dataObject Id
DocumentHandler.prototype.setActiveDataObjectId = function(id) {
	const self = this;
	return self.activeDataObjectId = id;
}

// Get dataObjects of a sentence
DocumentHandler.prototype.getDataObjectsOfSentence = function(sentence) {
	return this.dataObjects.current.filter(function(dataObject) {
		return dataObject.sentences.reduce(function(acc, item) {
			return acc || item.id === sentence.id;
		}, false);
	});
};

// Select a sentence
DocumentHandler.prototype.selectSentence = function (opts, cb) {
	let self = this;
	let dataObjects = self.getDataObjectsOfSentence(opts.sentence);
	self.activeSentence = self.documentView.getSentence(opts.sentence);

	// Filter out dataObjects that are not from the same activedataObjecttype
	const activeDataObjects = dataObjects.filter(item => item.dataObjectType === self.activeDataObjectType);

	let dataObject = activeDataObjects.length ? activeDataObjects[0] : undefined;
	if (opts.selectedDataObject) dataObject = { ...opts.selectedDataObject }

	let selectedSentences = this.documentView.getSelectedSentences();
	let sentence = this.documentView.getSentence(opts.sentence);

	return this.documentView.scrollToSentence({
			sentence: opts.sentence,
			noAnim: opts.noAnim
		}, (err) => {
			if (!opts.disableSelection) {
				self.documentView.unselectSentences(selectedSentences);
				self.documentView.selectSentence(opts.sentence);
			}

			self.events.onSentenceClick(dataObject, sentence);

			return typeof cb === `function` ? cb(true) : undefined;
		}
	);
};


// Show error on finish
DocumentHandler.prototype.throwDataObjectsNotValidError = function() {
	this.showModalError({
		title: `Error: DataObjects validation`,
		body: `Please validate all dataObjects below before moving on the next step.`,
		data: this.getDataObjectsNotValidList()
	});
};

// Build error msg
DocumentHandler.prototype.getDataObjectsNotValidList = function() {
	let dataObjects = this.dataObjects.current.filter(function(dataObject) {
		return dataObject.status !== `valid`;
	});
	return `<ul>${dataObjects
		.map(function(dataObject) {
			return `<li>(${dataObject._id}) ${dataObject.name ? dataObject.name : dataObject._id}</li>`;
		})
		.join(``)}</ul>`;
};

// Show error modal
DocumentHandler.prototype.showModalError = function(opts = {}) {
	$(`#datasets-error-modal-label`).html(opts.title);
	$(`#datasets-error-modal-body`).html(opts.body);
	$(`#datasets-error-modal-data`).html(opts.data);
	if (opts.data) $(`#datasets-error-modal-data`).show();
	else $(`#datasets-error-modal-data`).hide();
	$(`#datasets-error-modal-btn`).click();
};

// Show confirm modal
DocumentHandler.prototype.showModalConfirm = function(opts = {}, confirm) {
	$(`#datasets-confirm-modal-label`).html(opts.title);
	$(`#datasets-confirm-modal-action`).attr(`value`, opts.action);
	$(`#datasets-confirm-modal-body`).html(opts.body);
	$(`#datasets-confirm-modal-data`).html(opts.data);
	if (opts.data) $(`#datasets-confirm-modal-data`).show();
	else $(`#datasets-confirm-modal-data`).hide();
	$(`#datasets-confirm-modal-btn`).click();
};

// onModalConfirmAccept
DocumentHandler.prototype.onModalConfirmAccept = function() {
	let action = $(`#datasets-confirm-modal-action`).attr(`value`);
	console.log(`onModalConfirmAccept`);
	if (typeof this.events.onModalConfirmAccept === `function`)
		return this.events.onModalConfirmAccept(action);
};

// Update a dataObject
DocumentHandler.prototype.updateDataObject = function(id, data = {}) {
	// Get the current version of the dataObject
	const self = this;
	let dataObject = this.getDataObject(id);
	
	if (dataObject) {
		for (let key in data) {
			dataObject[key] = data[key];
		}
	}
	
	if (dataObject.dataObjectType !== this.activeDataObjectType) {
		self.documentView.updateDataObject(dataObject);
		self.setActiveDataObjectType(dataObject.dataObjectType);
	}
};

// Save a dataObject
DocumentHandler.prototype.saveDataObject = function(dataObject, cb) {
	let self = this;
	return API.dataObjects.update({
			documentId: self.ids.document,
			dataObjects: [dataObject],
		}, function(err, query) {
			if (err) return typeof cb === `function` ? cb(err) : undefined;
			if (query.err) return typeof cb === `function` ? cb(true, query) : undefined;
			self.saved(dataObject._id);
			self.hasChanged[dataObject._id] = false;
			const parsedDataObject = formatDataObject(query.res[0].res);
			self.updateDataObject(dataObject._id, parsedDataObject);
			self.refreshDataObject(dataObject._id);
			return typeof cb === `function` ? cb(err, parsedDataObject) : undefined;
	});
};

// Merge some dataObjects
DocumentHandler.prototype.mergeDataObjects = function(dataObjects, cb) {
	const self = this;
	if (dataObjects.length > 1) {
		const target = this.getDataObject(dataObjects[0]._id);
		
		return async.mapSeries(
			dataObjects.slice(1),
			function(item, callback) {
				const dataObject = Object.assign({}, self.getDataObject(item._id));
				const sentences = self.documentView.getLinks(dataObject).map((link) => link.sentence);
				
				return self.deleteDataObject(dataObject._id, function(err, res) {
					if (err) return callback(err);
					return self.newLinks(
						{ dataObject: { _id: target._id }, sentences: sentences },
						function(err) {
							return callback(err);
						}
					);
				});
			},
			function(err) {
				return cb(err);
			}
		);
	} else return cb();
};

// Delete some dataObjects
DocumentHandler.prototype.deleteDataObjects = function(dataObjects, cb) {
	let self = this;
	return async.reduce(
		dataObjects,
		[],
		function(acc, dataObject, callback) {
			return self.deleteDataObject(dataObject._id, function(err, res) {
				acc.push({err, res});
				return callback(err, acc);
			});
		},
		function(err, res) {
			return cb(err, res);
		}
	);
};

// Delete a dataObject
DocumentHandler.prototype.deleteDataObject = function(id, cb) {
	const self = this;
	const dataObject = this.getDataObject(id);
	this.loading(id);
	return API.dataObjects.delete({
			documentId: self.ids.document,
			dataObjects: [dataObject] 
		},
		function(err, query) {
			console.log(err, query);
			if (err) return cb(err); // Need to define error behavior
			if (query.err) return cb(true, query); // Need to define error behavior
			let index = self.getDataObjectIndex(dataObject._id);
			if (index > -1) self.dataObjects.deleted.push(self.dataObjects.current.splice(index, 1)[0]);
			self.dataObjectsList.delete(id);
			self.documentView.removeDataObject(dataObject);
			return (typeof cb === `function`) ? cb(undefined, query) : undefined;
		}
	);
};

// Delete a link
DocumentHandler.prototype.deleteLink = function(opts = {}, cb) {
	let self = this;
	return self.removeLink(
		{
			dataObject: self.getDataObject(opts.dataObject._id),
			sentence: { id: opts.sentence.id, text: opts.sentence.text }
		},
		function(err, dataObject) {
			return cb(err, dataObject);
		}
	);
};

// Create new DataObjectId
DocumentHandler.prototype.newDataObjectId = function() {
	let index = 1;
	let newId = `dataObject-` + index;
	while (this.dataObjectExist(newId)) {
		index += 1;
		newId = `dataObject-` + index;
	}
	return newId;
};

// Create new DataObjectId
DocumentHandler.prototype.newDataInstanceId = function() {
	let index = 1;
	let newId = `dataInstance-` + index;
	while (this.dataInstanceExist(newId)) {
		index += 1;
		newId = `dataInstance-` + index;
	}
	return newId;
};

// Create new DataObject
DocumentHandler.prototype.newDataObject = function(sentences = {}, cb) {
	const self = this;
	const dataObjectSentence = sentences[0];
	const linksSentences = sentences.slice(1);
	
	return API.dataseerML.getdataType({ text: dataObjectSentence.text }, function(err, res) {
		console.log(err, res);
		if (err) return cb(err, res);
		if (res.err) return cb(true, res);

		const dataType = res[`datatype`] ? res[`datatype`] : self.dataObjectForm?.defaultDataType;
		const subType = res[`subtype`] ? res[`subtype`] : ``;
		const cert = res[`cert`] ? res[`cert`] : 0;
		const reuse = res[`reuse`] ? res[`reuse`] : false;
		
		const sentence = {
			id: dataObjectSentence.id,
			text: dataObjectSentence.text
		};
		
		const dataObject = setDataObjectDataType({
			dataType: dataType,
			subType: subType,
			cert: cert
		}, self.activeDataObjectType);

		dataObject.reuse = reuse;
		dataObject.index = parseInt(dataObjectSentence.index, 10);
		// Default values depending of kind
		if (self.activeDataObjectType === 'dataset' || self.activeDataObjectType === 'protocol') dataObject.reuse = false;
		if (self.activeDataObjectType === 'material' || self.activeDataObjectType === 'code') dataObject.reuse = true;

		dataObject.sentences = [sentence];
		dataObject.document = self.ids.document;
		if (!dataObject.name) dataObject.name = `dataset-${self.dataObjects.current.length + self.dataObjects.deleted.length + 1}`;

		return API.dataObjects.create({
			documentId: self.ids.document,
			dataObjects: [dataObject]
		}, function(err, query) {
				if (err) return cb(err, query);
				if (query.err) return cb(true, query);
				return self.addDataObject(query.res[0].res, dataObjectSentence, function(err, dataObject) {
					if (linksSentences.length === 0) return cb(err, dataObject);
					else
						return self.newLinks(
							{ dataObject: { _id: dataObject._id }, sentences: linksSentences },
							function(err, dataObject) {
								return cb(err, dataObject);
							}
						);
				});
			}
		);
	});
};

// Duplicate DataObject
DocumentHandler.prototype.duplicateDataObject = function(source, cb) {
	const self = this;
	const dataObjectSentence = source.sentences[0];
	const linksSentences = source.sentences.slice(1);

	const sentence = {
		id: dataObjectSentence.id,
		text: dataObjectSentence.text
	};
	
	const copy = { ...source };

	copy.name = `${copy.kind}-${this.dataObjects.current.length}`;

	delete copy.issues;
	delete copy.flagged;
	delete copy.comments;

	if (copy.dataObjectType === "dataset") {
		delete copy.representativeImage;
		delete copy.qc;
		delete copy.URL;
		delete copy.PID;
	}
	if (copy.dataObjectType === "code") {
		delete copy.version;
		delete copy.URL;
		delete copy.DOI;
		delete copy.RRID;
		delete copy.suggestedEntity;
		delete copy.suggestedURL;
		delete copy.suggestedRRID;
	}
	if (copy.dataObjectType === "material") {
		delete copy.source;
		delete copy.catalogNumber;
		delete copy.RRID;
		delete copy.suggestedEntity;
		delete copy.suggestedRRID;
	}
	if (copy.dataObjectType === "protocol") {
		delete copy.DOI;
		delete copy.URL;
	}

	copy.sentences = source.sentences;
	copy.document = self.ids.document;

	return API.dataObjects.create({ 
				documentId: self.ids.document,
				dataObjects: [copy]
			 }, function(err, query) {
			if (err) return cb(err, query);
			if (query.err) return cb(true, query);
			return self.addDataObject(query.res[0].res, dataObjectSentence, function(err, dataObject) {
				if (linksSentences.length === 0) return cb(err, dataObject);
				else
					return self.newLinks(
						{ dataObject: { _id: dataObject._id }, sentences: linksSentences },
						function(err, dataObject) {
							return cb(err, dataObject);
						}
					);
			});
		}
	);
};

// Add new DataObject
DocumentHandler.prototype.addDataObject = function(dataObject, sentence, cb) {
	const dataObjectType = getDataObjectType(dataObject);
	dataObject.color = DATATYPE_COLORS[dataObjectType];
	dataObject.dataObjectType = dataObjectType;

	this.colors[dataObject._id] = {
		...dataObject.color,
		dataObjectType: dataObject.dataObjectType
	};
	this.dataObjects.current.push(dataObject);
	this.dataObjects.current.sort(sortDataObjects(this.sentencesMapping.pdf ? this.sentencesMapping.pdf : this.sentencesMapping.tei));
	this.documentView.addDataObject(dataObject, sentence);
	// this.dataObjectsList.add(dataObject);

	this.setActiveDataObjectType(dataObjectType);
	
	return cb(null, this.getDataObject(dataObject._id));
};

// Create new Links
DocumentHandler.prototype.newLinks = function(opts = {}, cb) {
	let self = this;
	let dataObject = self.getDataObject(opts.dataObject._id);
	return async.mapSeries(
		opts.sentences,
		function(sentence, next) {
			return self.addLink(
				{
					dataObject: self.getDataObject(opts.dataObject._id),
					sentence: { id: sentence.id, text: sentence.text }
				},
				function(err, dataObject) {
					return next(err);
				}
			);
		},
		function(err) {
			return cb(err, dataObject);
		}
	);
};

// Add Link
DocumentHandler.prototype.addLink = function(opts = {}, cb) {
	let self = this;
	this.documentView.addLink(opts.dataObject, opts.sentence);
	let dataObject = this.getDataObject(opts.dataObject._id);
	let sentenceAlreadyLinked =  dataObject.sentences.filter(function(sentence) {
		return (sentence.id === opts.sentence.id)
	}).length > 0;
	if (!sentenceAlreadyLinked) dataObject.sentences.push(opts.sentence);
	this.dataObjectsList.update(opts.dataObject._id, opts.dataObject);
	return API.dataObjects.update({
			documentId: self.ids.document,
			dataObjects: [dataObject],
		}, function(err, query) {
			if (err) return typeof cb === `function` ? cb(err) : undefined;
			if (query.err) return typeof cb === `function` ? cb(true, query) : undefined;
			const parsedDataObject = formatDataObject(query.res[0].res);
			self.updateDataObject(dataObject._id, parsedDataObject);
			return cb(null, self.getDataObject(dataObject._id));
		});
};

// Remove Link
DocumentHandler.prototype.removeLink = function(opts = {}, cb) {
	let self = this;
	this.documentView.removeLink(opts.dataObject, opts.sentence);
	let dataObject = this.getDataObject(opts.dataObject._id);
	let index = dataObject.sentences.reduce(function(acc, item, i) {
			if (item.id === opts.sentence.id) acc = i;
			return acc;
		}, -1);
	if (index > -1) dataObject.sentences.splice(index, 1); // delete sentence
	this.dataObjectsList.update(opts.dataObject._id, opts.dataObject);
	return API.dataObjects.update({
			documentId: self.ids.document,
			dataObjects: [dataObject],
		}, function(err, query) {
			if (err) return typeof cb === `function` ? cb(err) : undefined;
			if (query.err) return typeof cb === `function` ? cb(true, query) : undefined;
			const parsedDataObject = formatDataObject(query.res[0].res);
			self.updateDataObject(dataObject._id, parsedDataObject);
			return cb(null, self.getDataObject(opts.dataObject._id));
		});
};

// Refresh a dataObject
DocumentHandler.prototype.refreshDataObject = function(id) {
	let self = this;
	let dataObject = this.getDataObject(id);
	this.dataObjectsList.update(id, dataObject);
	if (this.dataObjectForm.currentId() === id)
		this.dataObjectForm.updateDataObject(
			dataObject,
			{ isCurator: this.user.isCurator || this.user.isAnnotator },
			function(err, res) {
				if (err) console.log(`dataObject not selected`);
				else console.log(`dataObject refreshed`);
			}
		);
};

// return index of dataObject
DocumentHandler.prototype.getDataObjectIndex = function(id) {
	for (let i = 0; i < this.dataObjects.current.length; i++) {
		if (this.dataObjects.current[i]._id === id) return i;
	}
	return -1;
};

// Get the next dataObject (or undefined)
DocumentHandler.prototype.getNextDataObject = function(id) {
	let dataObject = this.getDataObject(id)
	let firstChoice = this.dataObjectsList.getFirstDataObjectIdNotValid(dataObject._id);
	let secondChoice = this.dataObjectsList.getFirstDataObjectId();
	if (firstChoice) return this.getDataObject(firstChoice);
	else if (secondChoice) return this.getDataObject(secondChoice);
	return dataObject;
};

// Get the dataObject with given id (or undefined)
DocumentHandler.prototype.getDataObject = function(id) {
	for (let i = 0; i < this.dataObjects.current.length; i++) {
		if (this.dataObjects.current[i]._id === id) return this.dataObjects.current[i];
	}
	return null;
};

// Get the dataObject with given id (or undefined)
DocumentHandler.prototype.dataObjectExist = function(id) {
	for (let i = 0; i < this.dataObjects.current.length; i++) {
		if (this.dataObjects.current[i]._id === id) return true;
	}
	return false;
};

// Get the dataObject with given id (or undefined)
DocumentHandler.prototype.dataInstanceExist = function(id) {
	for (let i = 0; i < this.dataObjects.current.length; i++) {
		if (this.dataObjects.current[i].dataInstanceId === id) return true;
	}
	return false;
};

// Link some elements to the documentHandler
DocumentHandler.prototype.link = function(opts = {}) {
	let self = this;
	if (opts.documentView) {
		this.documentView = opts.documentView;
		this.documentView.init({
			DAS: this.metadata.DAS,
			pdf: this.pdf,
			xml: this.tei,
			colors: this.colors,
			activeDataObjectType: this.activeDataObjectType,
		}, function() {
			console.log(`documentView ready !`);
			if (typeof self.events.onDocumentViewReady === `function`) self.events.onDocumentViewReady();
			
			return self.isReady(`documentView`, true);
		});
	}
	// Init dataObjectForm First
	if (opts.dataObjectForm) {
		this.dataObjectForm = opts.dataObjectForm;
		this.isReady(`dataObjectForm`, true);
		this.dataObjectForm.loadResources(this.datatypes);
		console.log(`dataObjectForm ready !`);
	}
	// the dataObjectsList
	if (opts.dataObjectsList) {
		this.dataObjectsList = opts.dataObjectsList;
		// Load data in dataObjectsList
		this.dataObjectsList.load(this.dataObjects, function() {
			console.log(`dataObjectsList ready !`);
		});
		this.isReady(`dataObjectsList`, true);
	}
	this.synchronize();
};

// Set active dataObject type
DocumentHandler.prototype.setActiveDataObjectType = function (dataObjectType, cb) {
	const self = this;
	const selectedSentences = self.documentView.getSelectedSentences();
	self.activeDataObjectType = dataObjectType;
	self.documentView.setActiveDataObjectType(dataObjectType);

	// Unselect any active sentences
	if (selectedSentences) {
		self.documentView.unselectSentences(selectedSentences);
		for (let i = 0; i < selectedSentences.length; i++) {
			self.documentView.selectSentence(selectedSentences[i]);
		}
	}

	// Callback function
	if (typeof cb === `function`) return cb();
};

// Resync Json DataTypes
DocumentHandler.prototype.resyncJsonDataTypes = function(callback) {
	return API.dataseerML.resyncJsonDataTypes(function(err, res) {
		return callback(err, res);
	});
}

// DocumentHandler synchronization
DocumentHandler.prototype.getRRIDsFromSciscore = function(entity, cb) {
	return API.scicrunch.processEntity({ entity: entity }, function(err, res) {
		return cb(err, res);
	});
}

// DocumentHandler getCurrentViewport
DocumentHandler.prototype.getCurrentViewport = function() {
	return this.documentView.getCurrentViewport();
}

// DocumentHandler synchronization
DocumentHandler.prototype.synchronize = function() {
	let self = this;
	
	if (this.documentView) {
		// Attach documentView events
		this.documentView.attach(`onDataObjectClick`, function(sentence) {
			self.events.onDataObjectClick(sentence)
		});
		this.documentView.attach(`onSentenceClick`, function(sentence) {
			return self.selectSentence({ sentence: sentence, disableSelection: true });
		});
		this.documentView.attach(`onFulltextView`, function() {
			return self.refreshSentencesMapping();
		});
		this.documentView.attach(`onSectionView`, function() {
			return self.refreshSentencesMapping();
		});
		this.documentView.attach(`onParagraphView`, function() {
			return self.refreshSentencesMapping();
		});
		this.documentView.attach(`onPdfView`, function() {
			return self.refreshSentencesMapping();
		});
	}
		
	// Attach dataObjectsList events
	if (this.dataObjectsList) {
		this.dataObjectsList.attach(`onDataObjectLoaded`, function(dataObject) {
			console.log('onDataObjectLoaded');
		});
		this.dataObjectsList.attach(`onExtractDataFromSoftciteClick`, function(opts, cb) {
			return API.documents.extractDataFromSoftcite(
				{
					documentId: opts.documentId,
					params: {
						softcite: opts.softcite
					}
				},
				function(err, query) {
					console.log(err, query);
					if (err) return cb(err);
					if (query.err) return cb(query.res);
					let existing = query.res.filter(function(item) {
						return item.match && item.alreadyExist;
					});
					let created = query.res.filter(function(item) {
						return item.match && !item.alreadyExist;
					});
					let rejected = query.res.filter(function(item) {
						return !item.match;
					});
					let summary = [
						`Import data from Softcite process summary:`,
						`${existing.length} already existing data object(s)<br/>${created.length} data object(s) would be created<br/>${rejected.length} data object(s) would be rejected`
					];
					let logs = [];
					if (existing.length) {
						logs.push(`Already existing data object(s) details:`)
						for (var i = 0; i < existing.length; i++) {
							let dataObject = existing[i];
							logs.push(`${i + 1}) [${dataObject.isCommandLine ? "Command Line" : "Software" }] ${dataObject.name} ${dataObject.version} <br/>Mention(s)<br/>${dataObject.mentions.join('<br/>')}`);
						}
					}
					if (created.length) {
						logs.push(`Created data object(s) details:`)
						for (var i = 0; i < created.length; i++) {
							let dataObject = created[i];
							logs.push(`${i + 1}) [${dataObject.isCommandLine ? "Command Line" : "Software" }] ${dataObject.name} ${dataObject.version} <br/>Mention(s)<br/>${dataObject.mentions.join('<br/>')}`);
						}
					}
					if (rejected.length) {
						logs.push(`Rejected data object(s) details:`)
						for (var i = 0; i < rejected.length; i++) {
							let dataObject = rejected[i];
							logs.push([
								`${i + 1}) ${dataObject.name} ${dataObject.version}`,
								`Unmatching sentence(s)`,
								`${dataObject.sentences
									.filter(function (s) {
										return !s.match;
									})
									.map(function(s) {
										return `[DS] ${s.TEI}<br/>[Softcite] ${s.Softcite}`;
									})
									.join(`<br/>`)}`
							].join('<br/>'));
						}
					}
					return cb(null, { summary, logs });
			});
		});
		this.dataObjectsList.attach(`onImportDataFromSoftciteClick`, function(opts, cb) {
			return API.documents.importDataFromSoftcite(
				{
					documentId: opts.documentId,
					params: {
						softcite: opts.softcite,
						ignoreSoftCiteCommandLines: opts.ignoreSoftCiteCommandLines,
						ignoreSoftCiteSoftware: opts.ignoreSoftCiteSoftware
					}
				},
				function(err, query) {
					console.log(err, query);
					if (err) return cb(err);
					if (query.err) return cb(query.res);
					let existing = query.res.filter(function(item) {
						return item.match && item.alreadyExist;
					});
					let created = query.res.filter(function(item) {
						return item.match && !item.alreadyExist;
					});
					let rejected = query.res.filter(function(item) {
						return !item.match;
					});
					let summary = [
						`Import data from Softcite process summary:`,
						`${existing.length} already existing data object(s)<br/>${created.length} data object(s) created<br/>${rejected.length} data object(s) rejected`
					];
					let logs = [];
					if (existing.length) {
						logs.push(`Already existing data object(s) details:`)
						for (var i = 0; i < existing.length; i++) {
							let dataObject = existing[i];
							logs.push(`${i + 1}) [${dataObject.isCommandLine ? "Command Line" : "Software" }] ${dataObject.name} ${dataObject.version} <br/>Mention(s)<br/>${dataObject.mentions.join('<br/>')}`);
						}
					}
					if (created.length) {
						logs.push(`Created data object(s) details:`)
						for (var i = 0; i < created.length; i++) {
							let dataObject = created[i];
							logs.push(`${i + 1}) [${dataObject.isCommandLine ? "Command Line" : "Software" }] ${dataObject.name} ${dataObject.version} <br/>Mention(s)<br/>${dataObject.mentions.join('<br/>')}`);
						}
					}
					if (rejected.length) {
						logs.push(`Rejected data object(s) details:`)
						for (var i = 0; i < rejected.length; i++) {
							let dataObject = rejected[i];
							logs.push([
								`${i + 1}) ${dataObject.name} ${dataObject.version}`,
								`Unmatching sentence(s)`,
								`${dataObject.sentences
									.map(function(s) {
										return `[DS] ${s.TEI}<br/>[Softcite] ${s.Softcite}`;
									})
									.join(`<br/>`)}`
							].join('<br/>'));
						}
					}
					return cb(null, { summary, logs });
			});
		});
		this.dataObjectsList.attach(`onExtractDataFromBioNLPClick`, function(opts, cb) {
			return API.documents.extractDataFromBioNLP(
				{
					documentId: opts.documentId,
					params: {
						bioNLP: opts.bioNLP,
						refreshData: opts.refreshData,
						labMaterialsSectionsOnly: opts.labMaterialsSectionsOnly,
						pages: opts.pages
					}
				},
				function(err, query) {
					console.log(err, query);
					if (err) return cb(err);
					if (query.err) return cb(query.res);
					let existing = query.res.filter(function(item) {
						return item.alreadyExist;
					});
					let created = query.res.filter(function(item) {
						return !item.alreadyExist;
					});
					let summary = [
						`Import data from BioNLP process summary:`,
						`${existing.length} already existing data object(s)<br/>${created.length} data object(s) would be created`
					];
					let logs = [];
					if (existing.length) {
						logs.push(`Already existing data object(s) details:`)
						for (var i = 0; i < existing.length; i++) {
							let dataObject = existing[i];
							logs.push(`${i + 1}) ${dataObject.name}`);
						}
					}
					if (created.length) {
						logs.push(`Created data object(s) details:`)
						for (var i = 0; i < created.length; i++) {
							let dataObject = created[i];
							logs.push(`${i + 1}) ${dataObject.name}`);
						}
					}
					return cb(null, { summary, logs });
			});
		});
		this.dataObjectsList.attach(`onImportDataFromBioNLPClick`, function(opts, cb) {
			return API.documents.importDataFromBioNLP(
				{
					documentId: opts.documentId,
					params: {
						bioNLP: opts.bioNLP,
						refreshData: opts.refreshData,
						labMaterialsSectionsOnly: opts.labMaterialsSectionsOnly,
						pages: opts.pages
					}
				},
				function(err, query) {
					console.log(err, query);
					if (err) return cb(err);
					if (query.err) return cb(query.res);
					let existing = query.res.filter(function(item) {
						return item.alreadyExist;
					});
					let created = query.res.filter(function(item) {
						return !item.alreadyExist;
					});
					let summary = [
						`Import data from BioNLP process summary:`,
						`${existing.length} already existing data object(s)<br/>${created.length} data object(s) created`
					];
					let logs = [];
					if (existing.length) {
						logs.push(`Already existing data object(s) details:`)
						for (var i = 0; i < existing.length; i++) {
							let dataObject = existing[i];
							logs.push(`${i + 1}) ${dataObject.name}`);
						}
					}
					if (created.length) {
						logs.push(`Created data object(s) details:`)
						for (var i = 0; i < created.length; i++) {
							let dataObject = created[i];
							logs.push(`${i + 1}) ${dataObject.name}`);
						}
					}
					return cb(null, { summary, logs });
			});
		});
		this.dataObjectsList.attach(`onImportDataObjectsClick`, function(opts, cb) {
			let source = opts.source;
			let target = opts.target;
			let onlyLogs = opts.onlyLogs;
			let matchSource = source.match(/[a-f0-9]{24}/gm);
			let matchTarget = target.match(/[a-f0-9]{24}/gm);
			if (source === `` || !Array.isArray(matchSource) || matchSource.length !== 1)
				return cb(null, new Error("You must enter a valid document ID (source)"));
			if (target === `` || !Array.isArray(matchTarget) || matchTarget.length !== 1)
				return cb(null, new Error("You must enter a valid document ID (target)"));
			return API.documents.importDataObjects(
				{ source: source, target: target, onlyLogs: onlyLogs },
				function(err, query) {
					console.log(err, query);
					if (err) return cb(err);
					if (query.err) return cb(query.err);
					let summary = [
						`Import process summary:`,
						`${query.res.existing.length} already existing data object(s)<br/>${query.res.merged.length} data object(s) ${onlyLogs ? "would be" : ""} merged<br/>${query.res.rejected.length} data object(s) ${onlyLogs ? "would be" : ""} rejected`
					];
					let logs = [];
					let dataObjectsKinds = [{ key: "merged", label: "Merged" }, { key: "rejected", label: "Rejected" }, { key: "existing", label: "Existing" }];
					for (let i = 0; i < dataObjectsKinds.length; i++) {
						let item = dataObjectsKinds[i];
						let data = query.res[item.key];
						if (data.length) {
							logs.push(`${item.label} data object(s) details:<br/>--------------------------------------------------`)
							logs.push()
							for (let j = 0; j < data.length; j++) {
								let dataObject = data[j];
								logs.push([
									`${j + 1}/${data.length}`,
									`[${dataObject.kind}] - ${dataObject._id ? `(${dataObject._id})` : "" } ${dataObject.name} : ${dataObject.dataType} ${dataObject.subType}`,
									`${dataObject.sentences
										.map(function(s) {
											return s.text;
										})
										.join(`<br/>`)}`
								].join('<br/>'));
							}
						}
					}
					return cb(null, { summary, logs });
				}
			);
		});
		this.dataObjectsList.attach(`onDetectNewSentencesClick`, function() {
			let pages = prompt(
				`Enter the range of page numbers you wish to process (e.g. : 1-5, 8, 11-13)\nIt will take 20-30 seconds per page`
			);
			if (pages === null) return;
			$(`body`).css(`cursor`, `progress`);
			return API.documents.detectNewSentences(
				{ id: self.ids.document, params: { pages: pages } },
				function(err, query) {
					console.log(err, query);
					$(`body`).css(`cursor`, `default`);
					if (err || query.err) return alert(`An error has occured`);
					alert(`Process done. It will automatically refresh this page`);
					window.location.reload();
				}
			);
		});
		this.dataObjectsList.attach(`onDataObjectClick`, function(dataObject) {
				self.selectSentence({
					sentence: dataObject.sentence, // this data contain the current selected sentence
					selectedDataObject: dataObject
				});
		});
		this.dataObjectsList.attach(`onDataObjectCheck`, function(dataObject) {
			// console.log(dataObject);
		});
		this.dataObjectsList.attach(`onDataObjectDelete`, function(dataObject) {
			let sentence = dataObject.sentences[0];
			return self.deleteDataObject(dataObject._id, function() {
				return self.selectSentence({ sentence: sentence });
			});
		});
		this.dataObjectsList.attach(`onDataObjectLink`, function(dataObject) {
			console.log(dataObject);
			let selectedSentences = self.documentView.getSelectedSentences();
			if (selectedSentences.length === 0)
				return self.showModalError({
					title: `Error: Link sentence to dataObject`,
					body: `You must select a sentence before linking it to the dataObject`
				});
			else
				return self.newLinks(
					{ dataObject: { _id: dataObject._id }, sentences: selectedSentences },
					function(err, dataObject) {
						if (err) return console.log(err);
						return self.selectSentence({
							sentence: selectedSentences[selectedSentences.length - 1]
						});
					}
				);
		});
		this.dataObjectsList.attach(`onNewDataObjectClick`, function() {
			let selectedSentences = self.documentView.getSelectedSentences();
			
			if (selectedSentences.length === 0) {
				return self.showModalError({
					title: `Error: New dataObject`,
					body: `You must select a sentence to create a new dataObject`
				});
			}
			
			return self.newDataObject(selectedSentences, function(err, dataObject) {
				if (err) return console.log(err);
				
				// Select sentence
				return self.selectSentence({
					sentence: dataObject.sentences[0],
					selectedDataObject: dataObject
				});
			});
		});
		this.dataObjectsList.attach(`onMergeSelectionClick`, function(ids) {
			// console.log(ids);
			if (ids.length <= 1)
				return self.showModalError({
					title: `Error: Merge selection`,
					body: `You must select at least two dataObjects`
				});
			else {
				let id = ids[0]._id;
				let dataObject = self.getDataObject(id);
				return self.mergeDataObjects(ids, function() {
					return self.selectSentence({
						sentence: dataObject.sentences[0],
						selectedDataObject: dataObject
					});
				});
			}
		});
		this.dataObjectsList.attach(`onDeleteSelectionClick`, function(ids) {
			// console.log(ids);
			if (ids.length <= 0)
				return self.showModalError({
					title: `Error: Delete selection`,
					body: `You must select at least one dataObject`
				});
			else {
				let dataObject = self.getNextDataObject(ids[ids.length - 1]._id),
					nextId = dataObject._id;
				return self.deleteDataObjects(ids, function() {
					return self.selectSentence({
						sentence: dataObject.sentences[0],
						selectedDataObject: dataObject
					});
				});
			}
		});
	}
};
