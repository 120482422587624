// State
const state = {
	document: undefined,
	documentHandler: {},
	
	dataObjects: [],
	dataTypes: {},
	
	activeDataObject: undefined,
	activeDataObjectType: 'dataset',
	activeSentence: undefined,
	
	dataObjectsForAction: [],
	isMerging: false,
	isDeleting: false,
	isImportingDataObjects: false,
	isExtractingDataFromSoftcite: false,
	isImportingDataFromSoftcite: false,

	dataObjectsTypes: [
		{
			label: 'Datasets',
			icon: 'datasets',
			id: 'dataset',
		},
		{
			label: 'Code & Software',
			icon: 'brackets',
			id: 'code',
		},
		{
			label: 'Materials',
			icon: 'flask',
			id: 'material',
		},
		{
			label: 'Protocols',
			icon: 'protocols',
			id: 'protocol',
		},
	],
}

// Getters
const getters = {
	document: state => state.document,
	documentUsername: state => state.document.owner.username,
	documentHandler: state => state.documentHandler,
	activeDataObjectType: state => state.activeDataObjectType,
	activeDataObject: state => state.activeDataObject,
	activeDataObjectId: state => state.activeDataObject?._id || undefined,
	activeSentence: state => state.activeSentence,
	dataTypes: state => state.dataTypes,
	dataObjectsTypes: state => state.dataObjectsTypes,
	mergeState: state => state.isMerging,
	deleteState: state => state.isDeleting,
	dataObjectsForAction: state => state.dataObjectsForAction,
	dataObjects: state => state.dataObjects,
	filteredDataObjects: state => state.dataObjects.filter( dataObject => !state.activeDataObjectType || dataObject.dataObjectType === state.activeDataObjectType ),
	publicURL: state => `${process.env.VUE_APP_BASE_API_URL}/documents/${state.document._id}?token=${state.document.token}`.replace('/api', ''),
	uploadedFileURl: state => `${process.env.VUE_APP_BASE_API_URL}/documents/${state.document._id}/${state.document.pdf ?  "pdf" : "tei"}/content?token=${state.document.token}`,
	krtFileURl: state => state.document.krt.pdf ? `${process.env.VUE_APP_BASE_API_URL}/documents/${state.document._id}/krt/pdf/content?token=${state.document.token}` : ``,
	lensFileURl: state => `${process.env.VUE_APP_BASE_API_URL.replace("/api", "")}/documents/${state.document._id}/lens?token=${state.document.token}`
}

// Actions
const actions = {
	setDocument({commit}, document ) {
		commit('SET_DOCUMENT', document)
	},
	setDocumentHandler({commit}, documentHandler ) {
		commit('SET_DOCUMENT_HANDLER', documentHandler)
	},
	setDataTypes({commit}, dataTypes ) {
		commit('SET_DATA_TYPES', dataTypes)
	},
	setDataObjects({commit}, dataObjects ) {
		commit('SET_DATAOBJECTS', dataObjects)
	},
	setActiveDataObject({state, commit, getters, dispatch }, { dataObject, scrollToSentence }) {
		const documentHandler = state.documentHandler;
		if (!dataObject) {
			commit('SET_ACTIVE_DATAOBJECT', undefined);
			documentHandler.setActiveDataObjectId(undefined);
			
			return
		}
		
		if (dataObject && dataObject._id === getters.activeDataObjectId) return
		if (dataObject.dataObjectType !== state.activeDataObjectType) documentHandler.setActiveDataObjectType(dataObject.dataObjectType);

		documentHandler.setActiveDataObjectId(dataObject._id);
		
		if (scrollToSentence) {
			documentHandler.selectSentence({
				selectedDataObject: dataObject,
				sentences: dataObject.sentences,
				sentence: {
					id: dataObject.sentences[0].id
				}
			})
		}

		if ( dataObject.dataObjectType !== state.activeDataObjectType) {
			dispatch('setActiveDataObjectType', dataObject.dataObjectType )
		}
		
		commit('SET_ACTIVE_DATAOBJECT', dataObject);
	},
	setActiveDataObjectType({ state, commit }, dataObjectType) {
		// Do nothing if the dataObjectType is already active
		const documentHandler = state.documentHandler;

		documentHandler.setActiveDataObjectType(dataObjectType, () => {
			commit('SET_ACTIVE_DATAOBJECT_TYPE', dataObjectType);
		});
	},
	setActiveSentence({ commit }, sentence) {
		commit('SET_ACTIVE_SENTENCE', sentence)
	},
	addSentenceToDataObject({ state }) {
		const documentHandler = state.documentHandler;
		const selectedSentences = documentHandler.documentView.getSelectedSentences();

		if (selectedSentences.length === 0) {
			return alert('You must select a sentence before linking it to the dataObject');
		}
		
		documentHandler.newDataObject(selectedSentences, function(err, dataObject) {
			if (err) return console.log(err);
			
			// Select sentence
			return documentHandler.selectSentence({
				sentence: dataObject.sentences[0],
				selectedDataObject: dataObject
			});
		});
	},
	deleteDataObject({ commit }) {
		const { documentHandler, activeDataObject } = state;
		
		if (!activeDataObject) return console.log(`bad dataObject id`);
		if (!activeDataObject.sentences) return console.log(`empty sentences`);
		
		return documentHandler.deleteDataObject(activeDataObject._id, () => {
			commit('SET_ACTIVE_DATAOBJECT', undefined);
			documentHandler.setActiveDataObjectId(undefined);
		});
	},
	unlinkSentenceFromDataObject() {
		const { documentHandler, activeDataObject, activeSentence }  = state
		
		if (!activeDataObject) return console.log(`bad dataObject id`);
		if (!activeDataObject.sentences) return console.log(`empty sentences`);
		
		if (activeDataObject.sentences.length === 1) {
			let sentence = activeDataObject.sentences[0];
			return documentHandler.deleteDataObject(activeDataObject._id, function() {
				return documentHandler.selectSentence({ sentence: sentence });
			});
		} else
			return documentHandler.deleteLink({ dataObject: activeDataObject, sentence: activeSentence }, function(err) {
				if (err) return console.log(err);
				return documentHandler.selectSentence({ sentence: activeSentence });
			});
	},
	linkSentenceToDataObject({state}, dataObjectId) {
		const documentHandler = state.documentHandler;
		const selectedDataObject = state.dataObjects.filter(dataObject => dataObject._id === dataObjectId)[0];
		
		documentHandler.dataObjectsList.events.onDataObjectLink(selectedDataObject);
	},
	mergeDataObjects({state, commit, dispatch}) {
		const dataObjectsToMerge = state.dataObjects.filter(dataObject => state.dataObjectsForAction.some((id) => id === dataObject._id ));
		const documentHandler = state.documentHandler;

		const newDataObject = documentHandler.getDataObject(dataObjectsToMerge[0]._id);

		documentHandler.mergeDataObjects(dataObjectsToMerge, () => {
			commit('SET_ACTION_DATAOBJECTS', [])
			commit('SET_MERGE_STATE', false)

			dispatch('setActiveDataObject', {
				dataObject: newDataObject,
				scrollToSentence: false
			})
		});
	},
	deleteDataObjects({state, commit}) {
		const dataObjectsToDelete = state.dataObjects.filter(dataObject => state.dataObjectsForAction.some((id) => id === dataObject._id ));
		const documentHandler = state.documentHandler;

		documentHandler.deleteDataObjects(dataObjectsToDelete, () => {
			commit('SET_ACTION_DATAOBJECTS', [])
			commit('SET_DELETE_STATE', false)
		});
	},
	deleteAllDataObjects({state, commit}, cb) {
		const dataObjectsToDelete = state.dataObjects.filter(dataObject => !!dataObject._id);
		const documentHandler = state.documentHandler;

		documentHandler.deleteDataObjects(dataObjectsToDelete, (err, res) => {
			commit('SET_ACTION_DATAOBJECTS', [])
			commit('SET_DELETE_STATE', false)
			return cb(err, res);
		});
	},
	addDataObjectForAction({ commit, state }, dataObjectId){
		const dataObjects  = state.dataObjectsForAction
		dataObjects.push(dataObjectId)
		commit('SET_ACTION_DATAOBJECTS', dataObjects)
	},
	setMergeState({ commit }, value) {
		commit('SET_MERGE_STATE', value)
	},
	setDeleteState({ commit }, value) {
		commit('SET_DELETE_STATE', value)
	},
	removeDataObjectForAction({ commit }, dataObjectId){
		const dataObjects  = state.dataObjectsForAction
		dataObjects.splice(dataObjects.findIndex((el) => el === dataObjectId), 1)
		
		commit('SET_ACTION_DATAOBJECTS', dataObjects)
	},
	resyncJsonDataTypes({ commit }, cb){
		const documentHandler = state.documentHandler;

		documentHandler.resyncJsonDataTypes((err, res) => {
			if (err) return cb(err)
			
			commit('SET_DATA_TYPES', res);
			return cb(null, {...res})
		})
	},
	detectSentences() {
		state.documentHandler.dataObjectsList.events.onDetectNewSentencesClick();
	},
	importDataObjects({ commit }, { opts, cb }) {
		commit('IMPORT_DATAOBJECTS', true);
		state.documentHandler.dataObjectsList.events.onImportDataObjectsClick(opts, (err, res) => {
			commit('IMPORT_DATAOBJECTS', false);
			return setTimeout(() => { return cb(err, res) }, 3000);
		});
	},
	extractDataFromSoftcite({ commit }, { opts, cb }) {
		commit('EXTRACT_DATA_FROM_SOFTCITE', true);
		state.documentHandler.dataObjectsList.events.onExtractDataFromSoftciteClick(opts, (err, res) => {
			commit('EXTRACT_DATA_FROM_SOFTCITE', false);
			return cb(err, res)
			// return setTimeout(() => { return cb(err, res) }, 3000);
		});
	},
	importDataFromSoftcite({ commit }, { opts, cb }) {
		commit('IMPORT_DATA_FROM_SOFTCITE', true);
		state.documentHandler.dataObjectsList.events.onImportDataFromSoftciteClick(opts, (err, res) => {
			commit('IMPORT_DATA_FROM_SOFTCITE', false);
			return cb(err, res)
			// return setTimeout(() => { return cb(err, res) }, 3000);
		});
	},
	extractDataFromBioNLP({ commit }, { opts, cb }) {
		commit('EXTRACT_DATA_FROM_BIONLP', true);
		state.documentHandler.dataObjectsList.events.onExtractDataFromBioNLPClick(opts, (err, res) => {
			commit('EXTRACT_DATA_FROM_BIONLP', false);
			return cb(err, res)
			// return setTimeout(() => { return cb(err, res) }, 3000);
		});
	},
	importDataFromBioNLP({ commit }, { opts, cb }) {
		commit('IMPORT_DATA_FROM_BIONLP', true);
		state.documentHandler.dataObjectsList.events.onImportDataFromBioNLPClick(opts, (err, res) => {
			commit('IMPORT_DATA_FROM_BIONLP', false);
			return cb(err, res)
			// return setTimeout(() => { return cb(err, res) }, 3000);
		});
	},
	clearState({commit} ) {
		commit('CLEAR_STATE')
	}
}

// Mutations
const mutations = {
	SET_DOCUMENT(state, payload) {
		state.document = payload
	},
	SET_DATAOBJECTS(state, payload) {
		state.dataObjects = payload
	},
	SET_ACTION_DATAOBJECTS(state, payload) {
        state.dataObjectsForAction = payload;
    },
	SET_MERGE_STATE(state, payload) {
        state.isMerging = payload;
    },
	SET_DELETE_STATE(state, payload) {
        state.isDeleting = payload;
    },
	SET_DATA_TYPES(state, payload) {
        state.dataTypes = payload;
    },
	SET_ACTIVE_DATAOBJECT(state, payload) {
        state.activeDataObject = payload;
    },
	SET_ACTIVE_DATAOBJECT_TYPE(state, payload) {
		state.activeDataObjectType = payload;
	},
	SET_DOCUMENT_HANDLER(state, payload) {
        state.documentHandler = payload;
    },
	SET_ACTIVE_SENTENCE(state, payload) {
		state.activeSentence = payload;
	},
	CLEAR_STATE( state ) {
        state.dataTypes = {};
		state.documentHandler = {};
		state.dataObjects = [];
		state.dataObjectsForAction = [];
		state.document = undefined;
		state.activeDataObject = undefined;
    },
	IMPORT_DATAOBJECTS(state, payload) {
        state.isImportingDataObjects = payload;
    },
	EXTRACT_DATA_FROM_SOFTCITE(state, payload) {
        state.isExtractingDataFromSoftcite = payload;
    },
	IMPORT_DATA_FROM_SOFTCITE(state, payload) {
        state.isImportingDataFromSoftcite = payload;
    },
	EXTRACT_DATA_FROM_BIONLP(state, payload) {
        state.isImportingDataFromSoftcite = payload;
    },
	IMPORT_DATA_FROM_BIONLP(state, payload) {
        state.isImportingDataFromSoftcite = payload;
    }
}

export default {
    namespaced: true,
    state,
	getters,
    actions,
    mutations,
};
