<template>
	<Form className="form--filters" @submit.prevent="handleApplyFilters">
		<FormHead>
			<h4>Advanced Filters</h4>
		</FormHead>

		<FormBody>
			<Grid columnGap="medium" rowGap="small" columnSize="half">
				<GridColumn>
					<Field
						name="filter"
						type="text"
						placeholder="Type the value(s) you are looking for in the documents metadata here (separated by commas)"
						v-model.trim="formData.filter"
					>
						<Icon name="search" color="currentColor" />

						Search for value(s) in the documents metadata
					</Field>
				</GridColumn>
				<GridColumn>
					<FieldSelect
						v-model.trim="formData.filterFields"
						name="filterFields"
						placeholder="Restrict search to specific field(s)"
						:options="filterFields"
					>
						<Icon name="search" color="currentColor" />

						Restrict search to specific field(s)
					</FieldSelect>
				</GridColumn>
			</Grid>
			<br/>
			<Grid columnGap="medium" rowGap="small" :columnSize="gridColumnSize">
				<template v-if="this.userRoleWeight > 10">
					<GridColumn>
						<FieldSelect
							v-model.trim="formData.owners"
							name="owners"
							placeholder="Document Owner"
							multiple
							:options="accountsList"
						>
							<Icon name="user" color="currentColor" />

							Document Owner
						</FieldSelect>
					</GridColumn>

					<GridColumn>
						<FieldSelect
							v-model.trim="formData.organizations"
							name="organizations"
							placeholder="Include All"
							multiple
							:options="organizationsList"
						>
							<Icon name="organization" color="currentColor" />

							Institution/Organization
						</FieldSelect>
					</GridColumn>
				</template>

				<GridColumn>
					<div class="form__range">
						<ul>
							<li>
								<FieldDatepicker
									v-model="formData.uploadedAfter"
									placeholder="From"
									:disableFn="disableUploadedAfter"
								>
									<Icon name="document_upload" color="currentColor" />

									Uploaded
								</FieldDatepicker>
							</li>

							<li>
								<FieldDatepicker
									v-model="formData.uploadedBefore"
									placeholder="To"
									:disableFn="disableUploadedBefore"
								/>
							</li>
						</ul>
					</div> <!-- /.form__range -->
				</GridColumn>

				<GridColumn>
					<div class="form__range">
						<ul>
							<li>
								<FieldDatepicker
									v-model="formData.updatedAfter"
									placeholder="From"
									:disableFn="disableUpdatedAfter"
								>
									<Icon name="document_modify" color="currentColor" />

									Modified
								</FieldDatepicker>
							</li>

							<li>
								<FieldDatepicker
									v-model="formData.updatedBefore"
									placeholder="To"
									:disableFn="disableUpdatedBefore"
								/>
							</li>
						</ul>
					</div> <!-- /.form__range -->
				</GridColumn>
			</Grid>
		</FormBody>

		<FormActions>
			<li>
				<Button
					type="submit"
					:className="applyButtonClass"
					:disabled="areFiltersApplied"
				>
					{{ applyButtonText }}
				</Button>
			</li>

			<li>
				<Button className="tertiary" type="button" @onClick="handleClearFilters">Clear Filters</Button>
			</li>
		</FormActions>
	</Form>
</template>

<script>
/**
 * External Dependencies
 */
import { mapGetters } from 'vuex'

/**
 * Internal Dependencies
 */
import Grid, { GridColumn } from '@/components/grid/grid';
import Icon from '@/components/icon/icon';
import Button from '@/components/button/button';
import Field from '@/components/field/field';
import FieldSelect from '@/components/field-select/field-select';
import FieldDatepicker from '@/components/field-datepicker/field-datepicker';
import Form, { FormActions, FormHead, FormBody } from '@/components/form/form';

import accountsService from '@/services/account/accounts';
import organizationsService from '@/services/organizations/organizations';

export default {
	/**
	 * Name
	 */
	name: 'FormFilters',

	/**
	 * Components
	 */
	components: {
		Form,
		FormActions,
		FormHead,
		FormBody,
		Grid,
		Icon,
		Button,
		GridColumn,
		Field,
		FieldSelect,
		FieldDatepicker
	},

	/**
	 * Props
	 */
	props: {
		initialValues: {
			type: Object,
			default:  () => {}
		},
	},

	/**
	 * Data
	 */
	data: function() {
		return {
			formData: {},
			filterFields: [
				{ 'label': 'document name', value: `document.name` },
				{ 'label': 'document name OR article title', value: `document.name,metadata.article_title` },
				{ 'label': 'Everywhere', value: `document.name,document.doi,document.pmid,document.manuscript_id,metadata.article_title,metadata.doi,metadata.isbn,metadata.journal,metadata.manuscript_id,metadata.pmid,metadata.publisher,metadata.submitting_author,metadata.submitting_author_email,metadata.authorsName` }
			],
			accountsList: [],
			organizationsList: [],
			areFiltersApplied: true
		};
	},

	/**
	 * Watch
	 */
	watch: {
		formData: {
			handler() {
				this.areFiltersApplied = false;
			},
			deep: true
		}
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', ['userRoleWeight']),
		applyButtonText() {
			return this.areFiltersApplied ? 'Filters Applied' : 'Apply Filters';
		},
		applyButtonClass() {
			return this.areFiltersApplied ? 'tertiary' : 'primary';
		},
		gridColumnSize() {
			return this.userRoleWeight <= 10 ? 'half' : 'quarter';
		}
	},

	/**
	 * Methods
	 */
	methods: {
		handleClearFilters() {
			this.formData = {};
			this.formData.filterFields = `document.name,metadata.article_title`;
		},
		handleApplyFilters() {
			// Filter all falsy values ( "", 0, false, null, undefined )
			const query = Object.entries(this.formData).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
			
			this.$router.push({ query }).catch(() => {})
			this.$emit('applyFilters', query);
			this.areFiltersApplied = true;
		},
		disableUploadedAfter(date) {
			if (!this.formData.uploadedBefore) return false;
			const parseDate = new Date(this.formData.uploadedBefore);
			return date > parseDate;
		},
		disableUploadedBefore(date) {
			if (!this.formData.uploadedAfter) return false;
			const parseDate = new Date(this.formData.uploadedAfter);
			return date < parseDate;
		},
		disableUpdatedAfter(date) {
			if (!this.formData.updatedBefore) return false;
			const parseDate = new Date(this.formData.updatedBefore);
			return date > parseDate;
		},
		disableUpdatedBefore(date) {
			if (!this.formData.updatedAfter) return false;
			const parseDate = new Date(this.formData.updatedAfter);
			return date < parseDate;
		},
		async getAccountsList() {
			const accountsList = await accountsService.getAccountsList();
			this.accountsList = accountsList.filter(function(a) {
				return a.label.indexOf('@') > -1;
			})
		},
		async getOrganizationsList() {
			const organizationsList = await organizationsService.getOrganizationsList();
			
			this.organizationsList = organizationsList.sort(function(a, b) {
				if (a.label < b.label) return -1;
				if (a.label > b.label) return 1;
				return 0;
			});
		},
	},

	/**
	 * Created
	 */
	created () {
		this.formData = { ...this.initialValues };
	},

	/**
	 * mounted
	 */
	mounted () {
		this.getAccountsList();
		this.getOrganizationsList();
	},
};
</script>
