<template>
  <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 0L2.5 0C1.11981 0.0012817 0.0012817 1.11981 0 2.5L0 13.5C0.0012817 14.8802 1.11981 15.9987 2.5 16L17.5 16C18.8802 15.9987 19.9987 14.8802 20 13.5L20 2.5C19.9987 1.11981 18.8802 0.0012817 17.5 0ZM19 13.5C18.9991 14.3281 18.3281 14.9991 17.5 15L2.5 15C1.67194 14.9991 1.00092 14.3281 1 13.5L1 4.09137L9.73926 9.42676C9.74731 9.43164 9.75769 9.42981 9.76593 9.43427C9.83673 9.4726 9.91382 9.50006 10 9.5C10.0906 9.50006 10.1763 9.47363 10.252 9.42926C10.2546 9.42767 10.2581 9.42841 10.2607 9.42676L19 4.09137L19 13.5ZM19 2.91949L18.7612 3.06525C18.7542 3.06909 18.7462 3.06903 18.7393 3.07324L10.0079 8.40924L10 8.41406L9.99213 8.40924L1.26074 3.07324C1.26031 3.073 1.25989 3.07275 1.25946 3.07245C1.2525 3.06824 1.24451 3.0683 1.23743 3.06445L1 2.91949L1 2.5C1.00092 1.67194 1.67194 1.00092 2.5 1L17.5 1C18.3281 1.00092 18.9991 1.67194 19 2.5L19 2.91949Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconEmail',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
