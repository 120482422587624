<template>
	<div class="widgets">
		<slot></slot>
	</div> <!-- /.widgets -->
</template>

<script>
export default {
	/**
	 * Name
	 */
	name: 'Widgets'
};
</script>
