<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 27 30"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-634.000000, -403.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(109.000000, 60.000000)">
					<g transform="translate(46.000000, 293.000000)">
						<g transform="translate(229.000000, 46.000000)">
							<g transform="translate(250.000000, 4.000000)">
								<path
									d="M10.5,18 C9.2573547,18 8.25,19.0073547 8.25,20.25 C8.25,21.4926453 9.2573547,22.5 10.5,22.5 C11.742096,22.4986267 12.7486268,21.492096 12.75,20.25 C12.75,19.0073547 11.7426453,18 10.5,18 Z M10.5,21 C10.0858155,21 9.75,20.6641845 9.75,20.25 C9.75,19.8358155 10.0858155,19.5 10.5,19.5 C10.9139099,19.5008239 11.2491761,19.8360902 11.25,20.25 C11.25,20.6641845 10.9141845,21 10.5,21 Z M16.5,19.5 C15.2573547,19.5 14.25,20.5073547 14.25,21.75 C14.25,22.9926453 15.2573547,24 16.5,24 C17.742096,23.9986267 18.7486268,22.992096 18.75,21.75 C18.75,20.5073547 17.7426453,19.5 16.5,19.5 Z M16.5,22.5 C16.0858155,22.5 15.75,22.1641845 15.75,21.75 C15.75,21.3358155 16.0858155,21 16.5,21 C16.9139099,21.0008239 17.2491761,21.3360901 17.25,21.75 C17.25,22.1641845 16.9141845,22.5 16.5,22.5 Z M25.9042968,24.4174805 L18,10.3037109 L18,1.5 L20.25,1.5 C20.6641845,1.5 21,1.1641845 21,0.75 C21,0.3358155 20.6641845,0 20.25,0 L6.75,0 C6.3358155,0 6,0.3358155 6,0.75 C6,1.1641845 6.3358155,1.5 6.75,1.5 L9,1.5 L9,10.3037109 L1.0957032,24.4174804 C0.7827759,24.9768677 0.618163965,25.6070251 0.618163965,26.2479858 C0.6176148,28.3196411 2.29650885,29.9994507 4.3681641,30 L22.6318359,30 C23.2727966,30 23.9029541,29.8353882 24.4623413,29.5224609 C26.2702332,28.5109863 26.9157716,26.2253723 25.9042968,24.4174805 Z M10.4047851,10.8662109 C10.4675904,10.7544251 10.5004578,10.6282654 10.5,10.5 L10.5,1.5 L16.5,1.5 L16.5,10.5 C16.4995422,10.6282654 16.5324096,10.7544251 16.5952149,10.8662109 L18.070221,13.5 L8.929779,13.5 L10.4047851,10.8662109 Z M22.6318359,28.5 L4.3681641,28.5 C3.98300175,28.5 3.60424815,28.401123 3.268158,28.2127991 C2.18408205,27.6052551 1.7977296,26.2339782 2.40527355,25.1499023 L8.0896911,15 L18.9103088,15 L24.5947265,25.1499024 C24.7830504,25.4859009 24.8819274,25.8646547 24.8819274,26.2499085 C24.8819274,27.4925536 23.8745727,28.5 22.6318359,28.5 Z"
								></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconFlask',

	/**
	 * props
	 */
	props: {
		color: {},
		width: {
			default: 27
		},
		height: {
			default: 30
		}
	}
};
</script>
