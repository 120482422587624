<template>
  <svg width="16px" height="20px" viewBox="0 0 16 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 7L13 5C13 2.23859 10.7614 0 8 0C5.23859 0 3 2.23859 3 5L3 7C1.34387 7.00183 0.0018311 8.34387 0 10L0 17C0.0018311 18.6561 1.34387 19.9982 3 20L13 20C14.6561 19.9982 15.9982 18.6561 16 17L16 10C15.9982 8.34387 14.6561 7.00183 13 7ZM4 5C4 2.79083 5.79083 1 8 1C10.2092 1 12 2.79083 12 5L12 7L4 7L4 5ZM15 17C14.9986 18.104 14.104 18.9986 13 19L3 19C1.896 18.9986 1.0014 18.104 1 17L1 10C1.0014 8.896 1.896 8.0014 3 8L13 8C14.104 8.0014 14.9986 8.896 15 10L15 17ZM8 11C7.72388 11 7.5 11.2239 7.5 11.5L7.5 15.5005C7.50018 15.7766 7.724 16.0002 8 16L8.00061 16C8.27655 15.9998 8.50018 15.776 8.5 15.5L8.5 11.5C8.5 11.2239 8.27612 11 8 11Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconPassword',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
