<template>
	<svg
		width="20px"
		height="20px"
		viewBox="0 0 20 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-1286.000000, -110.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(1264.282951, 43.000000)">
					<g transform="translate(21.717049, 67.000000)">
						<path
							d="M19.8534546,0.1587524 C19.6616211,-0.039856 19.3450928,-0.0453492 19.1464844,0.1464843 L13.7138672,5.5786742 C13.706604,5.585266 13.6970825,5.5876464 13.6901245,5.5947875 C13.6838379,5.6011962 13.6819458,5.6098632 13.6760254,5.616516 L7.2940674,11.9979247 C6.5245361,11.3829956 5.5617065,11 4.5,11 C2.0147095,11 0,13.0147095 0,15.5 C0,17.9852905 2.0147095,20 4.5,20 C6.9841309,19.9973145 8.9973145,17.9841309 9,15.5 C9,14.4378052 8.6166382,13.4746094 8.0011597,12.704895 L14.0510254,6.6555176 L15.8183594,8.4228516 C15.9119873,8.5167847 16.0392456,8.5695191 16.171875,8.569336 L16.171875,8.569397 C16.3044434,8.569336 16.4315186,8.5166626 16.5252686,8.4229737 C16.7205811,8.2277222 16.7205811,7.9111329 16.5253907,7.7158204 L14.7580567,5.9484864 L16.8796998,3.8269654 L18.647461,5.5947266 C18.74115,5.6886597 18.8683472,5.7413941 19.0009766,5.741211 C19.133545,5.741211 19.2606812,5.6885377 19.3543702,5.5948487 C19.5496827,5.3995972 19.5497437,5.0830079 19.3544923,4.8877564 L17.586792,3.1199951 L19.8534546,0.8534546 C20.0406494,0.659729 20.0406494,0.3525391 19.8534546,0.1587524 Z M4.5,19 C2.5670166,19 1,17.4329834 1,15.5 C1,13.5670166 2.5670166,12 4.5,12 C6.4320068,12.0023193 7.9976807,13.5679932 8,15.5 C8,17.4329834 6.4329834,19 4.5,19 Z"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconKey',

	/**
	 * props
	 */
	props: ['color'],
};
</script>
