/* eslint-disable */

import $ from 'jquery';
import _ from 'lodash';

import PdfViewer from './pdfViewer';
import XmlViewer from './xmlViewer';

export const DocumentView = function(id, events = {}) {
	let self = this;
	this.id = id;
	this.selectedSentences = {};
	this.currentScrolledSentence;
	this.shiftPressed = false;
	this.ctrlPressed = false;
	this.viewersEvents = {
		onClick: function(element) {
			let sentence = self.getSentence({ id: element.id });
			//Handle Cases where ctrl ot shift are pressed
			if (self.ctrlPressed) {
				if (self.isSelected(sentence)) {
					self.unselectSentence(sentence);
					self.hoverSentence(sentence);
				} else self.selectSentence(sentence);
			} else if (self.shiftPressed) {
				let selectedSentences = self.getSelectedSentences();
				if (selectedSentences.length) {
					let sentencesIds = self.getSentences(selectedSentences, sentence);
					for (let i = 0; i < sentencesIds.length; i++) {
						if (typeof sentencesIds[i] === `string`) {
							self.selectSentence(self.getSentence({ id: sentencesIds[i] }));
						}
					}
				} else console.log(`At least one sentence must be selected`);
			} else {
				self.unselectSentences(self.getSelectedSentences());
				self.selectSentence(sentence);
			}
			if (typeof self.events.onSentenceClick === `function`)
				self.events.onSentenceClick(self.getSentence(sentence));
		},
		onHover: function(element) {
			let sentence = self.getSentence({ id: element.id });
			// document.body.title = sentence.text;
			self.hoverSentence(sentence);
			if (typeof self.events.onSentenceHover === `function`)
				self.events.onSentenceHover(self.getSentence(sentence));
		},
		onEndHover: function(element) {
			let sentence = self.getSentence({ id: element.id });
			// document.body.title = "";
			self.endHoverSentence(sentence);
			if (typeof self.events.onSentenceHover === `function`)
				self.events.onSentenceHover(self.getSentence(sentence));
		},
		onFirstPageButtonClick: function(infos) {
			if (self.pdfVisible) {
				self.screen.scrollTop(infos.scrollTop);
			}
			if (typeof self.events.onFirstPageButtonClick === `function`)
				self.events.onFirstPageButtonClick(infos);
		},
		onPreviousPageButtonClick: function(infos) {
			if (self.pdfVisible) {
				self.screen.scrollTop(infos.scrollTop);
			}
			if (typeof self.events.onPreviousPageButtonClick === `function`)
				self.events.onPreviousPageButtonClick(infos);
		},
		onNextPageButtonClick: function(infos) {
			if (self.pdfVisible) {
				self.screen.scrollTop(infos.scrollTop);
			}
			if (typeof self.events.onNextPageButtonClick === `function`)
				self.events.onNextPageButtonClick(infos);
		},
		onLastPageButtonClick: function(infos) {
			if (self.pdfVisible) {
				self.screen.scrollTop(infos.scrollTop);
			}
			if (typeof self.events.onLastPageButtonClick === `function`)
				self.events.onLastPageButtonClick(infos);
		},
		onCustomScroll: function(scrollTop) {
			if (self.pdfVisible && !self.pdfScrolling) {
				self.screen.scrollTop(scrollTop);
			}
		},
		onCustomScrollClick: function(scrollTop) {
			if (self.pdfVisible && !self.pdfScrolling) {
				self.screen.scrollTop(scrollTop);
			}
		},
		onPageNumberSubmit: function(scrollTop) {
			console.log("onPageNumberSubmit", scrollTop)
			if (self.pdfVisible && !self.pdfScrolling) {
				self.screen.scrollTop(scrollTop);
			}
		}
	};
	// documentView elements
	this.screen = $(`#documentView\\.screen`);
	this.pdf = $(`#pdf`);
	this.xml = $(`#xml`);
	// Listen scroll
	this.currentScroll = 0;
	const scrollEvent = function(e) {
		if (!self.pdfVisible) return;
		if (self.pdfScrolling) return self.screen.scrollTop(self.currentScroll);
		// PDF viewer is visible and not already scrolling
		self.screen.unbind("scroll");
		self.pdfScrolling = true;
		// direction < 0 -> "scroll up" || direction > 0 -> "scroll down"
		let direction = self.screen.scrollTop() > self.currentScroll ? +1 : -1;
		let scrollDelta = self.currentScroll - self.screen.scrollTop();
		let previousScrollHeight = self.screen.prop(`scrollHeight`);
		let scrollValue = Math.abs(self.screen.scrollTop() - self.currentScroll);
		self.currentScroll = self.screen.scrollTop();
		return self.pdfViewer.onScroll({
			direction: direction
		}, function(err, res) {
			let currentScroolHeight = self.screen.prop(`scrollHeight`);
			if (previousScrollHeight !== currentScroolHeight && direction < 0) self.screen.scrollTop(self.currentScroll + (currentScroolHeight - previousScrollHeight));
			else self.screen.scrollTop(self.currentScroll);
			self.pdfScrolling = false;
			self.screen.scroll(scrollEvent);
		}); 
	};
	this.screen.scroll(scrollEvent);
	// Element initialization
	this.xml.hide();
	this.pdfVisible = true;
	this.pdfAvailable = true;
	this.pdfScrolling = false;
	// Set ActiveDataObjectType
	this.activeDataObjectType = undefined;
	// Key events listeners
	$(document).keydown(function(event) {
		if (event.key === `Control`) self.ctrlPressed = true;
		else if (event.key === `Shift`) self.shiftPressed = true;
	});
	$(document).keyup(function(event) {
		if (event.key === `Control`) self.ctrlPressed = false;
		else if (event.key === `Shift`) self.shiftPressed = false;
	});
	// Events
	this.events = events;
	return this;
};

// Switch between PDF and XML view
DocumentView.prototype.toggleDocumentView = function(view) {
	let self = this;

	self.lastSelectedSentence = self.lastSelectedSentence ? self.lastSelectedSentence : self.getFirstSentence();
	
	if (view && self.pdfAvailable) {
		self.pdfVisible = true;
		self.pdf.show();
		if (self.pdfAvailable) self.pdfViewer.showMarkers();
		self.xml.hide();
		self.scrollToSentence({ sentence: self.lastSelectedSentence, noAnim: true });
		this.screen.removeClass('scrollbar-visible');
		this.screen.addClass('scrollbar-invisible');
		return typeof self.events.onPdfView === `function`
			? self.events.onPdfView()
			: undefined;
	}

	self.pdfVisible = false;
	self.pdf.hide();
	if (self.pdfAvailable) self.pdfViewer.hideMarkers();
	this.screen.removeClass('scrollbar-invisible');
	this.screen.addClass('scrollbar-visible');
	self.xml.show();
	self.xml.find(`*.hidden`).removeClass(`hidden`);
	self.xml.find(`text > div, text > *:not(div)`).map(function(i, el) {
		let element = $(el);
		if (element.find(`s[corresp]`).length === 0) return element.addClass(`hidden`);
	});
	self.scrollToSentence({ sentence: self.lastSelectedSentence, noAnim: true });
	return typeof self.events.onParagraphView === `function`
		? self.events.onParagraphView()
		: undefined;
}

// Check if the given sentence is selected
DocumentView.prototype.isSelected = function(sentence) {
	return (
		typeof this.selectedSentences[sentence.id] === `object` &&
		typeof this.selectedSentences[sentence.id].id !== `undefined`
	);
};

// Attach event
DocumentView.prototype.attach = function(event, fn) {
	this.events[event] = fn;
};

// Get selected sentence or undefined
DocumentView.prototype.getSentencesMapping = function() {
	return {
		pdf: this.pdfAvailable ? this.pdfViewer.getSentencesMapping() : undefined,
		xml: this.xmlViewer ? this.xmlViewer.getSentencesMapping() : undefined
	};
};

// Get last sentences index
DocumentView.prototype.getSentencesIndexesLimit = function() {
	return {
		pdf: this.pdfAvailable ? this.pdfViewer.getSentencesIndexesLimit() : undefined,
		xml: this.xmlViewer ? this.xmlViewer.getSentencesIndexesLimit() : undefined
	};
}

// Get part of sentences
DocumentView.prototype.getSentences = function(selectedSentences, lastSentence) {
	return this.pdfVisible
		? this.pdfViewer.getSentences(selectedSentences, lastSentence)
		: this.xmlViewer.getSentences(selectedSentences, lastSentence);
};

// Get sentence
DocumentView.prototype.getSentence = function(sentence) {
	let xmlSentence = this.xmlViewer.getInfoOfSentence(sentence);
	let pdfSentence = this.pdfVisible ? this.pdfViewer.getInfoOfSentence(sentence) : {};
	return Object.assign({}, pdfSentence, xmlSentence);
};

// Get sentence
DocumentView.prototype.getFirstSentence = function(sentence) {
	let xmlSentence = this.xmlViewer.getInfoOfFirstSentence();
	let pdfSentence = this.pdfVisible ? this.pdfViewer.getInfoOfFirstSentence() : {};
	return Object.assign({}, pdfSentence, xmlSentence);
};

DocumentView.prototype.getDataObjectsTypes = function() {

};

// Init documentView
DocumentView.prototype.init = function(opts, cb) {
	const self = this;
	const xml = opts.xml.data.toString(`utf8`).replace(/\s/gm, ` `);

	this.xmlViewer = new XmlViewer(`xml`, `documentView\\.screen`, this.viewersEvents);

	this.pdfAvailable = !!opts.pdf;
	this.pdfVisible = !!opts.pdf;
	this.pdfViewer = new PdfViewer(`pdf`, `documentView\\.screen`, this.viewersEvents);
	
	return this.xmlViewer.load({
		xmlString: xml,
		colors: opts.colors,
		mapping: opts.xml.metadata.mapping,
		activeDataObjectType: opts.activeDataObjectType
	},
		function(dataObjectsInfo) {
			if (opts.pdf)
				return self.pdfViewer.load(opts.pdf, dataObjectsInfo, function() {
					self.pdfViewer.setPage(1);
					return self.pdfViewer.loadDAS(opts.DAS, function() {
						return typeof cb === `function` ? cb() : undefined;	
					});
				});
			else {
				$(`#documentView\\.viewSelection\\.tei\\.all`).click();
				$(`#documentView\\.viewSelection\\.pdf`)
					.parent()
					.remove();
				self.pdfViewer.toggleDAS(!!opts.DAS.content);
				return typeof cb === `function` ? cb() : undefined;	
			}
		}
	);
};

// Refresh pdf display
DocumentView.prototype.refresh = function(cb) {
	if (this.pdfAvailable) this.pdfViewer.refresh(cb);
}

// Refresh pdf display
DocumentView.prototype.toggleDAS = function(cb) {
	this.pdfViewer.toggleDAS();
}

// Change active dataObject type
DocumentView.prototype.setActiveDataObjectType = function(dataObjectType) {
	if (this.pdfAvailable) this.pdfViewer.setActiveDataObjectType(dataObjectType);
	this.xmlViewer.setActiveDataObjectType(dataObjectType);
};

// Hover a sentence
DocumentView.prototype.hoverSentence = function(sentence) {
	this.xmlViewer.hoverSentence(sentence);
	let _sentence = this.getSentence(sentence);
	if (this.pdfAvailable && this.pdfVisible) this.pdfViewer.hoverSentence(_sentence);
};

// Unhover a sentence
DocumentView.prototype.endHoverSentence = function(sentence) {
	this.xmlViewer.endHoverSentence(sentence);
	let _sentence = this.getSentence(sentence);
	if (this.pdfAvailable && this.pdfVisible) this.pdfViewer.endHoverSentence(_sentence);
};

// Select a sentence
DocumentView.prototype.selectSentence = function(sentence) {
	this.xmlViewer.selectSentence(sentence);
	let _sentence = this.getSentence(sentence);
	this.lastSelectedSentence = _sentence;
	this.selectedSentences[_sentence.id] = _sentence;
	if (this.pdfAvailable && this.pdfVisible) this.pdfViewer.selectSentence(_sentence);
};

// Unselect a sentence
DocumentView.prototype.unselectSentence = function(sentence) {
	this.xmlViewer.unselectSentence(sentence);
	let _sentence = this.getSentence(sentence);
	delete this.selectedSentences[_sentence.id];
	if (this.pdfAvailable && this.pdfVisible) this.pdfViewer.unselectSentence(_sentence);
};

// Unselect all selected sentences
DocumentView.prototype.unselectSentences = function(sentences) {
	for (let i = 0; i < sentences.length; i++) {
		this.unselectSentence(sentences[i]);
	}
};

// Get selected sentence or undefined
DocumentView.prototype.getSelectedSentences = function() {
	if (this.selectedSentences) return Object.values(this.selectedSentences);
};

// Scroll to a sentence
DocumentView.prototype.scrollToSentence = function(opts, cb) {
	let self = this;
	this.currentScrolledSentence = opts.sentence;

	if (!this.currentScrolledSentence) return typeof cb === `function` ? cb() : undefined;
	
	if (this.pdfVisible) {
		return this.pdfViewer.scrollToSentence(opts.sentence, function(position) {
			if (position) {
				self.screen.scrollTop(position);
				// if (opts.noAnim) self.screen.scrollTop(position);
				// else self.screen.animate({ scrollTop: position });
			} else console.log(`dataObject not selected`);
			return typeof cb === `function` ? cb() : undefined;
		});
	} else {
		return this.xmlViewer.scrollToSentence(opts.sentence, function(position) {
			if (position) {
				self.screen.scrollTop(position);
				// if (opts.noAnim)
				// 	self.screen.scrollTop(
				// 		position + self.screen.scrollTop() - self.screen.height() / 1.8
				// 	);
				// else
				// 	self.screen.animate({
				// 		scrollTop: position + self.screen.scrollTop() - self.screen.height() / 1.8
				// 	});
			} else console.log(`dataObject not selected`);
			return typeof cb === `function` ? cb() : undefined;
		});
	}
};

// Get all Links
DocumentView.prototype.getLinks = function(dataObject) {
	return this.xmlViewer.getLinks(dataObject);
};

// Add a dataObject
DocumentView.prototype.addDataObject = function(dataObject, sentence) {
	if (this.pdfAvailable) this.pdfViewer.addDataObject(dataObject, sentence);
	this.xmlViewer.addDataObject(dataObject, sentence);
};

// Remove a dataObject
DocumentView.prototype.removeDataObject = function(dataObject) {
	if (this.pdfAvailable) this.pdfViewer.removeDataObject(dataObject, () => this.clearKeys());
	this.xmlViewer.removeDataObject(dataObject);
};

// Update a dataObject
DocumentView.prototype.updateDataObject = function(dataObject) {
	if (this.pdfAvailable) this.pdfViewer.updateDataObject(dataObject);
};

// Add a link
DocumentView.prototype.addLink = function(dataObject, sentence) {
	if (this.pdfAvailable) this.pdfViewer.addLink(dataObject, sentence);
	if (this.xmlViewer) this.xmlViewer.addLink(dataObject, sentence);
};

// Remove a corresp
DocumentView.prototype.removeLink = function(dataObject, sentence) {
	if (this.pdfAvailable) this.pdfViewer.removeLink(dataObject, sentence, () => this.clearKeys());
	if (this.xmlViewer)this.xmlViewer.removeLink(dataObject, sentence);
};

// Clear any keys that may be clicked
DocumentView.prototype.clearKeys = function() {
	if (this.shiftPressed) this.shiftPressed = false;
	if (this.ctrlPressed) this.ctrlPressed = false;
};

// display left
DocumentView.prototype.displayLeft = function() {
	if (this.pdfAvailable) this.pdfViewer.displayLeft();
};

// display right
DocumentView.prototype.displayRight = function() {
	if (this.pdfAvailable) this.pdfViewer.displayRight();
};

// DocumentView getCurrentViewport
DocumentView.prototype.getCurrentViewport = function() {
	return this.pdfAvailable ? this.pdfViewer.viewport : undefined;
}
