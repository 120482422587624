<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
		<g transform="translate(-0.5 -0.5)">
			<path
				data-name="Combined Shape"
				d="M17.008,19.59,17,19.5V17H14.5a.5.5,0,0,1-.09-.992L14.5,16H17V13.5a.5.5,0,0,1,.992-.09L18,13.5V16h2.5a.5.5,0,0,1,.09.992L20.5,17H18v2.5a.5.5,0,0,1-.992.09ZM11.089,19a.5.5,0,1,1,0-1h1a.5.5,0,1,1,0,1Zm-4,0a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Zm-4,0a.5.5,0,1,1,0-1h1a.5.5,0,1,1,0,1ZM.069,17.23A2.063,2.063,0,0,1,0,16.7v-.527a.5.5,0,1,1,1,0V16.7a1,1,0,0,0,.036.27.5.5,0,0,1-.354.613.483.483,0,0,1-.13.017A.5.5,0,0,1,.069,17.23ZM4,14.5a.5.5,0,0,1-.089-.992L4,13.5H8a.5.5,0,0,1,.09.992L8,14.5ZM0,13.173v-1a.5.5,0,1,1,1,0v1a.5.5,0,0,1-1,0ZM4,11.5a.5.5,0,0,1-.089-.992L4,10.5H14a.5.5,0,0,1,.089.992L14,11.5ZM17,9.659v-1a.5.5,0,1,1,1,0v1a.5.5,0,0,1-1,0ZM0,9.173v-1a.5.5,0,0,1,1,0v1a.5.5,0,1,1-1,0ZM4,8.5a.5.5,0,0,1-.089-.992L4,7.5H14a.5.5,0,0,1,.089.992L14,8.5ZM17,5.658v-1a.5.5,0,0,1,1,0v1a.5.5,0,1,1-1,0ZM0,5.173v-1a.5.5,0,1,1,1,0v1a.5.5,0,0,1-1,0ZM4,5.5a.5.5,0,0,1-.089-.992L4,4.5H14a.5.5,0,0,1,.089.992L14,5.5ZM16.9,1.847l-.068-.123a1.432,1.432,0,0,0-.419-.423.5.5,0,0,1-.139-.693.5.5,0,0,1,.418-.223.5.5,0,0,1,.276.084,2.363,2.363,0,0,1,.837.953.5.5,0,1,1-.9.426ZM.577,1.627a.5.5,0,0,1-.056-.7A2.594,2.594,0,0,1,1.534.207.5.5,0,0,1,1.719.171a.5.5,0,0,1,.185.965,1.586,1.586,0,0,0-.623.436.5.5,0,0,1-.7.056ZM12.743,1a.5.5,0,1,1,0-1h1a.5.5,0,0,1,0,1Zm-4,0a.5.5,0,0,1,0-1h1a.5.5,0,1,1,0,1Zm-4,0a.5.5,0,1,1,0-1h1a.5.5,0,0,1,0,1Z"
				transform="translate(0.5 0.5)"
				:fill="color"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconTextsAdd',

	/**
	 * color
	 */
	props: ['color']
};
</script>
