<template>
	<Main
		:loading="loading"
		:error="error"
		:errorMessage="errorMessage"
		className="main--report"
	>
		<template #subheader>
			<Subheader>
				<SubheaderReport :documentID="documentID"/>
			</Subheader>
		</template>

		<div class="report">

			<div v-if="userRoleWeight >= 100" class="report-group">
				<ReportGoogle @submit="reportSubmit" @response="reportResponse" title="Manage Reports" :documentID="documentID" :organizations="organizations" :documentToken="documentToken" />
			</div> <!-- /.report-group -->


			<div class="report-group">
				<div class="report-title">
					<h1>{{report.originalDocument.name}}</h1>
					
					<p v-if="report.originalDocument.metadata.doi">
						<Icon name="chain" :color="cssVariables.primary" />

						<strong>DOI</strong> <a href="#">{{report.originalDocument.metadata.doi}}</a>
					</p>
				</div> <!-- /.report-title -->
			</div> <!-- /.report-group -->

			<div class="report-group report-group--actions">
				<ul>
					<li>
						<ReportDataset 
							icon="datasets"
							title="Datasets"
							:data="report.sortedDataObjectsInfo.datasets"
						/>
						<br/>
						<div>
							<Field
								name="notes"
								type="text"
								v-model.trim="dataObjectsMetadata.datasets.notes"
								placeholder=""
								:readonly="userRoleWeight <= 10"
							>

								Comments
							</Field>
						</div>
					</li>

					<li>
						<ReportDataset 
							icon="brackets"
							title="code & software"
							:data="report.sortedDataObjectsInfo.code.concat(report.sortedDataObjectsInfo.software)"
						/>
						<br/>
						<div>
							<Field
								name="notes"
								type="text"
								v-model.trim="dataObjectsMetadata.codeAndSoftware.notes"
								placeholder=""
								:readonly="userRoleWeight <= 10"
							>

								Comments
							</Field>
						</div>
					</li>

					<li>
						<ReportDataset 
							icon="flask"
							title="materials"
							:data="report.sortedDataObjectsInfo.reagents"
						/>
						<br/>
						<div>
							<Field
								name="notes"
								type="text"
								v-model.trim="dataObjectsMetadata.materials.notes"
								placeholder=""
								:readonly="userRoleWeight <= 10"
							>

								Comments
							</Field>
						</div>
					</li>

					<li>
						<ReportDataset 
							icon="protocols"
							title="protocols"
							:data="report.sortedDataObjectsInfo.protocols"
						/>
						<br/>
						<div>
							<Field
								name="notes"
								type="text"
								v-model.trim="dataObjectsMetadata.protocols.notes"
								placeholder=""
								:readonly="userRoleWeight <= 10"
							>

								Comments
							</Field>
						</div>
					</li>
				</ul>
				<br/>
				<div>
						<Field
							name="notes"
							type="text"
							v-model.trim="formMetadata.affiliationAcknowledgementsLicenseNotes"
							placeholder=""
							:readonly="userRoleWeight <= 10"
						>

							Affiliation/Acknowledgements/License
						</Field>
				</div>
			</div> <!-- /.report-group -->

			<div class="report-group">
				<ReportSuggestions title="Research Output Availability Statement" :dataObjectsInfo="report.sortedDataObjectsInfo" />
			</div> <!-- /.report-group -->

			<div class="report-group">
				<FormGroup title="README fields" class="form--readme-section">
					<Grid columnGap="large">
						<GridColumn>
							<Checkboxes>
								<FieldCheckbox name="readmeIncluded" v-model="formMetadata.readmeIncluded.value" isToggle>
								</FieldCheckbox>
							</Checkboxes>
							<Field
								placeholder="Type your notes about 'ReadMe included' here"
								v-model="formMetadata.readmeIncluded.notes"
								name="readmeIncludedNotes"
							>
								ReadMe {{ formMetadata.readmeIncluded.value ? 'Included' : 'Not Included'}}
							</Field>
						</GridColumn>
						<GridColumn>
							<Checkboxes>
								<FieldCheckbox name="describesFiles" v-model="formMetadata.describesFiles.value" isToggle>
								</FieldCheckbox>
							</Checkboxes>
							<Field
								placeholder="Type your notes about 'describes files' here"
								v-model="formMetadata.describesFiles.notes"
								name="describesFilesNotes"
							>
								Files {{ formMetadata.describesFiles.value ? 'Described' : 'Not Described'}}
							</Field>
						</GridColumn>
						<GridColumn>
							<Checkboxes>
								<FieldCheckbox name="describesVariables" v-model="formMetadata.describesVariables.value" isToggle>
								</FieldCheckbox>
							</Checkboxes>
							<Field
								placeholder="Type your notes about 'describes variables' here"
								v-model="formMetadata.describesVariables.notes"
								name="describesVariablesNotes"
							>
								Variables {{ formMetadata.describesVariables.value ? 'Described' : 'Not Described'}}
							</Field>
						</GridColumn>
					</Grid>
				</FormGroup>
			</div> <!-- /.report-group -->

			<Form v-if="userRoleWeight >= 100" ref="form" className="form--edit" :loading="loading">
				<FormStatus v-if="error || success" :text="message" :isError="error" />
				<FormActions>
					<li>
						<Button type="button" @onClick.prevent="handleFormSubmit">Save Changes</Button>
					</li>
				</FormActions>
			</Form>
		</div><!-- /.report -->

		<template #right>
			<div class="report-about">
				<ul>
					<li v-if="report.originalDocument.metadata.doi">
						<h6>Preprint link</h6>

						<a :href="report.originalDocument.metadata.doi" target="_blank">
							{{report.originalDocument.metadata.doi}}
						</a >
					</li>

					<li v-if="dataseerLink">
						<h6>DataSeer Link</h6>

						<a :href="dataseerLink" target="_blank">Direct link to the document {{report.originalDocument.name}}</a>
					</li>

					<li v-if="userRoleWeight >= 1000">
						<h6>Manage Document Link</h6>
						<a :href="`#/documents/${$route.params.id}/manage`" target="_blank">

							Click here to manage document
						</a>
					</li>

					<li>
						<h6>Uploaded File</h6>
						<a :href="PDFLink" target="_blank">

							Open file {{report.originalDocument.name}} in new tab
						</a>
					</li>

					<li v-if="authors">
						<h6>Authors ({{ authors.length }})</h6>

						<Author v-for="(author, index) in leadAuthors" :key="index" :author="author"></Author>

						<ContentToggle labelClosed="Show All Authors" labelOpened="Hide All Authors">
							<Authors>
								<Author
									v-for="(author, index) in nonLeadAuthors"
									:key="index"
									:author="author"
								></Author>
							</Authors>
						</ContentToggle>
					</li>
				</ul>
			</div> <!-- /.report-about -->

			<ReportChart :src="graphImg" :url="graphURL" />
		</template>
	</Main>
</template>

<script>
/* eslint-disable */
/**
 * External Dependencies
 */
import { mapGetters } from 'vuex';

/**
 * Internal Dependencies
 */
import Subheader from '@/components/subheader/subheader';
import SubheaderReport from '@/components/subheader/subheader-report';
import Icon from '@/components/icon/icon';
import Main from '@/components/main/main';
import Author from '@/components/author/author';
import Authors from '@/components/authors/authors';
import ReportChart from '@/components/report-chart/report-chart';
import ContentToggle from '@/components/contenttoggle/contenttoggle';
import ReportSuggestions from '@/blocks/report-suggestions/report-suggestions';
import ReportDataset from '@/components/report-dataset/report-dataset';
import ReportGoogle from '@/blocks/report-google/report-google';
import Button from '@/components/button/button';
import Form, { FormStatus, FormActions, FormBody, FormGroup } from '@/components/form/form';
import Field from '@/components/field/field';
import FieldCheckbox from '@/components/field-checkbox/field-checkbox';
import Grid, { GridColumn } from '@/components/grid/grid';
import Checkboxes from '@/components/checkboxes/checkboxes';

// import filesService from '@/services/files/files';
import chartsService from '@/services/charts/charts';
import documentsService from '@/services/documents/documents';
import documentsDataObjectsService from '@/services/documents-dataObjects/documents-dataObjects';
import variables from '@/assets/scss/generic/_variables.scss'

import URLMANAGER from '@/lib/datasets/url';

export default {
	/**
	 * Name
	 */
	name: 'Report',

	/**
	 * Components
	 */
	components: {
		Button,
		Field,
		Form,
		FormBody,
		FormGroup,
		FormStatus,
		FormActions,
		Subheader,
		SubheaderReport,
		Icon,
		Main,
		Author,
		Authors,
		ReportChart,
		ReportDataset,
		ContentToggle,
		ReportSuggestions,
		ReportGoogle,
		FieldCheckbox,
		Grid,
		GridColumn,
		Checkboxes
	},

	/**
	 * Data
	 */
	data: function() {
		return {
			loading: true,
			error: false,
			success: false,
			message: '',
			errorMessage: '',
			dataObjectsMetadata: {
				datasets: { notes : "" },
				codeAndSoftware: { notes : "" },
				materials: { notes : "" },
				protocols: { notes : "" }
			},
			report: {
				sortedDataObjectsInfo: {
					all: [],
					protocols: [],
					code: [],
					software: [],
					reagents: [],
					datasets: []
				},
				originalDocument: {
					name: '',
					status: '',
					softcite: '',
					pdf: '',
					tei: '',
					metadata: {
						doi: '',
					},
				},
			},
			formMetadata: {
				affiliationAcknowledgementsLicenseNotes: "",
				readmeIncluded: {},
				describesFiles: {},
				describesVariables: {}
			},
			graphImg: '',
			graphURL: '',
		};
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', ['userRoleWeight']),
		authors() {
			// Get report authors and filter cases where author got no name or email
			const authors = this.report?.originalDocument.metadata.authors?.filter(author => author.name || author.email );
			return authors || []
		},
		organizations() {
			const organizations = this.report?.originalDocument?.organizations;
			return organizations || []
		},
		leadAuthors() {
			// Get lead authors only
			return this.authors.filter(author => author.isLeadSubmitting);
		},
		nonLeadAuthors() {
			// Get non lead authors only
			return this.authors.filter(author => !author.isLeadSubmitting);
		},
		documentID() {
			return this.$route.params.id
		},
		documentToken() {
			return this.report?.originalDocument.token || '';
		},
		cssVariables() {
			return variables
		},
		dataseerLink() {
			const id = this.report?.originalDocument._id || '';
			const token = this.report?.originalDocument.token || '';

			if (!id && !token) return undefined
			
			return `${process.env.VUE_APP_BASE_API_URL.replace('/api', '')}/documents/${id}?token=${token}`
		},
		PDFLink() {
			return URLMANAGER.buildURL(
				`api/documents/${this.documentID}/pdf/content`,
				{
					token: this.documentToken
				},
				{ origin: true }
			);
		}
	},

	/**
	 * Methods
	 */
	methods: {
		reportSubmit (data) {
			console.log(data)
		},
		reportResponse (data) {
			console.log(data)
		},
		parseDataToForm(data) {
			if (!data) return;
			const {
				metadata: {
					readmeIncluded,
					describesFiles,
					describesVariables,
					affiliationAcknowledgementsLicenseNotes
				}
			} = data;

			// Populate metadata
			if (typeof data.metadata === "object") {
				this.formMetadata = {
					affiliationAcknowledgementsLicenseNotes: affiliationAcknowledgementsLicenseNotes,
					readmeIncluded: readmeIncluded,
					describesFiles: describesFiles,
					describesVariables: describesVariables
				}
			}
		},
		async getDocumentReport() {
			try {
				const report = await documentsService.getDocumentReport(this.documentID);
				const doc = await documentsService.getDocument(this.documentID, {
					metadata: true,
					dataObjecs: true,
					dataObjectsMetadata: true
				});
				this.dataObjectsMetadata = doc.dataObjects.metadata;
				this.parseDataToForm(doc);
				document.title = report.originalDocument.name;
				const { sortedDataObjectsInfo } = report;
				const graphParams = {
					reUseDatasetsName: 'Data re-use correctly cited',
					reUseDatasetsDone: sortedDataObjectsInfo.datasets.filter(entry => entry.status !== 'No' && entry.reuse === true).length || 0,
					reUseDatasetsTotal: sortedDataObjectsInfo.datasets.filter(entry => entry.reuse === true).length || 0,
					
					newDatasetsName: 'New Datasets publicly shared',
					newDatasetsDone: sortedDataObjectsInfo.datasets.filter(entry => entry.status !== 'No' && entry.reuse === false).length || 0,
					newDatasetsTotal: sortedDataObjectsInfo.datasets.filter(entry => entry.reuse === false).length || 0,
					
					newCodesName: 'Original Code publicly shared',
					newCodesDone: sortedDataObjectsInfo.code.filter(entry => entry.status !== 'No' && !entry.reuse).length || 0,
					newCodesTotal: sortedDataObjectsInfo.code.filter(entry => entry.reuse === false).length || 0,
					reUseCodesName: 'Code re-use correctly cited',
					reUseCodesDone: sortedDataObjectsInfo.code.filter(entry => entry.status !== 'No' && entry.reuse).length || 0,
					reUseCodesTotal: sortedDataObjectsInfo.code.filter(entry => entry.reuse === true).length || 0,
					
					reUseMaterialsName: 'Existing Materials identified',
					reUseMaterialsDone: sortedDataObjectsInfo.reagents.filter(entry => entry.status !== 'No' && entry.
					reuse === true).length || 0,
					reUseMaterialsTotal: sortedDataObjectsInfo.reagents.filter(entry => entry.reuse === true).length || 0,
					newMaterialsName: 'New Materials Available',
					newMaterialsDone: sortedDataObjectsInfo.reagents.filter(entry => entry.status !== 'No' && entry.reuse === false).length || 0,
					newMaterialsTotal: sortedDataObjectsInfo.reagents.filter(entry => entry.reuse === false).length || 0,
					
					reUseProtocolsName: 'Existing Protocol re-use correctly cited',
					reUseProtocolsDone: sortedDataObjectsInfo.software.filter(entry => entry.status !== 'No' && entry.reuse).length || 0,
					reUseProtocolsTotal: sortedDataObjectsInfo.software.filter(entry => entry.reuse === true).length || 0,
					newProtocolsName: 'Protocols publicly shared',
					newProtocolsDone: sortedDataObjectsInfo.software.filter(entry => entry.status !== 'No' && !entry.reuse).length || 0,
					newProtocolsTotal: sortedDataObjectsInfo.software.filter(entry => entry.reuse === false).length || 0,
				}
				
				const graphImg = await chartsService.getChartImg(graphParams);
				const graphURL = chartsService.getChartUrl(graphParams);
				
				this.report = report;
				this.graphImg = graphImg;
				this.graphURL = graphURL;
			} catch (error) {
				this.error = true;
				this.message = error.message;
			}
			this.loading = false;
		},
		async handleFormSubmit() {
			this.loading = true;
			try {
				const updateDataObjectsMetadata = await documentsDataObjectsService.updateDataObjectsMetadata(this.documentID, { metadata: this.dataObjectsMetadata });
				const metadata = await documentsService.updateDocumentMetadata(this.documentID, { ...this.formMetadata });
				this.parseDataToForm({ metadata });
				this.dataObjectsMetadata = updateDataObjectsMetadata;
				this.success = true;
				this.message = `This document has been updated!`;
			} catch (error) {
				this.error = true;
				this.message = error.message;
			}

			this.loading = false;
		},
	},

	/**
	 * Created
	 */
	created () {
		this.getDocumentReport();
	},
};
</script>
