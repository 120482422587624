/* eslint-disable */

import $ from 'jquery';

const API = window.API;
export const DataObjectForm = function(id = `dataObjectForm`, events = {}) {
	let self = this;
	this.id = id;
	this.screen = $(`#${this.id}\\.screen`);
	this.container = $(`#${this.id} #dataObjectForm\\.container`); // container of dataObjectForm
	this.message = $(`#${this.id} #dataObjectForm\\.message`);
	this.dataObjectsTabs = this.container.find(`div[key="dataObjects"]`); // dataObjects list
	this.dataObjectTab = this.dataObjectsTabs.find(`div.tpl[key="dataObjects"]`); // dataObject tab tpl
	this.resources = {
		metadata: {},
		dataTypes: {},
		subTypes: {}
	};
	this.defaultDataType = undefined; // will contain the default dataType
	this.dataObject = {};
	this.events = events;
	this.buttons = {
		'display-left': this.screen.find(`.task-bar button[name="display-left"]`),
		'display-middle': this.screen.find(`.task-bar button[name="display-middle"]`),
		'display-right': this.screen.find(`.task-bar button[name="display-right"]`),
		hide: this.screen.find(`.task-bar button[name="hide"]`),
		show: this.screen.find(`.task-bar button[name="show"]`)
	};
	// On button click
	this.buttons[`display-left`].click(function() {
		self.screen.removeClass().addClass(`display-left`);
		self.hide();
		self.show();
		if (typeof self.events.onDisplayLeftClick === `function`)
			return self.events.onDisplayLeftClick();
	});
	this.buttons[`display-middle`].click(function() {
		self.screen.removeClass().addClass(`display-middle`);
		self.hide();
		self.show();
	});
	this.buttons[`display-right`].click(function() {
		self.screen.removeClass().addClass(`display-right`);
		self.hide();
		self.show();
		if (typeof self.events.onDisplayRightClick === `function`)
			return self.events.onDisplayRightClick();
	});
	this.buttons[`hide`].click(function() {
		self.hide();
	});
	this.buttons[`show`].click(function() {
		self.show();
	});
	// onDataObjectIdClick
	$(`#${this.id} div[key="dataObject\\.id"], #${this.id} div[key="dataObject\\.label"]`)
		.parent()
		.click(function() {
			if (typeof self.events.onDataObjectIdClick === `function`)
				return self.events.onDataObjectIdClick(self.getDataObject());
		});
	// onDataObjectDoneClick
	$(`#${this.id} button[name="dataObjectForm\\.done"]`).click(function() {
		if (typeof self.events.onDataObjectDoneClick === `function`)
			return self.events.onDataObjectDoneClick(self.getDataObject());
	});
	// onDataObjectUnlinkClick
	$(`#${this.id} button[name="dataObjectForm\\.unlink"]`).click(function(event) {
		event.stopPropagation();
		if (typeof self.events.onDataObjectUnlinkClick === `function`)
			return self.events.onDataObjectUnlinkClick(self.getDataObject());
	});
	// onRefreshDatatypesClick
	$(`#${this.id} button[name="dataObjectForm\\.refreshDatatypes"]`).click(function() {
		let el = $(this);
		if (
			el.attr(`loading`).toString() === `false` &&
			typeof self.events.onRefreshDatatypesClick === `function`
		) {
			el.attr(`loading`, true);
			el.find(`.fa-sync-alt`).addClass(`fa-spin`);
			el.find(`.fa-sync-alt`).addClass(`fa-pulse`);
			return self.events.onRefreshDatatypesClick(function() {
				el.attr(`loading`, false);
				el.find(`.fa-sync-alt`).removeClass(`fa-spin`);
				el.find(`.fa-sync-alt`).removeClass(`fa-pulse`);
			});
		}
	});
	// input text event
	this.container.find(`input[type="text"]`).bind(`input propertychange`, function() {
		let el = $(this),
			target = el.attr(`target`).replace(`dataObject.`, ``);
		if (typeof self.properties[target] === `function`) {
			self.properties[target](el.val());
			if (typeof self.events.onPropertyChange === `function`)
				self.events.onPropertyChange(target, self.properties[target]());
		}
	});
	this.container.find(`input[type="text"]`).focusout(function() {
		let el = $(this),
			target = el.attr(`target`).replace(`dataObject.`, ``);
		if (typeof self.events.onLeave === `function`)
			self.events.onLeave(target, self.properties[target]());
	});
	// input checkbox event
	this.container.find(`input[type="checkbox"]`).bind(`input propertychange`, function() {
		let el = $(this),
			target = el.attr(`target`).replace(`dataObject.`, ``);
		if (typeof self.properties[target] === `function`) {
			self.properties[target](el.prop(`checked`));
			if (typeof self.events.onPropertyChange === `function`)
				self.events.onPropertyChange(target, self.properties[target]());
		}
	});
	this.container.find(`input[type="checkbox"]`).focusout(function() {
		let el = $(this),
			target = el.attr(`target`).replace(`dataObject.`, ``);
		if (typeof self.events.onLeave === `function`)
			self.events.onLeave(target, self.properties[target]());
	});
	// select event
	this.container.find(`select`).bind(`input propertychange`, function() {
		let el = $(this),
			option = el.find(`option:selected`),
			target = el.attr(`target`).replace(`dataObject.`, ``),
			value = option.attr(`value`);
		if (typeof self.properties[target] === `function`) {
			self.properties[target](value);
			if (target === `dataType` || target === `subType`)
				return self.setRepos(function() {
					return self.events.onPropertyChange(target, self.properties[target]());
				});
			if (typeof self.events.onPropertyChange === `function`)
				self.events.onPropertyChange(target, self.properties[target]());
		}
	});
	this.container.find(`select`).focusout(function() {
		let el = $(this),
			target = el.attr(`target`).replace(`dataObject.`, ``);
		if (typeof self.events.onLeave === `function`)
			self.events.onLeave(target, self.properties[target]());
	});
	// textarea event
	this.container.find(`textarea`).bind(`input propertychange`, function() {
		let el = $(this),
			target = el.attr(`target`).replace(`dataObject.`, ``);
		if (typeof self.properties[target] === `function`) {
			self.properties[target](el.val());
			if (typeof self.events.onPropertyChange === `function`)
				self.events.onPropertyChange(target, self.properties[target]());
		}
	});
	this.container.find(`textarea`).focusout(function() {
		let el = $(this),
			target = el.attr(`target`).replace(`dataObject.`, ``);
		if (typeof self.events.onLeave === `function`)
			self.events.onLeave(target, self.properties[target]());
	});
	// Set or get a property
	this.properties = {
		id: function(value) {
			if (typeof value === `undefined`) return self.dataObject._id;
			self.container
				.find(`div[key="dataObject\\._id"]`)
				.attr(`value`, value)
				.text(value);
			self.dataObject._id = value;
			// input change behaviors
			self.updateLabel(value);
			// ----------------------
			return self.dataObject._id;
		},
		status: function(value) {
			if (typeof value === `undefined`) return self.dataObject.status;
			self.container
				.find(`div[key="dataObject\\.status"]`)
				.attr(`value`, value)
				.empty()
				.append(self.getIconOfStatus(value));
			self.dataObject.status = value;
			return self.dataObject.status;
		},
		reuse: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.reuse;
			let reuse = value === `false` || value === false ? false : !!value;
			self.container.find(`div[key="dataObject\\.reuse"]`).attr(`value`, reuse);
			if (inputs)
				self.container
					.find(`input[type="checkbox"][name="dataObjectForm\\.reuse"]`)
					.prop(`checked`, reuse);
			self.dataObject.reuse = reuse;
			// input change behaviors
			self.refreshDatatypeInfo(); // refresh dataType infos
			// ----------------------
			return self.dataObject.reuse;
		},
		dataType: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.dataType;
			self.container.find(`div[key="dataObject\\.dataType"]`).attr(`value`, value);
			let option = self.container.find(
				`select[name="dataObjectForm\\.dataType"] option[value="${value}"]`
			);
			if (inputs) {
				option.prop(`selected`, true);
			}
			if (option.length === 0) {
				self.properties[`customDataType`](value, true);
				self.dataObject.dataType = ``;
				self.container
					.find(`select[name="dataObjectForm\\.dataType"] option:first-child`)
					.prop(`selected`, true);
			} else self.dataObject.dataType = value;
			// input change behaviors
			self.setSubtypes(); // set subtypes
			self.refreshDatatypeInfo(); // refresh dataType infos
			// ----------------------
			return self.dataObject.dataType;
		},
		subType: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.subType;
			self.container.find(`div[key="dataObject\\.subType"]`).attr(`value`, value);
			if (inputs)
				self.container
					.find(`select[name="dataObjectForm\\.subType"] option[value="${value}"]`)
					.prop(`selected`, true);
			self.dataObject.subType = value;
			// input change behaviors
			self.refreshDatatypeInfo(); // refresh dataType infos
			// ----------------------
			return self.dataObject.subType;
		},
		customDataType: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.customDataType;
			self.container.find(`div[key="dataObject\\.customDataType"]`).attr(`value`, value);
			if (inputs)
				self.container
					.find(`input[type="text"][name="dataObjectForm\\.customDataType"]`)
					.val(value);
			self.dataObject.customDataType = value;
			return self.dataObject.customDataType;
		},
		description: function(value) {
			if (typeof value === `undefined`) return self.dataObject.description;
			let text = value === `` ? `n/a` : value;
			self.container
				.find(`div[key="dataObject\\.description"]`)
				.attr(`value`, value)
				.html(text);
			self.dataObject.description = value;
			return self.dataObject.description;
		},
		bestDataFormatForSharing: function(value) {
			if (typeof value === `undefined`) return self.dataObject.bestDataFormatForSharing;
			let text = value === `` ? `n/a` : value;
			self.container
				.find(`div[key="dataObject\\.bestDataFormatForSharing"]`)
				.attr(`value`, value)
				.html(text);
			self.dataObject.bestDataFormatForSharing = value;
			return self.dataObject.bestDataFormatForSharing;
		},
		url: function(value) {
			if (typeof value === `undefined`) return self.dataObject.url;
			let data = value === `` ? `http://wiki.dataseer.ai/doku.php` : value;
			self.container
				.find(`a[key="dataObject\\.url"]`)
				.attr(`value`, data)
				.attr(`href`, data);
			self.dataObject.url = data;
			return self.dataObject.url;
		},
		mostSuitableRepositories: function(value) {
			if (typeof value === `undefined`) return self.dataObject.mostSuitableRepositories;
			let text = value === `` ? `n/a` : value;
			self.container
				.find(`div[key="dataObject\\.mostSuitableRepositories"]`)
				.attr(`value`, value)
				.html(text);
			self.dataObject.mostSuitableRepositories = value;
			return self.dataObject.mostSuitableRepositories;
		},
		bestPracticeForIndicatingReUseOfExistingData: function(value) {
			if (typeof value === `undefined`)
				return self.dataObject.bestPracticeForIndicatingReUseOfExistingData;
			let text = value === `` ? `n/a` : value;
			self.container
				.find(`div[key="dataObject\\.bestPracticeForIndicatingReUseOfExistingData"]`)
				.attr(`value`, value)
				.html(text);
			self.dataObject.bestPracticeForIndicatingReUseOfExistingData = value;
			return self.dataObject.bestPracticeForIndicatingReUseOfExistingData;
		},
		highlight: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.highlight;
			let highlight = value === `false` ? false : !!value;
			self.container.find(`div[key="dataObject\\.highlight"]`).attr(`value`, highlight);
			if (inputs)
				self.container
					.find(`input[type="checkbox"][name="dataObjectForm\\.highlight"]`)
					.prop(`checked`, highlight);
			self.dataObject.highlight = highlight;
			return self.dataObject.highlight;
		},
		notification: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.notification;
			self.container
				.find(`div[key="dataObject\\.notification"]`)
				.attr(`value`, value)
				.text(value);
			if (inputs)
				self.container
					.find(`input[type="text"][name="dataObjectForm\\.notification"]`)
					.val(value);
			self.dataObject.notification = value;
			// input change behaviors
			// Hide notification if empty, else show
			if (!self.dataObject.notification)
				self.container.find(`div[key="dataObject\\.notification"]`).hide();
			else self.container.find(`div[key="dataObject\\.notification"]`).show();
			// ----------------------
			return self.dataObject.notification;
		},
		repoRecommenderUrls: function(values) {
			if (typeof values === `undefined`) return self.dataObject.repoRecommenderUrls;
			let data = !Array.isArray(values) ? [{ label: `n/a` }] : values;
			let element = self.container.find(`div[key="dataObject\\.repoRecommenderUrls"]`);
			element.attr(`value`, JSON.stringify(values));
			element.empty();
			let list = $(`<ol>`);
			data.map(function(item) {
				if (item.url) list.append(`<li><a href="${item.url}">${item.label}</a></li>`);
				else list.append(`<li>${item.label}</li>`);
			});
			element.append(list);
			self.dataObject.repoRecommenderUrls = data;
			// ----------------------
			return self.dataObject.repoRecommenderUrls;
		},
		name: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.name;
			self.container.find(`div[key="dataObject\\.name"]`).attr(`value`, value);
			if (inputs)
				self.container.find(`input[type="text"][name="dataObjectForm\\.name"]`).val(value);
			self.dataObject.name = value;
			// input change behaviors
			self.updateLabel(value);
			// ----------------------
			return self.dataObject.name;
		},
		DOI: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.DOI;
			self.container.find(`div[key="dataObject\\.DOI"]`).attr(`value`, value);
			if (inputs)
				self.container.find(`input[type="text"][name="dataObjectForm\\.DOI"]`).val(value);
			self.dataObject.DOI = value;
			return self.dataObject.DOI;
		},
		comments: function(value, inputs = false) {
			if (typeof value === `undefined`) return self.dataObject.comments;
			self.container.find(`div[key="dataObject\\.comments"]`).attr(`value`, value);
			if (inputs) self.container.find(`textarea[name="dataObjectForm\\.comments"]`).val(value);
			self.dataObject.comments = value;
			return self.dataObject.comments;
		}
	};
	this.setInitialazingMessage();
	return this;
};

// Set RepoRecommender result
DataObjectForm.prototype.setRepos = function(cb) {
	let self = this;
	let dataType = this.dataObject.dataType ? this.getDatatypeInfo(this.dataObject.dataType) : undefined;
	let subType = this.dataObject.subType
		? this.getDatatypeInfo(this.dataObject.dataType, this.dataObject.subType)
		: undefined;
	return API.repoRecommender.findRepo(
		{
			dataType: dataType ? dataType.label : undefined,
			subType: subType ? subType.label : undefined
		},
		function(err, query) {
			console.log(err, query);
			if (!query.err) self.properties.repoRecommenderUrls(query.res);
			return cb();
		}
	);
};

// update label of dataObject
DataObjectForm.prototype.updateLabel = function(value = ``) {
	let data = value ? value : this.dataObject._id;
	// Update label
	this.container
		.find(`div[key="dataObject\\.label"]`)
		.attr(`value`, data)
		.text(data);
	this.container
		.find(`div[key="dataObject\\.tab"][dataObject-id="${this.dataObject._id}"]`)
		.attr(`value`, data)
		.text(data);
};

// refresh label of dataObject
DataObjectForm.prototype.refreshLabel = function() {
	let data = this.dataObject.name ? this.dataObject.name : this.dataObject._id;
	// Update label
	this.container
		.find(`div[key="dataObject\\.label"]`)
		.attr(`value`, data)
		.text(data);
	this.container
		.find(`div[key="dataObject\\.tab"][dataObject-id="${this.dataObject._id}"]`)
		.attr(`value`, data)
		.text(data);
};

// refresh text of dataObject
DataObjectForm.prototype.refreshText = function(text) {
	// Update text
	this.container
		.find(`div[key="sentence\\.text"]`)
		.attr(`value`, text)
		.html(text);
};

// get current dataObject id
DataObjectForm.prototype.currentId = function() {
	return this.dataObject._id;
};

// get current dataObject sentence
DataObjectForm.prototype.currentSentence = function() {
	return this.dataObject.sentence;
};

// get current dataObject (API formated)
DataObjectForm.prototype.getDataObject = function() {
	return {
		id: this.dataObject._id,
		status: this.dataObject.status,
		reuse: this.dataObject.reuse,
		dataType: this.dataObject.dataType ? this.dataObject.dataType : this.dataObject.customDataType,
		subType: this.dataObject.subType,
		description: this.dataObject.description,
		bestDataFormatForSharing: this.dataObject.bestDataFormatForSharing,
		mostSuitableRepositories: this.dataObject.mostSuitableRepositories,
		bestPracticeForIndicatingReUseOfExistingData: this.dataObject
			.bestPracticeForIndicatingReUseOfExistingData,
		highlight: this.dataObject.highlight,
		notification: this.dataObject.notification,
		name: this.dataObject.name,
		DOI: this.dataObject.DOI,
		comments: this.dataObject.comments
	};
};

// Attach event
DataObjectForm.prototype.attach = function(event, fn) {
	this.events[event] = fn;
};

// Get resource property for an given dataType (or subType) id
DataObjectForm.prototype.getResourceOf = function(id, key, subKey) {
	if (
		!id ||
		!key ||
		!this.resources ||
		!this.resources.metadata ||
		!this.resources.metadata[id] ||
		!this.resources.metadata[id][key]
	)
		return ``;
	else {
		let result = subKey
			? this.resources.metadata[id][key][subKey]
			: this.resources.metadata[id][key];
		return result ? result : ``;
	}
};

// Extract some infos (based on resources)
DataObjectForm.prototype.extractInfo = function(keys, properties) {
	let self = this,
		result = {};
	keys.map(function(key) {
		result[key] = {};
		properties.map(function(property) {
			if (typeof property === `string`)
				result[key][property] = self.getResourceOf(self.dataObject[key], property);
			else if (typeof property === `object`)
				result[key][property.key] = self.getResourceOf(
					self.dataObject[key],
					property.key,
					property.subKey
				);
		});
	});
	return result;
};

// Refresh dataType infos (based on resources)
DataObjectForm.prototype.refreshDatatypeInfo = function() {
	let self = this,
		properties = [
			`description`,
			`bestDataFormatForSharing`,
			`bestPracticeForIndicatingReUseOfExistingData`,
			{ key: `mostSuitableRepositories`, subKey: this.dataObject.reuse ? `reuse` : `default` },
			`url`
		],
		keys = [`dataType`, `subType`],
		metadata = this.extractInfo(keys, properties);
	// Set dataType/subType infos
	properties.map(function(property) {
		if (typeof property === `string`) self.properties[property](``);
		else if (typeof property === `object` && property.key) self.properties[property.key](``);
	});
	properties.map(function(property) {
		keys.map(function(key) {
			if (typeof property === `string`)
				self.properties[property](
					metadata[key][property] ? metadata[key][property] : undefined
				);
			else if (typeof property === `object` && property.key)
				self.properties[property.key](
					metadata[key][property.key] ? metadata[key][property.key] : undefined
				);
		});
	});
	if (this.dataObject.reuse) {
		this.container
			.find(`div[key="dataObject\\.bestDataFormatForSharing"]`)
			.parent()
			.hide();
		this.container
			.find(`div[key="dataObject\\.bestPracticeForIndicatingReUseOfExistingData"]`)
			.parent()
			.show();
	} else {
		this.container
			.find(`div[key="dataObject\\.bestDataFormatForSharing"]`)
			.parent()
			.show();
		this.container
			.find(`div[key="dataObject\\.bestPracticeForIndicatingReUseOfExistingData"]`)
			.parent()
			.hide();
	}
};

// Get dataType infos (based on resources)
DataObjectForm.prototype.getDatatypeInfo = function() {
	let result = {},
		properties = [
			`label`,
			`description`,
			`bestDataFormatForSharing`,
			`bestPracticeForIndicatingReUseOfExistingData`,
			{ key: `mostSuitableRepositories`, subKey: this.dataObject.reuse ? `reuse` : `default` },
			`url`
		],
		keys = [`dataType`, `subType`],
		metadata = this.extractInfo(keys, properties);
	// Set dataType/subType infos
	properties.map(function(property) {
		if (typeof property === `string`) result[property] = ``;
		else if (typeof property === `object` && property.key) result[property.key] = ``;
	});
	properties.map(function(property) {
		keys.map(function(key) {
			if (typeof property === `string`)
				result[property] = metadata[key][property]
					? metadata[key][property]
					: result[property];
			else if (typeof property === `object` && property.key)
				result[property.key] = metadata[key][property.key]
					? metadata[key][property.key]
					: result[property.key];
		});
	});
	return result;
};

// Build default options
DataObjectForm.prototype.defaultOption = function() {
	return $(`<option>`)
		.text(`None`)
		.attr(`value`, ``);
};

// Build options (datatypes)
DataObjectForm.prototype.buildOptions = function(datatypes = []) {
	let options = [this.defaultOption()];
	for (let i = 0; i < datatypes.length; i++) {
		options.push(
			$(`<option>`)
				.text(datatypes[i].label)
				.attr(`value`, datatypes[i].id)
		);
	}
	return options;
};

// Load Resources
DataObjectForm.prototype.loadResources = function(resources) {
	this.defaultDataType = Object.keys(resources.dataTypes).sort(function(a, b) {
		let aCount = resources?.metadata[a]?.count ? resources?.metadata[a]?.count : 0;
		let bCount = resources?.metadata[b]?.count ? resources?.metadata[b]?.count : 0;
		if (aCount < bCount) return 1;
		else if (aCount > bCount) return -1;
		else return 0;
	})[0];
	this.resources = resources;
	this.setDatatypes();
	this.setSubtypes();
	this.setEmptyMessage();
};

// refresh Datatype view
DataObjectForm.prototype.refreshDatatypeView = function() {
	if (!this.dataObject.dataType || (!this.dataObject.dataType && !this.dataObject.subType))
		this.container
			.find(`div[key="dataObject\\.customDataType"]`)
			.parent()
			.show();
	else
		this.container
			.find(`div[key="dataObject\\.customDataType"]`)
			.parent()
			.hide();
};

// Set dataTypes
DataObjectForm.prototype.getOptionsInfo = function(ids = []) {
	let self = this;
	return ids
		.map(function(key) {
			if (typeof self.resources.metadata[key] === `object`)
				return {
					id: self.resources.metadata[key].id,
					label: self.resources.metadata[key].label,
					count: self.resources.metadata[key].count
						? self.resources.metadata[key].count
						: 0
				};
			else
				return {
					id: `unknow`,
					label: `unknow`,
					count: -1
				};
		})
		.sort(function(a, b) {
			return b.count - a.count;
		});
};

// Set dataTypes
DataObjectForm.prototype.setDatatypes = function() {
	let data = this.getOptionsInfo(Object.keys(this.resources.dataTypes)),
		options = this.buildOptions(data),
		select = this.container.find(`select[name="dataObjectForm\\.dataType"]`);
	select.empty();
	options.map(function(item) {
		select.append(item);
	});
	this.properties[`dataType`](``);
	this.refreshDatatypeView();
};

// Set subTypes
DataObjectForm.prototype.setSubtypes = function() {
	let data = this.dataObject.dataType
			? this.getOptionsInfo(this.resources.dataTypes[this.dataObject.dataType])
			: [],
		options = this.buildOptions(data),
		select = this.container.find(`select[name="dataObjectForm\\.subType"]`);
	select.empty();
	options.map(function(item) {
		select.append(item);
	});
	this.properties[`subType`](``);
	this.refreshDatatypeView();
};

// Set view for curator or not
DataObjectForm.prototype.setView = function(opts = {}) {
	if (opts.isCurator) {
		this.container
			.find(`input[type="text"][name="dataObjectForm\\.notification"]`)
			.parent()
			.show();
		this.container
			.find(`button[name="dataObjectForm\\.refreshDatatypes"]`)
			.parent()
			.show();
		this.container
			.find(`input[type="checkbox"][name="dataObjectForm\\.highlight"]`)
			.parent()
			.show();
	} else {
		this.container
			.find(`input[type="text"][name="dataObjectForm\\.notification"]`)
			.parent()
			.hide();
		this.container
			.find(`button[name="dataObjectForm\\.refreshDatatypes"]`)
			.parent()
			.hide();
		this.container
			.find(`input[type="checkbox"][name="dataObjectForm\\.highlight"]`)
			.parent()
			.hide();
	}
	if (opts.isLink === false) {
		this.container.find(`button[name="dataObjectForm\\.unlink"]`).hide();
	} else {
		this.container.find(`button[name="dataObjectForm\\.unlink"]`).show();
	}
};

// Return icon for a given status
DataObjectForm.prototype.getIconOfStatus = function(status) {
	let i = $(`<i>`);
	if (status === `valid`)
		i.addClass(`fas fa-check success-color-dark`).attr(`title`, `This dataObject is valid`);
	else if (status === `saved`)
		i.addClass(`far fa-save success-color-dark`).attr(`title`, `This dataObject is saved`);
	else if (status === `modified`)
		i.addClass(`fas fa-pen warning-color-dark`).attr(`title`, `This dataObject is modified`);
	else if (status === `loading`)
		i.addClass(`fas fa-spinner success-color-dark`).attr(`title`, `This dataObject is updating`);
	else i.addClass(`far fa-question-circle`).attr(`title`, `Unknow status`);
	return i;
};

// update dataObject
DataObjectForm.prototype.updateDataObject = function(dataObject) {
	// Set properties
	for (let key in dataObject) {
		if (typeof this.properties[key] === `function`) {
			this.properties[key](dataObject[key], true);
		}
	}
	this.properties[`customDataType`](``, true);
	this.properties[`dataType`](dataObject[`dataType`], true);
	this.properties[`subType`](dataObject[`subType`], true);
};

// update dataObject
DataObjectForm.prototype.updateSentence = function() {
	// Set properties
	// $('.sentence-img').attr('src', sentence.url);
	// $('.sentence-text').text(sentence.text);
};

// link dataObject
DataObjectForm.prototype.link = function(data, dataObjects, opts = {}, callback) {
	let self = this;
	let text = data.dataObject.sentences
		.sort(function(a, b) {
			let c = parseInt(a.id.replace(`sentence-`, ``), 10),
				d = parseInt(b.id.replace(`sentence-`, ``), 10);
			return c - d;
		})
		.map(function(item) {
			return item.text;
		})
		.join(`<br/>`);
	this.refreshText(text);
	this.refreshDataObject(data, opts, function(err, res) {
		if (dataObjects && dataObjects.length > 0) {
			self.refreshDataObjectsList(dataObjects);
		}
		return self.setRepos(function() {
			return callback(err, res);
		});
	});
};

// refresh dataObject list
DataObjectForm.prototype.refreshDataObjectsList = function(dataObjects) {
	this.clearTabs();
	for (let i = 0; i < dataObjects.length; i++) {
		this.addTab(dataObjects[i], { width: Math.floor((1 / dataObjects.length) * 100) });
	}
	this.refreshLabel();
};

// clear tabs in dataObjects list
DataObjectForm.prototype.clearTabs = function() {
	return this.dataObjectsTabs.find(`div[key="dataObject"]`).map(function() {
		let el = $(this);
		if (!el.hasClass(`tpl`)) el.remove();
	});
};

// add tab in dataObjects list
DataObjectForm.prototype.addTab = function(dataObject, opts) {
	let self = this;
	let newTab = this.dataObjectsTabs.find(`div.tpl[key="dataObject"]`).clone();
	newTab.removeClass(`tpl`);
	// color
	newTab
		.css(`color`, dataObject.color.foreground)
		.css(`background-color`, dataObject.color.background.rgba);
	// dataObject & sentence properties
	if (dataObject._id === this.dataObject._id) newTab.addClass(`selected`);
	newTab.css(`width`, `${opts.width}%`);
	newTab.click(function() {
		let el = $(this),
			dataObjectId = el.find(`div[key="dataObject\\.tab"]`).attr(`dataObject-id`),
			sentenceid = el.find(`div[key="sentence\\.id"]`).attr(`value`),
			sentenceText = el.find(`div[key="sentence\\.text"]`).attr(`value`);
		if (typeof self.events.onTabClick !== `undefined`)
			self.events.onTabClick({
				dataObject: { id: dataObjectId },
				sentence: { id: sentenceid, text: sentenceText }
			});
	});
	newTab
		.find(`div[key="dataObject\\.tab"]`)
		.attr(`value`, dataObject._id)
		.attr(`dataObject-id`, dataObject._id)
		.text(dataObject._id);
	newTab
		.find(`div[key="dataObject\\.label"]`)
		.attr(`value`, dataObject._id)
		.text(dataObject._id);
	newTab.find(`div[key="sentence\\.id"]`).attr(`value`, this.dataObject.sentence.id);
	newTab.find(`div[key="sentence\\.text"]`).attr(`value`, this.dataObject.sentence.text);
	return this.dataObjectsTabs.append(newTab);
};

// refresh dataObject
DataObjectForm.prototype.refreshDataObject = function(data = {}, opts = {}, callback) {
	if (!data.dataObject) {
		this.setEmptyMessage();
		this.showMessage();
		return typeof callback === `function` ? callback(true) : undefined;
	}
	this.dataObject.color = data.dataObject.color;
	this.dataObject.sentence = data.sentence;
	this.updateDataObject(data.dataObject);
	// this.updateSentence(data.sentence);
	let shouldSave =
		this.properties[`description`]() !== data.dataObject.description ||
		this.properties[`bestDataFormatForSharing`]() !== data.dataObject.bestDataFormatForSharing ||
		this.properties[`mostSuitableRepositories`]() !== data.dataObject.mostSuitableRepositories ||
		this.properties[`bestPracticeForIndicatingReUseOfExistingData`]() !==
			data.dataObject.bestPracticeForIndicatingReUseOfExistingData;
	this.setView({ isCurator: opts.isCurator, isLink: opts.isLink });
	this.color();
	this.hideMessage();
	return typeof callback === `function`
		? callback(null, { shouldSave: shouldSave, dataObject: this.dataObject })
		: undefined;
};

// Link dataObject to dataObjectForm
DataObjectForm.prototype.unlink = function() {
	this.dataObject = {};
	this.uncolor();
	this.setEmptyMessage();
	return this.showMessage();
};

// Set color on dataObject id
DataObjectForm.prototype.color = function() {
	return this.container
		.find(`div[key="dataObject\\._id"], div[key="dataObject\\.label"]`)
		.parent()
		.css(`color`, this.dataObject.color.foreground)
		.css(`background-color`, this.dataObject.color.background.rgba);
};

// Unset color on dataObject id
DataObjectForm.prototype.uncolor = function() {
	return this.container.find(`div[key="dataObject\\._id"]`);
};

// Set status "modified"
DataObjectForm.prototype.modified = function() {
	return this.properties[`status`](`modified`);
};

// Set status "modified"
DataObjectForm.prototype.loading = function() {
	return this.properties[`status`](`loading`);
};

// Set Empty dataObjectForm Message
DataObjectForm.prototype.hideMessage = function() {
	return this.message.addClass(`hide`);
};

// Set Empty dataObjectForm Message
DataObjectForm.prototype.showMessage = function() {
	return this.message.removeClass(`hide`);
};

// Set Empty dataObjectForm Message
DataObjectForm.prototype.setEmptyMessage = function() {
	return this.message
		.empty()
		.append($(`<div>`).text(`The selected sentences are not linked to a dataObject`))
		.append($(`<div>`).text(`If they are, click the button "Add new DataObject"`));
};

// Set Intializing dataObjectForm Message
DataObjectForm.prototype.setInitialazingMessage = function() {
	return this.message.empty().append($(`<div>`).text(`Populating dataObject Form...`));
};

// Hide dataObjectForm
DataObjectForm.prototype.hide = function() {
	this.container.hide();
	this.buttons[`hide`].hide();
	this.buttons[`show`].show();
	this.buttons[`display-left`].hide();
	this.buttons[`display-middle`].hide();
	this.buttons[`display-right`].hide();
	this.screen.removeClass(`maximized`).addClass(`minimized`);
};

// Show dataObjectForm
DataObjectForm.prototype.show = function() {
	this.container.show();
	this.buttons[`hide`].show();
	this.buttons[`show`].hide();
	this.buttons[`display-middle`].show();
	this.buttons[`display-left`].show();
	this.buttons[`display-middle`].show();
	this.buttons[`display-right`].show();
	this.screen.removeClass(`minimized`).addClass(`maximized`);
};
