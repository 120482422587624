<template>
	<svg width="56px" height="58px" viewBox="0 0 56 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-173.000000, -547.000000)" :fill="color" fill-rule="nonzero">
				<path
					id="Path-8"
					d="M175.574319,547.723989 L198.513259,548.33023 C199.617443,548.359412 200.488905,549.278186 200.459723,550.38237 C200.431867,551.436364 199.593453,552.278311 198.556938,552.327293 L198.407582,552.328834 L183.1,551.924 L193.683782,558.250861 L193.779726,558.311988 C213.109049,571.414246 224.62483,585.947955 228.20602,601.931785 C228.447513,603.009633 227.769513,604.079169 226.691666,604.320662 C225.613818,604.562154 224.544282,603.884155 224.302789,602.806307 C221.009611,588.107954 210.343288,574.522294 192.186995,562.067349 L191.578,561.652 L181.048,555.357 L188.651341,568.648651 C189.173674,569.561783 188.896829,570.713528 188.04104,571.294919 L187.908357,571.377749 C186.995225,571.900083 185.843481,571.623237 185.262089,570.767449 L185.179259,570.634766 L173.78544,550.716348 C173.011057,549.362589 174.01527,547.682785 175.574319,547.723989 Z"
				></path>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'icoArrowDown',

	/**
	 * color
	 */
	props: ['color']
};
</script>
