<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="5.494" height="9.988" viewBox="0 0 5.494 9.988">
		<g transform="translate(-0.253 -0.259)">
			<path
				d="M9.847.153A.5.5,0,0,0,9.14.14L4.994,4.287.847.14A.5.5,0,0,0,.14.847l4.5,4.5a.5.5,0,0,0,.707,0l4.5-4.5A.5.5,0,0,0,9.847.153Z"
				transform="translate(5.747 0.259) rotate(90)"
				:fill="color"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconAngleLeft',

	/**
	 * color
	 */
	props: ['color']
};
</script>
