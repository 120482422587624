<template>
	<div class="popup-draggable">
		<div v-if="isVisible && hasVeil" class="veil" @click="hideModal">
			<div
				class="modal"
				ref="modal"
				:style="{ width: width[size], height: height }"
				@click.stop
				@mousedown.stop="onMouseDown"
			>
				<BtnClose @onClick="hideModal" />
				<slot />
			</div>
		</div>
		<div v-if="isVisible && !hasVeil"
				class="modal"
				ref="modal"
				:style="{ width: width[size], height: height }"
				@click.stop
				@mousedown.stop="onMouseDown"
			>
				<BtnClose @onClick="hideModal" />
				<slot />
		</div>
	</div>
</template>

<script>
/**
 * Internal Dependencies
 */
import BtnClose from '@/components/btn-close/btn-close';

export default {
	/**
	 * Name
	 */
	name: 'DraggablePopup',

	/**
	 * Components
	 */
	components: {
		BtnClose
	},

	/**
	 * Props
	 */
	props: {
		name: String,
		size: {
			type: String,
			default: 'large',
			validator: function(value) {
				// The value must match one of these strings
				return ['small', 'large'].indexOf(value) !== -1;
			}
		},
		height: {
			type: String,
			default: 'auto'
		},
		hasVeil: {
			type: Boolean,
			default: false
		}
	},

	/**
	 * Data
	 */
	data() {
		return {
			width: {
				large: '1200px',
				small: '650px'
			},
			isDragging: false,
			dragStartX: 0,
			dragStartY: 0,
			isVisible: false
		};
	},

	/**
	 * Watchers
	 */
	watch: {
		isVisible(newValue) {
			if (newValue) {
				// The div is about to be shown
				this.$nextTick(() => {
					this.handleModalMounted();
				});
			} else {
				// The div is about to be hidden
				this.handleModalUnmounted();
			}
		}
	},

	/**
	 * Methods
	 */
	methods: {
		showModal() {
			this.isVisible = true;
		},
		hideModal() {
			this.isVisible = false;
		},
		onMouseDown(event) {
			if (!this.isCursorInPadding(this.$refs.modal, event)) return;
			this.isDragging = true;
			this.dragStartX = event.clientX - this.$refs.modal.offsetLeft;
			this.dragStartY = event.clientY - this.$refs.modal.offsetTop;

			document.addEventListener('mousemove', this.onMouseMove);
			document.addEventListener('mouseup', this.onMouseUp);
		},
		onMouseMove(event) {
			if (!this.isDragging) return;

			// Calculate new position
			let newLeft = event.clientX - this.dragStartX;
			let newTop = event.clientY - this.dragStartY;

			// Boundary checks
			const popupRect = this.$refs.modal.getBoundingClientRect();
			const minLeft = 0;
			const minTop = 0;
			const maxLeft = window.innerWidth - popupRect.width;
			const maxTop = window.innerHeight - popupRect.height;

			// Ensure the new position is within boundaries
			newLeft = Math.max(minLeft, Math.min(newLeft, maxLeft));
			newTop = Math.max(minTop, Math.min(newTop, maxTop));

			// Apply new position
			this.$refs.modal.style.left = `${newLeft}px`;
			this.$refs.modal.style.top = `${newTop}px`;
		},
		onMouseUp() {
			this.isDragging = false;

			document.removeEventListener('mousemove', this.onMouseMove);
			document.removeEventListener('mouseup', this.onMouseUp);
		},
		handleModalMounted() {
			// Add your custom logic here
			const popupRect = this.$refs.modal.getBoundingClientRect();
			this.$refs.modal.style.left = `${Math.floor(window.innerWidth / 2) - Math.floor(popupRect.width / 2)}px`
			this.$refs.modal.style.top = `${Math.floor(window.innerHeight / 2) - Math.floor(popupRect.height / 2)}px`
		},
		handleModalUnmounted() {
			// Add your custom logic here
		},
		/**
		 * Checks if the cursor is in the padding area of an element.
		 * 
		 * @param {HTMLElement} element - The HTML element.
		 * @param {MouseEvent} event - The mouse event containing cursor coordinates.
		 * 
		 * @returns {boolean} - true if the cursor is in the padding area, false if it's in the content area.
		 */
		isCursorInPadding(element, event) {
				// Get element's bounding box
				const rect = element.getBoundingClientRect();
				
				// Get the computed style of the element to access the padding values
				const style = getComputedStyle(element);
				
				const paddingLeft = parseFloat(style.paddingLeft);
				const paddingRight = parseFloat(style.paddingRight);
				const paddingTop = parseFloat(style.paddingTop);
				const paddingBottom = parseFloat(style.paddingBottom);

				// Get cursor coordinates relative to the viewport from the event
				const cursorX = event.clientX;
				const cursorY = event.clientY;

				// Calculate the content box boundaries
				const contentLeft = rect.left + paddingLeft;
				const contentRight = rect.right - paddingRight;
				const contentTop = rect.top + paddingTop;
				const contentBottom = rect.bottom - paddingBottom;

				// Check if the cursor is in the padding area
				if (cursorX < contentLeft || cursorX > contentRight || cursorY < contentTop || cursorY > contentBottom) {
						return true; // Cursor is in the padding area
				}

				// If not in the padding area, it must be in the content area
				return false; // Cursor is in the content area
		}

	}
};
</script>
