<template>
	<Form className="form--edit" @submit.prevent="updateOrganization" :loading="loading">
		<FormStatus v-if="error || success" :text="message" :isError="error" />
		
		<FormBody>
			<FormGroup :title="title">
				<Grid columnGap="large">
					<GridColumn>
						<Field
							v-model.trim="formData.name"
							name="name"
						>
							<Icon name="organization" color="currentColor" />

							Organization Name
						</Field>
					</GridColumn>
				</Grid>
			</FormGroup>
			
			<FormGroup title="Settings">
				<Grid columnGap="large">
					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="visible" v-model="formData.visible" isToggle>
									Organization Is {{ formData.visible ? 'Active' : 'Inactive'}}
								</FieldCheckbox>
								<FieldCheckbox name="locked" v-model="formData.locked" isToggle>
									Organization Is {{ formData.locked ? 'Locked' : 'Not Locked'}}
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>
				</Grid>
			</FormGroup>
			
			<FormGroup title="Reports">
				<Grid columnGap="large">
					<GridColumn>
						<h6>Manage all templates</h6>
						<table>
							<thead>
								<tr>
									<th title="Name of the template">Name</th>
									<th title="Enable or disable a template">Availability</th>
									<th class="center">Open</th>
									<th class="center">Generate</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in availableTemplates" :key="item.label">
									<td>
											{{ item.label }}
									</td>
									<td>
										<Checkboxes>
											<FieldCheckbox
												:name="'available-templates-enabled-' + item.label"
												v-model="item.enabled"
												isToggle
												@input="(value) => { handleChangeAvailableTemplate(item, value) }"
											>
												{{ item.enabled ? "Available" : "Unavailable" }}
											</FieldCheckbox>
										</Checkboxes>
									</td>
									<td>
										<Checkboxes>
											<FieldCheckbox
												:name="'available-templates-open-enabled-' + item.label"
												v-model="item.actions.open.enabled"
												isToggle
												:disabled="!item.enabled"
												@input="(value) => { handleChangeOpenTemplate(item, value) }"
											>
												{{ item.actions.open.enabled ? "Enabled" : "Disabled" }}
											</FieldCheckbox>
										</Checkboxes>
									</td>
									<td>
										<Checkboxes>
											<FieldCheckbox
												:name="'available-templates-generate-enabled-' + item.label"
												v-model="item.actions.generate.enabled"
												isToggle
												:disabled="!item.enabled"
												@input="(value) => { handleChangeGenerateTemplate(item, value) }"
											>
												{{ item.actions.generate.enabled ? "Enabled" : "Disabled" }}
											</FieldCheckbox>
										</Checkboxes>
									</td>
								</tr>
							</tbody>
						</table>
					</GridColumn>
				</Grid>

				<br/>

				<Grid columnSize="half">
					<GridColumn>
						<h6>Select the default Action</h6>
						<FieldSelect
							name="defaultActionSelect"
							v-model.trim="defaultActionSelect"
							:options="defaultActionsSelect"
							placeholder="Default Action"
							@input="handleDefaultActionChange"
						>
						</FieldSelect>
					</GridColumn>

					<GridColumn>
						<h6>Select the default Template</h6>
						<FieldSelect
							name="defaultTemplateSelect"
							v-model.trim="defaultTemplateSelect"
							:options="defaultTemplatesSelect"
							placeholder="Default Template"
							@input="handleDefaultTemplateChange"
						>
						</FieldSelect>
					</GridColumn>

					<GridColumn>
						<span>
							<i>
								{{ defaultAction === '' ?
									`You must enable at least one action${ (defaultTemplate === '') ? '.' : ` of the ${defaultTemplate.label} template.` }` :
									`The default action will be "${defaultAction}".`
								}}
							</i>
						</span>
					</GridColumn>

					<GridColumn>
						<span>
							<i>
								{{ defaultTemplate === '' ? 'You must enable at least one template' : `The default template will be "${defaultTemplate.label}"` }}
							</i>
						</span>
					</GridColumn>
				</Grid>
			</FormGroup>
			
			<FormGroup title="Upload Parameters">
				<Grid columnGap="large">
					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-alreadyProcessed" v-model="uploadParams.alreadyProcessed" isToggle>
									This is a new version of an article DataSeer has already assessed
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-dataseerML" v-model="uploadParams.dataseerML" isToggle>
									Automatically identify datasets (DataSeer ML will process this document)
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-removeResponseToViewerSection" v-model="uploadParams.removeResponseToViewerSection" isToggle>
									Remove "Response To Viewer" pages at the begining of the PDF
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-mergePDFs" v-model="uploadParams.mergePDFs" isToggle>
									Merge attached PDF files with the main PDF file
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-deleteDataObjects" v-model="uploadParams.deleteDataObjects" isToggle>
									Delete all extracted dataObjects
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-mute" v-model="uploadParams.mute" isToggle>
									Disable email (no email will be sent)
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>
				</Grid>

				<Grid columnGap="large">
					<GridColumn>
						<br/>
						<h6>Softcite settings</h6>
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-softcite" v-model="uploadParams.softcite" isToggle>
									Request Softcite service (may slow down the upload processing time)
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-importDataFromSoftcite" v-model="uploadParams.importDataFromSoftcite" isToggle>
									Automatically import data from Softcite<br/>
									<i>({{ uploadParams.ignoreSoftCiteSoftware ? "" : "Software" }}{{ uploadParams.ignoreSoftCiteCommandLines ? "" : `${!uploadParams.ignoreSoftCiteSoftware ? " & " : ""}Command Lines` }}{{ (!uploadParams.ignoreSoftCiteSoftware || !uploadParams.ignoreSoftCiteCommandLines) ? " will be automatically created" : ""}}{{ uploadParams.ignoreSoftCiteSoftware && uploadParams.ignoreSoftCiteCommandLines ? "No data objects will be created" : "" }})</i>
									<br/>
									<i>Note: if this option is enabled, the Softcite service will be requested if necessary</i>
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-ignoreSoftCiteSoftware" v-model="uploadParams.ignoreSoftCiteSoftware" isToggle>
									Ignore Software (no software will be created)
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-ignoreSoftCiteCommandLines" v-model="uploadParams.ignoreSoftCiteCommandLines" isToggle>
									Ignore Command Lines (no command lines will be created)
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>
				</Grid>

				<Grid columnGap="large">
					<GridColumn>
						<br/>
						<h6>Code/Software CustomList</h6>
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-refreshDataObjectsSuggestedProperties" v-model="uploadParams.refreshDataObjectsSuggestedProperties" isToggle>
									Automatically fill in suggested properties of code/software (using "CustomList & SciScore")
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>
					
				</Grid>

				<Grid columnGap="large">
					<GridColumn>
						<br/>
						<h6>BioNLP settings</h6>
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-BioNLP" v-model="uploadParams.bioNLP" isToggle>
									Request Bio NLP service (may slow down the upload processing time)
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-importDataFromBioNLP" v-model="uploadParams.importDataFromBioNLP" isToggle>
									Automatically import data from Bio NLP<br/>
									<i>({{ uploadParams.importDataFromBioNLP ? "'Lab Materials' will be created" : "No data objects will be created" }})</i>
									<br/>
									<i>Note: if this option is enabled, the BioNLP services will be requested if necessary</i>
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>
				</Grid>

				<Grid columnGap="large">
					<GridColumn>
						<br/>
						<h6>KRT settings</h6>
					</GridColumn>

					<GridColumn>
						<div class="checkboxes checkboxes--vertical">
							<Checkboxes>
								<FieldCheckbox name="uploadParams-importDataFromKRT" v-model="uploadParams.importDataFromKRT" isToggle>
									Create dataObjects from Key Resource Table part of the PDF
								</FieldCheckbox>
							</Checkboxes>
						</div> <!-- /.checkboxes -->
					</GridColumn>
				</Grid>

			</FormGroup>

		</FormBody>

		<FormActions>
			<li>
				<Button type="submit">Save Changes</Button>
			</li>

			<li>
				<Button to="/organizations" type="button" className="tertiary">Cancel</Button>
			</li>

			<li>
				<Button
					type="button"
					className="tertiary"
					modifier="is-warning"
					@onClick="handleDeleteButtonClick"
				>
					<Icon name="trash" color="#E36329" /> Delete Organization
				</Button>
			</li>
		</FormActions>
	</Form>
</template>

<script>
/**
 * Internal Dependencies
 */
import Icon from '@/components/icon/icon';
import Field from '@/components/field/field';
import Button from '@/components/button/button';
import Checkboxes from '@/components/checkboxes/checkboxes';
import FieldCheckbox from '@/components/field-checkbox/field-checkbox';
import FieldSelect from '@/components/field-select/field-select';
import Grid, { GridColumn } from '@/components/grid/grid';
import Form, { FormBody, FormActions, FormStatus, FormGroup } from '@/components/form/form';
import organizationsService from '@/services/organizations/organizations';

export default {
	/**
	 * Name
	 */
	name: 'FormEditOrganization',

	/**
	 * Components
	 */
	components: {
		Form,
		Icon,
		Grid,
		Field,
		Button,
		FormBody,
		FormGroup,
		FormStatus,
		GridColumn,
		FormActions,
		Checkboxes,
		FieldCheckbox,
		FieldSelect
	},

	/**
	 * Data
	 */
	data: function() {
		return {
			title: '',
			formData: {
				name: '',
				visible: false,
				locked: false
			},
			uploadParams: {
				alreadyProcessed: true,
				dataseerML: true,
				mergePDFs: true,
				removeResponseToViewerSection: true,
				softcite: false,
				mute: false,
				bioNLP: false,
				importDataFromBioNLP: false,
				importDataFromSoftcite: false,
				ignoreSoftCiteCommandLines: false,
				ignoreSoftCiteSoftware: false,
				importDataFromKRT: false,
				refreshDataObjectsSuggestedProperties: false,
				deleteDataObjects: false
			},
			organization: {},
			availableTemplates: [],
			defaultActionSelect: "",
			defaultTemplateSelect: "",
			loading: false,
			error: false,
			success: false,
			message: '',
			formMessages: {
				deleted: 'You\'ve successfully deleted this Organization',
				updated: 'You\'ve successfully updated this Organization',
				error: 'There was an error updating this Organization.',
				confirmDelete: 'Are you sure you want to delete this Organization?'
			},
		};
	},

	/**
	 * Computed
	 */
	computed: {
		organizationId() {
			return this.$route.params.id
		},
		defaultTemplate() {
			let filter = this.availableTemplates.filter((item) => {
				return item.isDefault && item.enabled;
			});
			if (filter.length > 0) return filter[0];
			else return ''
		}, 
		defaultAction() {
			let result = "";
			if (this.defaultTemplate !== "") {
				if (this.defaultTemplate.actions.open.enabled && this.defaultTemplate.actions.open.isDefault) result = 'open';
				else if (this.defaultTemplate.actions.generate.enabled && this.defaultTemplate.actions.generate.isDefault) result = 'generate';
				else if (this.defaultTemplate.actions.generate.enabled) result = 'generate';
				else if (this.defaultTemplate.actions.open.enabled) result = 'open';
			}
			return result;
		},
		defaultActionsSelect() {
			let result = [];
			if (this.defaultTemplate === '') return result;
			if (this.defaultTemplate.actions.open.enabled) result.push('open');
			if (this.defaultTemplate.actions.generate.enabled) result.push('generate');
			return result.map((item) => {
				return { label: item, value: item };
			});
		},
		defaultTemplatesSelect() {
			return this.availableTemplates.filter((item) => {
				return item.enabled;
			}).map((item) => {
				return { label: item.label, value: item.label };
			});
		},
		settings() {
			return {
				reports: {
					templates: this.availableTemplates
				},
				upload: {
					alreadyProcessed: this.uploadParams.alreadyProcessed,
					dataseerML: this.uploadParams.dataseerML,
					mergePDFs: this.uploadParams.mergePDFs,
					mute: this.uploadParams.mute,
					removeResponseToViewerSection: this.uploadParams.removeResponseToViewerSection,
					bioNLP: this.uploadParams.bioNLP,
					importDataFromBioNLP: this.uploadParams.importDataFromBioNLP,
					softcite: this.uploadParams.softcite,
					importDataFromSoftcite: this.uploadParams.importDataFromSoftcite,
					ignoreSoftCiteCommandLines: this.uploadParams.ignoreSoftCiteCommandLines,
					ignoreSoftCiteSoftware: this.uploadParams.ignoreSoftCiteSoftware,
					importDataFromKRT: this.uploadParams.importDataFromKRT,
					refreshDataObjectsSuggestedProperties: this.uploadParams.refreshDataObjectsSuggestedProperties,
					deleteDataObjects: this.uploadParams.deleteDataObjects
				}
			}
		}
	},


	/**
	 * Watch
	 */
	// watch: {
	// 	availableTemplates: {
	// 		handler() {},
	// 		deep: true
	// 	}
	// },

	/**
	 * Methods
	 */
	methods: {
		async updateOrganization() {
			this.loading = true;
			this.error = false;
			const params = {
				name: this.formData.name,
				visible: this.formData.visible,
				settings: this.settings
			}
			try {
				this.organization = await organizationsService.updateOrganization(this.organizationId, params);
				this.title = this.organization.name;
				this.success = true;
				this.message = this.formMessages.updated;
			} catch (error) {
				this.error = true;
				this.message = error.message;
			}
			this.loading = false;
		},
		async getOrganization() {
			this.loading = true;
			try {
				this.organization = await organizationsService.getOrganization(this.organizationId);
				this.availableTemplates = this.organization.settings?.reports?.templates?.length > 0 ? this.organization.settings?.reports?.templates : [];
				this.uploadParams.alreadyProcessed = this.organization.settings?.upload?.alreadyProcessed;
				this.uploadParams.dataseerML = this.organization.settings?.upload?.dataseerML;
				this.uploadParams.mergePDFs = this.organization.settings?.upload?.mergePDFs;
				this.uploadParams.mute = this.organization.settings?.upload?.mute;
				this.uploadParams.removeResponseToViewerSection = this.organization.settings?.upload?.removeResponseToViewerSection;
				this.uploadParams.bioNLP = this.organization.settings?.upload?.bioNLP;
				this.uploadParams.importDataFromBioNLP = this.organization.settings?.upload?.importDataFromBioNLP;
				this.uploadParams.softcite = this.organization.settings?.upload?.softcite;
				this.uploadParams.importDataFromSoftcite = this.organization.settings?.upload?.importDataFromSoftcite;
				this.uploadParams.ignoreSoftCiteCommandLines = this.organization.settings?.upload?.ignoreSoftCiteCommandLines;
				this.uploadParams.ignoreSoftCiteSoftware = this.organization.settings?.upload?.ignoreSoftCiteSoftware;
				this.uploadParams.importDataFromKRT = this.organization.settings?.upload?.importDataFromKRT;
				this.uploadParams.refreshDataObjectsSuggestedProperties = this.organization.settings?.upload?.refreshDataObjectsSuggestedProperties;
				this.uploadParams.deleteDataObjects = this.organization.settings?.upload?.deleteDataObjects;
				this.title = this.organization.name;
				this.formData.name = this.organization.name;
				this.formData.visible = this.organization.visible;
				this.defaultTemplateSelect = this.defaultTemplate ? this.defaultTemplate.label : this.defaultTemplate;
				this.defaultActionSelect = this.defaultAction;
			} catch (error) {
				this.error = true;
				this.message = error.message || this.formMessages.error;
			}
			this.loading = false;
		},
		async deleteOrganization() {
			this.loading = true;
			try {
				this.resetForm()
				await organizationsService.deleteOrganization(this.organizationId);
				this.success = true;
				this.message = this.formMessages.deleted;
			} catch (error) {
				this.error = true;
				this.message = error.message || this.formMessages.error;
			}
			this.loading = false;
		},
		handleChangeOpenTemplate(template) {
			if (template.isDefault) return this.refreshDefaultActionSelect();
		},
		handleChangeGenerateTemplate(template) {
			if (template.isDefault) return this.refreshDefaultActionSelect();
		},
		handleChangeAvailableTemplate(template) {
			if (template.enabled) {
				template.actions.open.enabled = true;
				template.actions.generate.enabled = true;
				template.isDefault = false;
			} else {
				template.actions.open.enabled = false;
				template.actions.generate.enabled = false;
				template.isDefault = false;
			}
			this.refreshDefaultTemplateSelect();
			return template;
		},
		refreshDefaultActionSelect() {
			if (this.defaultTemplate !== "") {
				if (!this.defaultTemplate.actions.open.enabled && this.defaultTemplate.actions.generate.enabled)
					this.handleDefaultActionChange('generate');
				if (!this.defaultTemplate.actions.generate.enabled && this.defaultTemplate.actions.open.enabled)
					this.handleDefaultActionChange('open');
			}
			this.defaultActionSelect = this.defaultAction;
		},
		refreshDefaultTemplateSelect() {
			let thereIsAtLeastOneDefaultTemplate = this.availableTemplates.filter((item) => { return item.isDefault; }).length > 0;
			let notDefaultTemplatesList = this.availableTemplates.filter((item) => { return !item.isDefault && item.enabled; });
			if (!thereIsAtLeastOneDefaultTemplate && notDefaultTemplatesList.length > 0) notDefaultTemplatesList[0].isDefault = true;
			this.defaultTemplateSelect = this.defaultTemplate ? this.defaultTemplate.label : this.defaultTemplate;
			this.refreshDefaultActionSelect()
		},
		handleDefaultActionChange(value) {
			if (this.defaultTemplate === "") return;
			if (value === 'open') {
				this.defaultTemplate.actions.open.isDefault = true;
				this.defaultTemplate.actions.generate.isDefault = false;
			} else if (value === 'generate') {
				this.defaultTemplate.actions.open.isDefault = false;
				this.defaultTemplate.actions.generate.isDefault = true;
			}
		},
		handleDefaultTemplateChange(value) {
			let defaultTemplate = this.availableTemplates.filter((item) => { return item.isDefault })[0];
			let template = this.availableTemplates.filter((item) => { return item.label === value })[0];
			defaultTemplate.isDefault = false;
			template.isDefault = true;
			this.refreshDefaultActionSelect();
		},
		handleDeleteButtonClick() {
			this.openConfirmModal({
				message: this.formMessages.confirmDelete,
				confirm: "Yes, Delete",
				cancel: "No, Keep It",
				onConfirm: this.deleteOrganization,
			})
		},
		resetForm() {
			this.error = false;
			this.success = false;
			this.message = '';
		}
	},
	
	/**
	 * Created
	 */
	created () {
		this.getOrganization();
	},
};
</script>
