<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 10.479 10.605"
	>
		<path
			d="M10.437-6.783a.923.923,0,0,1,.682.262.923.923,0,0,1,.263.682.862.862,0,0,1-.263.651.945.945,0,0,1-.682.252H7.2v3.213a1.059,1.059,0,0,1-.294.766,1.031,1.031,0,0,1-.777.3,1.039,1.039,0,0,1-.766-.3,1.039,1.039,0,0,1-.3-.766V-4.935H1.848a.913.913,0,0,1-.672-.273A.913.913,0,0,1,.9-5.88a.862.862,0,0,1,.262-.651.945.945,0,0,1,.683-.252H5.061v-3.423a1,1,0,0,1,.3-.756,1.059,1.059,0,0,1,.766-.294,1.05,1.05,0,0,1,.777.294,1.023,1.023,0,0,1,.294.756v3.423Z"
			transform="translate(-0.903 11.256)"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconPlus',

	/**
	 * props
	 */
	props: {
		color: {
			type: String
		},
		width: {
			default: 10
		},
		height: {
			default: 10
		}
	}
};
</script>
