<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 9L13.5 9C13.8 9 14 8.8 14 8.5C14 8.2 13.8 8 13.5 8L11.5 8C11.2 8 11 8.2 11 8.5C11 8.8 11.2 9 11.5 9ZM19.5 19L18 19L18 0.5C18 0.5 18 0.5 18 0.5C18 0.2 17.8 0 17.5 0L2.5 0C2.5 0 2.5 0 2.5 0C2.2 0 2 0.2 2 0.5L2 19L0.5 19C0.2 19 0 19.2 0 19.5C0 19.8 0.2 20 0.5 20L2.5 20L12.5 20L12.5 20L17.5 20C17.5 20 17.5 20 17.5 20L19.5 20C19.8 20 20 19.8 20 19.5C20 19.2 19.8 19 19.5 19ZM12 19L8 19L8 13L12 13L12 19ZM17 19L13 19L13 12.5C13 12.5 13 12.5 13 12.5C13 12.2 12.8 12 12.5 12L7.5 12C7.5 12 7.5 12 7.5 12C7.2 12 7 12.2 7 12.5L7 19L3 19L3 1L17 1L17 19ZM11.5 5L13.5 5C13.8 5 14 4.8 14 4.5C14 4.2 13.8 4 13.5 4L11.5 4C11.2 4 11 4.2 11 4.5C11 4.8 11.2 5 11.5 5ZM6.5 9L8.5 9C8.8 9 9 8.8 9 8.5C9 8.2 8.8 8 8.5 8L6.5 8C6.2 8 6 8.2 6 8.5C6 8.8 6.2 9 6.5 9ZM6.5 5L8.5 5C8.8 5 9 4.8 9 4.5C9 4.2 8.8 4 8.5 4L6.5 4C6.2 4 6 4.2 6 4.5C6 4.8 6.2 5 6.5 5Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconOrganization',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
