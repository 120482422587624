<template>
	<div
		ref="resizer"
		class="resizer"
		:class="{
			[`resizer--${modifier}`]: modifier
		}"
	>
		<div ref="leftContainer" class="resizer__left" >
			<slot name="leftContainer" />
		</div>

		<div ref="leftBar" class="resizer__bar bar__left" />

		<div ref="middleContainer" class="resizer__middle" >
			<slot name="middleContainer" />
		</div>

		<div ref="rightBar" class="resizer__bar bar__right" />

		<div ref="rightContainer" class="resizer__right">
			<slot name="rightContainer"/>
		</div>
		<div class="forceRefresh" :title="forceRefreshTitle">
			<button @click.prevent="handleForceRefresh">
				<Icon name="refresh" color="CurrentColor" />
			</button>
		</div>
	</div><!-- /.resizer -->
</template>

<script>

/**
 * Internal Dependencies
 */
import { debounce } from '@/utils/use-debounce'
import { clearDropdowns } from '@/utils/use-dropdowns';

import Icon from '@/components/icon/icon';

export default {
	/**
	 * Name
	 */
	name: "Resizer",

	/**
	 * Components
	 */
	components: {
		Icon,
	},

	/**
	 * Props
	 */
	props: {
		modifier: {
			type: String,
			default: ''
		},
		leftMinWidth: {
			type: Number,
			default: 0
		},
		middleMinWidth: {
			type: Number,
			default: 0
		},
		rightMinWidth: {
			type: Number,
			default: 0
		},
		leftMaxWidth: {
			type: Number,
			default: 0
		},
		middleMaxWidth: {
			type: Number,
			default: 0
		},
		rightMaxWidth: {
			type: Number,
			default: 0
		},
		leftMinWidthPx: {
			type: Number,
			default: 0
		},
		middleMinWidthPx: {
			type: Number,
			default: 0
		},
		rightMinWidthPx: {
			type: Number,
			default: 0
		},
		leftMaxWidthPx: {
			type: Number,
			default: 0
		},
		middleMaxWidthPx: {
			type: Number,
			default: 0
		},
		rightMaxWidthPx: {
			type: Number,
			default: 0
		},
		leftStartingWidth: {
			type: Number,
			default: 0
		},
		middleStartingWidth: {
			type: Number,
			default: 0
		},
		rightStartingWidth: {
			type: Number,
			default: 0
		},
		forceRefreshTitle: {
			type: String,
			default: "Click here to force refresh resizer"
		},
	},

	/**
	 * Data
	 */
	data() {
		return {
			observer: null,
			observerWidth: null,
			barSizeDelta: 6,
			dx: {
				left: 0,
				middle: 0,
				right: 0
			},
			xPos: 0
		}
	},

	/**
	 * Methods
	 */
	methods: {
		initialize() {
			const rightBar = this.$refs.rightBar;
			const leftBar = this.$refs.leftBar;
			const leftSide = this.$refs.leftContainer;
			const middleSide = this.$refs.middleContainer;
			const rightSide = this.$refs.rightContainer;

			leftSide.style.width = `calc(${this.leftStartingWidth}% - ${this.barSizeDelta}px)`;
			middleSide.style.width = `calc(${this.middleStartingWidth}% - ${this.barSizeDelta}px)`;
			rightSide.style.width = `calc(${this.rightStartingWidth}% - ${this.barSizeDelta}px)`;

			if (this.leftMinWidthPx) leftSide.style.minWidth = `${this.leftMinWidthPx}px`;
			if (this.middleMinWidthPx) middleSide.style.minWidth = `${this.middleMinWidthPx}px`;
			if (this.rightMinWidthPx) rightSide.style.minWidth = `${this.rightMinWidthPx}px`;

			if (this.leftMaxWidthPx) leftSide.style.maxWidth = `${this.leftMaxWidthPx}px`;
			if (this.middleMaxWidthPx) middleSide.style.maxWidth = `${this.middleMaxWidthPx}px`;
			if (this.rightMaxWidthPx) rightSide.style.maxWidth = `${this.rightMaxWidthPx}px`;

			//Emit drag event with small debounce
			const debouncedEmitLeftBarDragDropStartFunction = () => {
				this.$emit('leftBarDragDropStart');
			};
			//Emit drag event with small debounce
			const debouncedEmitRightBarDragDropStartFunction = () => {
				this.$emit('rightBarDragDropStart');
			};

			//Emit drag event with small debounce
			const debouncedEmitLeftBarDragDropMoveFunction = debounce(() => {
				this.$emit('leftBarDragDropMove');
			}, 400);
			//Emit drag event with small debounce
			const debouncedEmitRightBarDragDropMoveFunction = debounce(() => {
				this.$emit('rightBarDragDropMove');
			}, 400);

			//Emit drop event with small debounce
			const debouncedEmitLeftBarDragDropEndFunction = () => {
				this.$emit('leftBarDragDropEnd');
			};
			//Emit drop event with small debounce
			const debouncedEmitRightBarDragDropEndFunction = () => {
				this.$emit('rightBarDragDropEnd');
			};

			window.onresize = () => {
				this.updateSideSize();
				this.$emit('windowResized');
			};

			const rightBarMouseDownHandler = (e) => {
				document.body.classList.add('text-selection-disabled');
				// Get the current mouse position
				this.xPos = e.clientX;

				// Attach the listeners to `document`
				document.addEventListener('mousemove', rightBarMouseMoveHandler);
				document.addEventListener('mouseup', rightBarMouseUpHandler);

				// Clear any dropdowns that might be open
				clearDropdowns();

				debouncedEmitRightBarDragDropStartFunction();
			};

			const rightBarMouseMoveHandler = (e) => {
				document.body.style.cursor = 'col-resize';

				// How far the mouse has been moved
				const dx = e.clientX - this.xPos;

				if (dx === 0) return;

				this.manageRightBarMouseMoveHandler(dx);

				this.xPos = e.clientX;

				debouncedEmitRightBarDragDropMoveFunction();
			};

			const rightBarMouseUpHandler = () => {
				document.body.style.removeProperty('cursor');
				document.body.classList.remove('text-selection-disabled');

				leftSide.style.removeProperty('user-select');
				leftSide.style.removeProperty('pointer-events');

				middleSide.style.removeProperty('user-select');
				middleSide.style.removeProperty('pointer-events');

				rightSide.style.removeProperty('user-select');
				rightSide.style.removeProperty('pointer-events');

				// Remove the handlers of `mousemove` and `mouseup`
				document.removeEventListener('mousemove', rightBarMouseMoveHandler);
				document.removeEventListener('mouseup', rightBarMouseUpHandler);

				debouncedEmitRightBarDragDropEndFunction();
			};

			const leftBarMouseDownHandler = (e) => {
				document.body.classList.add('text-selection-disabled');
				// Get the current mouse position
				this.xPos = e.clientX;

				// Attach the listeners to `document`
				document.addEventListener('mousemove', leftBarMouseMoveHandler);
				document.addEventListener('mouseup', leftBarMouseUpHandler);

				// Clear any dropdowns that might be open
				clearDropdowns();

				debouncedEmitLeftBarDragDropStartFunction();
			};

			const leftBarMouseMoveHandler = (e) => {
				document.body.style.cursor = 'col-resize';

				// How far the mouse has been moved
				const dx = e.clientX - this.xPos;

				if (dx === 0) return;

				this.manageLeftBarMouseMoveHandler(dx);

				this.xPos = e.clientX;

				debouncedEmitLeftBarDragDropMoveFunction();
			};

			const leftBarMouseUpHandler = () => {
				document.body.style.removeProperty('cursor');
				document.body.classList.remove('text-selection-disabled');

				leftSide.style.removeProperty('user-select');
				leftSide.style.removeProperty('pointer-events');

				middleSide.style.removeProperty('user-select');
				middleSide.style.removeProperty('pointer-events');

				rightSide.style.removeProperty('user-select');
				rightSide.style.removeProperty('pointer-events');

				// Remove the handlers of `mousemove` and `mouseup`
				document.removeEventListener('mousemove', leftBarMouseMoveHandler);
				document.removeEventListener('mouseup', leftBarMouseUpHandler);

				debouncedEmitLeftBarDragDropEndFunction();
			};

			rightBar.addEventListener('mousedown', rightBarMouseDownHandler);
			leftBar.addEventListener('mousedown', leftBarMouseDownHandler);

			this.updateSideSize();
		},
		initResizerObserver() {
			const vm = this;
			const resizer = this.$refs.resizer;
			const leftSide = this.$refs.leftContainer;
			const middleSide = this.$refs.middleContainer;
			const rightSide = this.$refs.rightContainer;

			vm.observer = new ResizeObserver(() => {
				this.observerWidth = resizer.getBoundingClientRect().width
			}).observe(resizer);

			const debouncedResizeLeftSideFunction = debounce(() => {
				this.$emit('leftSideWidthChanged');
			}, 400);
			const debouncedResizeMiddleSideFunction = debounce(() => {
				this.$emit('middleSideWidthChanged');
			}, 400);
			const debouncedResizeRightSideFunction = debounce(() => {
				this.$emit('rightSideWidthChanged');
			}, 400);

			new ResizeObserver(debouncedResizeLeftSideFunction).observe(leftSide);
			new ResizeObserver(debouncedResizeMiddleSideFunction).observe(middleSide);
			new ResizeObserver(debouncedResizeRightSideFunction).observe(rightSide);
		},
		getSidesInfo() {
			const startingWidth = {
				left: Math.round(this.observerWidth * this.leftStartingWidth / 100),
				middle: Math.round(this.observerWidth * this.middleStartingWidth / 100),
				right: Math.round(this.observerWidth * this.rightStartingWidth / 100)
			};

			let width = {
				min: {
					left: Math.round(this.observerWidth * this.leftMinWidth / 100),
					middle: Math.round(this.observerWidth * this.middleMinWidth / 100),
					right: Math.round(this.observerWidth * this.rightMinWidth / 100)
				},
				max: {
					left: Math.round(this.observerWidth * this.leftMaxWidth / 100),
					middle: Math.round(this.observerWidth * this.middleMaxWidth / 100),
					right: Math.round(this.observerWidth * this.rightMaxWidth / 100)
				}
			};

			if (this.leftMinWidthPx) width.min.left = this.leftMinWidthPx;
			if (this.middleMinWidthPx) width.min.middle = this.middleMinWidthPx;
			if (this.rightMinWidthPx) width.min.right = this.rightMinWidthPx;

			if (this.leftMaxWidthPx) width.max.left = this.leftMaxWidthPx;
			if (this.middleMaxWidthPx) width.max.middle = this.middleMaxWidthPx;
			if (this.rightMaxWidthPx) width.max.right = this.rightMaxWidthPx;

			if (width.max.left < width.min.left) width.max.left = width.min.left;
			if (width.max.middle < width.min.middle) width.max.middle = width.min.middle;
			if (width.max.right < width.min.right) width.max.right = width.min.right;

			if (width.min.left > width.min.left) width.min.left = width.max.left;
			if (width.min.middle > width.min.middle) width.min.middle = width.max.middle;
			if (width.min.right > width.min.right) width.min.right = width.max.right;

			let delta = {
				min: {
					left: width.min.left - startingWidth.left,
					middle: width.min.middle - startingWidth.middle,
					right: width.min.right - startingWidth.right
				},
				max: {
					left: width.max.left - startingWidth.left,
					middle: width.max.middle - startingWidth.middle,
					right: width.max.right - startingWidth.right
				}
			};
			return { startingWidth, width, delta };
		},
		getSidesSize() {
			const { startingWidth, width } = this.getSidesInfo();

			const currentWith = {
				left: startingWidth.left + this.dx.left,
				middle: startingWidth.middle + this.dx.middle,
				right: startingWidth.right + this.dx.right
			};

			if (currentWith.left < width.min.left) currentWith.left = width.min.left;
			if (currentWith.left > width.max.left) currentWith.left = width.max.left;
			if (currentWith.middle < width.min.middle) currentWith.middle = width.min.middle;
			if (currentWith.middle > width.max.middle) currentWith.middle = width.max.middle;
			if (currentWith.right < width.min.right) currentWith.right = width.min.right;
			if (currentWith.right > width.max.right) currentWith.right = width.max.right;

			let available = Math.ceil(this.observerWidth) - (currentWith.left + currentWith.middle + currentWith.right + (this.barSizeDelta * 3));

			return { currentWith, available };
		},
		manageRightBarMouseMoveHandler(dx) {
			let { delta } = this.getSidesInfo();

			let nextDelta = {
				middle: this.dx.middle + dx,
				right: this.dx.right - dx
			};

			if (nextDelta.middle >= delta.max.middle) {
				let dxDiff = Math.abs(delta.max.middle - this.dx.middle);
				this.dx.middle = delta.max.middle;
				this.dx.right -= dxDiff;
			} else if (nextDelta.middle <= delta.min.middle) {
				let dxDiff = Math.abs(delta.min.middle - this.dx.middle);
				this.dx.middle = delta.min.middle;
				this.dx.right += dxDiff;
				if (this.dx.right > delta.max.right) this.dx.right = delta.max.right;
			} else {
				this.dx.middle += dx;
				this.dx.right -= dx;
			}

			this.updateSideSize();
		},
		manageLeftBarMouseMoveHandler(dx) {
			let { delta } = this.getSidesInfo();

			let nextDelta = {
				left: this.dx.left + dx,
				middle: this.dx.middle - dx
			};

			if (nextDelta.left >= delta.max.left) {
				let dxDiff = Math.abs(delta.max.left - this.dx.left);
				this.dx.left = delta.max.left;
				this.dx.middle -= dxDiff;
			}
			else if (nextDelta.left <= delta.min.left) {
				let dxDiff = Math.abs(delta.min.left - this.dx.left);
				this.dx.left = delta.min.left;
				this.dx.middle += dxDiff;
			} else {
				this.dx.left += dx;
				this.dx.middle -= dx;
			}

			this.updateSideSize();
		},
		updateSideSize() {
			const leftSide = this.$refs.leftContainer;
			const middleSide = this.$refs.middleContainer;
			const rightSide = this.$refs.rightContainer;
			let { currentWith, available } = this.getSidesSize();

			leftSide.style.width = `calc(${currentWith.left}px - ${this.barSizeDelta}px)`;
			middleSide.style.width = `calc(${currentWith.middle}px - ${this.barSizeDelta}px)`;
			rightSide.style.width = `calc(${currentWith.right + available}px - ${this.barSizeDelta}px)`;
		},
		handleForceRefresh() {
			this.updateSideSize();
			this.$emit('forceRefreshClick');
		}
	},

	/**
	 * Mounted
	 */
	mounted () {
		this.initialize();
		this.initResizerObserver();
		this.$nextTick(function () {
			// Code that will run only after the
			// entire view has been rendered
			setTimeout(() => {
				this.updateSideSize();
			}, 2500);
		})
	},
	
	/**
	 * Before Destroy
	 */
	beforeDestroy () {
		if (this.parentObserver) this.parentObserver.disconnect();
	},
}
</script>
