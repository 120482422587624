<template>
	<svg
		width="16px"
		height="20px"
		viewBox="0 0 16 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-1289.000000, -215.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(1264.282951, 43.000000)">
					<g transform="translate(24.717049, 172.000000)">
						<path
							d="M3.5,15.5 C3.223877,15.5 3,15.723877 3,16 C3,16.276123 3.223877,16.5 3.5,16.5 C3.776123,16.5 4,16.276123 4,16 C4,15.723877 3.776123,15.5 3.5,15.5 Z M3.5,9.5 C3.223877,9.5 3,9.723877 3,10 C3,10.276123 3.223877,10.5 3.5,10.5 C3.776123,10.5 4,10.276123 4,10 C4,9.723877 3.776123,9.5 3.5,9.5 Z M15.9599609,3.5903931 C15.5619507,1.5466309 12.2093506,0 8,0 C3.7906494,0 0.4380493,1.5466309 0.0400391,3.5903931 C0.0144043,3.6505127 0,3.7166138 0,3.7861328 L0,9.7861328 L0,10.2617187 L0,16.2617187 C0,18.3583984 3.5136719,20 8,20 C12.4863281,20 16,18.3583984 16,16.2617188 L16,10.2617188 L16,9.7861329 L16,3.7861329 C16,3.7166138 15.9855957,3.6505127 15.9599609,3.5903931 Z M15,16.2617188 C15,17.5566406 12.125,19 8,19 C3.875,19 1,17.5566406 1,16.2617188 L1,12.0952148 C2.352417,13.2400513 4.949707,14 8,14 C11.050293,14 13.647583,13.2400513 15,12.0952148 L15,16.2617188 Z M15,9.7861328 L15,10.2617187 C15,11.5566406 12.125,13 8,13 C3.875,13 1,11.5566406 1,10.2617188 L1,9.7861329 L1,5.961731 C2.352417,7.1867065 4.949707,8 8,8 C11.050293,8 13.647583,7.1867065 15,5.961731 L15,9.7861328 Z M8,7 C3.875,7 1,5.4189453 1,4 C1,2.5810547 3.875,1 8,1 C12.125,1 15,2.5809937 15,4 C15,5.4190063 12.125,7 8,7 Z"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconDatabase',

	/**
	 * props
	 */
	props: ['color'],
};
</script>
