<template>
	<nav class="nav-account" :class="{ 'is-logged-in': isLoggedIn }">
		<ul v-if="isLoggedIn">
			<li v-if="userRoleWeight >= 100">
				<DropDown>
					<template #header>
						<div class="dropdown__account">
							Administration

							<span class="dropdown__arrow"></span>
						</div> <!-- /.dropdown__account -->
					</template>

					<div class="dropdown__nav">
						<ul>
							<li>
								<router-link tabindex="0" to="/accounts">
									<Icon name="user" color="CurrentColor" />

									Accounts
								</router-link>
							</li>

							<li v-if="userRoleWeight >= 1000">
								<router-link tabindex="0" to="/curator-roles">
									<Icon name="key" color="CurrentColor" />

									Roles
								</router-link>
							</li>

							<li>
								<router-link tabindex="0" to="/organizations">
									<Icon name="organization" color="CurrentColor" />

									Organizations
								</router-link>
							</li>
						</ul>

						<ul>
							<li>
								<ButtonLink tabindex="0" href="https://dataseer.ai/reporecommender-form/" target="_blank">
									<Icon name="database" color="CurrentColor" />

									Repo-Recommender
								</ButtonLink>
							</li>

							<li>
								<ButtonLink tabindex="0" href="https://wiki.dataseer.ai/doku.php?id=data_type" target="_blank">
									<Icon name="list_alt" color="CurrentColor" />

									Go to Wiki
								</ButtonLink>
							</li>

							<li>
								<button @click.prevent="handleRefreshDataTypes" :disabled="postResyncJsonDataTypes.loading" :class="postResyncJsonDataTypes.loading ? 'is-disabled' : ''">
									<Icon name="refresh" color="CurrentColor" />

									Refresh Datatypes
								</button>

								<Loader
									size="medium"
									:loading="postResyncJsonDataTypes.loading"
									:error="postResyncJsonDataTypes.error"
									:errorMessage="postResyncJsonDataTypes.errorMessage"
								></Loader>
							</li>

							<li v-if="userRoleWeight >= 1000">
								<button @click.prevent="handleRefreshASAPAuthors" :disabled="postRefreshASAPAuthors.loading" :class="postRefreshASAPAuthors.loading ? 'is-disabled' : ''">
									<Icon name="refresh" color="CurrentColor" />

									Refresh ASAP Authors
								</button>

								<Loader
									size="medium"
									:loading="postRefreshASAPAuthors.loading"
									:error="postRefreshASAPAuthors.error"
									:errorMessage="postRefreshASAPAuthors.errorMessage"
								></Loader>
							</li>
						</ul>

						<ul>
							<li v-if="userRoleWeight >= 1000">
								<button @click.prevent="handleRefreshCustomIdentifiers" :disabled="postRefreshCustomIdentifiers.loading" :class="postRefreshCustomIdentifiers.loading ? 'is-disabled' : ''">
									<Icon name="refresh" color="CurrentColor" />

									Refresh ASAP IDs
								</button>

								<a :href="customIdentifiersListSourceURL" title="Source of Custom List" target="_blank">(Source)</a>

								<Loader
									size="medium"
									:loading="postRefreshCustomIdentifiers.loading"
									:error="postRefreshCustomIdentifiers.error"
									:errorMessage="postRefreshCustomIdentifiers.errorMessage"
								></Loader>
							</li>

							<li v-if="userRoleWeight >= 1000">
								<button @click.prevent="handleRefreshCustomSoftwareList" :disabled="postRefreshCustomSoftwareList.loading" :class="postRefreshCustomSoftwareList.loading ? 'is-disabled' : ''">
									<Icon name="refresh" color="CurrentColor" />

									Refresh Software
								</button>

								<a :href="customSoftwareListSourceURL" title="Source of Custom List" target="_blank">(Source)</a>

								<Loader
									size="medium"
									:loading="postRefreshCustomSoftwareList.loading"
									:error="postRefreshCustomSoftwareList.error"
									:errorMessage="postRefreshCustomSoftwareList.errorMessage"
								></Loader>
							</li>

							<li v-if="userRoleWeight >= 1000">
								<button @click.prevent="handleRefreshCustomReagentsList" :disabled="postRefreshCustomReagentsList.loading" :class="postRefreshCustomReagentsList.loading ? 'is-disabled' : ''">
									<Icon name="refresh" color="CurrentColor" />

									Refresh Reagents
								</button>

								<a :href="customReagentsListSourceURL" title="Source of Custom List" target="_blank">(Source)</a>

								<Loader
									size="medium"
									:loading="postRefreshCustomReagentsList.loading"
									:error="postRefreshCustomReagentsList.error"
									:errorMessage="postRefreshCustomReagentsList.errorMessage"
								></Loader>
							</li>
						</ul>

						<ul>
							<li v-if="userRoleWeight >= 1000">
								<button @click.prevent="handlePostCreateASAPChangesFromDSLogs" :disabled="postCreateASAPChangesFromDSLogs.loading" :class="postCreateASAPChangesFromDSLogs.loading ? 'is-disabled' : ''">
									<Icon name="refresh" color="CurrentColor" />

									Changes (LOGS)
								</button>

								<a :href="postCreateASAPChangesFromDSLogsURL" title="Source of ASAP Changes" target="_blank">(Config)</a>

								<Loader
									size="medium"
									:loading="postCreateASAPChangesFromDSLogs.loading"
									:error="postCreateASAPChangesFromDSLogs.error"
									:errorMessage="postCreateASAPChangesFromDSLogs.errorMessage"
								></Loader>
							</li>

							<li v-if="userRoleWeight >= 1000">
								<button @click.prevent="handlePostCreateASAPChangesFromASAPReports" :disabled="postCreateASAPChangesFromASAPReports.loading" :class="postCreateASAPChangesFromASAPReports.loading ? 'is-disabled' : ''">
									<Icon name="refresh" color="CurrentColor" />

									Changes (REPORTS)
								</button>

								<a :href="postCreateASAPChangesFromASAPReportsURL" title="Source of ASAP Changes" target="_blank">(Config)</a>

								<Loader
									size="medium"
									:loading="postCreateASAPChangesFromASAPReports.loading"
									:error="postCreateASAPChangesFromASAPReports.error"
									:errorMessage="postCreateASAPChangesFromASAPReports.errorMessage"
								></Loader>
							</li>

						</ul>
					</div>
					<!-- /.dropdown__nav -->
				</DropDown>
			</li>

			<li>
				<router-link tabindex="0" to="/documents">documents</router-link>
			</li>

			<li v-if="userRoleWeight >= 100">
				<router-link tabindex="0" to="/new-document">Upload</router-link>
			</li>

			<li>
				<DropDown>
					<template #header>
						<div class="dropdown__account">
							{{username}}

							<div class="dropdown__button">
								{{username.charAt(0)}}

								<span class="dropdown__arrow" />
							</div> <!-- /.dropdown__button -->
						</div> <!-- /.dropdown__account -->
					</template>

					<div class="dropdown__nav">
						<ul>
							<li>
								<router-link tabindex="0" to="/profile">
									<Icon name="user" color="CurrentColor" />

									User Profile
								</router-link>
							</li>

							<li>
								<a tabindex="0" @click.prevent="handleLogout">
									<Icon name="signout" color="CurrentColor" />

									Sign Out
								</a>
							</li>
						</ul>
					</div><!-- /.dropdown__nav -->
				</DropDown>
			</li>
		</ul>

		<ul v-else>
			<li>
				<Button tabindex="0" className="tertiary" to="/sign-in">
					Sign In
				</Button>
			</li>

			<li>
				<Button tabindex="0" className="secondary" to="/sign-up">
					Sign Up
				</Button>
			</li>
		</ul>
	</nav><!-- /.nav -->
</template>

<script>
/**
 * External Dependencies
 */
import { mapActions, mapGetters } from 'vuex';

/**
 * Internal Dependencies
 */
import DropDown from '@/components/dropdown/dropdown';
import Icon from '@/components/icon/icon';
import Button from '@/components/button/button';
import ButtonLink from '@/components/button-link/button-link';
import Loader from '@/blocks/loader/loader';

import API from '@/lib/datasets/api';
import URLMANAGER from '@/lib/datasets/url';

export default {
	/**
	 * Name
	 */
	name: 'navAccount',
	
	/**
	 * Components
	 */
	components: {
		Icon,
		Button,
		ButtonLink,
		DropDown,
		Loader
	},
	
	/**
	 * Data
	 */
	data: function() {
		return {
			postResyncJsonDataTypes: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			postRefreshASAPAuthors: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			postRefreshCustomIdentifiers: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			postRefreshCustomSoftwareList: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			postRefreshCustomReagentsList: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			postCreateASAPChangesFromDSLogs: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			postCreateASAPChangesFromASAPReports: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			}
		};
	},
	
	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', ['username', 'userRoleWeight', ]),
		isLoggedIn() {
			return this.$store.state.account.status?.loggedIn
		},
		customIdentifiersListSourceURL() {
			return URLMANAGER.buildURL(`${API.identifiers.url}/customList/source`, {}, { origin: true });
		},
		customSoftwareListSourceURL() {
			return URLMANAGER.buildURL(`${API.software.url}/customList/source`, {}, { origin: true });
		},
		customReagentsListSourceURL() {
			return URLMANAGER.buildURL(`${API.reagents.url}/customList/source`, {}, { origin: true });
		},
		postCreateASAPChangesFromDSLogsURL() {
			return URLMANAGER.buildURL(`${API.documents.url}/dataObjects/changes/gSpreadsheets/ASAP/DS_LOGS/source`, {}, { origin: true });
		},
		postCreateASAPChangesFromASAPReportsURL() {
			return URLMANAGER.buildURL(`${API.documents.url}/dataObjects/changes/gSpreadsheets/ASAP/ASAP_REPORTS/source`, {}, { origin: true });
		}
	},
	
	/**
	 * Methods
	 */
	methods: {
		...mapActions('account', ['logout']),
		handleLogout(e) {
			e.preventDefault();
			this.logout();
		},
		async handleRefreshDataTypes() {
			console.log('resyncJsonDataTypes')
			this.postResyncJsonDataTypes.loading = true;
			return API.dataseerML.resyncJsonDataTypes((err, res) => {
				console.log(err, res);
				this.postResyncJsonDataTypes.loading = false;
			});
		},
		async handleRefreshASAPAuthors() {
			this.postRefreshASAPAuthors.loading = true;
			return API.ORCID.refreshASAPAuthors((err, res) => {
				console.log(err, res);
				this.postRefreshASAPAuthors.loading = false;
			});
		},
		async handleRefreshCustomIdentifiers() {
			this.postRefreshCustomIdentifiers.loading = true;
			return API.identifiers.refreshCustomList((err, res) => {
				console.log(err, res);
				this.postRefreshCustomIdentifiers.loading = false;
			});
		},
		async handleRefreshCustomSoftwareList() {
			this.postRefreshCustomSoftwareList.loading = true;
			return API.software.refreshCustomList((err, res) => {
				console.log(err, res);
				this.postRefreshCustomSoftwareList.loading = false;
			});
		},
		async handleRefreshCustomReagentsList() {
			this.postRefreshCustomReagentsList.loading = true;
			return API.reagents.refreshCustomList((err, res) => {
				console.log(err, res);
				this.postRefreshCustomReagentsList.loading = false;
			});
		},
		async handlePostCreateASAPChangesFromDSLogs() {
			this.postCreateASAPChangesFromDSLogs.loading = true;
			return API.documents.getDataObjectsChangesSpreadsheets({
				kind: "ASAP",
				config: "DS_LOGS"
			}, (err, query) => {
				console.log(err, query);
				this.postCreateASAPChangesFromDSLogs.loading = false;
				if (err) return alert(err.toString());
				if (query.err)
					return alert(query.res ? query.res.toString() : "An error has occured. Please try again or contact an administrator.");
				return window.open(`https://docs.google.com/spreadsheets/d/${query.res}`, '_blank')?.focus();
			});
		},
		async handlePostCreateASAPChangesFromASAPReports() {
			this.postCreateASAPChangesFromASAPReports.loading = true;
			return API.documents.getDataObjectsChangesSpreadsheets({
				kind: "ASAP",
				config: "ASAP_REPORTS"
			}, (err, query) => {
				console.log(err, query);
				this.postCreateASAPChangesFromASAPReports.loading = false;
				if (err) return alert(err.toString());
				if (query.err)
					return alert(query.res ? query.res.toString() : "An error has occured. Please try again or contact an administrator.");
				return window.open(`https://docs.google.com/spreadsheets/d/${query.res}`, '_blank')?.focus();
			});
		}
	}
};
</script>
