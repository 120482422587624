<template>
	<div class="form__message">
		<slot />
	</div>
</template>

<script>
export default {
	/**
	 * Name
	 */
	name: 'FormMessage'
};
</script>
