<template>
	<div v-if="visible"
		class="form__status"
		:class="{
			'form__status--error': isError,
			'form__status--success': !isError
		}"
	>
		<div class="form__status-inner">
			<p v-if="url"><a :href="url" target="_blank">{{ text }}</a></p>
			<p v-if="!url">{{ text }}</p>
			<span
				class="form__status-close"
				@click="close()"
			/>
		</div> <!-- /.form__status__inner -->
	</div> <!-- /.form__status -->
</template>

<script>
export default {
	/**
	 * Name
	 */
	name: 'FormStatus',

	/**
	 * Props
	 */
	props: {
		text: {
			type: String,
			default: ''
		},
		url: {
			type: String,
			default: ''
		},
		isError: {
			type: Boolean,
			default: false
		}
	},

	/**
	 * data
	 */
	data: function() {
		return {
			visible: true
		}
	},

	/**
	 * Methods
	 */
	methods: {
		close() {
			this.visible = false;
		}
	}
};
</script>
