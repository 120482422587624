<template>
	<div class="datasets-utils">
		<ul
			:class="{
				'is-merging': mergeState,
				'is-deleteing': deleteState
			}"
		>
			<li>
				<div
					class="datasets__button datasets__button--delete"
					:class="{
						'is-opened': deleteState
					}"
					tabindex="0"
				>
					<button
						class="datasets__button-inner"
						:aria-label="tooltips.deleteText(this.activeDataObjectTypeTitleCase)"
						v-tooltip.right="tooltips.deleteText(this.activeDataObjectTypeTitleCase)"
						@click.prevent="() => setDeleteState(true)"
					>
						<span>
							<Icon name="trash" />
						</span>
						
						<p class="datasets__button-extended">Delete</p>
					</button><!-- /.datasets__button-inner -->
					
					<div class="datasets__button-overlay">
						<div class="datasets__button-overlay-inner">
							<p v-if="actionDataObjectsLength < 2">Select Items to Delete</p>

							<Button
								v-if="actionDataObjectsLength >= 2"
								@onClick="() => deleteDataObjects()"
							>
								{{ deleteButtonText }}
							</Button>
							
							<Button
								className="tertiary"
								size="small"
								@onClick="() => setDeleteState(false)"
							>
								Cancel
							</Button>
						</div><!-- /.datasets__button-overlay-inner -->
					</div><!-- /.datasets__button-wrapper -->
				</div>
			</li>

			<li>
				<div
					class="datasets__button datasets__button--merge"
					:class="{
						'is-opened': mergeState
					}"
					tabindex="0"
				>
					<button
						class="datasets__button-inner"
						:aria-label="tooltips.mergeText(this.activeDataObjectTypeTitleCase)"
						v-tooltip.right="tooltips.mergeText(this.activeDataObjectTypeTitleCase)"
						@click.prevent="() => setMergeState(true)"
					>
						<span>
							<Icon name="merge" />
						</span>
						
						<p class="datasets__button-extended">Merge</p>
					</button><!-- /.datasets__button-inner -->
					
					<div class="datasets__button-overlay">
						<div class="datasets__button-overlay-inner">
							<p v-if="actionDataObjectsLength < 2">Select Items to Merge</p>

							<Button
								v-if="actionDataObjectsLength >= 2"
								@onClick="() => mergeDataObjects()"
							>
								{{ mergeButtonText }}
							</Button>
							
							<Button
								className="tertiary"
								size="small"
								@onClick="() => setMergeState(false)"
							>
								Cancel
							</Button>
						</div><!-- /.datasets__button-overlay-inner -->
					</div><!-- /.datasets__button-wrapper -->
				</div>
			</li>

			<li>
				<div
					class="datasets__button datasets__button--add"
					tabindex="0"
					v-tooltip.right="tooltips.addText(this.activeDataObjectTypeTitleCase)"
					:aria-label="tooltips.addText(this.activeDataObjectTypeTitleCase)"
					@click.prevent="addSentenceToDataObject"
				>
					<button
						class="datasets__button-inner"
					>
						<span>
							<Icon name="add" />
						</span>

						<p class="datasets__button-extended">Add</p>
					</button><!-- /.datasets__button-inner -->
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Internal Dependencies
 */
import Button from '@/components/button/button';
import Icon from '@/components/icon/icon';

export default {
	/**
	 * Name
	 */
	name: 'DatasetsUtils',

	/**
	 * components
	 */
	components: {
		Icon,
		Button
	},

	/**
	 * Data
	 */
	data: function() {
		return {
			tooltips: {
				mergeText: function(txt) {
					return 'Merge two or more list items into a single ' + txt;
				},
				deleteText: function() {
					return 'Delete two or more items';
				},
				addText: function(txt) {
					return 'Add New ' + txt;
				}
			}
		};
	},
	
	/**
	 * Computed
	 */
	computed: {
		...mapGetters('pdfViewer', ['mergeState', 'dataObjectsForAction', 'deleteState', 'activeDataObjectType']),
		activeDataObjectTypeTitleCase() {
			return this.activeDataObjectType[0].toUpperCase() + this.activeDataObjectType.slice(1);
		},
		actionDataObjectsLength(){
			return this.dataObjectsForAction.length
		},
		mergeButtonText() {
			return `Merge ${this.actionDataObjectsLength} Items`
		},
		deleteButtonText() {
			return `Delete ${this.actionDataObjectsLength} Items`
		}
	},

	/**
	 * Methods
	 */
	methods: {
		...mapActions('pdfViewer', [
			'setMergeState',
			'mergeDataObjects',
			'setDeleteState',
			'deleteDataObjects',
			'addSentenceToDataObject'
		]),
	},
};
</script>
