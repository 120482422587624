<template>
	<svg
		width="20px"
		height="20px"
		viewBox="0 0 20 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g
				transform="translate(-180.000000, -209.000000)"
				:fill="color"
				fill-rule="nonzero"
			>
				<g transform="translate(176.000000, 209.000000)">
					<path
						d="M21,0 L11,0 C9.3438721,0.0018311 8.0018311,1.3438721 8,3 L8,4 L7,4 C5.3438721,4.0018311 4.0018311,5.3438721 4,7 L4,17 C4.0018311,18.6561279 5.3438721,19.9981689 7,20 L17,20 C18.6561279,19.9981689 19.9981689,18.6561279 20,17 L20,16 L21,16 C22.6561279,15.9981689 23.9981689,14.6561279 24,13 L24,3 C23.9981689,1.3438721 22.6561279,0.0018311 21,0 Z M19,17 C18.9985962,18.1040039 18.1040039,18.9985962 17,19 L7,19 C5.8959961,18.9985962 5.0014038,18.1040039 5,17 L5,9 L19,9 L19,17 Z M19,8 L5,8 L5,7 C5.0014038,5.8959961 5.8959961,5.0014038 7,5 L17,5 C18.1040039,5.0014038 18.9985962,5.8959961 19,7 L19,8 Z M23,13 C22.9985962,14.1040039 22.1040039,14.9985962 21,15 L20,15 L20,7 C19.9991455,6.2280884 19.6989136,5.5315552 19.2196655,5 L23,5 L23,13 Z M23,4 L9,4 L9,3 C9.0014038,1.8959961 9.8959961,1.0014038 11,1 L21,1 C22.1040039,1.0014038 22.9985962,1.8959961 23,3 L23,4 Z"
					></path>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconWindows',

	/**
	 * props
	 */
	props: ['color']
};
</script>
