<template>
	<ul v-if="!loading && filteredLogs.length > 0" class="logs">
		<li v-for="log, index in filteredLogs" :key="log._id" class="log">
			<div class="log-container">
				<div class="log-title">
					<label class="log-username">{{ log.account.username }}</label>
					<label class="log-date">v{{ log.version }} - {{ log.date ? new Date(log.date).toLocaleString() : "unknow" }}</label>
				</div>


				<label v-if="index === 0" class="log-has-diff">The dataObject have been created as follows</label>
				<label v-if="index > 0 && log.hasDiff" class="log-has-diff">The dataObject has been modified as follows</label>
				<label v-if="index > 0 && !log.hasDiff" class="log-has-diff">The dataObject has been saved without being modified</label>

				<ul v-if="index === 0 || (index > 0 && log.hasDiff)" class="log-properties">
					<li v-if="Object.keys(log.properties).length > 0" class="log-subtitle">Properties:</li>
					<li v-for="key in Object.keys(mapping[log.properties.kind])" :key="key" :title="typeof log.propertiesDiff[key] === 'undefined' ? '' : `The value '${log.propertiesDiff[key].__old}' has been ${ log.propertiesDiff[key].__new === '' ? 'cleared' : `replaced by the value '${log.propertiesDiff[key].__new}'`}`" class="log-property">
						<label class="log-property-key">{{ key }}</label>
						<label v-if="typeof log.propertiesDiff[key] === 'undefined'" class="log-property-value">{{ log.properties[key] }}</label>
						<label v-if="typeof log.propertiesDiff[key] !== 'undefined' && log.propertiesDiff[key].__new !== '' " class="log-property-new">{{ log.propertiesDiff[key].__new }}</label>
						<label v-if="typeof log.propertiesDiff[key] !== 'undefined' && log.propertiesDiff[key].__old !== '' && log.propertiesDiff[key].__new !== ''" class="log-property-value"> (</label>
						<label v-if="typeof log.propertiesDiff[key] !== 'undefined' && log.propertiesDiff[key].__old !== '' " class="log-property-old">{{ log.propertiesDiff[key].__old }}</label>
						<label v-if="typeof log.propertiesDiff[key] !== 'undefined' && log.propertiesDiff[key].__old !== '' && log.propertiesDiff[key].__new !== ''" class="log-property-value">)</label>
					</li>
					<li v-if="Object.keys(log.sentences).length > 0" class="log-subtitle">Sentences:</li>
					<li v-for="key in Object.keys(log.sentences)" :key="key" :title="typeof log.sentencesDiff[key] === 'undefined' ? '' : `This sentence (${key}) has been ${log.sentencesDiff[key].isDeleted ? 'unlinked' : 'linked'} to this object`" class="log-sentence">
						<label v-if="typeof log.sentencesDiff[key] === 'undefined'" class="log-sentence-value">{{ log.sentences[key] }}</label>
						<label v-if="typeof log.sentencesDiff[key] !== 'undefined'" class="log-sentence-key">{{ log.sentencesDiff[key].isAdded ? 'Link' : 'Unlink'}}</label>
						<label v-if="typeof log.sentencesDiff[key] !== 'undefined' && log.sentencesDiff[key].isDeleted" class="log-sentence-deleted">{{ log.sentencesDiff[key].value }}</label>
						<label v-if="typeof log.sentencesDiff[key] !== 'undefined' && log.sentencesDiff[key].isAdded" class="log-sentence-added">{{ log.sentencesDiff[key].value }}</label>
					</li>
				</ul>

				<div v-if="index === 0 || log.hasDiff" class="log-submit-container">
					<Button
						size="small"
						class="log-submit-button"
						@onClick.prevent="restoreDataObject(log.revert)"
					>
						Restore this version
					</Button>
				</div>
			</div> <!-- /.list -->
		</li>
	</ul>
</template>

<script>
/**
 * Internal Dependencies
 */
import jsonDiff from 'json-diff';

import Button from '@/components/button/button'

export default {
	/**
	 * Name
	 */
	name: 'DataObjectLog',

	/**
	 * Components
	 */
	components: {
		Button
	},

	/**
	 * Props
	 */
	props: {
		logs: {
			type: Array,
			default: () => []
		},

		loading: {
			type: Boolean,
			default: false
		}
	},

	/**
	 * Computed
	 */
	computed: {
		filteredLogs() {
			let results = [];
			for (let i = 0; i < this.logs.length; i++) {
				let newVersion = JSON.parse(JSON.stringify(this.logs[i].dataObject.new));
				let oldVersion = JSON.parse(JSON.stringify(this.logs[i].dataObject.old));
				let sentences = {};
				let properties = JSON.parse(JSON.stringify(oldVersion));
				// ---
				let newVersionSentences = newVersion.sentences.reduce((acc, item) => {
					sentences[item.id] = item.text;
					acc[item.id] = item.text;
					return acc;
				}, {});
				let oldVersionSentences = oldVersion.sentences.reduce((acc, item) => {
					sentences[item.id] = item.text;
					acc[item.id] = item.text;
					return acc;
				}, {});
				newVersion.issues = !!newVersion.issues;
				oldVersion.issues = !!oldVersion.issues;
				properties.issues = !!properties.issues;
				// ---
				delete newVersion.dataObjectType;
				delete newVersion.color;
				delete newVersion.updatedAt;
				delete newVersion.createdAt;
				delete newVersion.__v;
				delete newVersion.index;
				delete newVersion.flagged;
				delete newVersion.sentences;
				delete newVersion.rules;
				delete newVersion.rule;
				delete newVersion.status;
				delete newVersion.actionRequired;
				// ---
				delete oldVersion.dataObjectType;
				delete oldVersion.color;
				delete oldVersion.updatedAt;
				delete oldVersion.createdAt;
				delete oldVersion.__v;
				delete oldVersion.index;
				delete oldVersion.flagged;
				delete oldVersion.sentences;
				delete oldVersion.rules;
				delete oldVersion.rule;
				delete oldVersion.status;
				delete oldVersion.actionRequired;
				// ---
				delete properties.dataObjectType;
				delete properties.color;
				delete properties.updatedAt;
				delete properties.createdAt;
				delete properties.__v;
				delete properties.extracted;
				delete properties.deleted;
				delete properties.dataInstanceId;
				delete properties.cert;
				delete properties._id;
				delete properties.document;
				delete properties.index;
				delete properties.flagged;
				delete properties.sentences;
				delete properties.rules;
				delete properties.rule;
				delete properties.status;
				delete properties.actionRequired;
				// ---
				// let _propertiesDiff = jsonDiff.diff(newVersion, oldVersion);
				// let _sentencesDiff = jsonDiff.diff(newVersionSentences, oldVersionSentences);
				let _propertiesDiff = jsonDiff.diff(oldVersion, newVersion);
				let _sentencesDiff = jsonDiff.diff(oldVersionSentences, newVersionSentences);
				let hasDiff = !!_propertiesDiff || !!_sentencesDiff;
				let propertiesDiff = _propertiesDiff ? _propertiesDiff : {};
				let sentencesDiff = {};
				for (let key in _sentencesDiff) {
					let split = key.split("__");
					sentencesDiff[split[0]] = {
						value: sentences[split[0]],
						isDeleted: split[1] === "deleted",
						isAdded: split[1] === "added",
					};
				}
				results.push({
					revert: this.logs[i].dataObject.new,
					sentences,
					properties,
					propertiesDiff,
					sentencesDiff,
					hasDiff,
					account: this.logs[i].account,
					version: i + 1,
					date: this.logs[i].date,
				});
			}
			console.log( results)
			return results;
		}
	},

	/**
	 * Data
	 */
	data() {
		return {
			mapping : {
				dataset: {
					issues: true,
					kind: true,
					name: true,
					dataType: true,
					subType: true,
					reuse: true,
					representativeImage: true,
					qc: true,
					URL: true,
					PID: true,
					comments: true,
					associatedFigureOrTable: true,
					headSentence: true
				},
				code: {
					issues: true,
					kind: true,
					name: true,
					dataType: true,
					subType: true,
					reuse: true,
					URL: true,
					DOI: true,
					comments: true,
					suggestedEntity: true,
					suggestedURL: true,
					suggestedRRID: true,
					associatedReference: true,
					headSentence: true
				},
				software: {
					issues: true,
					kind: true,
					name: true,
					dataType: true,
					subType: true,
					reuse: true,
					version: true,
					URL: true,
					RRID: true,
					comments: true,
					suggestedEntity: true,
					suggestedURL: true,
					suggestedRRID: true,
					associatedReference: true,
					headSentence: true
				},
				reagent: {
					issues: true,
					kind: true,
					name: true,
					dataType: true,
					subType: true,
					reuse: true,
					source: true,
					catalogNumber: true,
					RRID: true,
					comments: true,
					suggestedEntity: true,
					suggestedURL: true,
					associatedReference: true,
					headSentence: true
				},
				protocol: {
					issues: true,
					kind: true,
					name: true,
					dataType: true,
					subType: true,
					reuse: true,
					DOI: true,
					URL: true,
					comments: true,
					headSentence: true
				},
			}
		}
	},

	/**
	 * Methods
	 */
	methods: {
		restoreDataObject(dataObject) {
			this.$emit('clickOnRestoreDataObject', dataObject);
		}
	},

	/**
	 * Created
	 */
	created () {},
};
</script>
