<template>
	<router-link tabindex="0" to="/" class="logo" aria-label="dataseer logo">
		<img src="@/assets/images/logo@2x.png" alt="dataseer">
	</router-link>
</template>

<script>
export default {
	name: 'Logo',
}
</script>
