import $ from 'jquery';
import _ from 'lodash';
import async from 'async';
export const DataObjectsList = function(id = 'dataObjectsList', events = {}) {
	let self = this;
	this.id = id;
	this.container = $(`#${this.id} #dataObjectsList\\.container\\.items\\.container`); // container of dataObjects
	this.scrollContainer = $('#documentView\\.screen\\.container'); // container with scroll
	this.message = $(`#${this.id} #dataObjectsList\\.container\\.items\\.message`);
	this.events = events;
	this.animationFinished = true;
	this.sentencesMapping = undefined;
	this.debouncedScroll = _.debounce(
		function(position) {
			self.scrollContainer.animate({ scrollLeft: position }, 200);
		},
		500,
		{ leading: true, trailing: true }
	);
	// on mouse scroll
	/* this.scrollContainer.get(0).addEventListener(
		'wheel',
		function(e) {
			if (self.animationFinished) {
				let element = $(this);
				self.animationFinished = false;
				let scroll = e.deltaY > 0 ? 250 : -250,
					position = Math.round(element.scrollLeft() + scroll);
				// element.scrollLeft(position);
				// self.animationFinished = true;
				element.animate({ scrollLeft: position }, 300, function() {
					self.animationFinished = true;
				});
			}
		},
		{ passive: true }
	); */
	// onNewDataObjectClick
	$(`#${this.id} #dataObjectsList\\.container\\.bulkActions\\.newDataObject`).click(function() {
		if (typeof self.events.onNewDataObjectClick === 'function')
			return self.events.onNewDataObjectClick();
	});
	// onMergeSelectionClick
	$(`#${this.id} #dataObjectsList\\.container\\.bulkActions\\.mergeSelection`).click(function() {
		if (typeof self.events.onMergeSelectionClick === 'function')
			return self.events.onMergeSelectionClick(self.getCheckedItems());
	});
	// onNewDataObjectClick
	$(`#${this.id} #dataObjectsList\\.container\\.bulkActions\\.deleteSelection`).click(function() {
		if (typeof self.events.onDeleteSelectionClick === 'function')
			return self.events.onDeleteSelectionClick(self.getCheckedItems());
	});
	this.setInitialazingMessage();
	return this;
};

// Reorder an array
DataObjectsList.prototype.getDataObjectsIds = function(id) {
	let dataObjects = this.container
		.find('.item[sentences]')
		.map(function(index, elem) {
			return {
				id: $(elem).attr('value'),
				status: $(elem)
					.find('[key="dataObject\\.status"]')
					.attr('value')
			};
		})
		.get();
	if (typeof id === 'string') {
		let index = dataObjects.reduce(
			function(acc, item) {
				if (!acc.continue) return acc;
				acc.continue = acc.id !== item._id;
				acc.index++;
				return acc;
			},
			{ continue: true, index: -1, id: id }
		).index;
		if (index > -1) dataObjects = dataObjects.slice(index + 1).concat(dataObjects.slice(0, index + 1));
	}
	return dataObjects;
};

// Get the first not "valid" dataObject (or undefined)
DataObjectsList.prototype.getFirstDataObjectIdNotValid = function(id) {
	let dataObjects = this.getDataObjectsIds(id);
	for (let i = 0; i < dataObjects.length; i++) {
		if (dataObjects[i].status !== 'valid') return dataObjects[i]._id;
	}
	return null;
};

// Get the first dataObject (or undefined)
DataObjectsList.prototype.getFirstDataObjectId = function() {
	return this.container.find('.item[sentences]:first-child').attr('value');
};

// Set mapping of sentences
DataObjectsList.prototype.setSentencesMapping = function(sentencesMapping) {
	this.sentencesMapping = sentencesMapping;
	this.sortItems();
};

// Get first sentence
DataObjectsList.prototype.getFirstSentenceOf = function(json) {
	return JSON.parse(json)[0];
};

// Refresh order of items
DataObjectsList.prototype.sortItems = function() {
	let self = this;
	this.container
		.find('.item[sentences]')
		.sort(function(a, b) {
			let aPos = self.sentencesMapping[self.getFirstSentenceOf($(a).attr('sentences')).id],
				bPos = self.sentencesMapping[self.getFirstSentenceOf($(b).attr('sentences')).id],
				aIsInteger = Number.isInteger(aPos),
				bIsInteger = Number.isInteger(bPos);
			if (aIsInteger && !bIsInteger) return -1;
			if (!aIsInteger && bIsInteger) return 1;
			if (!aIsInteger && !bIsInteger) return 0;
			return aPos - bPos;
		})
		.appendTo(this.container);
};

// Return icon for a given status
DataObjectsList.prototype.getIconOfStatus = function(status) {
	let i = $('<i>');
	if (status === 'valid')
		i.addClass('fas fa-check success-color-dark').attr('title', 'This dataObject is valid');
	else if (status === 'saved')
		i.addClass('far fa-save success-color-dark').attr('title', 'This dataObject is saved');
	else if (status === 'modified')
		i.addClass('fas fa-pen warning-color-dark').attr('title', 'This dataObject is modified');
	else if (status === 'loading')
		i.addClass('fas fa-spinner success-color-dark').attr('title', 'This dataObject is updating');
	else i.addClass('far fa-question-circle').attr('title', 'Unknow status');
	return i;
};

// Attach event
DataObjectsList.prototype.attach = function(event, fn) {
	this.events[event] = fn;
};

// Scroll to a given dataObject
DataObjectsList.prototype.scrollTo = function(id) {
	let element = this.container.find(`.item[key="dataObject._id"][value="${id}"]`),
		position = Math.round(
			element.position().left +
				this.scrollContainer.scrollLeft() -
				this.scrollContainer.width() / 2
		);
	this.debouncedScroll(position);
};

// Get checked ids
DataObjectsList.prototype.getCheckedItems = function() {
	return this.container
		.find(`.item.checked`)
		.get()
		.map(function(item) {
			return { id: $(item).attr('value'), checked: true };
		});
};

// Get checked ids
DataObjectsList.prototype.getSelectedItem = function() {
	return this.container
		.find(`.item.selected`)
		.get()
		.map(function(item) {
			let el = $(item);
			return { id: el.attr('value'), checked: el.hasClass('checked') };
		});
};

// Refresh Empty Message display
DataObjectsList.prototype.refreshMsg = function() {
	let items = this.container.children('.item');
	if (items.length > 0) {
		this.scrollContainer.css('overflow-x', 'scroll');
		this.message.hide();
	} else this.setEmptyMessage();
};

// Set Empty dataObjectsList Message
DataObjectsList.prototype.setEmptyMessage = function() {
	this.scrollContainer.css('overflow-x', 'auto');
	this.message.show();
	this.message.text('DataSeer has not detected any dataObjects in this document');
};

// Set Intializing dataObjectsList Message
DataObjectsList.prototype.setInitialazingMessage = function() {
	this.scrollContainer.css('overflow-x', 'auto');
	this.message.text('Populating dataObjects list...');
	this.message.show();
};

// Load some dataObjects
DataObjectsList.prototype.load = function(dataObjects = [], cb) {
	let self = this;
	return async.eachSeries(
		dataObjects,
		function(dataObject, callback) {
			self.add(dataObject);
			if (typeof self.events.onDataObjectLoaded === 'function')
				self.events.onDataObjectLoaded(dataObject);
			return callback();
		},
		function(err) {
			return cb(err);
		}
	);
};

// Unload some dataObjects
DataObjectsList.prototype.unload = function(dataObjects, cb) {
	let self = this;
	if (!dataObjects || !Array.isArray(dataObjects)) {
		this.container.empty();
		return cb();
	}
	return async.eachSeries(
		dataObjects,
		function(dataObject, callback) {
			self.delete(dataObject);
			if (typeof self.events.onDataObjectUnloaded === 'function')
				self.events.onDataObjectUnloaded(dataObject);
			return callback();
		},
		function(err) {
			return cb(err);
		}
	);
};

// Add a dataObject
DataObjectsList.prototype.add = function(dataObject) {
	if (
		!Array.isArray(dataObject.sentences) ||
		!dataObject.sentences.length ||
		typeof dataObject.sentences[0] === 'undefined'
	)
		return;
	let self = this,
		label = dataObject.name ? dataObject.name : dataObject._id,
		elements = {
			item: $('<div>')
				.addClass('item')
				.attr('key', 'dataObject._id')
				.attr('value', dataObject._id)
				.attr('sentences', JSON.stringify(dataObject.sentences))
				.attr('sentence', dataObject.sentences[0].id)
				.css('background-color', dataObject.color?.background.rgba || '')
				.css('border-color', dataObject.color?.background.rgba || ''),
			label: $('<div>')
				.attr('key', 'dataObject.label')
				.attr('value', label)
				.attr('title', 'Click here to work on this DataObject')
				.css('color', dataObject.color?.foreground || '')
				.append(
					$('<div>')
						.text(label)
						.addClass('noselect')
				),
			status: $('<div>')
				.attr('key', 'dataObject.status')
				.attr('value', dataObject.status)
				.append(this.getIconOfStatus(dataObject.status)),
			checked: $('<div>')
				.attr('key', 'dataObject.checked')
				.attr('value', false)
				.attr('title', 'Click here to add this DataObject to the selection')
				.append(
					$('<i>')
						.addClass('far fa-check-square')
						.attr('title', 'This DataObject is not in the selection')
				),
			link: $('<button>')
				.addClass('btn btn-primary btn-sm btn-lite')
				.attr('title', 'Link the selected sentence to this dataObject')
				.attr('name', 'dataObjectsList.link')
				.append($('<i>').addClass('fas fa-link')),
			delete: $('<button>')
				.addClass('btn btn-danger btn-sm btn-lite')
				.attr('title', 'Delete this dataObject')
				.attr('name', 'dataObjectsList.delete')
				.append($('<i>').addClass('far fa-trash-alt'))
		};
	if (dataObject.highlight) elements.item.addClass('highlight');
	elements.item
		.append(elements.label)
		.append(elements.checked)
		.append(elements.link)
		.append(elements.delete)
		.append(elements.status);
	this.container.append(elements.item);
	// sort elements by sentence Ids
	if (this.sentencesMapping) this.sortItems();
	this.refreshMsg();
	// events
	elements.item.click(function() {
		self.select(dataObject._id);
		self.scrollTo(dataObject._id);
		let elem = $(this);
		if (typeof self.events.onDataObjectClick === 'function') {
			let sentences = JSON.parse(elem.attr('sentences')),
				currentSentenceId = elem.attr('sentence'),
				nextSentence = sentences.reduce(function(acc, item) {
					if (acc === false) acc = item;
					if (item.id === currentSentenceId) acc = false;
					return acc;
				}, false),
				nextSentenceId = nextSentence === false ? sentences[0].id : nextSentence.id;
			elem.attr('sentence', nextSentenceId);
			return self.events.onDataObjectClick({
				id: dataObject._id,
				sentences: sentences,
				sentence: { id: currentSentenceId },
				checked: elem.hasClass('checked')
			});
		}
	});
	elements.link.click(function(event) {
		event.preventDefault();
		event.stopPropagation();
		let item = self.container.find(`.item[key="dataObject._id"][value="${dataObject._id}"]`),
			checked = item.children('[key="dataObject.checked"]'),
			isChecked = checked.attr('value') !== 'true';
		if (typeof self.events.onDataObjectLink === 'function')
			return self.events.onDataObjectLink({
				id: dataObject._id,
				sentences: dataObject.sentences,
				checked: isChecked
			});
	});
	elements.delete.click(function(event) {
		event.preventDefault();
		event.stopPropagation();
		let item = self.container.find(`.item[key="dataObject._id"][value="${dataObject._id}"]`),
			checked = item.children('[key="dataObject.checked"]'),
			isChecked = checked.attr('value') !== 'true';
		if (typeof self.events.onDataObjectDelete === 'function')
			return self.events.onDataObjectDelete({
				id: dataObject._id,
				sentences: dataObject.sentences,
				checked: isChecked
			});
	});
	elements.checked.click(function(event) {
		event.preventDefault();
		event.stopPropagation();
		let item = self.container.find(`.item[key="dataObject._id"][value="${dataObject._id}"]`),
			checked = item.children('[key="dataObject.checked"]'),
			i = checked.children('i'),
			isChecked = checked.attr('value') !== 'true';
		checked.attr('value', isChecked);
		if (isChecked) {
			i.attr('title', 'This DataObject is in the selection');
			checked.attr('title', 'Click here to remove this DataObject to the selection');
			item.addClass('checked');
		} else {
			i.attr('title', 'This DataObject is not in the selection');
			checked.attr('title', 'Click here to add this DataObject to the selection');
			item.removeClass('checked');
		}
		if (typeof self.events.onDataObjectCheck === 'function')
			return self.events.onDataObjectCheck({
				id: dataObject._id,
				sentences: dataObject.sentences,
				checked: isChecked
			});
	});
};

// Update a dataObject
DataObjectsList.prototype.update = function(id, dataObject) {
	let element = this.container.find(`.item[key="dataObject._id"][value="${id}"]`);
	if (!element.get().length) return false;
	let label = dataObject.name ? dataObject.name : dataObject._id;
	this.setStatus(dataObject._id, dataObject.status);
	element.attr('sentences', JSON.stringify(dataObject.sentences));
	element
		.find('div[key="dataObject.label"]')
		.attr('value', label)
		.find('div.noselect')
		.text(label);
};

// Delete a dataObject
DataObjectsList.prototype.delete = function(id) {
	this.container.find(`.item[key="dataObject._id"][value="${id}"]`).remove();
	this.refreshMsg();
};

// Check if a dataObject is selected
DataObjectsList.prototype.isSelected = function(id) {
	let dataObject = this.container.find(`.item[key="dataObject._id"][value="${id}"]`);
	if (!dataObject.get().length) return new Error('Invalid dataObject-id');
	return dataObject.hasClass('selected');
};

// Check if a dataObject is checked
DataObjectsList.prototype.isChecked = function(id) {
	let dataObject = this.container.find(`.item[key="dataObject._id"][value="${id}"]`);
	if (!dataObject.get().length) return new Error('Invalid dataObject-id');
	return dataObject.hasClass('checked');
};

// Select a dataObject
DataObjectsList.prototype.select = function(id) {
	let dataObject = this.container.find(`.item[key="dataObject._id"][value="${id}"]`);
	if (!dataObject.get().length) return false;
	this.container.find('.item.selected').removeClass('selected');
	dataObject.addClass('selected');
	this.scrollTo(id);
	return true;
};

// Unselect a dataObject
DataObjectsList.prototype.unselect = function(id) {
	if (!id) return this.container.find(`.item`).removeClass('selected');
	let dataObject = this.container.find(`.item[key="dataObject._id"][value="${id}"]`);
	if (!dataObject.get().length) return false;
	if (dataObject.hasClass('selected')) dataObject.removeClass('selected');
	return true;
};

// Select a dataObject
DataObjectsList.prototype.check = function(id) {
	let dataObject = this.container.find(`.item[key="dataObject._id"][value="${id}"]`);
	if (!dataObject.get().length) return false;
	if (dataObject.hasClass('checked')) dataObject.removeClass('checked');
	else {
		this.container.find('.item checked').removeClass('checked');
		dataObject.addClass('checked');
	}
	return true;
};

// Unselect a dataObject
DataObjectsList.prototype.uncheck = function(id) {
	let dataObject = this.container.find(`.item[key="dataObject._id"][value="${id}"]`);
	if (!dataObject.get().length) return false;
	if (dataObject.hasClass('checked')) dataObject.removeClass('checked');
	return true;
};

// Set status to a dataObject
DataObjectsList.prototype.setStatus = function(id, status) {
	return this.container
		.find(`.item[key="dataObject._id"][value="${id}"] div[key="dataObject.status"]`)
		.empty()
		.append(this.getIconOfStatus(status));
};

// Highlight a dataObject
DataObjectsList.prototype.highlight = function(id) {
	return this.container.find(`.item[key="dataObject._id"][value="${id}"]`).addClass('highlight');
};

// Unhighlight a dataObject
DataObjectsList.prototype.unhighlight = function(id) {
	return this.container.find(`.item[key="dataObject._id"][value="${id}"]`).removeClass('highlight');
};

// Color a dataObject
DataObjectsList.prototype.color = function(id, color) {
	return this.container
		.find(`.item[key="dataObject._id"][value="${id}"]`)
		.css('background-color', color.background)
		.css('color', color.foreground);
};

// Uncolor a dataObject
DataObjectsList.prototype.uncolor = function(id) {
	return this.container
		.find(`.item[key="dataObject._id"][value="${id}"]`)
		.css('background-color', '')
		.css('color', '');
};

// Set status "modified"
DataObjectsList.prototype.modified = function() {
	let id = this.container.find(`.item[key="dataObject._id"].selected`).attr('value');
	if (id) return this.setStatus(id, 'modified');
};

// Set status "loading"
DataObjectsList.prototype.loading = function(id) {
	if (id) return this.setStatus(id, 'loading');
};

// Merge some dataObjects (into the first)
DataObjectsList.prototype.merge = function() {};
