<template>
	<Main>
		<template #subheader>	
			<Subheader>
				<SubheaderEdit title="Edit Organization" icon="organization">
					<Button to="/organizations" className="tertiary">Exit Organization</Button>
				</SubheaderEdit>
			</Subheader>
		</template>
		<FormEditOrganization />
		<template #right>
			<div class="widget-associations" style="margin-bottom: 15px;">
				<h6>
					<strong>{{associatedAccountsCount}}</strong>

					Associated {{pluralizeAccount}}
				</h6>

				<Button
					v-if="associatedAccountsCount > 0"
					:to="`/accounts?organization=${organizationID}`"
					className="secondary"
				>
					<Icon name="user" color="currentColor" />

					View {{pluralizeAccount}}
				</Button>
			</div> <!-- /.widget-associations -->


			<div class="widget-associations">
				<h6>
					<strong>{{associatedDocumentsCount}}</strong>

					Associated {{pluralizeDocument}}
				</h6>

				<Button
					v-if="associatedDocumentsCount > 0"
					@onClick="handleGetChanges"
					:disabled="postGetChanges.loading"
					:class="postGetChanges.loading ? 'is-disabled' : ''"
					className="secondary"
				>
					<Icon name="document_import" color="currentColor" />

					CSV changes
					<Spinner
						v-if="postGetChanges.loading"
						size="medium"
					></Spinner>
				</Button>
			</div> <!-- /.widget-associations -->
		</template>
	</Main>
</template>

<script>
/**
 * Internal Dependencies
 */
import Main from '@/components/main/main';
import Icon from '@/components/icon/icon';
import Button from '@/components/button/button';
import Subheader from '@/components/subheader/subheader';
import SubheaderEdit from '@/components/subheader/subheader-edit';
import AccountsService from '@/services/account/accounts';
import DocumentsService from '@/services/documents/documents';
import FormEditOrganization from '@/blocks/form-edit-organization/form-edit-organization';
import Spinner from '@/components/spinner/spinner';

import API from '@/lib/datasets/api';

export default {
	/**
	 * Name
	 */
	name: 'EditOrganization',

	/**
	 * Components
	 */
	components: {
		Main,
		Icon,
		Button,
		Subheader,
		SubheaderEdit,
		FormEditOrganization,
		Spinner
	},

	/**
	 * Data
	 */
	data() {
		return {
			postGetChanges: {
				loading: false,
				error: false,
				errorMessage: "Something went wrong..."
			},
			organizationID: this.$route.params.id,
			associatedAccountsCount: null,
			associatedDocumentsCount: null,
			associatedDocumentsIds: [],
			associatedAccountsIds: []
		}
	},
	
	/**
	 * Computed
	 */
	computed: {
		pluralizeAccount() {
			return (this.associatedAccountsCount === 1) ? "Account" : "Accounts"
		},
		pluralizeDocument() {
			return (this.associatedDocumentsCount === 1) ? "Document" : "Documents"
		}
	},
	
	/**
	 * Methods
	 */
	methods: {
		async getAssociatedAccountsCount() {
			const params = {
				organizations: [this.organizationID]
			}
			
			const AssociatedAccounts = await AccountsService.getAccounts(params)

			this.associatedAccountsCount = AssociatedAccounts.length;
			this.associatedAccountsIds = AssociatedAccounts.map((item) => { return item._id.toString(); });
		},
		async getAssociatedDocumentsCount() {
			const params = {
				organizations: [this.organizationID],
				count: true,
			}

			const AssociatedDocuments = await DocumentsService.getDocuments(params)


			this.associatedDocumentsCount = AssociatedDocuments.count;

			const AllAssociatedDocuments = await DocumentsService.getDocuments({
				organizations: [this.organizationID],
				count: true,
				limit: this.associatedDocumentsCount
			});

			this.associatedDocumentsIds = AllAssociatedDocuments.err ? [] : AllAssociatedDocuments.data.map((item) => { return item._id.toString(); });
		},
		async handleGetChanges() {
			console.log('handleGetChanges')
			this.postGetChanges.loading = true;
			return API.documents.getDataObjectsChangesCSV({
				documents: this.associatedDocumentsIds,
				untrustedAccounts: this.associatedAccountsIds
			}, (err, res) => {
				console.log(err, res);
				this.postGetChanges.loading = false;
				this.downloadCSV("changes.csv", res);
			});
		},
		downloadCSV(filename, data) {
			let a = document.createElement("a");
			document.body.appendChild(a);
			a.style = "display: none";
			let blob = new Blob([data], {type: "text/csv"});
			let url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = filename;
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		}
	},

	/**
	 * Created
	 */
	created () {
		this.getAssociatedAccountsCount();
		this.getAssociatedDocumentsCount();
	}
};
</script>
