var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tabs",staticClass:"tabs",class:['are-expanded']},[_c('div',{staticClass:"tabs__links"},[_c('ul',{ref:"dataObjectsSortableList",attrs:{"id":"dataObjectsSortableList"}},_vm._l((_vm.sortedFilteredDataObjects),function(dataObject){return _c('li',{key:dataObject._id,staticClass:"tabs__link",class:{
					'is-active': dataObject._id === _vm.activeDataObjectId,
					'is-completed': dataObject.actionRequired === 'No',
					'is-new': !dataObject.reuse,
					'is-optionnal': dataObject.kind === 'dataset' && (dataObject.representativeImage || dataObject.qc),
					'has-suggested-properties-filled':
					dataObject.actionRequired !== 'No' &&
					(dataObject.kind === 'code' || dataObject.kind === 'software' || dataObject.kind === 'reagent') &&
					(dataObject.suggestedEntity !== '' || dataObject.suggestedURL !== '' || dataObject.suggestedRRID !== ''),
					'has-issue': ( dataObject.flagged === true || dataObject.flagged === 'true')
				},attrs:{"dataObjectId":dataObject._id}},[(_vm.mergeState || _vm.deleteState)?_c('div',{staticClass:"checkbox checkbox--default"},[_c('input',{ref:"checkbox",refInFor:true,staticClass:"sr-only",attrs:{"tabindex":"0","type":"checkbox","name":("merge-" + (dataObject._id)),"id":("merge-" + (dataObject._id))},domProps:{"checked":_vm.dataObjectsForAction.some(function (entry) { return entry === dataObject._id; })},on:{"change":function (e) { return _vm.handleCheckboxChange(e, dataObject._id); }}}),_c('label',{attrs:{"for":("merge-" + (dataObject._id))}})]):_vm._e(),_c('button',{attrs:{"title":dataObject.name ? dataObject.name : dataObject._id,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.handleLinkButtonClick(dataObject)}}},[(( dataObject.flagged === true || dataObject.flagged === 'true' ))?_c('Dot'):_vm._e(),_c('span',[(dataObject.actionRequired === 'No')?_c('Icon',{attrs:{"name":"check","color":"currentColor"}}):_vm._e()],1),_c('p',{staticClass:"overflow-truncate"},[_vm._v(_vm._s(dataObject.name ? dataObject.name : dataObject._id))])],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }