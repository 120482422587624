<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 33 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.5 0C7.38733 0 0 7.38733 0 16.5C0 25.6127 7.38733 33 16.5 33C25.6082 32.9893 32.9893 25.6082 33 16.5C33 7.38733 25.6127 0 16.5 0ZM12.4588 13.0308C13.0295 10.8269 15.2787 9.50301 17.4826 10.0737C19.6864 10.6444 21.0104 12.8936 20.4397 15.0974C20.0909 16.4443 19.0863 17.5244 17.7681 17.9695C17.4442 18.1153 17.2446 18.4465 17.267 18.801V19.8371C17.2673 20.2925 16.8983 20.6618 16.4429 20.6621H16.442C15.9866 20.6624 15.6172 20.2934 15.617 19.838V18.801C15.5897 17.7388 16.2404 16.7766 17.2364 16.4065C18.5329 15.9744 19.2335 14.5731 18.8013 13.2765C18.3691 11.9801 16.9678 11.2794 15.6713 11.7116C14.8742 11.9774 14.2663 12.6295 14.0572 13.4433C13.9433 13.8847 13.4932 14.1502 13.0518 14.0363C12.6104 13.9224 12.3449 13.4722 12.4588 13.0308ZM15.4104 23.137C15.4104 22.5675 15.8721 22.1057 16.4417 22.1057C17.0112 22.1057 17.4729 22.5675 17.4729 23.137C17.4729 23.7065 17.0112 24.1682 16.4417 24.1682C15.8721 24.1682 15.4104 23.7065 15.4104 23.137ZM1.65 16.5C1.65 24.7015 8.29854 31.35 16.5 31.35C24.7015 31.35 31.35 24.7015 31.35 16.5C31.341 8.30227 24.6977 1.65896 16.5 1.65C8.29854 1.65 1.65 8.29854 1.65 16.5Z"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'IconQuestion',

	/**
	 * props
	 */
	props: {
		color: {
			default: 'currentColor'
		},
		width: {
			default: 33
		},
		height: {
			default: 33
		}
	}
};
</script>
