<template>
	<svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-968.000000, -191.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(965.000000, 191.000000)">
					<g transform="translate(3.000000, 0.000000)">
						<path
							d="M11.5,19 L3,19 C1.8959351,18.9987183 1.0012817,18.1040649 1,17 L1,3 C1.0012817,1.8959351 1.8959351,1.0012817 3,1 L9,1 L9,4.6669922 C9.0016479,5.9547729 10.0452271,6.9983521 11.3330078,7 L15,7 L15,11.5 C15,11.776123 15.223877,12 15.5,12 C15.776123,12 16,11.776123 16,11.5 L16,6.5 C16,6.3673706 15.9473267,6.2402344 15.8535156,6.1464844 L9.8535156,0.1464844 C9.7597656,0.0526733 9.6326294,0 9.5,0 L3,0 C1.3438721,0.0018311 0.0018311,1.3438721 0,3 L0,17 C0.0018311,18.6561279 1.3438721,19.9981689 3,20 L11.5,20 C11.776123,20 12,19.776123 12,19.5 C12,19.223877 11.776123,19 11.5,19 Z M10,1.7069702 L14.2930298,6 L11.3330078,6 C10.5970459,5.9993896 10.0006104,5.4029541 10,4.6669922 L10,1.7069702 Z M17.8535156,16.1464844 L15.8535156,14.1464844 C15.8534546,14.1464234 15.8534546,14.1464234 15.8533935,14.1463623 C15.658142,13.9511719 15.3416747,13.9512329 15.1465454,14.1464844 L13.1465454,16.1464844 C12.9593506,16.340271 12.9593506,16.647461 13.1465454,16.8412476 C13.3383789,17.039856 13.6549072,17.0453492 13.8535156,16.8535157 L15,15.7069702 L15,19.5005493 C15.0001831,19.7765503 15.223999,20.0001831 15.5,20.0000076 L15.5006104,20.0000076 C15.7765503,19.9998169 16.0001831,19.776001 16,19.5000076 L16,15.7069702 L17.1464844,16.8535156 C17.2401123,16.9474487 17.3673706,17.0001831 17.5,17.0000076 C17.6325684,17.0000076 17.7597046,16.9473267 17.8534546,16.8535767 C18.0487061,16.6583862 18.0487671,16.3417969 17.8535156,16.1464844 Z M9.5,15 C9.776123,15 10,14.776123 10,14.5 C10,14.223877 9.776123,14 9.5,14 L4.5,14 C4.223877,14 4,14.223877 4,14.5 C4,14.776123 4.223877,15 4.5,15 L9.5,15 Z M4.5,6 C4.223877,6 4,6.223877 4,6.5 C4,6.776123 4.223877,7 4.5,7 L6.5,7 C6.776123,7 7,6.776123 7,6.5 C7,6.223877 6.776123,6 6.5,6 L4.5,6 Z M11.5,10 L4.5,10 C4.223877,10 4,10.223877 4,10.5 C4,10.776123 4.223877,11 4.5,11 L11.5,11 C11.776123,11 12,10.776123 12,10.5 C12,10.223877 11.776123,10 11.5,10 Z"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'DocumentUpload',

	/**
	 * props
	 */
	props: ['color'],
};
</script>
