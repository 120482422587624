<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 13.105 9.297"
	>
		<path
			d="M0,3.49,3.394,6.883,10.277,0"
			transform="translate(1.414 1.414)"
			fill="none"
			:stroke="color"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-miterlimit="10"
			stroke-width="2"
		/>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconCheck',

	/**
	 * props
	 */
	props: {
		color: {
			default: 'currentColor'
		},
		width: {
			default: 13.105
		},
		height: {
			default: 9.297
		}
	}
};
</script>
