<template>
	<div
		class="list list-rrids"
		:class="{
			'is-readonly': readonly,
			'list--sm': this.size === 'small',
			'list--md': this.size === 'medium',
			'list--lg': this.size === 'large'
		}"
	>
		<label
			v-if="this.$slots.default"
			:for="toKebabCase(name)"
			class="list__label"
			:class="{ 'sr-only': hideLabel }"
		>
			<slot />
		</label>

		<span v-if="error !== ''" class="list__error">{{ error }}</span>
		<div v-if="Array.isArray(sortedResults)" class="list__RRIDs">
			<p v-if="sortedResults.length < 1 && error === ''" class="list__RRIDsMsg">No result found</p>
			<p v-if="sortedResults.length > 0 && error === ''" class="list__RRIDsMsg">Results for "{{ entity.name }}" in :</p>
			<p v-if="sortedResults.length < 1 && error === ''" class="list__RRIDsMsg">No result found</p>
			<ul class="list__RRIDsLists">
				<li v-for="list in sortedResults" :key="list.text" class="list__RRIDsList">
					<a v-if="list.href !== ''" :href="list.href" title="Source of displayed data available here">{{ list.text }}:</a>
					<span v-else>{{ list.text }}:</span>
					<span v-if="list.error !== ''" class="list__RRIDsListMsg"> {{ list.error }}</span>
					<span v-if="!list.error && list.entities.length < 1" class="list__RRIDsListMsg"> No result found</span>
					<ul v-if="!list.error && list.entities.length > 0" class="list__RRIDsListItems">
						<li v-for="item, i in list.entities" :key="list.text+i" class="list__RRIDsListItem">
							<a title="Automtically fill 'Suggested' fields" @click.prevent="automticallyFillFields(item, list.requestAPI)">
								<span class="name">{{ item.suggestedEntity ? item.suggestedEntity : item.name }}</span>
								<span class="identifier">{{ item.suggestedRRID ? item.suggestedRRID : `(RRID: ${item.RRID})` }}</span>
								↗
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div> <!-- /.list -->
</template>

<script>
/**
 * Internal Dependencies
 */
import toKebabCase from '@/utils/str-to-kebab-case';

export default {
	/**
	 * Name
	 */
	name: 'ListOfRRIDs',

	/**
	 * Components
	 */
	components: {},

	/**
	 * Props
	 */
	props: {
		entity: {
			type: Object,
			default: () => {}
		},
		results: {
			type: Array,
			default: () => []
		},
		error: {
			type: String,
			default: ''
		},
		size: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: ''
		},
		readonly: {
			type: Boolean,
			default: false
		},
		hideLabel: {
			type: Boolean,
			default: false
		}
	},

	/**
	 * Computed
	 */
	computed: {
		sortedResults() {
			return [].concat(this.results).sort((a, b) => {
				return a.index - b.index;
			});
		}
	},

	/**
	 * Methods
	 */
	methods: {
		automticallyFillFields(data, requestAPI) {
			this.$emit('clickOnRRID', { data, requestAPI });
		},
		toKebabCase
	}
};
</script>
