<template>
	<Form v-if="activeSentence" className="form--dataset form--connect-text">
		<FormHead>
			<h5 class="overflow-truncate">{{activeSentence.text}}</h5>
		</FormHead>

		<FormEntry v-if="textReferences.length === 0 && userRoleWeight >= 10">
			<p>The selected text does not currently have any associated {{ activeDataObjectTypeTitleCase }}s. You can add a new {{ activeDataObjectTypeTitleCase }} or Connect this text to an existing {{ activeDataObjectTypeTitleCase }}.</p>
		</FormEntry>

		<FormEntry v-if="textReferences.length === 0 && userRoleWeight < 10">
			<p>The selected text does not currently have any associated {{ activeDataObjectTypeTitleCase }}s.</p>
		</FormEntry>

		<FormEntry v-if="textReferences.length > 0">
			<p class="dataset-references">
				<label>{{ otherReferencesText }}</label>
				<Dropdown ref="textReferencesDropdown" placement="bottom-end">
					<template #header>
						<span class="dropdown__arrow" />
					</template>

					<DropdownNavDatasets
						:dataObjects="textReferences"
						@dropdownButtonClick="handleDropdownButtonClick"
					/>
				</Dropdown>
			</p><!-- /.datasets-references -->
		</FormEntry>

		<FormBody v-if="userRoleWeight >= 10">
			<Button @onClick.prevent="addSentenceToDataObject">
				<Icon name="add" />

				Add New {{ activeDataObjectTypeTitleCase }}
			</Button>

			<FormDivider title="or" />
			
			<FieldSelect
				v-model.trim="selectedDataObject"
				name="dataObject"
				:placeholder="'Select an Existing ' + activeDataObjectTypeTitleCase"
				:options="dataObjectsOptions"
			/>
		</FormBody>
		
		<FormActions v-if="userRoleWeight >= 10">
			<li>
				<Button
					@onClick.prevent="() => linkSentenceToDataObject(selectedDataObject)"
					:disabled="!selectedDataObject.length"
				>
					<Icon name="connect" />
					
					Connect to {{ activeDataObjectTypeTitleCase }}
				</Button>
			</li>
		</FormActions>
	</Form>
</template>

<script>
	/* eslint-disable */
	/**
	 * External Dependencies
	 */
	import { mapGetters, mapActions } from 'vuex'
	
	/**
	 * Internal Dependencies
	 */
	import Grid, { GridColumn } from '@/components/grid/grid';
	import Form, { FormHead, FormBody, FormGroup, FormDivider, FormEntry, FormActions } from '@/components/form/form';
	import Button from '@/components/button/button';
	import Icon from '@/components/icon/icon';
	import FieldSelect from '@/components/field-select/field-select';
	import Dropdown, { DropdownNavDatasets } from '@/components/dropdown/dropdown';
	import { getDataObjectType } from '@/utils/use-dataObjects';
	import { clearDropdown } from '@/utils/use-dropdowns';
	
	export default {
		/**
		 * Name
		 */
		name: 'FormConnectText',

		/**
		 * Components
		 */
		components: {
			Grid,
			GridColumn,
			Icon,
			Button,
			Dropdown,
			DropdownNavDatasets,
			Form,
			FormBody,
			FormHead,
			FormGroup,
			FormEntry,
			FormActions,
			FormDivider,
			FieldSelect,
		},

		/**
		 * Data
		 */
		data() {
			return {
				selectedDataObject: '',
				otherReferencesText: 'There are other references related to this text selection',
			}
		},

		/**
		 * Computed
		 */
		computed: {
			...mapGetters('pdfViewer', ['dataObjects', 'activeSentence', 'activeDataObjectType']),
			...mapGetters('account', ['user', 'userRoleWeight']),
			activeDataObjectTypeTitleCase() {
				return this.activeDataObjectType[0].toUpperCase() + this.activeDataObjectType.slice(1);
			},
			dataObjectsOptions() {
				return this.dataObjects.filter(item => { return getDataObjectType(item) === this.activeDataObjectType; }).map(dataObject => ({
					value: dataObject._id,
					label: dataObject.name ? dataObject.name  : dataObject._id
				}))
			},
			textReferences() {
				return this.dataObjects.filter((dataObject) => dataObject.sentences.some((sentence) => sentence.id === this.activeSentence.id));
			},
		},

		/**
		 * Methods
		 */
		methods: {
			...mapActions('pdfViewer', [
				'addSentenceToDataObject',
				'setActiveDataObject',
				'linkSentenceToDataObject'
			]),
			handleDropdownButtonClick(dataObject) {
				if (dataObject._id === this.activeDataObjectId) return

				// Close text references dropdown when the active dataObject changes
				clearDropdown(this.$refs.textReferencesDropdown.$el);
				
				this.setActiveDataObject({
					dataObject,
					scrollToSentence: true
				});
			},
		}
	}
</script>
