<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 11L11.4995 11C11.2234 11.0002 10.9998 11.224 11 11.5L11 19.5005C11.0002 19.7766 11.224 20.0002 11.5 20L19.5006 20C19.7766 19.9998 20.0002 19.776 20 19.5L20 11.4994C19.9998 11.2234 19.776 10.9998 19.5 11ZM19 19L12 19L12 12L19 12L19 19ZM8.5 2.24572e-07L0.49939 2.24572e-07C0.22345 0.000183325 -0.000182875 0.223999 2.24572e-07 0.5L2.24572e-07 8.50055C0.000183325 8.77655 0.223999 9.00018 0.5 9L8.50061 9C8.77655 8.99982 9.00018 8.776 9 8.5L9 0.49939C8.99982 0.22345 8.776 -0.000182875 8.5 2.24572e-07ZM8 8L1 8L1 1L8 1L8 8ZM8.5 11L0.49939 11C0.22345 11.0002 -0.000182875 11.224 2.24572e-07 11.5L2.24572e-07 19.5005C0.000183325 19.7766 0.223999 20.0002 0.5 20L8.50061 20C8.77655 19.9998 9.00018 19.776 9 19.5L9 11.4994C8.99982 11.2234 8.776 10.9998 8.5 11ZM8 19L1 19L1 12L8 12L8 19ZM19.5 2.24572e-07L11.4995 2.24572e-07C11.2234 0.000183325 10.9998 0.223999 11 0.5L11 8.50055C11.0002 8.77655 11.224 9.00018 11.5 9L19.5006 9C19.7766 8.99982 20.0002 8.776 20 8.5L20 0.49939C19.9998 0.22345 19.776 -0.000182875 19.5 2.24572e-07ZM19 8L12 8L12 1L19 1L19 8Z"  :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconViewGrid',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
