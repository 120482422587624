<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 18 18"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="none" fill-rule="evenodd">
			<g transform="translate(-541 -855)" :fill="color" fill-rule="nonzero">
				<g transform="translate(533 846)">
					<g transform="translate(8 9)">
						<path
							d="m15 2h-4.5c-0.27612 0-0.5 0.22388-0.5 0.5s0.22388 0.5 0.5 0.5h4.5c1.104 0.0014038 1.9986 0.896 2 2v11.358l-2.4111-2.2256c-0.09198-0.085693-0.21313-0.13318-0.33887-0.13281h-9.25c-1.104-0.0014038-1.9986-0.896-2-2v-1.5c0-0.27612-0.22388-0.5-0.5-0.5s-0.5 0.22388-0.5 0.5v1.5c0.0018311 1.6561 1.3439 2.9982 3 3h9.0547l3.1064 2.8672c0.092224 0.085327 0.2132 0.13281 0.33887 0.13281 0.27606-2.441e-4 0.49976-0.22394 0.5-0.5v-12.5c-0.0018311-1.6561-1.3439-2.9982-3-3zm-9.9199 6h2.4199 6.0277e-4c0.27594-1.9073e-4 0.49957-0.22401 0.4994-0.5v-2.4199c0-0.13263-0.052681-0.25977-0.14649-0.35352l-4.5801-4.5801c-6.1e-5 -6.1e-5 -1.221e-4 -1.221e-4 -1.831e-4 -1.221e-4 -0.19525-0.19519-0.51172-0.19513-0.70685 1.221e-4l-2.4199 2.42c-6.1e-5 0-1.221e-4 6.1e-5 -1.221e-4 1.221e-4 -0.19519 0.19525-0.19513 0.51172 1.221e-4 0.70685l4.5801 4.5801c0.09375 0.093811 0.22089 0.14649 0.35352 0.14649zm-2.1602-6.793 4.0801 4.0801v1.7129h-1.7129l-4.0801-4.0801 1.713-1.713z"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconSciscore',

	/**
	 * props
	 */
	props: {
		color: {
			default: 'currentColor'
		},
		width: {
			default: 18
		},
		height: {
			default: 18
		}
	}
};
</script>
