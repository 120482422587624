<template>
  <svg width="16px" height="20px" viewBox="0 0 16 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.00001 10.0062C5.00001 10.2823 5.22388 10.5062 5.50001 10.5062L14.293 10.5062L11.6466 13.1527C11.4594 13.3464 11.4594 13.6536 11.6466 13.8474C11.8384 14.046 12.1549 14.0515 12.3535 13.8596L15.8535 10.3596C15.8536 10.3596 15.8536 10.3596 15.8536 10.3595C16.0488 10.1643 16.0488 9.8478 15.8535 9.65267L12.3535 6.15267C12.2599 6.05874 12.1326 6.006 12 6.00619C11.8674 6.00619 11.7403 6.05886 11.6466 6.15261C11.4513 6.34786 11.4513 6.66445 11.6465 6.8597L14.293 9.50619L5.50001 9.50619C5.22388 9.50619 5.00001 9.73007 5.00001 10.0062ZM13.5 0L2.5 0C1.11981 0.0012817 0.0012817 1.11981 0 2.5L0 17.5C0.0012817 18.8802 1.11981 19.9987 2.5 20L13.5 20C14.8802 19.9987 15.9987 18.8802 16 17.5L16 13.5C16 13.2239 15.7761 13 15.5 13C15.2239 13 15 13.2239 15 13.5L15 17.5C14.9991 18.3281 14.3281 18.9991 13.5 19L2.5 19C1.67194 18.9991 1.00092 18.3281 1 17.5L1 2.5C1.00092 1.67194 1.67194 1.00092 2.5 1L13.5 1C14.3281 1.00092 14.9991 1.67194 15 2.5L15 6.5C15 6.77612 15.2239 7 15.5 7C15.7761 7 16 6.77612 16 6.5L16 2.5C15.9987 1.11981 14.8802 0.0012817 13.5 0Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconSignout',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
