<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5202 19.9937 19.9937 15.5202 20 10C20 4.47717 15.5228 0 10 0ZM10 19C7.60071 19 5.42639 18.0552 3.81299 16.5248C4.46436 14.4861 6.07373 12.8932 8.12781 12.2755C11.5301 11.2524 15.1115 13.1526 16.1881 16.5238C14.5746 18.0547 12.3998 19 10 19ZM7 8C7 6.34314 8.34314 5 10 5C11.6561 5.00183 12.9982 6.34387 13 8C13 9.65686 11.6569 11 10 11C8.34314 11 7 9.65686 7 8ZM16.9521 15.7151C16.1132 13.622 14.379 12.0023 12.2025 11.3335C13.2834 10.6174 13.9985 9.39349 14 8C14 5.79083 12.2092 4 10 4C7.79083 4 6 5.79083 6 8C6 9.39252 6.71313 10.6162 7.79248 11.3326C5.68726 11.9832 3.90643 13.55 3.04279 15.7083C1.76678 14.1548 1 12.1672 1 10C1 5.02942 5.02942 1 10 1C14.9683 1.00562 18.9944 5.03174 19 10C19 12.1702 18.2314 14.1607 16.9521 15.7151Z" :fill="color" stroke="none" />
  </svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconUser',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
