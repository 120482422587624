<template>
	<svg
		width="20px"
		height="20px"
		viewBox="0 0 20 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-1180.000000, -71.000000)" :fill="color" fill-rule="nonzero">
				<g transform="translate(1170.000000, 60.000000)">
					<g transform="translate(10.000000, 11.000000)">
						<path
							d="M3.5,4.550415 L3.5,1 C3.5,0.723877 3.276123,0.5 3,0.5 C2.723877,0.5 2.5,0.723877 2.5,1 L2.5,4.550415 C1.3588867,4.7821045 0.5,5.7905273 0.5,7 C0.5,8.2094727 1.3588867,9.2178955 2.5,9.449585 L2.5,19.0005494 C2.5001831,19.2765503 2.723999,19.5 3,19.5 L3.0006104,19.5 C3.2765503,19.5 3.5001831,19.276001 3.5,19 L3.5,9.4491577 C4.6405029,9.2167358 5.4989014,8.2089233 5.5,7 C5.5,5.7905273 4.6411133,4.7821045 3.5,4.550415 Z M3,8.5 C2.1715698,8.5 1.5,7.8284302 1.5,7 C1.5,6.1715698 2.1715698,5.5 3,5.5 C3.828064,5.5009155 4.4990845,6.171936 4.5,7 C4.5,7.8284302 3.8284302,8.5 3,8.5 Z M10.5,12.550415 L10.5,1 C10.5,0.723877 10.276123,0.5 10,0.5 C9.723877,0.5 9.5,0.723877 9.5,1 L9.5,12.550415 C8.3588867,12.7821045 7.5,13.7905273 7.5,15 C7.5,16.2094727 8.3588867,17.2178955 9.5,17.449585 L9.5,19.0005494 C9.5001831,19.2765503 9.723999,19.5 10,19.5 L10.0006104,19.5 C10.2765503,19.5 10.5001831,19.276001 10.5,19 L10.5,17.4491577 C11.6405029,17.2167358 12.4989014,16.2089233 12.5,15 C12.5,13.7905273 11.6411133,12.7821045 10.5,12.550415 Z M10,16.5 C9.1715698,16.5 8.5,15.8284302 8.5,15 C8.5,14.1715698 9.1715698,13.5 10,13.5 C10.828064,13.5009155 11.4990845,14.171936 11.5,15 C11.5,15.8284302 10.8284302,16.5 10,16.5 Z M17.5,6.550415 L17.5,1 C17.5,0.723877 17.276123,0.5 17,0.5 C16.723877,0.5 16.5,0.723877 16.5,1 L16.5,6.550415 C15.3588867,6.7821045 14.5,7.7905273 14.5,9 C14.5,10.2094727 15.3588867,11.2178955 16.5,11.449585 L16.5,19.0005494 C16.5001831,19.2765503 16.723999,19.5 17,19.5 L17.0006104,19.5 C17.2765503,19.5 17.5001831,19.276001 17.5,19 L17.5,11.4491577 C18.6405029,11.2167358 19.4989014,10.2089233 19.5,9 C19.5,7.7905273 18.6411133,6.7821045 17.5,6.550415 Z M17,10.5 C16.1715698,10.5 15.5,9.8284302 15.5,9 C15.5,8.1715698 16.1715698,7.5 17,7.5 C17.828064,7.5009155 18.4990845,8.171936 18.5,9 C18.5,9.8284302 17.8284302,10.5 17,10.5 Z"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	/**
	 * name
	 */
	name: 'iconSettings',

	/**
	 * props
	 */
	props: ['color'],
};
</script>
