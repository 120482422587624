<template>
 <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 4.48594e-07L0.49939 4.48594e-07C0.22345 0.000183549 -0.000182988 0.223999 1.12355e-07 0.5L1.12355e-07 18.5005C0.000183212 18.7766 0.223999 19.0002 0.5 19L18.5006 19C18.7766 18.9998 19.0002 18.776 19 18.5L19 0.49939C18.9998 0.22345 18.276 -0.000182651 18 4.48594e-07ZM6 18L1 18L1 13L6 13L6 18ZM6 12L1 12L1 7L6 7L6 12ZM6 6L1 6L1 1L6 1L6 6ZM12 18L7 18L7 13L12 13L12 18ZM12 12L7 12L7 7L12 7L12 12ZM12 6L7 6L7 1L12 1L12 6ZM18 18L13 18L13 13L18 13L18 18ZM18 12L13 12L13 7L18 7L18 12ZM18 6L13 6L13 1L18 1L18 6Z" :fill="color" stroke="none" />
</svg>
</template>

<script>
	export default {
	/**
	 * name
	*/
	name: 'iconGrid',

	/**
	 * props
	*/
	props: ['color']
	}
</script>
