<template>
	<Grid rowGap="medium">
		<GridColumn>
			<FieldSelect
				name="dataType"
				:error="$v.type.$error"
				:options="dataTypesOptions"
				:value="formData.dataType"
				@input="handleDataTypeChange"
				placeholder="Tabular Data"
				:disabled="readOnly"
			>
				<Icon name="grid" />

				Data Type <a href="#" target="_blank">Type Definitions</a>
			</FieldSelect>
		</GridColumn>

		<GridColumn>
			<FieldSelect
				:error="$v.subtype.$error"
				:options="subTypeOptions"
				name="subType"
				v-model.trim="formData.subType"
				placeholder="Select"
				:disabled="readOnly"
			>
				<Icon name="grid" />

				Data Subtype
			</FieldSelect>
		</GridColumn>

		<GridColumn>
			<Checkboxes>
				<FieldCheckbox
					name="reuse"
					v-model="formData.reuse"
					isDropdown
					:disabled="readOnly"
				>
					This protocol is re-used from a public or private source
				</FieldCheckbox>
			</Checkboxes>
		</GridColumn>

		<GridColumn>
			<Field
				name="DOI"
				v-model.trim="formData.DOI"
				placeholder="https://"
				:disabled="readOnly"
			>
				<Icon name="chain" color="currentColor" />

				DOI or appropriate citation
			</Field>
		</GridColumn>

		<GridColumn>
			<Field
				name="URL"
				v-model.trim="formData.URL"
				placeholder="https://"
				:disabled="readOnly"
			>
				<Icon name="chain" color="currentColor" />

				Stable URL or other link to this object
			</Field>
		</GridColumn>
		
		<GridColumn>
			<Field
				name="comments"
				v-model="formData.comments"
				:disabled="readOnly"
			>
				<Icon name="comment" />

				Additional Comments or Instructions
			</Field>
		</GridColumn>

		<GridColumn v-if="userRoleWeight >= 1000 && formData.reuse">
			<Field
				name="associatedReference"
				v-model="formData.associatedReference"
				:disabled="readOnly"
			>
				<Icon name="document" />

				Associated Reference
			</Field>
		</GridColumn>

		<GridColumn v-if="userRoleWeight >= 1000">
			<Field
				name="headSentence"
				v-model="formData.headSentence"
				:disabled="readOnly"
			>
				<Icon name="document" />

				Associated Head Sentence
			</Field>
		</GridColumn>
	</Grid>
</template>

<script>
/**
 * External Dependencies
 */
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'

/**
 * Internal Dependencies
 */
import Icon from '@/components/icon/icon';
import Field from '@/components/field/field';
import Checkboxes from '@/components/checkboxes/checkboxes';
import FieldSelect from '@/components/field-select/field-select';
import FieldCheckbox from '@/components/field-checkbox/field-checkbox';
import Grid, { GridColumn } from '@/components/grid/grid';

export default {
	/**
	 * Name
	 */
	name: 'formDatasetsProtocols',

	/**
	 * Components
	 */
	components: {
		Grid,
		Icon,
		Field,
		GridColumn,
		Checkboxes,
		FieldCheckbox,
		FieldSelect
	},

	/**
	 * Props
	 */
	props: {
		formData: {
			type: Object,
			default: () => {}
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', ['userRoleWeight', ]),
		...mapGetters('pdfViewer', ['dataTypes']),
		dataTypesOptions() {		
			return Object.keys(this.dataTypes.dataTypes).map(key => ({
					value: key,
					label: key,
				}));
		},
		subTypeOptions() {
			const subTypes = this.dataTypes.dataTypes[this.formData.dataType];

			return subTypes.map(type => ({
					value: type,
					label: type,
				})).concat([{
					value: '',
					label: 'None',
				}]);
		}
	},

	/**
	 * Validations
	 */
	validations: {
		type: {
			required
		},
		subtype: {
			required
		},
		permalink: {
			required
		}
	},

	/**
	 * Methods
	 */
	methods: {
		// Reset the sub type value when the main datatype changes
		handleDataTypeChange(value) {
			this.formData.dataType = value;
			this.formData.subType = '';
		}
	}
}
</script>

