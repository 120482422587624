const DATATYPE_COLORS = {
	code: {
		background: {
			border: 'rgb(114, 93, 189)',
			rgb: 'rgb(232,215,255)'
		},
		foreground: 'black'
	},
	material: {
		background: {
			border: 'rgb(210, 112, 68)',
			rgb: 'rgb(244,219,208)'
		},
		foreground: 'black'
	},
	protocol: {
		background: {
			border: 'rgb(64, 164, 105)',
			rgb: 'rgb(206,244,222)'
		},
		foreground: 'black'
	},
	dataset: {
		background: {
			border: 'rgb(0, 106, 201)',
			rgb: 'rgb(243,252,255)'
		},
		foreground: 'black'
	}
};

const formatDataObject = dataObject => {
	const dataObjectType = getDataObjectType(dataObject);

	Object.assign(dataObject, {
		dataObjectType: dataObjectType,
		color: DATATYPE_COLORS[dataObjectType]
	});

	return dataObject;
};

const sortDataObjects = function(mapping) {
	return function(a, b) {
		let aPos = isNaN(a.index) ? getFirstSentenceIndexOf(a.sentences, mapping) : a.index;
		let bPos = isNaN(b.index) ? getFirstSentenceIndexOf(b.sentences, mapping) : b.index;
		let aIsInteger = !isNaN(aPos);
		let bIsInteger = !isNaN(bPos);
		if (aIsInteger && !bIsInteger) return -1;
		if (!aIsInteger && bIsInteger) return 1;
		if (!aIsInteger && !bIsInteger) return 0;
		return aPos - bPos;
	}
}

const getFirstSentenceIndexOf = function(sentences = [], mapping = {}) {
	let tmp = [];
	for (let i = 0; i < sentences.length; i++) {
		let id = sentences[i]?.id;
		if (typeof id === "string") {
			let index = mapping[id];
			if (Number.isInteger(index)) tmp.push(index);
		}
	}
	if (tmp.length <= 0) return;
	return Math.min(...tmp); 
};

const getDataObjectType = dataObject => {
	const isMaterial = () =>
		dataObject.kind === 'reagent' ;

	const isCode = () =>
		dataObject.kind === 'code' || dataObject.kind === 'software' ;

	const isProtocol = () =>
		dataObject.kind === 'protocol' ;

	const isDataset = () =>
		dataObject.kind === 'dataset' ;

	if (isMaterial()) return 'material';
	if (isCode()) return 'code';
	if (isProtocol()) return 'protocol';
	if (isDataset()) return 'dataset';

	return dataObject.kind;
};

const setDataObjectDataType = (dataObject, dataObjectType) => {
	switch (dataObjectType) {
		case 'code':
			return {
				...dataObject,
				dataType: 'code software',
				subType: ''
			};
		case 'material':
			return {
				...dataObject,
				dataType: 'lab materials',
				subType: ''
			};
		case 'protocol':
			return {
				...dataObject,
				dataType: 'protocol',
				subType: ''
			};
		default:
			return dataObject;
	}
};

export { DATATYPE_COLORS, formatDataObject, setDataObjectDataType, getDataObjectType, getFirstSentenceIndexOf, sortDataObjects };
